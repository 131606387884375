<script setup lang="ts">
// define props with author content date time
import TextEditor from '@/views/courses/TextEditor.vue';
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import ProgressButton from '@/components/ProgressButton.vue';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const textEditorHandle = ref(null);
const emit = defineEmits(['editIsOn', 'saveNote']);

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  index: Number,
  author: Object,
  date: String,
  time: String,
  date_edited: String,
  time_edited: String,
  isEditing: {
    type: Boolean,
    default: false,
  },
  isSaved: {
    type: Boolean,
    default: false,
  },
});

const isEditing = ref(false);
const isSaved = ref(props.isSaved);
const dateEdited = ref(props.date_edited);
const timeEdited = ref(props.time_edited);

onBeforeMount(() => {
  isEditing.value = props.isEditing;
});

watch(
  () => props.isEditing,
  (value) => {
    isEditing.value = value;
  },
);

function disableEdit() {
  isEditing.value = false;
}

function editToggled() {
  isEditing.value = !isEditing.value;
  if (isEditing.value) {
    if (textEditorHandle.value) textEditorHandle.value.focus();
    emit('editIsOn');
  } else {
    if (isSaved.value) {
      dateEdited.value = new Date().toISOString().split('T')[0];
      timeEdited.value = new Date().toISOString().split('T')[1].split('.')[0];
    }
    isSaved.value = true;
    emit('saveNote', textEditorHandle.value.getRawTextContent(), props.index);
  }
}

defineExpose({
  disableEdit,
});
</script>

<template>
  <div class="flex-col">
    <!-- Avatar -->
    <div class="flex justify-between items-center">
      <div class="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
        <div class="flex-shrink-0">
          <img
            v-if="!!props.author?.profile_image?.image_urls?.small"
            class="size-12 rounded-full"
            :src="props.author.profile_image.image_urls.small"
          />
        </div>

        <div class="grow">
          <div class="flex justify-between items-center gap-x-2">
            <div>
              <!-- Tooltip -->
              <div class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block">
                <div class="hs-tooltip-toggle block text-start cursor-pointer">
                  <span class="font-semibold text-gray-800 dark:text-neutral-200">
                    {{ !!props.author.details.academic_title ? props.author.details.academic_title + ' ' : ''
                    }}{{ props.author.details.first_name }} {{ props.author.details.last_name }}
                  </span>

                  <!-- Dropdown Card -->
                  <div
                    class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 max-w-xs cursor-default bg-white divide-y divide-gray-700 shadow-lg rounded-xl dark:bg-neutral-950 dark:divide-neutral-700"
                    role="tooltip"
                  >
                    <!-- Body -->
                    <div class="p-4 sm:p-5">
                      <div class="mb-2 flex w-full sm:items-center gap-x-5 sm:gap-x-3">
                        <div class="flex-shrink-0">
                          <img
                            class="size-8 rounded-full"
                            src="https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                            alt="Image Description"
                          />
                        </div>

                        <div class="grow">
                          <p class="text-lg font-semibold text-gray-600 dark:text-neutral-200">
                            {{ !!props.author.details.academic_title ? props.author.details.academic_title + ' ' : ''
                            }}{{ props.author.details.first_name }} {{ props.author.details.last_name }}
                          </p>
                        </div>
                      </div>
                      <p class="text-sm text-gray-400 dark:text-neutral-400 sm:pb-1">
                        {{ props.author.details.position }}
                      </p>
                    </div>
                    <!-- End Body -->
                  </div>
                  <!-- End Dropdown Card -->
                </div>
              </div>
              <ul class="text-xs text-gray-500 dark:text-neutral-500">
                <li
                  class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-neutral-400 dark:before:bg-neutral-600"
                >
                  {{ props.author.details.position }}
                </li>
                <li
                  class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-neutral-400 dark:before:bg-neutral-600"
                >
                  {{ props.date }}
                </li>
                <li
                  class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-neutral-400 dark:before:bg-neutral-600"
                >
                  {{ props.time }}
                </li>
                <li
                  v-if="dateEdited"
                  class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-neutral-400 dark:before:bg-neutral-600 italic"
                >
                  bearbeitet {{ dateEdited }} {{ timeEdited }}
                </li>
              </ul>
              <!-- End Tooltip -->
            </div>

            <!-- Button Group -->
            <div>
              <ProgressButton
                v-if="!!props.author?.id && props.author.id === user.id"
                :color="isEditing ? 'win-blue' : 'white'"
                :text="isEditing ? 'Speichern' : 'Bearbeiten'"
                @click="editToggled"
                :icon="isEditing ? 'save' : 'edit'"
                iconSize="md"
              />
            </div>
            <!-- End Button Group -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Avatar -->

    <div class="text-gray-500 dark:text-neutral-500">
      <TextEditor
        ref="textEditorHandle"
        :content="props.content"
        :allowFormatting="false"
        :allowEdit="isEditing"
        :focusOnMount="true"
      />
    </div>
  </div>
</template>

<style scoped></style>
