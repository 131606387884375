<script setup lang="ts">
import { computed, useSlots } from 'vue';

const { icon, markUnread, animatePing, innerSize, outerSize } = defineProps({
  icon: {
    default: '',
    type: String,
  },
  markUnread: {
    default: false,
    type: Boolean,
  },
  animatePing: {
    default: false,
    type: Boolean,
  },
  innerPingSize: {
    default: '4px',
    type: String,
  },
  outerPingSize: {
    default: '10px',
    type: String,
  },
  iconSize: {
    default: '',
    type: String,
  },
});

const slots = useSlots();

const hasDefaultSlot = computed(() => !!slots.default);
</script>

<template>
  <div class="relative">
    <span translate="no" class="material-symbols-outlined notranslate" :class="iconSize">
      {{ icon }}
    </span>
    <span>
      <slot></slot>
    </span>
    <span
      v-if="markUnread"
      class="grid absolute z-10 -mt-1.5 -me-1.5 items-center justify-center"
      :class="{
        'end-0.5 top-0.5': !hasDefaultSlot,
        '-end-0.5 top-1': hasDefaultSlot,
      }"
    >
      <span
        class="row-start-1 col-start-1 animate-ping inline-flex size-full rounded-full bg-blue-400 opacity-75 dark:bg-blue-600"
        :style="{ width: outerPingSize, height: outerPingSize }"
        v-if="animatePing"
      >
      </span>
      <span
        class="row-start-1 col-start-1 inline-flex justify-center items-center bg-transparent rounded-full"
        :style="{ width: outerPingSize, height: outerPingSize }"
      >
        <span
          class="row-start-1 col-start-1 inline-flex justify-center items-center bg-blue-600 rounded-full"
          :style="{ width: innerPingSize, height: innerPingSize }"
        >
        </span>
      </span>
    </span>
  </div>
</template>

<style scoped></style>
