<script setup>
import MainHeader from '@/components/headers/MainHeader.vue';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const pdfFilePath = ref('');
const viewerHeight = ref('');

onMounted(() => {
  import('@/assets/privacy/privacy_statement.pdf').then((file) => {
    pdfFilePath.value = file.default;
  });

  viewerHeight.value = `${window.innerHeight}px`;

  window.addEventListener('resize', () => {
    viewerHeight.value = `${window.innerHeight}px`;
  });
});
</script>

<template>
  <div class="w-full min-w-full">
    <embed :src="pdfFilePath" type="application/pdf" width="100%" :height="viewerHeight" />
  </div>
</template>
