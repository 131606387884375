/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LearningObjective } from '../models/LearningObjective';
import type { LearningObjectiveEvaluation } from '../models/LearningObjectiveEvaluation';
import type { LearningObjectiveEvaluationsCreate } from '../models/LearningObjectiveEvaluationsCreate';
import type { LearningObjectivesCreate } from '../models/LearningObjectivesCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvaluationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Extract Learning Objectives
   * Extracts learning objectives from the given content.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user object.
   * learning_objective_create (models.LearningObjectiveCreate): The learning objective create object.
   *
   * Returns:
   * List[str]: A list of extracted learning objectives.
   * @param requestBody
   * @returns LearningObjective Successful Response
   * @throws ApiError
   */
  public extractLearningObjectives(requestBody: LearningObjectivesCreate): CancelablePromise<Array<LearningObjective>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/learning-objectives/extract',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Learning Objectives
   * Evaluate learning objectives based on the provided content and learning
   * objectives.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * learning_objective_evaluation_create (models.LearningObjectiveEvaluationsCreate): The learning objective evaluation data.
   *
   * Returns:
   * evaluations: The evaluations of the learning objectives.
   * @param requestBody
   * @returns LearningObjectiveEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateLearningObjectives(
    requestBody: LearningObjectiveEvaluationsCreate,
  ): CancelablePromise<Array<LearningObjectiveEvaluation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/learning-objectives/evaluate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
