<script setup lang="ts">
// TODO: make proper list of course interactions, as for case interactions

import { ref, computed, reactive, onMounted } from 'vue';
import CaseInteractionList from '@/components/CaseInteractionList.vue';
import { router } from '@/router';
import { useAuthStore, useCaseInteractionStore, useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { CourseInteraction } from '@/apiclient';
import CourseHighlightList from '@/components/CourseHighlightList.vue';

const caseStore = useCaseStore();
const authStore = useAuthStore();
const { currentCase } = storeToRefs(caseStore);

const courseInteractions = ref([] as CourseInteraction[]);
const isLoading = ref(false);

async function loadCourseInteractionsOfUser(user_id: string) {
  isLoading.value = true;
  const client = await getApiClient();
  courseInteractions.value = await client.users.listCourseInteractionsOfUser(authStore.userId);
  console.log('courseInteractions', courseInteractions.value);
  isLoading.value = false;
}

onMounted(async () => {
  await router.isReady();
  await loadCourseInteractionsOfUser(authStore.userId);
});

const courses = computed(() => {
  console.log('courseInteractions', JSON.stringify(courseInteractions.value));
  return courseInteractions.value.map((ci) => ci.course);
});
</script>

<template>
  <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    />
  </div>
  <div v-else class="w-full min-h-full h-full max-h-full overflow-y-scroll">
    <div class="w-full flex min-h-fit h-fit max-h-fit overflow-y-visible pb-10">
      <CourseHighlightList :courses="courses" class="w-full" />
    </div>
  </div>
</template>

<style></style>
