/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChapterInteractionUpdate } from '../models/ChapterInteractionUpdate';
import type { UpdateChapterInteractionResponse } from '../models/UpdateChapterInteractionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChapterInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update Chapter Interaction
   * @param chapterInteractionId
   * @param requestBody
   * @returns UpdateChapterInteractionResponse Successful Response
   * @throws ApiError
   */
  public updateChapterInteraction(
    chapterInteractionId: string,
    requestBody: ChapterInteractionUpdate,
  ): CancelablePromise<UpdateChapterInteractionResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/chapter-interactions/{chapter_interaction_id}',
      path: {
        chapter_interaction_id: chapterInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
