/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MediaService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Allowed Section Media Types
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAllowedSectionMediaTypes(): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/media/allowed-types',
    });
  }

  /**
   * Get Allowed Section Media File Sizes
   * @returns number Successful Response
   * @throws ApiError
   */
  public getAllowedSectionMediaFileSizes(): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/media/allowed-file-sizes',
    });
  }
}
