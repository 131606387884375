<!--
TODO: eigenständiges Design+
TODO 2: wenn Anwort geklickt, dieses als User messae wegschicken
-->

<script setup>
import SystemMessageFooter from '@/components/SystemMessageFooter.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, useMcExamInteractionStore } from '@/stores';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

const mcExamInteractionStore = useMcExamInteractionStore();
const { chatIsStreaming } = storeToRefs(mcExamInteractionStore);

const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

const { message } = defineProps(['message']);
const editOn = ref(false);
const editedMessage = ref('');
const originalMessage = ref('');
const hasBeenEdited = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter, unobfuscateUserName } from '@/helper';
import DOMPurify from 'dompurify';

const { selectedSentenceIndcs, calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectableDiv = ref(null);

function formatQuestionAndFeedback(message) {
  message = message.replace('**Feedback**:', '');
  message = message.replace('Question ', '');

  let pattern = /\*\*(\d+)\*\*|\*([A-Z])\.\*/g;
  // const newQuestionPattern = /\*\*(\d+)\*\*/g;
  // const newOptionPattern = /\*([A-E])\*\./g;

  let modifiedString = message.replace(pattern, (match, number, letter) => {
    // Check if the match is for **N**
    if (number !== undefined) {
      return `<br><b>Q${parseInt(number)}</b>`;
    }
    // Check if the match is for *A.*
    else if (letter !== undefined) {
      return `<br>${letter}.`;
    }
  });

  if (modifiedString.startsWith('<br>')) {
    modifiedString = modifiedString.substring(4);
  }

  return modifiedString;
}

const formattedMessage = computed(() => {
  return DOMPurify.sanitize(
    formatQuestionAndFeedback(
      unobfuscateUserName(message.content['processed_model_output'], userFirstName, userLastName),
    ),
  );
});

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  if (!selectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.content['processed_model_output'], characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

async function editToggled(new_value) {
  editOn.value = new_value.value;
  console.debug('MC exam chat bubble: Edit mode toggled to: ' + editOn.value);
  if (editOn.value === true) {
    console.debug('Edit on.');
    editedMessage.value = message.content['processed_model_output'];
    originalMessage.value = message.content['processed_model_output'];
    return;
  }
  console.debug('Edit off. Checking for new values');
  console.debug('Old message: ' + originalMessage.value);
  console.debug('New message: ' + editedMessage.value);
  if (editedMessage.value !== originalMessage.value) {
    console.debug('Message changed. Pushing to DB.');
    await mcExamInteractionStore.storeUserEditedChatMessage(message, editedMessage.value);
    originalMessage.value = editedMessage.value;
    hasBeenEdited.value = true;
  }
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <li class="flex gap-x-2 sm:gap-x-4">
    <!-- Icon -->
    <span
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage
          :image="mcExamInteractionStore.examinerProfileImageSmall"
          :initials="mcExamInteractionStore.examinerInitials"
        />
      </span>
    </span>
    <!-- End of icon -->

    <div>
      <!-- class="grow max-w-[90%] md:max-w-2xl w-full space-y-3" -->
      <!-- Content -->
      <div class="bg-white border border-gray-200 rounded-lg p-4 space-y-3 dark:bg-neutral-900 dark:border-gray-700">
        <p v-if="editOn">
          <textarea
            class="border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            v-model="editedMessage"
            :rows="calculateRows(editedMessage)"
            :cols="calculateCols(editedMessage)"
          >
          </textarea>
        </p>
        <p v-else-if="hasBeenEdited" class="text-sm text-gray-800 dark:text-white">
          {{ unobfuscateUserName(originalMessage, userFirstName, userLastName) }}
        </p>
        <p v-else class="text-sm text-gray-800 dark:text-white" @mouseup="onSelectedText" ref="selectableDiv">
          <a v-html="formattedMessage"></a>
        </p>
      </div>
      <!-- End of Content -->

      <!-- Button Group -->
      <SystemMessageFooter
        :message="message"
        messageType="SAY-MC-EXAM"
        conversation="MC-EXAM"
        @onEditToggled="editToggled"
        :hideFooter="chatIsStreaming"
        :markedSentenceIndcs="selectedSentenceIndcs"
      />
      <!-- End Button Group -->
    </div>
  </li>
</template>

<style scoped></style>
