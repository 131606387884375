/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserVocabListDetails } from '../models/UserVocabListDetails';
import type { VocabListCreate } from '../models/VocabListCreate';
import type { VocabListUpdate } from '../models/VocabListUpdate';
import type { VocabListUpdateResponse } from '../models/VocabListUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VocabListsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get My Vocab Lists
   * Get the current user's vocab lists.
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public getMyVocabLists(): CancelablePromise<Array<UserVocabListDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/vocab-lists/my',
    });
  }

  /**
   * Create Personal Vocab List
   * Add a new personal vocab list for the current user.
   * @param requestBody
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public createPersonalVocabList(requestBody: VocabListCreate): CancelablePromise<UserVocabListDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/vocab-lists/create-my',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Personal Vocab List
   * Update a personal vocab list of the current user.
   * @param vocabListId
   * @param requestBody
   * @returns VocabListUpdateResponse Successful Response
   * @throws ApiError
   */
  public updatePersonalVocabList(
    vocabListId: string,
    requestBody: VocabListUpdate,
  ): CancelablePromise<VocabListUpdateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/vocab-lists/update-my/{vocab_list_id}',
      path: {
        vocab_list_id: vocabListId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Copy Vocab To Personal Vocab List
   * Copy a vocab item to a personal vocab list.
   *
   * If make_copy = True: THIS INTENTIONALLY CREATES A COPY.
   * Reason: The original vocab item is not modified when the user works on their copy!
   *
   * If false: only okay if copying from one personal list to another
   *
   * retain_identity_if_in_other_user_vocab_list: if True, we check if the same item has
   * already been copied to another list. If so, we reference that copy instead of
   * creating another copy.
   * @param vocabListId
   * @param vocabItemId
   * @param makeCopy
   * @param retainIdentityIfInOtherUserVocabList
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public copyVocabToPersonalVocabList(
    vocabListId: string,
    vocabItemId: string,
    makeCopy: boolean = true,
    retainIdentityIfInOtherUserVocabList: boolean = true,
  ): CancelablePromise<UserVocabListDetails> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/vocab-lists/copy-vocab-to-my/{vocab_list_id}',
      path: {
        vocab_list_id: vocabListId,
      },
      query: {
        vocab_item_id: vocabItemId,
        make_copy: makeCopy,
        retain_identity_if_in_other_user_vocab_list: retainIdentityIfInOtherUserVocabList,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
