<script setup lang="ts">
import { getCurrentInstance, nextTick, onMounted, ref } from 'vue';
import Blogpost from '@/views/blog/Blogpost.vue';

const carousel = ref(null);

const instance = getCurrentInstance();
onMounted(() => {
  instance?.emit('carouselMounted', carousel.value.splide);
  console.log('Carousel Two mounted and emitted:', carousel.value.splide);
});
</script>

<template>
  <Splide
    ref="carousel"
    :options="{
      updateOnMove: true,
      type: 'loop',
      rewind: true,
      perPage: 2,
      breakpoints: {
        1280: {
          perPage: 2,
        },
        840: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      },
      perMove: 1,
      arrows: false,
      pagination: true,
      drag: 'free',
      snap: true,
      preloadPages: 3,
      pauseOnHover: true,
      direction: 'ltr',
    }"
    aria-label="Blogpost carousel"
  >
    <SplideSlide>
      <Blogpost
        :preview="true"
        image="tschentscher.jpg"
        title="Im Gespräch"
        summary="Beim Hamburg Innovation Summit besucht uns ein besonderer Kollege: Hamburgs Erster Bürgermeister Dr. Peter Tschentscher."
        :showMore="true"
        url="https://startupport.de/hamburg-innovation-summit-2024-erfolgreiche-startup-area-und-ausgezeichnete-startups/"
      />
    </SplideSlide>

    <SplideSlide>
      <Blogpost
        :preview="true"
        image="patientenkongress.png"
        title="Am Patienten"
        summary="casuu ist auf dem Patientenkongress der Deutschen Leukämiehilfe - und wir gründen mit interessierten Patient:innen unseren Beirat!"
        :showMore="true"
        url="/patient-advisory-board"
      />
    </SplideSlide>

    <SplideSlide>
      <Blogpost
        :preview="true"
        image="gruendungswettbewerb.jpg"
        title="Nominiert!"
        summary="Wir sind unter den Top 50 von über 400 Bewerbern im Gründungswettbewerb des Bundesministeriums für Wirtschaft und Klimaschutz."
        :showMore="true"
        url="https://www.de.digital/DIGITAL/Redaktion/DE/Gruenderwettbewerb/Artikel/GW-Startseite/Preistraeger-Nominierung.html"
      />
    </SplideSlide>

    <SplideSlide>
      <Blogpost
        :preview="true"
        image="hhia_publikumspreis.jpg"
        title="Hoooray!"
        summary="'Publikumsliebling casuu' trifft mit 'seinem Beitrag gegen den Fachkräftemangel im Pflege- und Gesundheitsbereich offensichtlich einen Nerv' - und gewinnt den Publikumspreis des Hamburg Innovation Award.
          Danke an Senatorin Dr. Leonhard, die Firma Continental - und vor allem an alle, die für uns gestimmt und uns nach der Veranstaltung mit ihren Ideen bereichert haben!"
        :showMore="true"
        url="https://www.hamburg-startups.net/erfolgreicher-neustart-fuer-den-hamburg-innovation-summit/"
      />
    </SplideSlide>

    <!--    <SplideSlide>-->
    <!--      <Blogpost-->
    <!--          :preview="true"-->
    <!--          image="dialoggremium.png"-->
    <!--          title="Bei Freund:innen"-->
    <!--          summary="casuu beim 'Dialoggremium Deutsch' - mit Expert:innen aus der Sprachdidaktik aus ganz Deutschland."-->
    <!--      />-->
    <!--    </SplideSlide>-->

    <!--    <SplideSlide>-->
    <!--      <Blogpost-->
    <!--          :preview="true"-->
    <!--          image="bergfest.jpg"-->
    <!--          title="Unsere Reise"-->
    <!--          :showMore="true"-->
    <!--          showMoreLabel="Was uns antreibt"-->
    <!--          url="/values-and-purpose"-->
    <!--          summary="Wir präsentieren casuu und unsere Story beim 'Bergfest' des AI Startup Hub Hamburg: von der Idee im Arztzimmer bis zum fertigen Produkt."-->
    <!--      />-->
    <!--    </SplideSlide>-->

    <!--    <SplideSlide>-->
    <!--      <Blogpost-->
    <!--          :preview="true"-->
    <!--          image="gruendungswettbewerb.jpg"-->
    <!--          title="Nominiert!"-->
    <!--          summary="Wir sind unter den Top 50 von über 400 Bewerbern im Gründungswettbewerb des Bundesministeriums für Wirtschaft und Klimaschutz."-->
    <!--          :showMore="true"-->
    <!--          url="https://www.de.digital/DIGITAL/Redaktion/DE/Gruenderwettbewerb/Artikel/GW-Startseite/Preistraeger-Nominierung.html"-->
    <!--      />-->
    <!--    </SplideSlide>-->

    <SplideSlide>
      <Blogpost
        :preview="true"
        image="newspapers_on_table.png"
        title="Nach Herrenberg"
        summary='
          "Ein Urteil des Bundessozialgerichts bedroht [...] auch die ohnehin überlaufenen Sprachkurse [...]: Es drohen Mehrkosten und Einschränkungen."
          Wir hoffen, mit casuu den Kliniken und Pflegediensten zu helfen, dass hierunter nicht ihre Mitarbeiter:innen und Patient:innen leiden.
          '
        :showMore="true"
        showMoreLabel="Die ganze Geschichte"
        url="https://www.welt.de/politik/deutschland/plus251802988/Asylbewerber-Bei-Sprach-und-Integrationskursen-droht-Chaos-mit-Ansage.html"
      />
    </SplideSlide>
  </Splide>
</template>

<style scoped></style>
