// Configuration for the leveling system
const baseXp = 200; // Base XP required for level 2
const growthFactor = 1.25; // Growth factor for XP required per level

/**
 * Function to calculate the current level from total XP
 * @param {number} xp - The total experience points of the user
 * @returns {number} - The current level of the user
 */
export function getLevelFromXp(xp: number) {
  let level = 1;
  let xpForNextLevel = baseXp;

  // Increment level while user's XP is greater than the threshold for the next level
  while (xp >= xpForNextLevel) {
    level++;
    xpForNextLevel += Math.floor(baseXp * Math.pow(growthFactor, level - 1));
  }

  return level;
}

/**
 * Function to calculate the XP threshold for the current level
 * @param {number} level - The current level of the user
 * @returns {number} - The XP threshold required to reach the current level
 */
export function getThresholdForCurrentLevel(level: number) {
  if (level <= 1) {
    return 0; // No XP required for level 1
  }
  let xpThreshold = 0;
  for (let i = 1; i < level; i++) {
    xpThreshold += Math.floor(baseXp * Math.pow(growthFactor, i - 1));
  }
  return xpThreshold;
}

/**
 * Function to calculate the XP threshold for the next level
 * @param {number} level - The current level of the user
 * @returns {number} - The XP threshold required to reach the next level
 */
export function getThresholdForNextLevel(level: number) {
  return getThresholdForCurrentLevel(level + 1);
}
