<script setup lang="ts">
const { isLoading, buttonText } = defineProps(['isLoading', 'buttonText']);
const emits = defineEmits(['onClicked']);
</script>

<template>
  <button
    type="button"
    class="flex grow py-2 px-3 rounded-md border-transparent items-center gap-x-2 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
    @click="emits('onClicked')"
  >
    <div
      v-if="isLoading"
      class="animate-spin inline-block w-4 h-4 text-white rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading</span>
    </div>
    <div class="flex min-w-full gap-x-3 rounded-md border border-transparent items-center justify-center" v-else>
      <svg
        class="flex-shrink-0 size-4"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M5 12h14" />
        <path d="M12 5v14" />
      </svg>
      {{ buttonText }}
    </div>
  </button>
</template>

<style scoped></style>
