/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Case } from '../models/Case';
import type { CaseCreate } from '../models/CaseCreate';
import type { CaseDetailed } from '../models/CaseDetailed';
import type { CreateCaseInteraction } from '../models/CreateCaseInteraction';
import type { CreateCaseInteractionResponse } from '../models/CreateCaseInteractionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CasesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Cases
   * @param tagNames
   * @param otherCaseListNames
   * @returns CaseDetailed Successful Response
   * @throws ApiError
   */
  public listCases(
    tagNames?: Array<string>,
    otherCaseListNames?: Array<string>,
  ): CancelablePromise<Array<CaseDetailed>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/cases/',
      query: {
        tag_names: tagNames,
        other_case_list_names: otherCaseListNames,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Case
   * @param requestBody
   * @returns Case Successful Response
   * @throws ApiError
   */
  public createCase(requestBody: CaseCreate): CancelablePromise<Case> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/cases/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Case
   * @param caseId
   * @returns CaseDetailed Successful Response
   * @throws ApiError
   */
  public getCase(caseId: string): CancelablePromise<CaseDetailed> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/cases/{case_id}',
      path: {
        case_id: caseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Tag To Case
   * @param caseId
   * @param tagId
   * @returns CaseDetailed Successful Response
   * @throws ApiError
   */
  public addTagToCase(caseId: string, tagId: string): CancelablePromise<CaseDetailed> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/cases/add_tag/{case_id}/{tag_id}',
      path: {
        case_id: caseId,
        tag_id: tagId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Tag From Case
   * @param caseId
   * @param tagId
   * @returns CaseDetailed Successful Response
   * @throws ApiError
   */
  public removeTagFromCase(caseId: string, tagId: string): CancelablePromise<CaseDetailed> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/cases/remove_tag/{case_id}/{tag_id}',
      path: {
        case_id: caseId,
        tag_id: tagId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Case Interaction
   * This initiates a new patient interaction, i.e. a patient interaction
   * without any messages. The messages are created by a separate endpoint.
   *
   * :param session:
   * :param user:
   * :param case_id: identifies the case to interact with
   * :return: newly created patient interaction DB object
   * @param caseId
   * @param requestBody
   * @returns CreateCaseInteractionResponse Successful Response
   * @throws ApiError
   */
  public createCaseInteraction(
    caseId: string,
    requestBody: CreateCaseInteraction,
  ): CancelablePromise<CreateCaseInteractionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/cases/interact/{case_id}',
      path: {
        case_id: caseId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
