<script setup lang="ts">
import { getCurrentInstance, nextTick, onMounted, ref } from 'vue';

const carousel = ref(null);

const props = defineProps({
  showPassage: {
    type: Boolean,
    default: true,
  },
  showAIHub: {
    type: Boolean,
    default: true,
  },
  showMicrosoft: {
    type: Boolean,
    default: true,
  },
  showPatientAdvisoryBoard: {
    type: Boolean,
    default: true,
  },
  showAwoBielefeld: {
    type: Boolean,
    default: true,
  },
  showSKK: {
    type: Boolean,
    default: true,
  },
  showAndYou: {
    type: Boolean,
    default: false,
  },
  maxPagesShow: {
    type: Number,
    default: 3,
  },
  interval: {
    type: Number,
    default: 6000,
  },
  direction: {
    type: String,
    default: 'ltr',
  },
});

const instance = getCurrentInstance();
onMounted(() => {
  instance?.emit('carouselMounted', carousel.value.splide);
  console.log('Carousel Two mounted and emitted:', carousel.value.splide);
});
</script>

<template>
  <Splide
    class="max-w-full"
    ref="carousel"
    :options="{
      updateOnMove: true,
      type: 'loop',
      rewind: true,
      perPage: maxPagesShow < 3 ? maxPagesShow : 3,
      breakpoints: {
        1280: {
          perPage: maxPagesShow < 3 ? maxPagesShow : 3,
        },
        840: {
          perPage: maxPagesShow < 2 ? maxPagesShow : 2,
        },
        640: {
          perPage: 1,
        },
      },
      perMove: 1,
      arrows: false,
      pagination: false,
      drag: 'free',
      snap: true,
      autoplay: true,
      interval: interval,
      pauseOnHover: true,
      direction: direction,
    }"
    aria-label="Partner carousel"
  >
    <!--    <SplideSlide v-for="case_ in cases">-->
    <!--      <div class="px-8 pt-8">-->
    <!--        <CaseHighlightCard :case="case_"></CaseHighlightCard>-->
    <!--        &lt;!&ndash;        <CaseCard&ndash;&gt;-->
    <!--        &lt;!&ndash;          :id="case_.id"&ndash;&gt;-->
    <!--        &lt;!&ndash;          :name="case_.details.first_name"&ndash;&gt;-->
    <!--        &lt;!&ndash;          description="Aua hilf mit bitte!"&ndash;&gt;-->
    <!--        &lt;!&ndash;          authorName="Dummy Name"&ndash;&gt;-->
    <!--        &lt;!&ndash;          authorId="123"&ndash;&gt;-->
    <!--        &lt;!&ndash;        />&ndash;&gt;-->
    <!--      </div>-->
    <!--    </SplideSlide>-->
    <SplideSlide v-if="showAIHub">
      <a class="min-w-fit" href="https://www.aistartuphub.com/de/startups/">
        <img
          class="h-[150px] mx-auto hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/ai_hub.png"
          alt="AI Hub Hamburg"
        />
      </a>
    </SplideSlide>
    <!--    <SplideSlide>-->
    <!--      <a-->
    <!--          class="min-w-fit"-->
    <!--          href="https://www.de.digital/DIGITAL/Redaktion/DE/Gruenderwettbewerb/Artikel/GW-Startseite/Preistraeger-Nominierung.html"-->
    <!--      >-->
    <!--        <img-->
    <!--            class="h-[150px] mx-auto"-->
    <!--            src="@/assets/images/partner-logos/gruendungswettbewerb.jpg"-->
    <!--            alt="Gründungswettbewerb Digitale Innovation"-->
    <!--        />-->
    <!--      </a>-->
    <!--    </SplideSlide>-->
    <SplideSlide v-if="showPassage">
      <a class="min-w-fit" href="https://passage.hamburg/projekte/fachstelle-berufsbezogenes-deutsch">
        <img
          class="h-[150px] mx-auto hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/passage.webp"
          alt="Passage gGmbH"
        />
      </a>
    </SplideSlide>
    <SplideSlide v-if="showAwoBielefeld">
      <a class="min-w-fit group" href="https://awo-bielefeld.de/">
        <img
          class="mt-[35px] h-[80px] mx-auto group-hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/awo_bielefeld.svg"
          alt="AWO Kreisverband Bielefeld"
        />
      </a>
    </SplideSlide>
    <SplideSlide v-if="showMicrosoft">
      <a class="min-w-fit" href="https://www.microsoft.com/de-de/startups/">
        <img
          class="h-[150px] mx-auto hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/ms_for_startups.png"
          alt="Microsoft for Startups"
        />
      </a>
    </SplideSlide>
    <SplideSlide v-if="showSKK">
      <a class="min-w-fit group" href="https://www.krankenhaus-kiel.de/">
        <img
          class="h-[150px] mx-auto group-hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/skk_kiel.png"
          alt="Städtisches Krankenhaus Kiel"
        />
      </a>
    </SplideSlide>
    <SplideSlide v-if="showPatientAdvisoryBoard">
      <a class="min-w-fit group" href="/patient-advisory-board">
        <img
          class="mt-[35px] h-[80px] mx-auto group-hover:scale-105 duration-500 ease-in-out rounded-xl"
          src="@/assets/images/partner-logos/patientenbeirat.png"
          alt="Der casuu-Patientenbeirat"
        />
      </a>
    </SplideSlide>
    <SplideSlide v-if="showAndYou">
      <a class="min-w-fit">
        <div
          class="h-[150px] w-[500px] bg-gray-100 align-content-center content-center justify-center mx-auto hover:scale-105 duration-500 ease-in-out rounded-xl text-5xl"
        >
          <div class="font-bold text-center">Und<span class="text-blue-600"> Sie?</span></div>
        </div>
      </a>
    </SplideSlide>
  </Splide>
</template>

<style scoped></style>
