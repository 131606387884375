<script>
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import OrderedList from '@tiptap/extension-ordered-list';
import Underline from '@tiptap/extension-underline';
import { Placeholder } from '@tiptap/extension-placeholder';
import _ from 'lodash';
import { nextTick, ref } from 'vue';

export default {
  components: {
    EditorContent,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
    placeholderMessage: {
      type: [String, null],
      default: null,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    allowFormatting: {
      type: Boolean,
      default: true,
    },
    allowTable: {
      type: Boolean,
      default: false,
    },
    allowList: {
      type: Boolean,
      default: false,
    },
    rawTextMode: {
      type: Boolean,
      default: false,
    },
    showFormatButtonText: {
      type: Boolean,
      default: true,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
  },

  data() {
    return {
      editor: null,
      unsavedChangesSent: false,
      originalHtml: '',
    };
  },

  watch: {
    allowEdit: {
      immediate: true,
      async handler(newVal) {
        console.log('allowEdit changed to ' + newVal);
        if (this.editor) {
          await nextTick();
          this.editor.setOptions({ editable: newVal });
        }
      },
    },
  },

  mounted() {
    this.editor = new Editor({
      autofocus: this.focusOnMount ? 'end' : false,
      extensions: [
        StarterKit,
        // Paragraph,
        // ListItem,
        // BulletList,
        // Document,
        // Text,
        // Gapcursor,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        // OrderedList,
        Underline,
        this.placeholderMessage ? Placeholder.configure({ placeholder: this.placeholderMessage }) : {},
      ],
      content: this.content,
      editable: this.allowEdit,
      editorProps: {
        attributes: {
          class: '',
        },
      },
    });
    this.originalHtml = this.editor.getHTML();

    // Listen for editor content changes
    this.editor.on(
      'update',
      _.debounce(() => {
        let newText = this.editor.getHTML();
        // if (!this.unsavedChangesSent && newText !== this.originalHtml) {
        if (newText !== this.originalHtml) {
          if (this.rawTextMode) newText = this.editor.getText();
          this.$emit('unsavedChanges', newText);
          this.unsavedChangesSent = true;
        } else if (this.unsavedChangesSent && newText === this.originalHtml) {
          this.$emit('changesCleared');
          this.unsavedChangesSent = false;
        }
      }, 500),
    );

    this.editor.on(
      'focus',
      _.debounce(() => {
        console.log('editor focused');
        // TODO: show menu
      }, 500),
    );

    this.editor.on(
      'blur',
      _.debounce(() => {
        console.log('editor blurred');
        // TODO: hide menu
      }, 500),
    );
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    getHTMLContent() {
      return this.editor.getHTML();
    },
    getRawTextContent() {
      return this.editor.getText();
    },
    resetEmitState() {
      this.unsavedChangesSent = false;
    },
    focus() {
      this.editor.chain().focus().run();
    },
  },
};
</script>

<template>
  <div
    v-if="editor"
    class="group overflow-hidden dark:border-neutral-700 py-[1px] rounded-md"
    :class="{ 'border-2 border-gray-200': allowEdit }"
    :style="{ width: width }"
  >
    <div
      v-show="allowEdit && allowFormatting"
      class="flex align-middle items-baseline gap-x-2 border-b border-gray-200 p-2 dark:border-neutral-700"
      :class="{ 'justify-between': showFormatButtonText }"
    >
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M14 12a4 4 0 0 0 0-8H6v8"></path>
              <path d="M15 20a4 4 0 0 0 0-8H6v8Z"></path>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Fett</div>
        </div>
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="19" x2="10" y1="4" y2="4"></line>
              <line x1="14" x2="5" y1="20" y2="20"></line>
              <line x1="15" x2="9" y1="4" y2="20"></line>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Kursiv</div>
        </div>
      </button>
      <button
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6 4v6a6 6 0 0 0 12 0V4"></path>
              <line x1="4" x2="20" y1="20" y2="20"></line>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Unterstreichen</div>
        </div>
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M16 4H9a3 3 0 0 0-2.83 4"></path>
              <path d="M14 12a4 4 0 0 1 0 8H6"></path>
              <line x1="4" x2="20" y1="12" y2="12"></line>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Durchstreichen</div>
        </div>
      </button>
      <button
        v-show="allowList"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="8" x2="21" y1="6" y2="6"></line>
              <line x1="8" x2="21" y1="12" y2="12"></line>
              <line x1="8" x2="21" y1="18" y2="18"></line>
              <line x1="3" x2="3.01" y1="6" y2="6"></line>
              <line x1="3" x2="3.01" y1="12" y2="12"></line>
              <line x1="3" x2="3.01" y1="18" y2="18"></line>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Liste</div>
        </div>
      </button>
      <button
        v-show="allowList"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="10" x2="21" y1="6" y2="6"></line>
              <line x1="10" x2="21" y1="12" y2="12"></line>
              <line x1="10" x2="21" y1="18" y2="18"></line>
              <path d="M4 6h1v4"></path>
              <path d="M4 10h2"></path>
              <path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1"></path>
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Nummeriert</div>
        </div>
      </button>
      <div class="hs-dropdown relative inline-flex">
        <button
          v-show="allowList"
          @click=""
          id="hs-dropdown-default"
          type="button"
          class="hs-dropdown-toggle size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
        >
          <div class="justify-center">
            <div class="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-table"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"
                />
              </svg>
            </div>
            <div v-show="showFormatButtonText" class="text-xs font-medium">Tabelle</div>
          </div>
        </button>
        <div
          class="z-20 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
          aria-labelledby="hs-dropdown-default"
        >
          <div class="flex-col">
            <div>
              <button
                v-show="allowTable"
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
              >
                Neue Tabelle einfügen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().addColumnBefore().run()"
              >
                Spalte zuvor einfügen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().addColumnAfter().run()"
              >
                Spalte dahinter einfügen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().deleteColumn().run()"
              >
                Spalte löschen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().addRowBefore().run()"
              >
                Zeile zuvor einfügen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().addRowAfter().run()"
              >
                Zeile dahinter einfügen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().deleteRow().run()"
              >
                Zeile löschen
              </button>
            </div>
            <div>
              <div>
                <button
                  class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                  v-show="allowTable"
                  @click="editor.chain().focus().mergeOrSplit().run()"
                >
                  Zusammenführen oder teilen
                </button>
              </div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().mergeCells().run()"
              >
                Zellen zusammenführen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().splitCell().run()"
              >
                Zelle teilen
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().toggleHeaderColumn().run()"
              >
                Spalte als Header an/aus
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().toggleHeaderRow().run()"
              >
                Zeile als Header an/aus
              </button>
            </div>
            <div>
              <button
                class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"
                v-show="allowTable"
                @click="editor.chain().focus().toggleHeaderCell().run()"
              >
                Zelle als Header an/aus
              </button>
            </div>
            <!--            <div>-->
            <!--              <button-->
            <!--                  class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"-->
            <!--                  v-show="allowTable" @click="editor.chain().focus().setCellAttribute('colspan', 2).run()">-->
            <!--                setCellAttribute-->
            <!--              </button>-->
            <!--            </div>-->
            <!--            <div>-->
            <!--              <button-->
            <!--                  class="text-sm text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-gray-400"-->
            <!--                  v-show="allowTable" @click="editor.chain().focus().fixTables().run()">-->
            <!--                fixTables-->
            <!--              </button>-->
            <!--            </div>-->
            <div>
              <button
                class="text-sm text-red-500 hover:text-red-600 dark:text-red-500 dark:hover:text-red-600"
                v-show="allowTable"
                @click="editor.chain().focus().deleteTable().run()"
              >
                Tabelle löschen
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        v-show="allowEdit"
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-arrow-counterclockwise"
              viewBox="0 0 16 16"
            >
              <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z" />
              <path
                d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"
              />
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Rückgängig</div>
        </div>
      </button>
      <button
        v-show="allowEdit"
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
        class="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <div class="justify-center">
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
              <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"
              />
            </svg>
          </div>
          <div v-show="showFormatButtonText" class="text-xs font-medium">Wiederholen</div>
        </div>
      </button>
    </div>
    <div class="">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul {
    list-style-type: disc;
    padding: 0 2rem;
  }

  ol {
    list-style-type: decimal;
    padding: 0 2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: 600;
      text-align: left;
      background-color: #eff6ff;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tiptap .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font: inherit;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
