<template>
  <div class="form-container">
    <form @submit.prevent="handleSubmit">
      <div class="vertical-border"></div>

      <!-- Zeitpunkt der Erfassung -->
      <div class="section">
        <div class="section-title">Zeitpunkt der Erfassung</div>
        <div class="radio-group">
          <label><input type="radio" v-model="formData.zeitpunkt" value="Konsil" /> Konsil vor Aufnahme</label>
          <label><input type="radio" v-model="formData.zeitpunkt" value="Aufnahme" /> Aufnahme</label>
          <label><input type="radio" v-model="formData.zeitpunkt" value="Verlauf" /> Verlauf</label>
          <label><input type="radio" v-model="formData.zeitpunkt" value="Entlassung" /> Entlassung</label>
        </div>
      </div>

      <!-- Essen -->
      <div class="section">
        <div class="section-title">Essen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.essen" value="10" /> 10: komplett selbständig oder selbständige
            PEG-Beschickung/-Versorgung</label
          >
          <label
            ><input type="radio" v-model="formData.essen" value="5" /> 5: Hilfe bei mundgerechter Vorbereitung oder
            selbständiges Einnehmen oder Hilfe bei PEG-Beschickung/-Versorgung</label
          >
          <label
            ><input type="radio" v-model="formData.essen" value="0" /> 0: kein selbständiges Einnehmen und keine
            MS/PEG-Ernährung</label
          >
        </div>
      </div>

      <!-- Aufsetzen & Umsetzen -->
      <div class="section">
        <div class="section-title">Aufsetzen & Umsetzen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.aufsetzen" value="15" /> 15: komplett selbständig bei jeder Position
            (z.B. Rollstuhl und zurück)</label
          >
          <label
            ><input type="radio" v-model="formData.aufsetzen" value="10" /> 10: Aufsicht oder geringe Hilfe (ungeübte
            Laienhilfe)</label
          >
          <label
            ><input type="radio" v-model="formData.aufsetzen" value="5" /> 5: erhebliche Hilfe (geschulte Laienhilfe
            oder professionelle Hilfe)</label
          >
          <label
            ><input type="radio" v-model="formData.aufsetzen" value="0" /> 0: wird faktisch nicht aus dem Bett
            transferiert</label
          >
        </div>
      </div>

      <!-- Sich Waschen -->
      <div class="section">
        <div class="section-title">Sich Waschen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.waschen" value="5" /> 5: vor Ort komplett selbständig inkl.
            Zähneputzen Rasieren und Frisieren</label
          >
          <label><input type="radio" v-model="formData.waschen" value="0" /> 0: erfüllt 5 nicht</label>
        </div>
      </div>

      <!-- Toilettenbenutzung -->
      <div class="section">
        <div class="section-title">Toilettenbenutzung</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.toilette" value="10" /> 10: vor Ort komplett selbständige Nutzung von
            Toilette oder Toilettenstuhl inkl. Spülung / Reinigung</label
          >
          <label
            ><input type="radio" v-model="formData.toilette" value="5" /> 5: vor Ort Hilfe oder Aufsicht bei Toiletten-
            oder Toilettenstuhlbenutzung oder deren Spülung / Reinigung erforderlich</label
          >
          <label
            ><input type="radio" v-model="formData.toilette" value="0" /> 0: benutzt faktisch weder Toilette noch
            Toilettenstuhl</label
          >
        </div>
      </div>

      <!-- Baden/Duschen -->
      <div class="section">
        <div class="section-title">Baden/Duschen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.baden" value="5" /> 5: selbständiges Baden oder Duschen inkl.
            Ein-/Ausstieg und sich reinigen und abtrocknen</label
          >
          <label><input type="radio" v-model="formData.baden" value="0" /> 0: erfüllt 5 nicht</label>
        </div>
      </div>

      <!-- Aufstehen & Gehen -->
      <div class="section">
        <div class="section-title">Aufstehen & Gehen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.gehen" value="15" /> 15: ohne Aufsicht oder personelle Hilfe vom Sitz
            in den Stand kommen und mindestens 50 m ohne Gehwagen (aber ggf. mit Handstock/Gehstützen) gehen</label
          >
          <label
            ><input type="radio" v-model="formData.gehen" value="10" /> 10: ohne Aufsicht oder personelle Hilfe vom Sitz
            in den Stand kommen und mindestens 50 m mit Hilfe eines Gehwagens gehen</label
          >
          <label
            ><input type="radio" v-model="formData.gehen" value="5" /> 5: mit Laienhilfe oder Gehwagen vom Sitz in den
            Stand kommen und Strecken im Wohnbereich bewältigen - alternativ: im Wohnbereich komplett selbständig im
            Rollstuhl</label
          >
          <label><input type="radio" v-model="formData.gehen" value="0" /> 0: erfüllt 5 nicht</label>
        </div>
      </div>

      <!-- Treppensteigen -->
      <div class="section">
        <div class="section-title">Treppensteigen</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.treppensteigen" value="10" /> 10: ohne Aufsicht oder personelle Hilfe
            (ggf. mit Handstock/Gehstützen) mindestens ein Stockwerk hinauf und hinuntersteigen</label
          >
          <label
            ><input type="radio" v-model="formData.treppensteigen" value="5" /> 5: mit Aufsicht oder Laienhilfe mind.
            ein Stockwerk hinauf und hinunter</label
          >
          <label><input type="radio" v-model="formData.treppensteigen" value="0" /> 0: erfüllt 5 nicht</label>
        </div>
      </div>

      <!-- An- & Auskleiden -->
      <div class="section">
        <div class="section-title">An- & Auskleiden</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.ankleiden" value="10" /> 10: zieht sich in angemessener Zeit
            selbständig Tageskleidung und Schuhe (und ggf. benötigte Hilfsmittel z.B. ATS oder Prothesen) an und
            aus</label
          >
          <label
            ><input type="radio" v-model="formData.ankleiden" value="5" /> 5: kleidet mindestens den Oberkörper in
            angemessener Zeit selbständig an und aus sofern die Utensilien in greifbarer Nähe sind</label
          >
          <label><input type="radio" v-model="formData.ankleiden" value="0" /> 0: erfüllt 5 nicht</label>
        </div>
      </div>

      <!-- Stuhlkontrolle -->
      <div class="section">
        <div class="section-title">Stuhlkontrolle</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.stuhlkontrolle" value="10" /> 10: ist stuhlkontinent - ggf.
            selbständig bei rektalen Abführmaßnahmen oder AP-Versorgung</label
          >
          <label
            ><input type="radio" v-model="formData.stuhlkontrolle" value="5" /> 5: ist durchschnittlich nicht mehr als
            1x/Woche inkontinent oder benötigt Hilfe bei rektalen Abführmaßnahmen / AP-Versorgung</label
          >
          <label
            ><input type="radio" v-model="formData.stuhlkontrolle" value="0" /> 0: ist durchschnittlich mehr als
            1x/Woche inkontinent</label
          >
        </div>
      </div>

      <!-- Harnkontrolle -->
      <div class="section">
        <div class="section-title">Harnkontrolle</div>
        <div class="radio-group">
          <label
            ><input type="radio" v-model="formData.harnkontrolle" value="10" /> 10: ist harnkontinent oder kompensiert
            seine Harninkontinenz / versorgt seinen DK komplett selbständig und mit Erfolg (kein Einnässen von Kleidung
            oder Bettwäsche)</label
          >
          <label
            ><input type="radio" v-model="formData.harnkontrolle" value="5" /> 5: kompensiert seine Harninkontinenz
            selbständig und mit überwiegendem Erfolg (durchschnittlich nicht mehr als 1x/Tag Einnässen von Kleidung oder
            Bettwäsche) oder benötigt Hilfe bei der Versorgung seines Harnkathetersystems</label
          >
          <label
            ><input type="radio" v-model="formData.harnkontrolle" value="0" /> 0: ist durchschnittlich mehr als 1x/Tag
            harninkontinent</label
          >
        </div>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>
</template>

<script setup>
import { reactive } from 'vue';

const formData = reactive({
  zeitpunkt: '',
  essen: '',
  aufsetzen: '',
  waschen: '',
  toilette: '',
  baden: '',
  gehen: '',
  treppensteigen: '',
  ankleiden: '',
  stuhlkontrolle: '',
  harnkontrolle: '',
});

const handleSubmit = () => {
  // Define all options for each section in the original order
  const options = {
    zeitpunkt: [
      { label: 'Konsil vor Aufnahme', value: 'Konsil' },
      { label: 'Aufnahme', value: 'Aufnahme' },
      { label: 'Verlauf', value: 'Verlauf' },
      { label: 'Entlassung', value: 'Entlassung' },
    ],
    essen: [
      { label: '10: komplett selbständig oder selbständige PEG-Beschickung/-Versorgung', value: '10' },
      {
        label:
          '5: Hilfe bei mundgerechter Vorbereitung oder selbständiges Einnehmen oder Hilfe bei PEG-Beschickung/-Versorgung',
        value: '5',
      },
      { label: '0: kein selbständiges Einnehmen und keine MS/PEG-Ernährung', value: '0' },
    ],
    aufsetzen: [
      { label: '15: komplett selbständig bei jeder Position (z.B. Rollstuhl und zurück)', value: '15' },
      { label: '10: Aufsicht oder geringe Hilfe (ungeübte Laienhilfe)', value: '10' },
      { label: '5: erhebliche Hilfe (geschulte Laienhilfe oder professionelle Hilfe)', value: '5' },
      { label: '0: wird faktisch nicht aus dem Bett transferiert', value: '0' },
    ],
    waschen: [
      { label: '5: vor Ort komplett selbständig inkl. Zähneputzen Rasieren und Frisieren', value: '5' },
      { label: '0: erfüllt 5 nicht', value: '0' },
    ],
    toilette: [
      {
        label: '10: vor Ort komplett selbständige Nutzung von Toilette oder Toilettenstuhl inkl. Spülung / Reinigung',
        value: '10',
      },
      {
        label:
          '5: vor Ort Hilfe oder Aufsicht bei Toiletten- oder Toilettenstuhlbenutzung oder deren Spülung / Reinigung erforderlich',
        value: '5',
      },
      { label: '0: benutzt faktisch weder Toilette noch Toilettenstuhl', value: '0' },
    ],
    baden: [
      { label: '5: selbständiges Baden oder Duschen inkl. Ein-/Ausstieg und sich reinigen und abtrocknen', value: '5' },
      { label: '0: erfüllt 5 nicht', value: '0' },
    ],
    gehen: [
      {
        label:
          '15: ohne Aufsicht oder personelle Hilfe vom Sitz in den Stand kommen und mindestens 50 m ohne Gehwagen (aber ggf. mit Handstock/Gehstützen) gehen',
        value: '15',
      },
      {
        label:
          '10: ohne Aufsicht oder personelle Hilfe vom Sitz in den Stand kommen und mindestens 50 m mit Hilfe eines Gehwagens gehen',
        value: '10',
      },
      {
        label:
          '5: mit Laienhilfe oder Gehwagen vom Sitz in den Stand kommen und Strecken im Wohnbereich bewältigen - alternativ: im Wohnbereich komplett selbständig im Rollstuhl',
        value: '5',
      },
      { label: '0: erfüllt 5 nicht', value: '0' },
    ],
    treppensteigen: [
      {
        label:
          '10: ohne Aufsicht oder personelle Hilfe (ggf. mit Handstock/Gehstützen) mindestens ein Stockwerk hinauf und hinuntersteigen',
        value: '10',
      },
      { label: '5: mit Aufsicht oder Laienhilfe mind. ein Stockwerk hinauf und hinunter', value: '5' },
      { label: '0: erfüllt 5 nicht', value: '0' },
    ],
    ankleiden: [
      {
        label:
          '10: zieht sich in angemessener Zeit selbständig Tageskleidung und Schuhe (und ggf. benötigte Hilfsmittel z.B. ATS oder Prothesen) an und aus',
        value: '10',
      },
      {
        label:
          '5: kleidet mindestens den Oberkörper in angemessener Zeit selbständig an und aus sofern die Utensilien in greifbarer Nähe sind',
        value: '5',
      },
      { label: '0: erfüllt 5 nicht', value: '0' },
    ],
    stuhlkontrolle: [
      {
        label: '10: ist stuhlkontinent - ggf. selbständig bei rektalen Abführmaßnahmen oder AP-Versorgung',
        value: '10',
      },
      {
        label:
          '5: ist durchschnittlich nicht mehr als 1x/Woche inkontinent oder benötigt Hilfe bei rektalen Abführmaßnahmen / AP-Versorgung',
        value: '5',
      },
      { label: '0: ist durchschnittlich mehr als 1x/Woche inkontinent', value: '0' },
    ],
    harnkontrolle: [
      {
        label:
          '10: ist harnkontinent oder kompensiert seine Harninkontinenz / versorgt seinen DK komplett selbständig und mit Erfolg (kein Einnässen von Kleidung oder Bettwäsche)',
        value: '10',
      },
      {
        label:
          '5: kompensiert seine Harninkontinenz selbständig und mit überwiegendem Erfolg (durchschnittlich nicht mehr als 1x/Tag Einnässen von Kleidung oder Bettwäsche) oder benötigt Hilfe bei der Versorgung seines Harnkathetersystems',
        value: '5',
      },
      { label: '0: ist durchschnittlich mehr als 1x/Tag harninkontinent', value: '0' },
    ],
  };

  const result = {};

  // Process each section in the defined order
  for (const [section, choices] of Object.entries(options)) {
    const selectedValue = formData[section];
    result[section] = {
      selected: choices.find((option) => option.value === selectedValue)?.label || 'No selection',
      otherOptions: choices.filter((option) => option.value !== selectedValue).map((option) => option.label),
    };
  }

  console.log(result);
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.form-container {
  position: relative;
  width: 1200px; /* Set width to match document */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333; /* Text color to match document */
}

.vertical-border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25%;
  width: 1px;
  background-color: #ddd;
}

.section {
  display: flex;
  align-items: flex-start;
  padding: 8px 0;
  border-top: 1px solid #ddd;
}

.section-title {
  width: 25%;
  font-weight: bold;
  padding-right: 20px;
  padding-top: 5px;
  margin-right: 20px;
  color: #333;
  font-size: 13px;
}

.radio-group {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.radio-group label {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.4;
  color: #333;
}

/* Custom styles for radio buttons */
.radio-group input[type='radio'] {
  margin-right: 8px;
  color: #0d0d0d;
  position: relative;
}

.radio-group input[type='radio']:focus {
  outline: 2px solid #0d0d0d; /* Adds a custom blue focus ring */
  outline-offset: 2px; /* Adds space between the outline and the radio button */
}

.submit-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007acc;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #005f99;
}
</style>
