/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { McExamInteractionMessage } from '../models/McExamInteractionMessage';
import type { McExamInteractionMessageCreate } from '../models/McExamInteractionMessageCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class McExamInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Mc Exam Interaction Messages
   * This will only return user or mc_exam messages, not system messages.
   *
   * :param session:
   * :param user:
   * :param mc_exam_interaction_id:
   * :return:
   * @param mcExamInteractionId
   * @returns McExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public listMcExamInteractionMessages(
    mcExamInteractionId: string,
  ): CancelablePromise<Array<McExamInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/mc-exam-interactions/{mc_exam_interaction_id}',
      path: {
        mc_exam_interaction_id: mcExamInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Mc Exam Interaction Message
   * @param mcExamInteractionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createMcExamInteractionMessage(
    mcExamInteractionId: string,
    requestBody: McExamInteractionMessageCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/mc-exam-interactions/{mc_exam_interaction_id}',
      path: {
        mc_exam_interaction_id: mcExamInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
