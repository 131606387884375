<!--TODO
TODO: Send input to DB
TODO: Buttons for examination (Sthethoskop-Icon?) und Blutabnahme (Nadel-Icon?) neben "Senden"
TODO v1: scene desc from AI. Patient chat w/ AI
- v1.b: patient avatar
TODO v2: try therapy. Get updated scene desc as feedback.
TODO v3: user input suggestions? (toggle on/off?)

-->

<script setup>
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import {
  useAttendingInteractionStore,
  useAuthStore,
  useCaseInteractionStore,
  usePatientInteractionStore,
} from '@/stores';

import { unobfuscateUserName } from '@/helper';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import AudioPlayerTTS from '@/components/AudioPlayerTTS.vue';
import InteractionInputToggleGroup from '@/components/inputs/InteractionInputToggleGroup.vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const attendingInteractionStore = useAttendingInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const { user, userFirstName, userLastName, textToSpeechEnabled } = storeToRefs(authStore);
const { chatMessages, chatIsStreaming, descIsStreaming } = storeToRefs(patientInteractionStore);
const {
  allPersons,
  selectedPerson,
  selectedPersonIndex,
  selectedPersonsLastChatMessage,
  selectedPersonsVoice,
  unselectedPersons,
  currentlyReadingChatMessageText,
  someChatIsStreaming,
} = storeToRefs(caseInteractionStore);

const lastChatMessage = computed(() => {
  return chatMessages.value[chatMessages.value.length - 1];
});

const playingAudio = ref(false);
const mountAudioPlayerTTS = ref(true);

const emit = defineEmits(['personSelected']);

const remountAudioPlayerTTS = () => {
  mountAudioPlayerTTS.value = false;
  nextTick(() => {
    mountAudioPlayerTTS.value = true;
  });
};

const props = defineProps({
  showSubtitles: {
    type: Boolean,
    default: true,
  },
  inputText: {
    type: String,
    default: '',
  },
});

onMounted(async () => {
  await nextTick();
  emit('personSelected', selectedPerson.value);
  console.log('Selected person by mount: ' + selectedPerson.value);
});

watch(
  () => props.inputText,
  async (inputText) => {
    console.debug('User input: ' + inputText + ' for selected person ' + selectedPerson.value);
    if (!selectedPerson.value) {
      console.log('No selected person');
      return;
    }
    if (selectedPerson.value.type === 'PATIENT') {
      console.log('Saying to patient');
      await patientInteractionStore.say(inputText);
    } else if (selectedPerson.value.type === 'ATTENDING') {
      console.log('Saying to attending');
      await attendingInteractionStore.say(inputText);
    } else if (selectedPerson.value.type === 'THIRD_PERSON') {
      console.log('Saying to third person');
      await thirdPersonInteractionStore.say(selectedPerson.value.thirdPersonIndex, inputText);
    } else {
      console.log('Unknown person type');
    }
  },
);

const currentlyReadingChatMessageTextWithTrailingSpecialCharacter = computed(() => {
  if (currentlyReadingChatMessageText.value === '') {
    return '';
  }
  let modifiedString = currentlyReadingChatMessageText.value.replace(/"/, '');

  // if testString ends on a special character or a special character and a white space, add this in the front
  // Regular expression to match a special character followed by optional white space at the end of the string
  const regex = /([\.,?!])\s*$/;

  // Test if the originalText ends with the special character(s) pattern
  const match = currentlyReadingChatMessageText.value.match(regex);
  if (match) {
    // Extract the special character
    const specialCharacter = match[1];

    // Remove the special character and whitespace from the end of the string
    modifiedString = modifiedString.slice(0, -match[0].length);

    // remove leading whitespace
    modifiedString = modifiedString.trim();

    // Prepend the special character to the beginning of the string
    return specialCharacter + modifiedString;
  } else {
    return modifiedString;
  }
});

function selectPerson(index) {
  if (!!playingAudio) {
    console.log('Cannot select person while playing audio');
    return;
  }
  currentlyReadingChatMessageText.value = '';
  caseInteractionStore.selectPerson(index);
}

const moreThanOnePerson = computed(() => {
  return allPersons.value.length > 1;
});

watch(
  () => selectedPersonIndex.value,
  (newIndex) => {
    console.log('New selected person: ', selectedPerson.value, ' at index ', newIndex);
    emit('personSelected', selectedPerson.value);
  },
  { immediate: true },
);
</script>

<template>
  <div class="grid grid-cols-10 grid-rows-10 overflow-hidden">
    <div
      v-show="moreThanOnePerson"
      class="col-start-1 col-span-10 md:col-span-8 2xl:col-span-7 row-start-1 row-span-2 border border-gray-200 rounded-xl mx-6 my-1 gap-x-2 overflow-hidden min-h-[12vH] max-h-[12vH] inline-grid grid-rows-1"
    >
      <!-- TODO: make this container full height of parent, cave viewport on mobile -->
      <div
        class="row-start-1"
        style="width: 100%; height: 100%; position: relative"
        v-for="person in unselectedPersons"
      >
        <span
          v-show="person.involvement === 'ON_THE_PHONE'"
          class="absolute top-4 end-14 z-10 sm:end-14 md:end-16 lg:end-20 xl:end-26 2xl:end-32 inline-flex items-center py-2 px-2 rounded-xl text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white"
        >
          <FontAwesomeIcon :icon="faPhone" size="xl" key="notIsLargeScreen" />
        </span>
        <img
          @click="selectPerson(person.index)"
          v-if="!!person.profileImageLarge"
          class="absolute rounded-xl top-0 inset-x-0 transition-transform duration-500 ease-in-out object-cover"
          :class="!!playingAudio ? 'opacity-30' : 'hover:scale-125'"
          :src="person.profileImageLarge"
          alt="person profile image"
          style="width: 100%; height: 100%; object-fit: contain"
        />
      </div>
    </div>
    <div
      class="flex w-full min-w-full col-start-1 col-span-10 md:col-span-8 2xl:col-span-7 row-span-4 md:row-span-6 px-6 overflow-hidden rounded-xl items-center justify-center"
      :class="{ 'row-start-2 md:row-start-3 ': moreThanOnePerson, 'row-start-1 md:row-start-2': !moreThanOnePerson }"
    >
      <!--            <ProfileImage-->
      <!--              :image="patientInteractionStore.patientProfileImageLarge"-->
      <!--              :initials="patientInteractionStore.patientInitials"-->
      <!--              size="3rem"-->
      <!--            />-->
      <div class="flex w-full rounded-xl overflow-hidden justify-center">
        <div class="flex w-full justify-center">
          <span
            v-if="!!selectedPerson"
            v-show="selectedPerson.involvement === 'ON_THE_PHONE'"
            class="absolute top-4 end-4 md:top-8 md:end-16 lg:end-32 2xl:end-48 z-10 inline-flex items-center py-2 px-2 rounded-xl text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white"
          >
            <span class="hidden lg:block">
              <FontAwesomeIcon :icon="faPhone" size="3x" key="isLargeScreen" />
            </span>
            <span class="block lg:hidden">
              <FontAwesomeIcon :icon="faPhone" size="xl" key="notIsLargeScreen" />
            </span>
          </span>

          <!--        Playing audio: {{playingAudio}}.-->
          <!--        {{ !!playingAudio ? '125' : '100' }}-->
          <!--        <img-->
          <!--            v-if="!!selectedPerson"-->
          <!--            class="absolute rounded-xl top-0 left-1/2 -translate-x-1/2 transition-transform duration-500 ease-in-out object-cover"-->
          <!--            :class="'scale-' + (!!playingAudio ? '125' : '100')"-->
          <!--            :src="selectedPerson.profileImageLarge"-->
          <!--            alt="patient profile image"-->
          <!--            style="width: auto; height: auto"-->
          <!--        />-->
          <div class="">
            <div class="top-0 z-[100] w-fit">
              <InteractionInputToggleGroup
                :showChatInputToggle="false"
                :showSubtitlesToggle="false"
                :showLanguageLevelSelect="true"
                :bgTransparent="true"
                :hideWhenSmallScreen="false"
              />
            </div>
            <img
              v-show="!!playingAudio"
              v-if="!!selectedPerson"
              class="scale-105 z-10 rounded-xl top-0 left-1/2 transition-transform duration-500 ease-in-out object-cover"
              :src="selectedPerson.profileImageLarge"
              alt="patient profile image"
            />
            <img
              v-show="!playingAudio"
              v-if="!!selectedPerson"
              class="rounded-xl z-0 top-0 left-1/2 transition-transform duration-500 ease-in-out object-cover"
              :src="selectedPerson.profileImageLarge"
              alt="patient profile image"
            />
          </div>
        </div>
      </div>
      <!-- hover:scale-105 -->
    </div>
    <div
      v-show="!!props.showSubtitles"
      class="z-20 relative col-start-1 col-span-10 md:col-span-8 2xl:col-span-7 row-span-1 mt-2 pb-2 mx-6 flex items-start justify-center"
      :class="{ 'row-start-5 md:row-start-9': moreThanOnePerson, 'row-start-4 md:row-start-8': !moreThanOnePerson }"
    >
      <div
        v-if="textToSpeechEnabled"
        class="flex px-2 py-2 min-w-full w-full h-12 min-h-12 items-center rounded-lg"
        :class="someChatIsStreaming ? 'bg-gray-200/50 md:bg-gray-200' : 'bg-white/30 md:bg-white'"
      >
        <div class="overflow-hidden whitespace-nowrap" :style="{ direction: 'rtl', textAlign: 'center' }">
          {{ currentlyReadingChatMessageTextWithTrailingSpecialCharacter }}
        </div>
        <AudioPlayerTTS
          v-if="!!selectedPersonsLastChatMessage && mountAudioPlayerTTS"
          :message="
            unobfuscateUserName(
              selectedPersonsLastChatMessage.content['processed_model_output'],
              userFirstName,
              userLastName,
            )
          "
          :messageIsComplete="!someChatIsStreaming"
          :messageId="selectedPersonsLastChatMessage.id"
          :voice="selectedPersonsVoice"
          @playingAudio="playingAudio = $event"
          @playbackFinished="remountAudioPlayerTTS"
        />
      </div>
      <div v-else class="overflow-y-auto flex-col-reverse">
        <div class="translate-z-0">
          <div class="text-center text-md text-gray-800">
            {{
              !!lastChatMessage
                ? unobfuscateUserName(lastChatMessage.content['processed_model_output'], userFirstName, userLastName)
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
