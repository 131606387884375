import { CaseInteraction, CaseInteractionList, Evaluation } from '@/views/case-interactions';
import { LoggedInRoot } from '@/views/root';

export default {
  path: '/case-interactions',
  component: LoggedInRoot,
  children: [
    { path: '/case-interactions/:caseInteractionId', component: CaseInteraction },
    { path: '/case-interactions/:caseInteractionId/evaluation', component: Evaluation },
    { path: '/users/:userId/case-interactions', component: CaseInteractionList },
  ],
};
