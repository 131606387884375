import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

const RESET_AFTER = 3500;
const FADE_OUT_DURATION = 500;

export const useAlertStore = defineStore({
  id: 'alert',
  state: () => ({
    alerts: [] as Array<{
      id: string;
      message: string;
      type: 'success' | 'error' | 'info' | 'xp';
      title: string;
      visible: boolean;
    }>,
  }),
  actions: {
    success(message: string, title?: string) {
      this.addAlert(message, 'success', title);
    },
    error(message: string, title?: string, error?: Error) {
      this.addAlert(message, 'error', title);
      // Todo: send error to Sentry
    },
    info(message: string, title?: string) {
      this.addAlert(message, 'info', title);
    },
    xp(message: string, title?: string) {
      this.addAlert(message, 'xp', title);
    },
    addAlert(message: string, type: 'success' | 'error' | 'info' | 'xp', title?: string) {
      const id = uuidv4();
      if (!title) {
        title = 'status.' + type + '.alertTitle';
      }
      this.alerts.unshift({ id, message, type, title, visible: true });

      setTimeout(() => {
        this.clearAlert(id);
      }, RESET_AFTER);
    },
    clearAlert(id: string) {
      const alert = this.alerts.find((alert) => alert.id === id);
      if (alert) {
        // Triggers exit animation
        alert.visible = false;

        // Removes the alert after exit animation
        setTimeout(() => {
          this.alerts = this.alerts.filter((alert) => alert.id !== id);
        }, FADE_OUT_DURATION);
      }
    },
    clear() {
      this.alerts.length = 0;
    },
  },
});
