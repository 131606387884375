export enum PairOfTermsItemType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export type PairOfTermsItem = {
  id: string;
  pair_of_terms_item_type: PairOfTermsItemType;
  content: string;
  description: string;
};

export type PairOfTermsItemPair = PairOfTermsItem[];
