<script setup>
import MainHeader from '@/components/headers/MainHeader.vue';
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';

const outerHeader = ref(null);
const outerHeaderHeight = ref(0);

const adjustHeight = () => {
  if (outerHeader.value) {
    outerHeaderHeight.value = outerHeader.value.offsetHeight;
  }
};

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onMounted(() => {
  adjustHeight();
  window.addEventListener('resize', debouncedAdjustHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerHeaderHeight.value}px)`,
  };
});

const computedStyleMainPlusHeader = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh)`,
  };
});

const shouldCache = (route) => {};

onMounted(() => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }
});
</script>

<template>
  <div class="flex flex-col w-screen overflow-hidden" :style="computedStyleMainPlusHeader">
    <header
      ref="outerHeader"
      class="flex-none sticky top-0 md:justify-start md:flex-nowrap z-40 w-full bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="true"
        :showChallengesAndEventsLink="true"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showQuizDropdown="true"
        :showSignInOrEditProfile="true"
        @openDailyVocabTestModal="
          () => {
            console.log('openDailyVocabTestModal');
          }
        "
      />
    </header>
    <main :style="computedStyleMain" class="flex-grow w-full overflow-hidden" ref="mainContent">
      <router-view :outer-header-height="outerHeaderHeight" />
    </main>
  </div>
</template>
