<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import ProgressButton from '@/components/ProgressButton.vue';
import OtpConfirm from '@/components/OtpConfirm.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { router } from '@/router';

const authStore = useAuthStore();
const alertStore = useAlertStore();

const emailSent = ref(false);
const emailSentTo = ref('');
const isSubmitting = ref(false);

const initialValues = {
  email: '',
};

async function handlePasswordResetRequest(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }

  isSubmitting.value = true;

  const client = await getApiClient();

  client.authentication
    .resetPassword({
      email: controlledValues.email,
    })
    .then(() => {
      isSubmitting.value = false;
      emailSent.value = true;
      emailSentTo.value = controlledValues.email;
    })
    .catch((error) => {
      isSubmitting.value = false;
      emailSent.value = false;
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

async function handleConfirmResetPassword(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }

  isSubmitting.value = true;
  const client = await getApiClient();

  client.authentication
    .confirmResetPassword({
      email: emailSentTo.value,
      token: controlledValues.otpConfirm,
    })
    .then(async (token) => {
      await authStore.setToken(token);
      isSubmitting.value = false;
      // user is now logged in, redirect to password change page
      router.push('/account/change-password'); // does not work if awaited. TODO: why?
    })
    .catch((error) => {
      isSubmitting.value = false;
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}
</script>

<template>
  <main class="w-full h-screen max-w-md mx-auto p-6">
    <div
      v-if="!emailSent"
      class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700"
    >
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.resetPassword') }}</h1>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.enterEmailForPasswordReset') }}
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <Form @submit="handlePasswordResetRequest" v-slot="{ values, errors }" :initial-values="initialValues">
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label for="email" class="block text-sm mb-2 dark:text-white">E-Mail Adresse</label>
                <div class="relative">
                  <Field
                    :disabled="isSubmitting"
                    name="email"
                    type="email"
                    rules="email|required"
                    :class="{
                      'border-red-500': errors.email,
                    }"
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="email" />
                </div>
              </div>
              <!-- End Form Group -->

              <ProgressButton :text="$t('message.resetPassword')" :showProgress="isSubmitting" type="submit" />
            </div>
          </Form>
          <!-- End Form -->
        </div>
      </div>
    </div>

    <OtpConfirm v-else="emailSent" :heading="$t('message.resetPassword')" :onConfirm="handleConfirmResetPassword">
    </OtpConfirm>
  </main>
</template>
