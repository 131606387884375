<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';

const { overlayId } = defineProps(['overlayId']);
const hashtagOverlayId = '#' + overlayId;
const refName = ref(null);
const resolvePromise = ref(undefined as any);
const rejectPromise = ref(undefined as any);

const closeCallback = (evt: any) => {
  console.debug('close.hs.overlay event detected');
  // Assuming `resolvePromise` is available in the parent scope
  resolvePromise.value = true;
};

onMounted(async () => {
  await nextTick(async () => {
    const element = refName.value;
    if (!element) {
      console.warn('MockDesktop: refName element is empty');
      return;
    }
    element.addEventListener('close.hs.overlay', closeCallback);
    // nextTick: deferred to wait for next DOM update + 500 ms
    await new Promise((resolve) => setTimeout(resolve, 500));
  });
});

onBeforeUnmount(async () => {
  console.debug('MetaChatModal onBeforeUnmount()');
  const element = refName.value;
  if (!element) {
    console.warn('MockDesktop: refName element is empty');
    return;
  }
  element.removeEventListener('close.hs.overlay', closeCallback);
  await window.HSOverlay.close(element);
  resolvePromise.value = true;
});

async function close() {
  console.debug('close()');

  await window.HSOverlay.close(refName.value);
  resolvePromise.value = true;
}

async function pauseForModal() {
  console.debug('Initiating meta chat dialog');
  await nextTick(); // Ensure DOM updates are complete
  await new Promise((resolve) => setTimeout(resolve, 200));
  await window.HSOverlay.open(refName.value);
  console.debug('Returning promise');
  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve;
    rejectPromise.value = reject;
  });
}

defineExpose({
  pauseForModal,
  close,
});
</script>

<template>
  <div
    ref="refName"
    :id="overlayId"
    class="hs-overlay hidden size-full fixed top-0 start-0 z-[100] overflow-x-hidden pointer-events-none"
  >
    <div
      class="hs-overlay-open:mt-[5vH] hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-[80vH] opacity-0 ease-out transition-all sm:max-w-screen sm:w-screen sm:max-h-[95vH] sm:h-[95vH] sm:min-h-[95vH] px-4 sm:mx-auto"
    >
      <div class="flex flex-col flex-1 h-full">
        <div class="mt-auto bg-zinc-300 px-6 py-6 grid grid-rows-1 grid-cols-1 shadow-xl shadow-gray-800 rounded-3xl">
          <!-- "display buttons" -->
          <div class="row-start-1 col-start-1 h-full w-full rounded-full bg-red-600 flex items-end justify-end">
            <div class="-mb-5 mr-1 h-4 w-4 rounded-md bg-zinc-300 border border-1 border-zinc-400 z-10"></div>
            <div class="-mb-5 mr-5 h-4 w-4 rounded-md bg-zinc-300 border border-1 border-zinc-400 z-10"></div>
          </div>
          <!-- end "display buttons" -->

          <!-- "display screen" -->
          <div
            class="row-start-1 col-start-1 shadow-inner shadow-gray-800 flex flex-col bg-white rounded-md pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70"
          >
            <!-- "menu bar" -->
            <div
              class="flex justify-between items-center py-0.5 px-4 bg-blue-300 rounded-t-md shadow-inner shadow-gray-800 dark:border-neutral-700"
            >
              <h2 class="text-white dark:text-white inline-flex">
                <div class="pr-4">next clinical OS</div>
              </h2>
              <button
                type="button"
                class="hs-dropup-toggle flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-white hover:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                :data-hs-overlay="hashtagOverlayId"
              >
                <span class="sr-only">Close</span>
                <span translate="no" class="material-symbols-outlined notranslate">close</span>
              </button>
            </div>
            <!-- end "menu bar" -->
            <div class="px-1 pb-1 pt-0 autofocus pointer-events-auto">
              <!-- "window content" -->
              <p class="text-gray-800 dark:text-neutral-400 max-h-[85vH] h-[85vH] min-w-full w-full overflow-y-scroll">
                <slot class="flex min-w-full w-full"></slot>
              </p>
              <!-- end "window content" -->
            </div>
          </div>
        </div>
        <!-- end "display" -->

        <!-- "display stand with cables" + "logo" -->
        <div class="flex justify-center">
          <div class="grid grid-rows-1 grid-cols-1">
            <div
              class="row-start-1 col-start-1 -mt-5 h-5 w-10 mx-[200px] text-center text-xs text-light text-zinc-400 overline"
            >
              ETAIO
            </div>
            <!-- "stand" -->
            <div class="row-start-1 col-start-1 h-3 w-10 mx-[200px] bg-zinc-300 shadow-gray-800 shadow-inner"></div>
            <!-- "cables" -->
            <div class="row-start-1 col-start-1 ml-[400px] z-0 h-3 w-1 bg-gray-700 shadow-inner shadow-gray-800"></div>
            <div
              class="row-start-1 col-start-1 ml-[395px] z-0 h-3 w-0.5 bg-stone-900 shadow-inner shadow-gray-800"
            ></div>
          </div>
        </div>
        <!-- end "display stand" etc -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
