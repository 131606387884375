<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
  window.location.href = 'https://t.ly/uJja3';
});
</script>

<template></template>

<style scoped></style>
