/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Patient } from '../models/Patient';
import type { PatientCreate } from '../models/PatientCreate';
import type { PatientDetails } from '../models/PatientDetails';
import type { ProfileImage } from '../models/ProfileImage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PatientsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Patients
   * @returns Patient Successful Response
   * @throws ApiError
   */
  public listPatients(): CancelablePromise<Array<Patient>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/patients/',
    });
  }

  /**
   * Create Patient
   * @param requestBody
   * @returns Patient Successful Response
   * @throws ApiError
   */
  public createPatient(requestBody: PatientCreate): CancelablePromise<Patient> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/patients/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Patient
   * @param patientId
   * @returns Patient Successful Response
   * @throws ApiError
   */
  public getPatient(patientId: string): CancelablePromise<Patient> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/patients/{patient_id}',
      path: {
        patient_id: patientId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Generate Patient From Freehand Description And Other Details
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public generatePatientFromFreehandDescriptionAndOtherDetails(
    requestBody: PatientDetails,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/patients/freehand',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create New Patient Profile Image
   * @param requestBody
   * @returns ProfileImage Successful Response
   * @throws ApiError
   */
  public createNewPatientProfileImage(requestBody: PatientDetails): CancelablePromise<ProfileImage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/patients/profile-image',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
