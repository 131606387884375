<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import { router } from '@/router';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useForm } from 'vee-validate';
import ProgressButton from '@/components/ProgressButton.vue';
const initialValues = {
  password: '',
  passwordConfirm: '',
};
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();

const isSubmitting = ref(false);

async function handlePasswordChange(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }

  console.log(controlledValues);

  isSubmitting.value = true;

  const client = await getApiClient();

  client.authentication
    .changePassword({
      password: controlledValues.password,
    })
    .then(async () => {
      alertStore.success('status.success.passwordChanged');
      await authStore.logout();
      isSubmitting.value = false;
      await router.push('/account/sign-in');
    })
    .catch((error) => {
      alertStore.error('status.error.passwordChangeError');
      isSubmitting.value = false;
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}
</script>

<template>
  <main class="w-full h-screen max-w-md mx-auto p-6">
    <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.changePassword') }}</h1>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <Form
            @submit="handlePasswordChange"
            v-slot="{ values, errors }"
            :initial-values="initialValues"
            ref="passwordChangeForm"
          >
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="password" class="block text-sm mb-2 dark:text-white">{{
                    $t('message.newPassword')
                  }}</label>
                </div>
                <div class="relative">
                  <Field
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="password"
                    type="password"
                    ref="password"
                    rules="required|password"
                    :class="{
                      'border-red-500': errors.password,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="password" />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="passwordConfirm" class="block text-sm mb-2 dark:text-white">{{
                    $t('message.confirmNewPassword')
                  }}</label>
                </div>
                <div class="relative">
                  <Field
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="passwordConfirm"
                    type="password"
                    :rules="`required|confirmed:${values.password}`"
                    :class="{
                      'border-red-500': errors.passwordConfirm,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="passwordConfirm" />
                </div>
              </div>
              <!-- End Form Group -->

              <ProgressButton :text="$t('message.changePassword')" :showProgress="isSubmitting" type="submit" />
            </div>
          </Form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
