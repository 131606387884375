import { defineStore } from 'pinia';

import { router } from '@/router';

import { getApiClient } from '@/apiclient/client';
import {
  McExamInteractionMessage,
  McExaminer,
  McExamInteractionMessageContent,
  McExamInteractionMessage,
  MessageType,
  OralExamInteractionMessage,
  AttendingInteractionMessage,
  McExamInteraction,
  CaseInteraction,
  Attending,
} from '@/apiclient';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { Partial } from '@/helper';
import { useCaseInteractionStore } from '@/stores/caseInteraction.store';

interface mcExamInteractionState {
  currentCaseInteraction: CaseInteraction | null;
  currentExaminer: McExaminer | null;
  currentExaminerInteractionId: string;
  chatMessages: McExamInteractionMessage[];
  chatIsStreaming: Boolean;
}

export const useMcExamInteractionStore = defineStore({
  id: 'mcExamInteraction',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    // localStorage.getItem can be void, therefore using || so that json.parse does not receive = ill type
    currentCaseInteraction: null as CaseInteraction | null,
    currentExaminer: null as McExaminer | null,
    currentMcExamInteractionId: '',

    chatMessages: [] as McExamInteractionMessage[],
    chatIsStreaming: false,
    requestedWelcomeMessage: false,
  }),
  getters: {
    currentCaseInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.id : '';
    },
    examinerFirstName(state) {
      return state.currentExaminer ? state.currentExaminer.details.first_name : '';
    },
    examinerLastName(state) {
      return state.currentExaminer ? state.currentExaminer.details.last_name : '';
    },
    examinerName(state) {
      return state.currentExaminer
        ? (state.currentExaminer.details.academic_title ? +' ' : '') +
            state.currentExaminer.details.first_name +
            ' ' +
            state.currentExaminer.details.last_name
        : '';
    },
    examinerAcademicTitle(state) {
      return state.currentExaminer ? state.currentExaminer.details.academic_title : null;
    },
    examinerInitials(state) {
      // TS problem: https://github.com/vuejs/pinia/discussions/1076
      // return initials based on userFirstName and userLastName
      // check if length of userFirstName and userLastName is > 0
      var firstInitial = '';
      var lastInitial = '';

      if (
        state.currentExaminer &&
        state.currentExaminer.details.first_name &&
        state.currentExaminer.details.first_name.length > 0
      ) {
        firstInitial = state.currentExaminer.details.first_name[0].toUpperCase();
      }
      if (
        state.currentExaminer &&
        state.currentExaminer.details.last_name &&
        state.currentExaminer.details.last_name.length > 0
      ) {
        lastInitial = state.currentExaminer.details.last_name[0].toUpperCase();
      }

      return firstInitial + lastInitial;
    },
  },
  actions: {
    async setCaseInteraction(caseInteraction: CaseInteraction) {
      if (!caseInteraction) {
        console.warn('Null case interaction provided to setCaseInteraction');
        return;
      }
      this.currentCaseInteraction = caseInteraction;
      this.currentMcExamInteractionId = caseInteraction.mc_exam_interaction.id;

      // check if patient is already set and the same as in the case interaction
      // otherwise, fetch patient from API
      if (!(this.currentExaminer && this.currentExaminer.id === caseInteraction.case.mc_examiner.id)) {
        this.currentExaminer = await (
          await getApiClient()
        ).mcExaminers.getMcExaminer(caseInteraction.case.mc_examiner.id);
      }

      console.debug(
        'Set case interaction ' +
          this.currentCaseInteraction.id +
          ' and patient interaction ' +
          this.currentMcExamInteractionId +
          ' for patient ' +
          this.currentExaminer.id,
      );
    },
    async setCaseInteractionById(id: string) {
      // fetch patient interaction from API
      const caseInteraction = await (await getApiClient()).caseInteractions.getCaseInteraction(id);
      await this.setCaseInteraction(caseInteraction);
    },
    async createPreliminaryChatMessage(userMessage: string = '', type: string = 'MC') {
      this.chatIsStreaming = true;
      console.debug('Creating preliminary chat message for mc exm interaction ' + this.currentMcExamInteractionId);

      const content: McExamInteractionMessageContent = {
        processed_model_output: '',
        user_message: userMessage,
        user_message_language: null,
        timestamp: null,
      };
      const message: McExamInteractionMessage = {
        id: 'not_yet_in_database',
        mc_exam_interaction_id: this.currentMcExamInteractionId,
        content: content,
        type: type as MessageType,
        translations: null,
        created_at: 'incomplete',
      };

      this.chatMessages.push(message);
    },
    async appendToChatMessage(idx: number, chunk: string) {
      console.debug(chunk);
      try {
        let chunk_ = JSON.parse(chunk);
        if (chunk_.type === 'message') {
          this.chatMessages[idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          this.chatMessages[idx].id = chunk_['content'];
          console.debug('Finished message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
      // console.debug('Last message now reads: ' + this.chatMessages[idx].content['processed_model_output'])
    },
    async appendToLastChatMessageAndRefetchOnceFinished(chunk: string) {
      const idx = this.chatMessages.length - 1;
      await this.appendToChatMessage(idx, chunk);
      if (this.chatMessages.length && this.chatMessages[idx].id !== 'not_yet_in_database') {
        // stream finished
        this.chatIsStreaming = false;
        await this.fetchAndReplaceChatMessage(idx);
      }
    },
    async fetchAndReplaceChatMessage(idx: number) {
      const id = this.chatMessages[idx].id;
      console.debug('Fetching complete message for incomplete message ' + id);
      const completeMessage = await (await getApiClient()).mcExamMessages.getMcExamInteractionMessage(id);
      this.chatMessages[idx] = completeMessage;
    },
    _appendToIndexedChatMessageTranslation(
      index: number,
      target_language: string,
      translation_option: string,
      chunk: string,
    ) {
      let chunk_ = JSON.parse(chunk);
      if (chunk_.type === 'message') {
        this.chatMessages[index].translations[target_language][translation_option] += chunk_['content'];
      } else if (chunk_.type === 'id') {
        console.debug('Finished translation of message with id ' + chunk_['content']);
      }
      // console.debug(this.chatMessages[index].translations[target_language][translation_option])
    },
    appendToIndexedChatMessageTranslation: function (
      this: mcExamInteractionState,
      index: number,
      target_language: string,
      translation_option: string,
      chunk?: string,
    ) {
      if (chunk !== undefined) {
        // Full call
        return this._appendToIndexedChatMessageTranslation(index, target_language, translation_option, chunk);
      } else {
        // Partial application
        const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
          return this._appendToIndexedChatMessageTranslation(index, target_language, translation_option, chunk);
        };
        return partialFn;
        // NOTE: if function is async, need to be returned as object, i.e. return {partialFn}, then unpacked
        // as const {callback} = this.appendToIndexedChatMessageTranslation(index, target_language, translation_option)
      }
    } as Partial,
    async translateChatMessage(message: McExamInteractionMessage, language: string, option: string) {
      // TODO: fix bug -- after reloading case (URL + enter), translations are loaded double (every types and time?)

      console.debug('Getting translation of ' + message.content + ' to ' + language);

      if (
        message.translations &&
        language in message.translations &&
        option in message.translations[language]
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return; // message.translations.language.option;
      }

      let index = this.chatMessages.indexOf(message);
      if (this.chatMessages[index].translations === null) {
        this.chatMessages[index].translations = {};
      }
      if (this.chatMessages[index].translations[language] === undefined) {
        this.chatMessages[index].translations[language] = {};
      }
      this.chatMessages[index].translations[language][option] = '';
      let origMessage = this.chatMessages[index];
      let url =
        '/mc-exam-messages/' +
        origMessage.id +
        '/translation?' +
        new URLSearchParams({
          target_language: language,
          translation_option: option,
        }).toString();
      console.debug('URL is: ' + url);

      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, this.appendToIndexedChatMessageTranslation(index, language, option));
    },
    replaceChatMessage(orig: McExamInteractionMessage, replace: McExamInteractionMessage) {
      const index = this.chatMessages.indexOf(orig);
      if (index !== -1) {
        this.chatMessages[index] = replace;
        return;
      }
    },
    async getExaminerWelcomeMessage() {
      if (this.requestedWelcomeMessage) {
        console.debug('Already requested welcome message. Doing nothing.');
        return;
      }
      await this.createPreliminaryChatMessage('', 'MC_REACT');
      this.requestedWelcomeMessage = true;
      console.debug('Length of chat messages between calls is : ' + this.chatMessages.length);
      const url = '/mc-exam-interactions/' + this.currentMcExamInteractionId;
      const callback = this.appendToLastChatMessageAndRefetchOnceFinished;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          content: '',
          type: 'MC_REACT',
          event: {
            type: 'INTERACTION_START',
          },
        },
        callback,
      );
    },
    async fetchHistory(): Promise<boolean> {
      console.debug('Fetching history for mc exam interaction ' + this.currentMcExamInteractionId);
      const chatMessages = await (
        await getApiClient()
      ).mcExamInteractions.listMcExamInteractionMessages(this.currentMcExamInteractionId);

      console.debug('Retrieved ' + chatMessages.length + ' chat messages');

      // load history if length > 0
      // if any message has been loaded, we return true to indicate that history has been loaded
      let historyLoaded = false;
      if (chatMessages.length > 0) {
        this.chatMessages = chatMessages;
        historyLoaded = true;
      }

      return historyLoaded;
    },
    async say(message: string) {
      await this.createPreliminaryChatMessage(message, 'MC');
      const url = '/mc-exam-interactions/' + this.currentMcExamInteractionId;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'MC',
          content: message,
        },
        this.appendToLastChatMessageAndRefetchOnceFinished,
      );
    },
    async storeUserEditedChatMessage(message: McExamInteractionMessage, userEdit: string) {
      console.debug('Storing user edit for message ' + message.id);
      console.debug('Edited content: ' + userEdit);
      // upload to DB as "user_edited_output"
      await (
        await getApiClient()
      ).mcExamMessages.storeUserEditForMcExamInteractionMessage(message.id, {
        content: userEdit,
      });
    },
    async reset() {
      this.requestedWelcomeMessage = false;

      this.currentExaminer = null;
      this.currentMcExamInteractionId = '';
      this.currentCaseInteraction = null;

      this.chatMessages.length = 0;
      this.currentExaminer = null;
      // this.chatMessages = [] as McExamInteractionMessage[];
      this.chatIsStreaming = false;
    },
  },
});
