<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import { formatDate, mapJobStatus, mapTitle, VocabMode } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import DOMPurify from 'dompurify';
import TextEditor from '@/views/courses/TextEditor.vue';
import { useAlertStore, useCourseInteractionStore, useAuthStore, useLanguageStore, useCourseStore } from '@/stores';
import SectionMediaUpload from '@/views/courses/SectionMediaUpload.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import CaseVocabModal from '@/components/CaseVocabModal.vue';
import SectionVocabModal from '@/components/SectionVocabModal.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import SectionContent from '@/views/courses/SectionContent.vue';
import { onBeforeMount } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import Card from '@/components/didactics/pair_of_terms/Card.vue';

const { t } = useI18n();

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const languageStore = useLanguageStore();
const { userNativeLanguage, userIngameLanguage } = storeToRefs(authStore);
const courseStore = useCourseStore();
const { currentChapter } = storeToRefs(courseStore);
const sectionContentComponent = ref<InstanceType<typeof SectionContent> | null>(null);

const learningObjectives = ref(null);

const props = defineProps(['index', 'chapterId', 'chapterIndex', 'outerHeaderHeight', 'fullWidth', 'showNative']);
const reactiveShowNative = toRef(props, 'showNative'); // Create a ref directly from props

const emit = defineEmits(['showNativeToggled', 'scrollBy']);
const caseList = ref();
const cases = ref([]);
const media = ref([] as any[]);
const fullWidthIndex = ref(-1);
const initialFetchCompleted = ref(false);
const germanContentContainer = ref(null);
const germanContentContainerHeight = ref(0);
const outerTextContainer = ref(null);
let germanContentContainerResizeObserver = null;
const exampleSentenceModal = ref(null);
const vocabModal = ref(null);

const padding = ref(100);
const gap = ref(50);

const section = computed(() => {
  if (!currentChapter.value || !currentChapter.value.sections || props.index == null) {
    return null;
  }
  return currentChapter.value.sections[props.index];
});

const outerContentContainerWidth = computed(() => {
  return props.fullWidth - 2 * padding.value;
});

const outerTextContentContainerWidth = computed(() => {
  if (padding.value < 10) {
    return outerContentContainerWidth.value - 20;
  }
  return outerContentContainerWidth.value;
});

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  if (!sectionLearningObjectives) {
    return;
  }
  if (!sectionContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  if (!section.value) {
    return;
  }
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  sectionContentComponent.value.learningObjectives.setLearningObjectives(sectionLearningObjectives.slice(), false);
}

const adjustPadding = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    padding.value = 5;
    return;
  } else if (screenWidth < 1024) {
    padding.value = 10;
    return;
  }
  padding.value = Math.min(100, Math.round(screenWidth * 0.05));
};

const debounceAdjustPadding = debounce(adjustPadding, 100);

onBeforeMount(async () => {
  await courseStore.settingCoursePromise;
  await courseStore.settingChapterPromise; // only mount after chapter is loaded to store
});

onMounted(async () => {
  await languageStore.loadLanguages(false);
  await nextTick(() => {
    // HSTooltip.autoInit();
  });

  if (!section.value) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + section.value.learning_objectives?.length);
  setLearningObjectives(section.value.learning_objectives);

  adjustPadding();
  window.addEventListener('resize', debounceAdjustPadding);

  setTimeout(async () => {
    await triggerSectionRefetch(); // replaces case preview with full case details
  }, 200); // deferring this is a bit of a dirty hack
});

const triggerSectionRefetch = async () => {
  initialFetchCompleted.value = false;
  await courseStore.refetchSelectedSection(props.index);
  await setEditorRefs();
  await nextTick();
  initialFetchCompleted.value = true;
};

const setEditorRefs = async () => {
  if (!section.value) {
    return;
  }
  if (!section.value.section_content_items) {
    return;
  }
  if (!sectionContentComponent.value) {
    return;
  }
  await sectionContentComponent.value.setEditorRefs();
  await nextTick();
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceAdjustPadding);
});

const sectionStarted = computed(() => {
  return courseInteractionStore.sectionStarted(section.value.index);
});

const sectionCompleted = computed(() => {
  return courseInteractionStore.sectionCompleted(section.value.index);
});

const firstCaseStarted = computed(() => {
  return courseInteractionStore.sectionFirstCaseStarted(section.value.index);
});

const sanitizedSectionContentsHtml = computed(() => {
  return DOMPurify.sanitize(section.value.contents);
});

const translationAvailable = computed(() => {
  return !!section.value.content_translations[userNativeLanguage.value];
});

const translatedContentIfAny = computed(() => {
  return translationAvailable.value
    ? DOMPurify.sanitize(section.value.content_translations[userNativeLanguage.value])
    : 'No translation available';
});

async function markSection() {
  if (!sectionStarted.value) {
    await courseInteractionStore.startSectionIfNotAlready(section.value.index);
  } else {
    await courseInteractionStore.toggleSectionCompleted(section.value.index, t);
  }
}

const toggleFullWidth = (index: number) => {
  console.log('section - toggleFullWidth to index: ', index);
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

const untoggleFullWidth = () => {
  fullWidthIndex.value = -1;
};

async function onExplainVocab() {
  if (!vocabModal.value) {
    console.error('vocabModal not set');
    return;
  }
  await vocabModal.value.pauseForMetaChat();
}

async function onExplainExampleSentence() {
  if (!exampleSentenceModal.value) {
    console.error('exampleSentenceModal not set');
    return;
  }
  await exampleSentenceModal.value.pauseForMetaChat();
}

// TODO i18n
// TODO language icon => user language flag icon ?
</script>

<template>
  <!-- Blog Article -->
  <div
    class="px-1 pt-6 lg:pt-10 pb-12 sm:px-4 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
    :style="{ width: props.fullWidth + 'px' }"
    v-if="!initialFetchCompleted"
  >
    <LoadingSpinnerLarge />
  </div>
  <div
    v-show="initialFetchCompleted"
    class="mx-auto transition-all duration-500 ease-in-out pt-6 lg:pt-10 pb-12 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
    :style="{ width: props.fullWidth + 'px' }"
  >
    <div class="grow transition-all duration-500 ease-in-out">
      <div
        class="mx-auto justify-between items-center mb-6 transition-all duration-500 ease-in-out"
        :style="{ width: outerContentContainerWidth + 'px' }"
      >
        <div class="w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div class="grow">
            <div class="flex justify-between items-center gap-x-2">
              <!-- Button Group -->
              <div class="inline-flex items-center">
                <button
                  type="button"
                  class="group py-1.5 px-0.5 md:px-2.5 inline-flex items-center gap-x-0.5 md:gap-x-2 text-xs md:text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white"
                  @click.capture="markSection"
                  :class="{
                    'border-gray-200 text-gray-200 hover:bg-blue-700 hover:text-white':
                      !sectionCompleted && !sectionStarted,
                    'border-gray-800 text-gray-800 dark:border-gray-700 bg-white hover:bg-gray-50 dark:bg-neutral-900 dark:hover:bg-gray-800':
                      !sectionCompleted && sectionStarted,
                    'text-green-700 border-green-700 dark:border-green-700  bg-green-100 hover:text-green-600 dark:bg-green-900 dark:hover:bg-green-800':
                      sectionCompleted,
                  }"
                >
                  <span v-show="!sectionCompleted && !sectionStarted" class="text-gray-200 material-symbols-outlined">
                    circle
                  </span>
                  <span
                    v-show="!sectionCompleted && sectionStarted"
                    translate="no"
                    class="material-symbols-outlined notranslate"
                  >
                    {{ firstCaseStarted ? 'clock_loader_60' : 'clock_loader_40' }}
                  </span>
                  <span v-show="sectionCompleted" translate="no" class="material-symbols-outlined notranslate">
                    check_circle
                  </span>
                  <span class="block group-hover:hidden">
                    {{ sectionStarted ? (sectionCompleted ? 'Fertig' : 'In Bearbeitung') : 'Noch nicht bearbeitet' }}
                  </span>
                  <span class="hidden group-hover:block">
                    {{
                      sectionStarted
                        ? sectionCompleted
                          ? 'Fertig'
                          : 'Als vollständig bearbeitet markieren'
                        : 'Bearbeitung starten'
                    }}
                  </span>
                </button>
              </div>
              <!-- End Button Group -->

              <div class="flex justify-between items-center gap-x-2">
                <div>
                  <!-- Tooltip -->
                  <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                    <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                      <span>
                        <span class="text-xs text-gray-400"> by </span>
                        <span class="font-semibold text-gray-800 dark:text-gray-200">
                          {{ section.user.academic_title ? mapTitle(section.user.academic_title) + ' ' : ''
                          }}{{ section.user.first_name }}
                          {{ section.user.last_name }}
                        </span>
                      </span>
                      <div class="text-xs text-gray-400">
                        {{ section.user.job_status ? mapJobStatus(section.user.job_status) : '' }}
                      </div>
                    </div>
                  </div>
                  <!-- End Tooltip -->
                  <ul class="text-xs text-gray-400">
                    <li
                      class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                    >
                      {{ formatDate(section.created_at) }}
                    </li>
                  </ul>
                </div>
                <div class="flex-shrink-0">
                  <ProfileImage
                    :image="section.user.userProfileImageSmall"
                    initials=""
                    size="4rem"
                    :showIngameLevel="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Avatar Media -->

      <!-- Content -->

      <!--      show: {{ showNative }} / {{ reactiveShowNative }}-->
      <!--      {{ sanitizedSectionContentsHtml.length }}-->
      <!--      {{ translatedContentIfAny.length }}-->
      <!--      full width:{{ !!outerTextContainer ? outerTextContainer.offsetWidth : 'N/A' }} german width:{{-->
      <!--        germanContainerWidth-->
      <!--      }}-->
      <!--      native translation:{{ nativeContainerTranslation }}-->

      <div
        class="mx-auto flex space-y-5 transition-all duration-500 ease-in-out md:space-y-8"
        :style="{ width: outerContentContainerWidth + 'px' }"
        ref="outerTextContainer"
      >
        <div
          class="space-y-3 overflow-hidden transition-all duration-500 ease-in-out"
          :style="{
            gap: `${gap}px`,
          }"
        >
          <div>
            <div class="grid items-center min-w-full w-full relative">
              <h2
                class="text-2xl flex-col font-bold md:text-3xl dark:text-white text-center row-start-1 col-start-1 flex justify-center items-center"
              >
                <span class="z-10 flex justify-center items-center md:px-48 text-gray-300">
                  <span class="text-gray-300">{{ chapterIndex + 1 }}</span
                  ><span class="whitespace-pre text-gray-300 px-0.5">.</span>{{ section.index + 1 }}
                </span>
                <span class="z-10 flex justify-center items-center md:px-48">
                  {{ section.title }}
                </span>
              </h2>
              <div class="flex row-start-1 -mt-8 md:mt-0 col-start-1 justify-end items-center gap-x-3">
                <div class="z-10 flex justify-center items-center">
                  <input
                    type="checkbox"
                    id="hs-basic-with-description-checked"
                    class="hidden md:block relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                    @change="emit('showNativeToggled', !props.showNative)"
                    :checked="props.showNative"
                  />
                  <input
                    type="checkbox"
                    id="hs-xs-switch"
                    @change="emit('showNativeToggled', !props.showNative)"
                    :checked="props.showNative"
                    class="block md:hidden relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                  />
                  <label
                    for="hs-basic-with-description-checked"
                    class="text-sm text-gray-800 ms-3 dark:text-neutral-400"
                  >
                    <span class="hidden md:block">Show in</span>
                    {{ languageStore.getLanguageNameFromCode(userNativeLanguage) }}
                  </label>
                </div>
              </div>
              <h3
                class="text-lg font-bold md:text-xl dark:text-white text-center text-gray-400"
                v-show="props.showNative"
              >
                {{
                  !!section.title_translations[userNativeLanguage] ? section.title_translations[userNativeLanguage] : ''
                }}
              </h3>
            </div>
          </div>

          <SectionContent
            ref="sectionContentComponent"
            :allow-editing="false"
            :chapter-id="chapterId"
            :section="section"
            :external-fetch-completed="initialFetchCompleted"
            :outer-header-height="props.outerHeaderHeight"
            :outer-width="outerTextContentContainerWidth"
            :show-native="props.showNative"
            @scrollBy="(distance) => emit('scrollBy', distance)"
          />

          <!--                    <div-->
          <!--                      class="grid grid-rows-1 max-h-fit h-fit divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden"-->
          <!--                      :style="{-->
          <!--                        width: showNative-->
          <!--                          ? `${outerTextContainerWidthWithNativeShown}px`-->
          <!--                          : `${outerTextContainerWidthWithoutNativeShown}px`,-->
          <!--                      }"-->
          <!--                    >-->
          <!--                      <div-->
          <!--                        ref="germanContentContainer"-->
          <!--                        class="col-start-1 transition-all duration-500 ease-in-out row-start-1 h-fit max-h-fit text-base text-justify text-gray-800 dark:text-gray-200 overflow-hidden"-->
          <!--                        :style="{ width: `${germanContainerWidth}px` }"-->
          <!--                      >-->
          <!--                        <TextEditor :content="sanitizedSectionContentsHtml" :allowEdit="false" />-->
          <!--                      </div>-->

          <!--                      <div-->
          <!--                        class="col-start-1 inline-flex row-start-1 text-base text-justify items-justify text-gray-400 dark:text-gray-200 transition-all duration-500 ease-in-out overflow-hidden"-->
          <!--                        :style="{-->
          <!--                          height: showNative ? `auto` : `${germanContentContainerHeight}px`,-->
          <!--                          transform: `translateX(${nativeContainerTranslation}px)`,-->
          <!--                          width: `${nativeContainerOuterWidth}px`,-->
          <!--                          gap: `${gap}px`,-->
          <!--                        }"-->
          <!--                      >-->
          <!--                        <p class="border-s border-s-gray-100"></p>-->
          <!--                        <p :style="{ width: `${nativeContainerInnerWidth}px` }">-->
          <!--                          <TextEditor :content="translatedContentIfAny" :allowEdit="false" />-->
          <!--                        </p>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--          -->
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>

  <!-- End Blog Article -->

  <!-- Bottom button group (not sticky as in template) -->
  <div class="inset-x-0 text-center justify-center flex gap-x-2">
    <button
      v-show="sectionStarted"
      type="button"
      @click.capture="markSection"
      class="flex items-center shadow-md rounded-full py-3 pl-4 pr-6 text-xs md:text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      :class="{
        'text-white bg-blue-600 hover:bg-blue-700': !sectionCompleted,
        'text-gray-800 bg-white hover:bg-gray-50': sectionCompleted,
      }"
    >
      <span v-show="!sectionCompleted" translate="no" class="material-symbols-outlined notranslate">
        check_circle
      </span>
      {{ sectionCompleted ? 'Nochmal bearbeiten' : 'Abschließen' }}
      <span v-show="props.showNative" class="text-gray-400 dark:text-gray-200">{{
        sectionCompleted ? $t('message.revokeMarkAsCompleted') : $t('message.markAsCompleted')
      }}</span>
    </button>

    <button
      v-show="false"
      type="button"
      @click.capture="alertStore.error('Noch nicht unterstützt')"
      class="flex items-center shadow-md rounded-full text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> expand_more </span>
      Notizen
      <span v-show="props.showNative" class="text-gray-400 dark:text-gray-200">{{ $t('message.notes') }}</span>
    </button>
  </div>
  <!-- End Bottom Button Group -->

  <!--  <SectionVocabModal-->
  <!--      v-if="!!section.vocabularies"-->
  <!--      ref="vocabModal"-->
  <!--      :overlayId="'vocab-modal-' + section.id"-->
  <!--      :showSolutions="false"-->
  <!--      :mode="VocabMode.EXPLAIN"-->
  <!--      :vocabularies="section.vocabularies"-->
  <!--      :nVocabs="8"-->
  <!--  />-->
  <!--  <SectionVocabModal-->
  <!--      v-if="!!section.example_formulations"-->
  <!--      ref="exampleSentenceModal"-->
  <!--      :overlayId="'example-sentence-modal-' + section.id"-->
  <!--      :showSolutions="false"-->
  <!--      :mode="VocabMode.EXPLAIN"-->
  <!--      :vocabularies="section.example_formulations"-->
  <!--      :nVocabs="8"-->
  <!--  />-->
</template>

<style scoped></style>
