/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserBlockDetails } from '../models/UserBlockDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserBlocksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Delete User Block
     * @param userBlockId
     * @returns UserBlockDetails Successful Response
     * @throws ApiError
     */
    public deleteUserBlock(
        userBlockId: string,
    ): CancelablePromise<UserBlockDetails> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user-blocks/{user_block_id}',
            path: {
                'user_block_id': userBlockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
