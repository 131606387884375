<script setup lang="ts">
// const chatEnabled = defineModel('chatEnabled', {
//   type: [Boolean, null],
//   required: true,
// });
// const subtitlesEnabled = defineModel('subtitlesEnabled', {
//   type: [Boolean, null],
//   required: true,
// });
// const languageLevel = defineModel('languageLevel', {
//   type: [String, null],
//   required: false,
// });

import { useAuthStore } from '@/stores';
import { ref, watch } from 'vue';
const authStore = useAuthStore();

const localChatEnabled = ref(authStore.currentChatEnabled);
const localSubtitlesEnabled = ref(authStore.currentSubtitlesEnabled);
const localLanguageLevel = ref(authStore.currentLanguageLevel);

const props = defineProps({
  showDetailedLabels: {
    type: Boolean,
    default: false,
  },
  showLanguageLevelSelect: {
    type: Boolean,
    default: false,
  },
  showChatInputToggle: {
    type: Boolean,
    default: true,
  },
  showSubtitlesToggle: {
    type: Boolean,
    default: true,
  },
  bgTransparent: {
    type: Boolean,
    default: false,
  },
  hideWhenSmallScreen: {
    type: Boolean,
    default: true,
  },
});

watch(
  localChatEnabled,
  (value) => {
    authStore.temporarilySetChatEnabled(value);
  },
  { immediate: true },
);

watch(
  localSubtitlesEnabled,
  (value) => {
    authStore.temporarilySetSubtitlesEnabled(value);
  },
  { immediate: true },
);

watch(
  localLanguageLevel,
  (value) => {
    authStore.temporarilySetLanguageLevel(value);
  },
  { immediate: true },
);
</script>

<template>
  <div
    class="flex-col mt-1 px-1 justify-center items-center gap-2 py-1 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm focus:z-10 text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white"
    :class="{
      'hidden md:block': props.hideWhenSmallScreen,
      'px-3 py-4': props.showDetailedLabels,
      'bg-transparent': props.bgTransparent,
      'border border-gray-200 bg-white shadow-sm dark:bg-neutral-900 dark:border-neutral-700': !props.bgTransparent,
    }"
  >
    <div
      v-if="props.showChatInputToggle"
      class="flex justify-between items-center pb-1 gap-x-1"
      :class="{ 'pb-4': props.showDetailedLabels }"
    >
      <div v-show="props.showDetailedLabels" class="pr-8">
        <label for="hs-large-switch-chat" class="text-gray-800 dark:text-neutral-500">
          Chat-Input
          <!-- TODO i18n -->
        </label>
      </div>
      <div class="relative inline-block">
        <input
          v-model="localChatEnabled"
          type="checkbox"
          id="hs-large-switch-chat"
          class="peer relative w-[3.25rem] h-7 p-px bg-gray-100 hover:bg-gray-200 border border-gray-200 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-gray-500 checked:border-gray-200 focus:checked:border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-800/30 dark:checked:border-gray-800 dark:focus:ring-offset-gray-800 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-gray-800 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-gray-500"
        />
        <label for="hs-default-switch-soft-with-icons" class="sr-only">switch</label>
        <span
          class="hidden lg:flex peer-checked:text-gray-800 text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span translate="no" class="material-symbols-outlined notranslate"> keyboard </span>
        </span>
        <span
          class="flex peer-checked:text-white text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span translate="no" class="material-symbols-outlined notranslate"> keyboard </span>
        </span>
        <span
          class="hidden peer-checked:flex peer-checked:text-white w-6 h-6 absolute top-0.5 end-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <svg
            class="flex-shrink-0 h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </div>
    </div>

    <div v-if="props.showSubtitlesToggle" class="flex justify-between items-center gap-x-1">
      <div v-show="props.showDetailedLabels" class="pr-8">
        <label for="hs-large-switch-chat" class="text-gray-800 dark:text-neutral-500">
          Untertitel
          <!-- TODO i18n -->
        </label>
      </div>
      <div class="relative inline-block">
        <input
          v-model="localSubtitlesEnabled"
          type="checkbox"
          id="hs-large-switch-subtitles"
          class="peer relative w-[3.25rem] h-7 p-px bg-gray-100 hover:bg-gray-200 border border-gray-200 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-gray-500 checked:border-gray-200 focus:checked:border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-800/30 dark:checked:border-gray-800 dark:focus:ring-offset-gray-800 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-gray-800 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-gray-500"
        />
        <label for="hs-default-switch-soft-with-icons" class="sr-only">switch</label>
        <span
          class="hidden lg:flex peer-checked:text-gray-800 text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span translate="no" class="material-symbols-outlined notranslate"> closed_caption </span>
        </span>
        <span
          class="flex peer-checked:text-white text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span translate="no" class="material-symbols-outlined notranslate"> closed_caption </span>
        </span>
        <span
          class="hidden peer-checked:flex peer-checked:text-white w-6 h-6 absolute top-0.5 end-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <svg
            class="flex-shrink-0 h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </div>
    </div>

    <div
      class="flex justify-between items-center gap-x-1"
      :class="{ 'pt-4': props.showDetailedLabels && (props.showChatInputToggle || props.showSubtitlesToggle) }"
      v-if="props.showLanguageLevelSelect"
    >
      <div v-show="props.showDetailedLabels" class="pr-8">
        <label for="hs-large-switch-chat" class="text-gray-800 dark:text-neutral-500">
          Sprachniveau
          <!-- TODO i18n -->
        </label>
      </div>
      <div class="relative inline-block">
        <!-- Select -->
        <select
          v-model="localLanguageLevel"
          data-hs-select='{
          "placeholder": "Select option...",
          "toggleTag": "<button type=\"button\" aria-expanded=\"false\"><span class=\"text-gray-800 dark:text-neutral-200 \" data-title></span></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-[3px] pl-2 pe-6 flex items-center text-nowrap w-full cursor-pointer bg-gray-100 hover:bg-gray-200 border border-gray-200 rounded-full text-start text-sm focus:border-gray-300 focus:ring-gray-300 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600",
          "dropdownClasses": "mt-2 z-50 w-72 max-h-fit p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-lg [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-justify text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
          "optionTemplate": "<div><div class=\"flex items-center\"><div class=\"font-semibold text-gray-800 dark:text-neutral-200 \" data-title></div></div><div class=\"mt-1.5 text-sm text-gray-500 dark:text-neutral-500 \" data-description></div></div>",
          "extraMarkup": "<div class=\"absolute top-1/2 end-1 -translate-y-1/2\"><svg class=\"shrink-0 size-3.5 text-gray-500 dark:text-neutral-500 \" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
        }'
          class="hidden"
        >
          <option value="">Choose</option>
          <option
            value="SIMPLE"
            selected=""
            data-hs-select-option='{
      "description": "Select this if you just started and want to get into talking (e.g., GER A1-A2).",
      "icon": ""
    }'
          >
            Simple
          </option>
          <option
            value="MEDIUM"
            selected=""
            data-hs-select-option='{
      "description": "Select this if you somewhat advanced but feel that realistic simulations are still a bit too hard (e.g., GER A2-B1).",
      "icon": ""
    }'
          >
            Advanced
          </option>
          <option
            value="NATIVE"
            data-hs-select-option='{
      "description": "Select this for completely natural and realistic conversations (if your are, e.g., B2-C1 level).",
      "icon": ""
    }'
          >
            Realistic
          </option>
        </select>
        <!--        <input-->
        <!--            v-model="languageLevel"-->
        <!--            type="checkbox"-->
        <!--            id="hs-large-switch-subtitles"-->
        <!--            class="peer relative w-[3.25rem] h-7 p-px bg-gray-100 border border-gray-200 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-gray-500 checked:border-gray-200 focus:checked:border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-800/30 dark:checked:border-gray-800 dark:focus:ring-offset-gray-800 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-gray-800 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-gray-500"-->
        <!--        />-->
        <label for="hs-default-switch-soft-with-icons" class="sr-only">switch</label>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
