<script setup>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { boolean } from 'yup';

const router = useRouter();
const props = defineProps({
  text: {
    type: String,
    default: 'Abschicken',
  },
  type: {
    type: String,
    default: 'submit',
  },
  showProgress: {
    default: false,
  },
  onclick: {
    default: null,
  },
  disabled: {
    default: false,
  },
  color: {
    type: String,
    default: 'blue',
  },
  size: {
    type: String,
    default: 'sm',
  },
  additionalClasses: {
    type: String,
    default: '',
  },
  routerLinkTo: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  iconLeft: {
    type: Boolean,
    default: true,
  },
  iconSize: {
    type: String,
    default: null,
  },
  fontMedium: {
    type: Boolean,
    default: true,
  },
});

const colorClass = computed(() => {
  let darkness = props.color !== 'win-blue' ? '600' : '300';
  let darknessHovered = props.color !== 'win-blue' ? '700' : '700';
  let color = props.color !== 'win-blue' ? props.color : 'blue';
  return props.color === 'white'
    ? `bg-white focus:ring-gray-200 text-gray-800 border-gray-200 hover:bg-gray-50 font-medium`
    : `bg-${color}-${darkness} focus:ring-${color}-${darkness} hover:focus:ring-${color}-${darknessHovered} hover:bg-${color}-${darknessHovered} text-white border-transparent font-medium`;
});

const sizeClasses = computed(() => ({
  sm: 'py-2 px-3 text-xs md:text-sm min-w-16 md:min-w-24 min-h-8 md:min-h-10',
  md: 'py-3 px-4 text-sm md:text-base min-w-24 md:min-w-28 min-h-10 md:min-h-12',
  lg: 'py-4 px-5 text-base md:text-lg min-w-28 md:min-w-36 min-h-12 md:min-h-14',
}));

const iconSizeClasses = computed(() => {
  if (props.iconSize) {
    return {
      sm: `text-${props.iconSize}`,
      md: `text-${props.iconSize}`,
      lg: `text-${props.iconSize}`,
    };
  }
  return {
    sm: 'text-sm',
    md: 'text-sm',
    lg: 'text-md',
  };
});

const iconClasses = computed(() => [iconSizeClasses.value[props.size]]);

const buttonClasses = computed(() => [
  'inline-flex',
  'justify-center',
  'items-center',
  'content-center',
  'align-middle',
  'gap-2',
  'rounded-lg',
  'border',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'disabled:pointer-events-none',
  'transition-all',
  'shadow-sm',
  'dark:focus:ring-offset-gray-800',
  colorClass.value,
  sizeClasses.value[props.size],
  props.fontMedium ? 'font-medium' : 'font-normal',
]);

const onclick = computed(() => {
  if (props.routerLinkTo) {
    return () => {
      router.push(props.routerLinkTo);
    };
  }
  return props.onclick;
});
</script>

<template>
  <button
    :disabled="disabled"
    type="{{ type }}"
    :class="[buttonClasses, { 'opacity-50': disabled }]"
    @click="onclick && onclick()"
  >
    <span
      v-if="showProgress"
      class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading</span>
    </span>
    <span v-else class="inline-flex h-full items-center">
      <span
        v-show="iconLeft && !!icon"
        class="-ml-1 mr-1 -my-1 material-symbols-outlined flex align-bottom"
        :class="iconClasses"
      >
        {{ icon }}
      </span>
      <span class="text-center">{{ text }}</span>
      <span class="flex items-center min-h-full">
        <slot></slot>
      </span>
      <span
        v-show="!iconLeft && !!icon"
        class="ml-2 -my-1 material-symbols-outlined flex align-bottom"
        :class="iconClasses"
      >
        {{ icon }}
      </span>
    </span>
  </button>
</template>
