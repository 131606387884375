/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Attending } from '../models/Attending';
import type { AttendingCreate } from '../models/AttendingCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AttendingsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Attendings
   * @returns Attending Successful Response
   * @throws ApiError
   */
  public listAttendings(): CancelablePromise<Array<Attending>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/attendings/',
    });
  }

  /**
   * Get Attending
   * @param attendingId
   * @returns Attending Successful Response
   * @throws ApiError
   */
  public getAttending(attendingId: string): CancelablePromise<Attending> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/attendings/{attending_id}',
      path: {
        attending_id: attendingId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Attending
   * @param requestBody
   * @returns Attending Successful Response
   * @throws ApiError
   */
  public createAttending(requestBody: AttendingCreate): CancelablePromise<Attending> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/attendings/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
