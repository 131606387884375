/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_append_section_media_item_course_section_items_media__section_id__post } from '../models/Body_append_section_media_item_course_section_items_media__section_id__post';
import type { PairOfTermsGameUpdate } from '../models/PairOfTermsGameUpdate';
import type { SectionContentItem_Output } from '../models/SectionContentItem_Output';
import type { SectionContentItemCreateResponse } from '../models/SectionContentItemCreateResponse';
import type { SectionContentItemVocabResponse } from '../models/SectionContentItemVocabResponse';
import type { TextItemCreate } from '../models/TextItemCreate';
import type { TextItemUpdate } from '../models/TextItemUpdate';
import type { VocabItemCreate } from '../models/VocabItemCreate';
import type { VocabItemUpdate } from '../models/VocabItemUpdate';
import type { VocabItemUpdateResponse } from '../models/VocabItemUpdateResponse';
import type { VocabListDetails } from '../models/VocabListDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CourseSectionItemsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Append Section Content Item With Text Content
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithTextContent(
    sectionId: string,
    requestBody: TextItemCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/text/{section_id}',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Pair Of Terms Game
   * @param sectionId
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithPairOfTermsGame(
    sectionId: string,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/pair-of-terms-game/{section_id}',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Content Item With Pair Of Terms Game
   * :param database_client:
   * :param section_content_item_id:
   * :param pair_of_terms_game: list with all item tuples.
   * :param user:
   * :param purge_unlisted: Existing tuples without an entry in the list will be deleted.
   * :return:
   * @param sectionContentItemId
   * @param requestBody
   * @param purgeUnlisted
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public updateSectionContentItemWithPairOfTermsGame(
    sectionContentItemId: string,
    requestBody: PairOfTermsGameUpdate,
    purgeUnlisted: boolean = true,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/pair-of-terms-game/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      query: {
        purge_unlisted: purgeUnlisted,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Content Item With Text Content
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItem_Output Successful Response
   * @throws ApiError
   */
  public updateSectionContentItemWithTextContent(
    sectionContentItemId: string,
    requestBody: TextItemUpdate,
  ): CancelablePromise<SectionContentItem_Output> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/course-section-items/text/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Section Content Item
   * @param sectionContentItemId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteSectionContentItem(sectionContentItemId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Media Item
   * @param sectionId
   * @param formData
   * @param description
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionMediaItem(
    sectionId: string,
    formData: Body_append_section_media_item_course_section_items_media__section_id__post,
    description?: string | null,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/media/{section_id}',
      path: {
        section_id: sectionId,
      },
      query: {
        description: description,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Media Item Description
   * @param sectionContentItemId
   * @param description
   * @returns any Successful Response
   * @throws ApiError
   */
  public updateSectionMediaItemDescription(sectionContentItemId: string, description: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-itemsmedia/{section_content_item_id}/description',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      query: {
        description: description,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Case
   * @param sectionId
   * @param caseId
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithCase(
    sectionId: string,
    caseId: string,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/cases/{section_id}',
      path: {
        section_id: sectionId,
      },
      query: {
        case_id: caseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Emtpy Vocab List
   * @param sectionId
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithEmtpyVocabList(
    sectionId: string,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Vocab List Extracting From String
   * @param sectionId
   * @param textToExtractFrom
   * @param includeVocab
   * @param includePhrases
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithVocabListExtractingFromString(
    sectionId: string,
    textToExtractFrom: string,
    includeVocab: boolean = true,
    includePhrases: boolean = false,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_id}/extract',
      path: {
        section_id: sectionId,
      },
      query: {
        text_to_extract_from: textToExtractFrom,
        include_vocab: includeVocab,
        include_phrases: includePhrases,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add More Vocab Of Same Context To Vocab List
   * @param sectionContentItemId
   * @param context
   * @param includeVocab
   * @param includePhrases
   * @returns SectionContentItemVocabResponse Successful Response
   * @throws ApiError
   */
  public addMoreVocabOfSameContextToVocabList(
    sectionContentItemId: string,
    context: string,
    includeVocab: boolean = true,
    includePhrases: boolean = false,
  ): CancelablePromise<SectionContentItemVocabResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/vocab-list/{section_id}/more',
      query: {
        section_content_item_id: sectionContentItemId,
        context: context,
        include_vocab: includeVocab,
        include_phrases: includePhrases,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Vocab Item To Content Item With Vocab List
   * Note: the path over the content item rather than the vocab list directly allows to properly check
   * for editing rights to the section when deciding if allowed to add, edit a vocab item or not etc.
   * :param database_client:
   * :param section_content_item_id:
   * :param user:
   * :param vocab_item:
   * :return:
   * @param sectionContentItemId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public addVocabItemToContentItemWithVocabList(
    sectionContentItemId: string,
    requestBody: VocabItemCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_id}/single',
      query: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Vocab Item In Content Item With Vocab List
   * @param sectionContentItemId
   * @param vocabItemId
   * @param requestBody
   * @returns VocabItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateVocabItemInContentItemWithVocabList(
    sectionContentItemId: string,
    vocabItemId: string,
    requestBody: VocabItemUpdate,
  ): CancelablePromise<VocabItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/vocab-list/{section_content_item_id}/single/{vocab_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        vocab_item_id: vocabItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Vocab List For Content Item
   * @param sectionContentItemId
   * @returns VocabListDetails Successful Response
   * @throws ApiError
   */
  public getVocabListForContentItem(sectionContentItemId: string): CancelablePromise<VocabListDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-section-items/vocab-list/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
