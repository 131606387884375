/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Specialty } from '../models/Specialty';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SpecialtiesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Available Specialties
   * @returns Specialty Successful Response
   * @throws ApiError
   */
  public getAvailableSpecialties(): CancelablePromise<Array<Specialty>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/specialties/',
    });
  }
}
