/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseInteraction } from '../models/CourseInteraction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CourseInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Delete Course Interaction
   * @param courseInteractionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCourseInteraction(courseInteractionId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-interactions/{course_interaction_id}',
      path: {
        course_interaction_id: courseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Course Interaction
   * @param courseInteractionId
   * @returns CourseInteraction Successful Response
   * @throws ApiError
   */
  public getCourseInteraction(courseInteractionId: string): CancelablePromise<CourseInteraction> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-interactions/{course_interaction_id}',
      path: {
        course_interaction_id: courseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Course Interactions
   * @returns CourseInteraction Successful Response
   * @throws ApiError
   */
  public listCourseInteractions(): CancelablePromise<Array<CourseInteraction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-interactions/',
    });
  }
}
