/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_generate_or_refresh_access_token_auth_token_post } from '../models/Body_generate_or_refresh_access_token_auth_token_post';
import type { EmailSent } from '../models/EmailSent';
import type { InviteCreate } from '../models/InviteCreate';
import type { LoginResponse } from '../models/LoginResponse';
import type { OTPToken } from '../models/OTPToken';
import type { OTPTokenWithPassword } from '../models/OTPTokenWithPassword';
import type { PasswordChange } from '../models/PasswordChange';
import type { ResetPasswordData } from '../models/ResetPasswordData';
import type { SignUpConfimationSuccess } from '../models/SignUpConfimationSuccess';
import type { SignUpData } from '../models/SignUpData';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Sign Up
   * :param database_client:
   * :param data:
   * :return:
   * @param requestBody
   * @returns EmailSent Successful Response
   * @throws ApiError
   */
  public signUp(requestBody: SignUpData): CancelablePromise<EmailSent> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/sign-up',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Confirm Sign Up
   * @param requestBody
   * @returns SignUpConfimationSuccess Successful Response
   * @throws ApiError
   */
  public confirmSignUp(requestBody: OTPToken): CancelablePromise<SignUpConfimationSuccess> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/sign-up/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Generate Or Refresh Access Token
   * @param formData
   * @returns LoginResponse Successful Response
   * @throws ApiError
   */
  public generateOrRefreshAccessToken(
    formData?: Body_generate_or_refresh_access_token_auth_token_post,
  ): CancelablePromise<LoginResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/token',
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sign Out
   * @returns any Successful Response
   * @throws ApiError
   */
  public signOut(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/sign-out',
    });
  }

  /**
   * Reset Password
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public resetPassword(requestBody: ResetPasswordData): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/reset-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Confirm Reset Password
   * @param requestBody
   * @returns Token Successful Response
   * @throws ApiError
   */
  public confirmResetPassword(requestBody: OTPToken): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/reset-password/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Change Password
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public changePassword(requestBody: PasswordChange): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/change-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Invite
   * @param requestBody
   * @returns EmailSent Successful Response
   * @throws ApiError
   */
  public invite(requestBody: InviteCreate): CancelablePromise<EmailSent> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/invite',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Accept Invite
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public acceptInvite(requestBody: OTPTokenWithPassword): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/invite/accept',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
