/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Course } from '../models/Course';
import type { CourseCreate } from '../models/CourseCreate';
import type { CourseInteraction } from '../models/CourseInteraction';
import type { CourseOverview } from '../models/CourseOverview';
import type { CourseUpdate } from '../models/CourseUpdate';
import type { EnrollCourseResponse } from '../models/EnrollCourseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoursesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Courses
   * @returns CourseOverview Successful Response
   * @throws ApiError
   */
  public listCourses(): CancelablePromise<Array<CourseOverview>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/courses/',
    });
  }

  /**
   * Create Course
   * @param requestBody
   * @returns Course Successful Response
   * @throws ApiError
   */
  public createCourse(requestBody: CourseCreate): CancelablePromise<Course> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/courses/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Course
   * @param courseId
   * @param loadNExemplaryCasesPerChapter
   * @returns Course Successful Response
   * @throws ApiError
   */
  public getCourse(courseId: string, loadNExemplaryCasesPerChapter?: number): CancelablePromise<Course> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/courses/{course_id}',
      path: {
        course_id: courseId,
      },
      query: {
        load_n_exemplary_cases_per_chapter: loadNExemplaryCasesPerChapter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Course
   * :param database_client:
   * :param course_id:
   * :param course:
   * :param user:
   * :return: CourseOverview, avoiding to reload chapter
   * @param courseId
   * @param requestBody
   * @returns CourseOverview Successful Response
   * @throws ApiError
   */
  public updateCourse(courseId: string, requestBody: CourseUpdate): CancelablePromise<CourseOverview> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/courses/{course_id}',
      path: {
        course_id: courseId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Course Interaction
   * @param courseId
   * @returns EnrollCourseResponse Successful Response
   * @throws ApiError
   */
  public createCourseInteraction(courseId: string): CancelablePromise<EnrollCourseResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/courses/{course_id}/enroll',
      path: {
        course_id: courseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Try Get Course Interaction
   * :param database_client:
   * :param user:
   * :param course_id:
   * :return: None if not interaction for this user and course
   * @param courseId
   * @returns any Successful Response
   * @throws ApiError
   */
  public tryGetCourseInteraction(courseId: string): CancelablePromise<CourseInteraction | null> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/courses/{course_id}/try-get-interaction',
      path: {
        course_id: courseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
