/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LearningObjective } from '../models/LearningObjective';
import type { Section } from '../models/Section';
import type { SectionCreate } from '../models/SectionCreate';
import type { SectionInteraction } from '../models/SectionInteraction';
import type { SectionUpdate } from '../models/SectionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SectionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Section
   * @param sectionId
   * @returns Section Successful Response
   * @throws ApiError
   */
  public getSection(sectionId: string): CancelablePromise<Section> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/sections/{section_id}',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section
   * :param database_client:
   * :param section_id:
   * :param section:
   * :param user:
   * :return: SectionOverview, avoiding to reload case list
   * @param sectionId
   * @param requestBody
   * @returns Section Successful Response
   * @throws ApiError
   */
  public updateSection(sectionId: string, requestBody: SectionUpdate): CancelablePromise<Section> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/sections/{section_id}',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Section
   * @param sectionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteSection(sectionId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/sections/{section_id}',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Learning Objectives Of Section
   * @param sectionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getLearningObjectivesOfSection(sectionId: string): CancelablePromise<Array<LearningObjective> | null> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/sections/{section_id}/learning-objectives',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Section
   * @param requestBody
   * @returns Section Successful Response
   * @throws ApiError
   */
  public createSection(requestBody: SectionCreate): CancelablePromise<Section> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sections/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch Or Create Section Interaction
   * @param sectionId
   * @returns SectionInteraction Successful Response
   * @throws ApiError
   */
  public fetchOrCreateSectionInteraction(sectionId: string): CancelablePromise<SectionInteraction> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/sections/{section_id}/interact',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Section Translations
   * :param database_client:
   * :param user:
   * :param section_id:
   * :param target_language_codes: all supported if None
   * :param input_language_code: deu by eefault
   * :return:
   * @param sectionId
   * @param inputLanguageCode
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestSectionTranslations(
    sectionId: string,
    inputLanguageCode: string = 'deu',
    requestBody?: Array<string>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/sections/{section_id}/translations',
      path: {
        section_id: sectionId,
      },
      query: {
        input_language_code: inputLanguageCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Extract Section Vocabulary And Example Formulations
   * @param sectionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public extractSectionVocabularyAndExampleFormulations(sectionId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/sections/{section_id}/vocabulary',
      path: {
        section_id: sectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
