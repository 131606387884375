<script setup lang="ts"></script>

<template>
  <div class="min-w-7 min-h-7 w-full inline-flex items-center text-xs align-middle">
    <span
      class="h-7 w-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-600 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600"
    >
      <span class="hs-stepper-success:hidden hs-stepper-completed:hidden"> 2 </span>
      <svg
        class="hidden flex-shrink-0 h-3 w-3 hs-stepper-success:block"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </span>
    <div
      class="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"
    ></div>
  </div>
  <div class="mt-3">
    <span class="block text-sm font-medium text-gray-800 dark:text-white"> Beteiligte </span>
  </div>
</template>

<style scoped></style>
