/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryStatistics } from '../models/CountryStatistics';
import type { LanguageStatistics } from '../models/LanguageStatistics';
import type { Organization } from '../models/Organization';
import type { OrganizationCreate } from '../models/OrganizationCreate';
import type { OrganizationEdit } from '../models/OrganizationEdit';
import type { OrganizationMember } from '../models/OrganizationMember';
import type { OrganizationMemberCreate } from '../models/OrganizationMemberCreate';
import type { OrganizationMemberDetails } from '../models/OrganizationMemberDetails';
import type { OrganizationMemberEdit } from '../models/OrganizationMemberEdit';
import type { OrganizationSeats } from '../models/OrganizationSeats';
import type { OrganizationSubscription } from '../models/OrganizationSubscription';
import type { OrganizationSubscriptionCreate } from '../models/OrganizationSubscriptionCreate';
import type { OrganizationSubscriptionDetails } from '../models/OrganizationSubscriptionDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Organizations
   * Retrieve a list of organizations.
   *
   * Args:
   *
   * Returns:
   * List[models.Organization]: A list of organization objects.
   * @returns Organization Successful Response
   * @throws ApiError
   */
  public listOrganizations(): CancelablePromise<Array<Organization>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/',
    });
  }

  /**
   * Create Organization
   * Create a new organization.
   *
   * Args:
   * organization (models.OrganizationCreate): The organization data to be created.
   *
   * Returns:
   * models.Organization: The created organization.
   * @param requestBody
   * @returns Organization Successful Response
   * @throws ApiError
   */
  public createOrganization(requestBody: OrganizationCreate): CancelablePromise<Organization> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/organizations/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization
   * Retrieve an organization by its ID.
   *
   * Args:
   * organization_id (uuid.UUID): The ID of the organization to retrieve.
   *
   * Returns:
   * models.Organization: The organization object.
   * @param organizationId
   * @returns Organization Successful Response
   * @throws ApiError
   */
  public getOrganization(organizationId: string): CancelablePromise<Organization> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}',
      path: {
        organization_id: organizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Organization
   * Edit an organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client.
   * user (CurrentUserByToken): The current user.
   * organization_id (uuid.UUID): The ID of the organization to edit.
   * organization (models.OrganizationEdit): The updated organization data.
   *
   * Returns:
   * models.Organization: The edited organization.
   * @param organizationId
   * @param requestBody
   * @returns Organization Successful Response
   * @throws ApiError
   */
  public editOrganization(organizationId: string, requestBody: OrganizationEdit): CancelablePromise<Organization> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/organizations/{organization_id}',
      path: {
        organization_id: organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization Seats
   * @param organizationId
   * @returns OrganizationSeats Successful Response
   * @throws ApiError
   */
  public getOrganizationSeats(organizationId: string): CancelablePromise<OrganizationSeats> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/seats',
      path: {
        organization_id: organizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization Language Stats
   * Retrieve language statistics for a specific organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client used to retrieve data.
   * user (CurrentUserByToken): The current user making the request.
   * organization_id (uuid.UUID): The ID of the organization.
   * kind (Literal["native_language", "ingame_language"], optional): The type of language statistics to retrieve. Defaults to "native_language".
   *
   * Returns:
   * List[models.LanguageStatistics]: A list of language statistics for the organization.
   * @param organizationId
   * @param kind
   * @returns LanguageStatistics Successful Response
   * @throws ApiError
   */
  public getOrganizationLanguageStats(
    organizationId: string,
    kind: 'native_language' | 'ingame_language' = 'native_language',
  ): CancelablePromise<Array<LanguageStatistics>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/stats/language',
      path: {
        organization_id: organizationId,
      },
      query: {
        kind: kind,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization Country Stats
   * Retrieve country statistics for a specific organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client used to query the database.
   * user (CurrentUserByToken): The current user making the request.
   * organization_id (uuid.UUID): The ID of the organization.
   * kind (Literal["country_of_origin"], optional): The type of country statistics to retrieve. Defaults to "country_of_origin".
   *
   * Returns:
   * List[models.CountryStatistics]: A list of country statistics for the organization.
   * @param organizationId
   * @param kind
   * @returns CountryStatistics Successful Response
   * @throws ApiError
   */
  public getOrganizationCountryStats(
    organizationId: string,
    kind: 'country_of_origin' = 'country_of_origin',
  ): CancelablePromise<Array<CountryStatistics>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/stats/country',
      path: {
        organization_id: organizationId,
      },
      query: {
        kind: kind,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Organization Members
   * Retrieve a list of organization members.
   *
   * Args:
   * database_client (DatabaseClient): The database client used to interact with the database.
   * user (CurrentUserByToken): The current user making the request.
   * organization_id (uuid.UUID): The ID of the organization.
   * filter (OrganizationMemberFilter, optional): The filter to apply to the member list. Defaults to FilterDepends(OrganizationMemberFilter).
   *
   * Returns:
   * List[models.OrganizationMemberDetails]: A list of organization member details.
   * @param organizationId
   * @param search
   * @param orderBy
   * @param isManager
   * @returns OrganizationMemberDetails Successful Response
   * @throws ApiError
   */
  public listOrganizationMembers(
    organizationId: string,
    search?: string | null,
    orderBy?: string | null,
    isManager?: boolean | null,
  ): CancelablePromise<Array<OrganizationMemberDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/members',
      path: {
        organization_id: organizationId,
      },
      query: {
        search: search,
        order_by: orderBy,
        is_manager: isManager,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Member To Organization
   * Add a member to an organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client used to interact with the database.
   * user (CurrentUserByToken): The current user making the request.
   * organization_id (uuid.UUID): The ID of the organization to add the member to.
   * organization_member (models.OrganizationMemberCreate): The details of the member to add.
   *
   * Returns:
   * models.OrganizationMemberDetails: The details of the added member.
   * @param organizationId
   * @param requestBody
   * @returns OrganizationMember Successful Response
   * @throws ApiError
   */
  public addMemberToOrganization(
    organizationId: string,
    requestBody: OrganizationMemberCreate,
  ): CancelablePromise<OrganizationMember> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/organizations/{organization_id}/members',
      path: {
        organization_id: organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Member Of Organization
   * Edit a member of an organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client.
   * user (CurrentUserByToken): The current user.
   * organization_id (uuid.UUID): The ID of the organization.
   * user_id (uuid.UUID): The ID of the user.
   * organization_member_edit (models.OrganizationMemberEdit): The edited organization member.
   *
   * Returns:
   * models.OrganizationMember: The updated organization member.
   * @param organizationId
   * @param userId
   * @param requestBody
   * @returns OrganizationMember Successful Response
   * @throws ApiError
   */
  public editMemberOfOrganization(
    organizationId: string,
    userId: string,
    requestBody: OrganizationMemberEdit,
  ): CancelablePromise<OrganizationMember> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/organizations/{organization_id}/members/{user_id}',
      path: {
        organization_id: organizationId,
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Member From Organization
   * Remove a member from an organization.
   *
   * Args:
   * database_client (DatabaseClient): The database client used to interact with the database.
   * user (CurrentUserByToken): The current user making the request.
   * organization_id (uuid.UUID): The ID of the organization.
   * user_id (uuid.UUID): The ID of the user to remove from the organization.
   *
   * Returns:
   * None
   * @param organizationId
   * @param userId
   * @returns any Successful Response
   * @throws ApiError
   */
  public removeMemberFromOrganization(organizationId: string, userId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/organizations/{organization_id}/members/{user_id}',
      path: {
        organization_id: organizationId,
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Organization Subscriptions
   * List subscriptions for an organization.
   *
   * Args:
   * organization_id (uuid.UUID): The ID of the organization.
   * active_only (bool | None): Whether to list only active subscriptions.
   *
   * Returns:
   * List[models.Subscription]: A list of subscriptions.
   * @param organizationId
   * @param active
   * @returns OrganizationSubscriptionDetails Successful Response
   * @throws ApiError
   */
  public listOrganizationSubscriptions(
    organizationId: string,
    active?: boolean | null,
  ): CancelablePromise<Array<OrganizationSubscriptionDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/subscriptions',
      path: {
        organization_id: organizationId,
      },
      query: {
        active: active,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Subscribe Organization To Plan
   * Subscribes an organization to a plan.
   *
   * Args:
   * organization_id (uuid.UUID): The ID of the organization.
   * user (CurrentUserByToken): The current user making the request.
   * database_client (DatabaseClient): The database client used to interact with the database.
   * organization_subscription (models.OrganizationSubscriptionCreate): The details of the organization subscription.
   *
   * Raises:
   * NotEnoughPermissionsError: If the user is not an admin.
   *
   * Returns:
   * models.OrganizationSubscription: The created organization subscription.
   * @param organizationId
   * @param requestBody
   * @returns OrganizationSubscription Successful Response
   * @throws ApiError
   */
  public subscribeOrganizationToPlan(
    organizationId: string,
    requestBody: OrganizationSubscriptionCreate,
  ): CancelablePromise<OrganizationSubscription> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/organizations/{organization_id}/subscriptions',
      path: {
        organization_id: organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization Subscription
   * Retrieve details of a specific organization subscription.
   *
   * Args:
   * organization_id (uuid.UUID): The ID of the organization.
   * user (CurrentUserByToken): The current user making the request.
   * database_client (DatabaseClient): The database client used to retrieve the subscription details.
   * subscription_id (uuid.UUID): The ID of the subscription.
   *
   * Returns:
   * models.OrganizationSubscriptionDetails: The details of the organization subscription.
   * @param organizationId
   * @param subscriptionId
   * @returns OrganizationSubscriptionDetails Successful Response
   * @throws ApiError
   */
  public getOrganizationSubscription(
    organizationId: string,
    subscriptionId: string,
  ): CancelablePromise<OrganizationSubscriptionDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}/subscriptions/{subscription_id}',
      path: {
        organization_id: organizationId,
        subscription_id: subscriptionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
