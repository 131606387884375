<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue';
import { useAuthStore, useEvaluationStore, useAlertStore } from '@/stores';
import { router } from '@/router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import LearningObjectiveEvaluationCard from '@/components/LearningObjectiveEvaluationCard.vue';

const { t } = useI18n();
import ProgressButton from '@/components/ProgressButton.vue';
import { HSCollapse, HSStaticMethods } from 'preline';

const authStore = useAuthStore();
const evaluationStore = useEvaluationStore();
const alertStore = useAlertStore();
const { user } = storeToRefs(authStore);
const { learningObjectivesEvaluation } = storeToRefs(evaluationStore);

const isLoading = ref(false);
const hasBeenEvaluated = ref(false);
const originChapterId = ref('');

onMounted(async () => {
  // get case interaction id from path
  await router.isReady();
  evaluationStore.reset();

  if (router.currentRoute.value.query.originChapterId) {
    const chapterId = router.currentRoute.value.query.originChapterId;
    console.debug('Chapter ID: ' + chapterId);
    originChapterId.value = chapterId;
    // set the learning objectives for the section
  } else {
    console.debug('No Chapter ID in query params');
  }

  console.log('Mounting Evaluation for case interaction id: ' + router.currentRoute.value.params.caseInteractionId);
  evaluationStore.setCaseInteractionId(router.currentRoute.value.params.caseInteractionId);
});

async function evaluateLearningObjectives() {
  // load evaluation
  isLoading.value = true;
  try {
    await evaluationStore.evaluateLearningObjectives();
    hasBeenEvaluated.value = true;
    await displayEarnedXp();
    await authStore.fetchUserXp(); // update displayed xp
  } catch (error) {
    console.error('Error while loading evaluation: ' + error);
  } finally {
    isLoading.value = false;
  }
}

async function displayEarnedXp() {
  let earnedXp = 0;
  if (!learningObjectivesEvaluation.value) {
    return;
  }
  learningObjectivesEvaluation.value.forEach((evaluation) => {
    earnedXp += evaluation.score;
  });
  alertStore.xp(t('message.xpCaseCompleted'), t('message.receivedXP', earnedXp));
}

async function backToOrigin() {
  isLoading.value = true;
  if (!!originChapterId.value) {
    await router.push('/chapter/' + originChapterId.value);
  } else {
    await router.push('/wartebereich-zna');
  }
  isLoading.value = false;
}
</script>

<template>
  <main class="flex-col flex gap-4 p-4 overflow-auto w-full h-full max-h-full">
    <div class="flex mx-auto items-center min-h-full" v-show="!hasBeenEvaluated">
      <ProgressButton
        :onclick="evaluateLearningObjectives"
        text="Jetzt auswerten"
        :showProgress="isLoading"
        type="button"
      />
    </div>
    <LearningObjectiveEvaluationCard
      v-for="evaluation in learningObjectivesEvaluation"
      :learningObjective="evaluation.learning_objective"
      :feedback="evaluation.feedback"
      :possibleImprovements="evaluation.aspects_to_improve"
      :score="evaluation.score"
    />
    <ProgressButton
      v-show="hasBeenEvaluated"
      :onclick="backToOrigin"
      :text="!!originChapterId ? 'Zurück zum Lehrbuch' : 'Zurück zur Notaufnahme'"
      :showProgress="isLoading"
      type="button"
    />
  </main>
</template>
