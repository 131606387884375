/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationSubscriptionPlan } from '../models/OrganizationSubscriptionPlan';
import type { OrganizationSubscriptionPlanCreate } from '../models/OrganizationSubscriptionPlanCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Organization Subscription Plan
   * @param requestBody
   * @returns OrganizationSubscriptionPlan Successful Response
   * @throws ApiError
   */
  public createOrganizationSubscriptionPlan(
    requestBody: OrganizationSubscriptionPlanCreate,
  ): CancelablePromise<OrganizationSubscriptionPlan> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/subscriptions/plans/organization',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Organization Subscription Plan
   * @param organizationSubscriptionPlanId
   * @returns OrganizationSubscriptionPlan Successful Response
   * @throws ApiError
   */
  public getOrganizationSubscriptionPlan(
    organizationSubscriptionPlanId: string,
  ): CancelablePromise<OrganizationSubscriptionPlan> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/subscriptions/plans/organization/{organization_subscription_plan_id}',
      path: {
        organization_subscription_plan_id: organizationSubscriptionPlanId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
