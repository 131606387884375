<script setup lang="ts">
import { ref } from 'vue';
import CategoryZone from '@/components/didactics/categorization/CategoryZone.vue';
import DraggableItem from '@/components/didactics/categorization/DraggableItem.vue';

const categories = ref([
  { id: 'category1', name: 'Category 1', items: [] },
  { id: 'category2', name: 'Category 2', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
  { id: 'category3', name: 'Category 3', items: [] },
]);

const items = ref([
  { id: 'item1', content: 'Item 1', categoryId: 'category1', isIncorrect: false },
  { id: 'item2', content: 'Item 2', categoryId: 'category2', isIncorrect: false },
  { id: 'item3', content: 'Item 3', categoryId: 'category3', isIncorrect: false },
]);

// Function to handle dropping items in categories
const handleDrop = (itemId, categoryId) => {
  const itemIndex = items.value.findIndex((item) => item.id === itemId);
  const item = items.value[itemIndex];

  if (item) {
    const isCorrect = item.categoryId === categoryId;
    if (isCorrect) {
      // Place item in the correct category and remove from the waiting zone
      const category = categories.value.find((cat) => cat.id === categoryId);
      category.items.push(item);
      items.value.splice(itemIndex, 1);
    } else {
      // Trigger bounce-back effect and red background for incorrect items
      item.isIncorrect = true;
      setTimeout(() => {
        item.isIncorrect = false; // Reset after animation
      }, 500); // Match the bounce-back animation duration
    }
  }
};
</script>

<template>
  <div class="w-full p-4 flex flex-col gap-4">
    <h2 class="text-lg font-semibold text-center">Categorization Exercise</h2>

    <!-- Display categories as drop zones -->
    <div class="flex flex-wrap justify-around gap-1">
      <CategoryZone v-for="category in categories" :key="category.id" :category="category" @dropItem="handleDrop">
        <!-- Display items already placed in this category -->
        <DraggableItem v-for="item in category.items" :key="item.id" :item="item" :isInCategory="true" />
      </CategoryZone>
    </div>

    <!-- Waiting Zone for unplaced items -->
    <div class="flex flex-wrap gap-4 justify-center mt-6">
      <DraggableItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :isInCategory="false"
        :isIncorrect="item.isIncorrect"
      />
    </div>
  </div>
</template>

<style scoped>
/* Additional styling here if needed */
</style>
