<script setup lang="ts">
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { debounce } from 'lodash';

const outerVocabDashboardStatsHeaderContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(184);

const adjustHeight = () => {
  if (outerVocabDashboardStatsHeaderContainer.value) {
    fullHeight.value = outerVocabDashboardStatsHeaderContainer.value.offsetHeight;
  }
};

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

onMounted(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 10));
  console.log('VocabDashboardStatsHeader mounted: ', outerVocabDashboardStatsHeaderContainer.value);
  console.log('height is:', outerVocabDashboardStatsHeaderContainer.value?.offsetHeight);
  await nextTick(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    adjustHeight();
    window.addEventListener('resize', debouncedAdjustHeight);
  });
});

defineExpose({
  fullHeight,
});
</script>

<template>
  <!-- Stats Grid -->
  <!-- TODO i18n -->
  <div
    ref="outerVocabDashboardStatsHeaderContainer"
    class="grid grid-cols-3 overflow-visible h-fit md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-3 xl:gap-3"
  >
    <div class="row-span-2 hidden xl:block">
      <SimpleStatsCard
        title="Current score"
        value="89"
        unit="%"
        recentChange="2"
        :showCentered="true"
        :increasedFontSize="true"
      />
    </div>
    <div class="block xl:hidden">
      <SimpleStatsCard title="Current score" value="89" unit="%" recentChange="2" />
    </div>
    <SimpleStatsCard title="All-time best" :value="93" unit="%" />
    <SimpleStatsCard title="Current streak" :value="4" unit="days" />
    <SimpleStatsCard title="Longest streak" :value="12" unit="days" />
    <SimpleStatsCard title="Tests taken per week" :value="4.5" :recent-change="-0.2" />
    <SimpleStatsCard title="Number of tests taken" :value="200" :recent-change="2" />
  </div>
  <!-- End Stats Grid -->
</template>

<style scoped></style>
