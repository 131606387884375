/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VocabsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Translate Vocab Test
   * Returns n_vocabularies pairs of patient_language vocabularies and their
   * translations to user_language.
   *
   * :param database_client:
   * :param request: by injection, to track disconnect
   * :param user:
   * :param case_interaction_id:
   * :param patient_language:
   * :param user_language:
   * :param n_vocabularies:
   * :param probability_main_medical_condition: probability of choosing a main medical condition. One minus probability:
   * probability to choose from one of the preexisting medical conditions
   * :param probability_layman_terms: probability of choosing a layman's term. One minus probability: Probability to
   * choose from one of the expert terms
   *
   * rationale:
   * # - randomly assign the n_vocabularies to the disease vignettes of the case interaction
   * # - randomly choose from expert or layman terms
   * # - translate to patient_language (if different from German). Translate the translations (!!) to user_language
   * # - return as list of pairs
   * # - Note: no LLM call here, all precomputed
   * # TODO for later: choose by difficulty (not used currently)
   * # TODO for later: how can we store the translations in the DB factsheet_full?
   *
   * :return:
   * @param caseInteractionId
   * @param patientLanguage
   * @param userLanguage
   * @param nVocabularies
   * @param probabilityMainMedicalCondition
   * @param probabilityLaymanTerms
   * @returns any Successful Response
   * @throws ApiError
   */
  public getTranslateVocabTest(
    caseInteractionId: string,
    patientLanguage: string,
    userLanguage: string,
    nVocabularies: number = 12,
    probabilityMainMedicalCondition: number = 0.5,
    probabilityLaymanTerms: number = 0.6,
  ): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/vocabs/translate',
      query: {
        case_interaction_id: caseInteractionId,
        patient_language: patientLanguage,
        user_language: userLanguage,
        n_vocabularies: nVocabularies,
        probability_main_medical_condition: probabilityMainMedicalCondition,
        probability_layman_terms: probabilityLaymanTerms,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Score Vocab Translation
   * Scores translation of a single vocab.
   *
   * :param database_client:
   * :param user:
   * :param request: by injection, to track disconnect
   * :param case_interaction_id: identifyng the case interaction
   * :param vocabulary: single word/ term/ phrase
   * :param user_translation: single
   * :param expected_translation: single
   * :return:
   * @param caseInteractionId
   * @param vocabulary
   * @param userTranslation
   * @param expectedTranslation
   * @returns string Successful Response
   * @throws ApiError
   */
  public scoreVocabTranslation(
    caseInteractionId: string,
    vocabulary: string,
    userTranslation: string,
    expectedTranslation: string,
  ): CancelablePromise<Array<Array<string>>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/vocabs/translate',
      query: {
        case_interaction_id: caseInteractionId,
        vocabulary: vocabulary,
        user_translation: userTranslation,
        expected_translation: expectedTranslation,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Explain Vocab Test
   * Returns n_vocabularies pairs of patient_language expert term
   * vocabularies and their explanations in layman's terms in the same language.
   *
   * :param database_client:
   * :param request: by injection, to track disconnect
   * :param user:
   * :param case_interaction_id:
   * :param patient_language:
   * :param n_vocabularies:
   * :param probability_main_medical_condition: probability of choosing a main medical condition. One minus probability:
   * probability to choose from one of the preexisting medical conditions
   * :param probability_layman_terms: probability of choosing a layman's term. One minus probability: Probability to
   * choose from one of the expert terms
   *
   * rationale:
   * # - randomly assign the n_vocabularies exterm terms to the disease vignettes of the case interaction
   * # - translate to patient_language (if different from German).
   * #  - exlain in the same language
   * # - return as list of pairs
   * # - Note: no LLM call here, all precomputed
   * # TODO for later: choose by difficulty (not used currently)
   * # TODO store explanations in DB (factsheet_full) !
   * # TODO: currently leads to SIGSEGV, so commented out (CAS-86)
   *
   * :return:
   * @param caseInteractionId
   * @param patientLanguage
   * @param nVocabularies
   * @param probabilityMainMedicalCondition
   * @param probabilityLaymanTerms
   * @returns any Successful Response
   * @throws ApiError
   */
  public getExplainVocabTest(
    caseInteractionId: string,
    patientLanguage: string,
    nVocabularies: number = 12,
    probabilityMainMedicalCondition: number = 0.5,
    probabilityLaymanTerms: number = 0.6,
  ): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/vocabs/explain',
      query: {
        case_interaction_id: caseInteractionId,
        patient_language: patientLanguage,
        n_vocabularies: nVocabularies,
        probability_main_medical_condition: probabilityMainMedicalCondition,
        probability_layman_terms: probabilityLaymanTerms,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
