<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import MainHeader from '@/components/headers/MainHeader.vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';
import TeamMember from '@/views/about-us/TeamMember.vue';
import { debounce } from 'lodash';
import PublicFooter from '@/views/landing/PublicFooter.vue';

const imgRuediger = ref();
const imgFrederic = ref();

const outerHeader = ref(null);
const outerHeaderHeight = ref(0);

const adjustHeight = () => {
  if (outerHeader.value) {
    outerHeaderHeight.value = outerHeader.value.offsetHeight;
  }
};

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

onMounted(() => {
  adjustHeight();
  window.addEventListener('resize', debouncedAdjustHeight);

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerHeaderHeight.value}px)`,
  };
});

onMounted(() => {});
</script>

<template>
  <div class="w-full h-screen bg-gray-50 dark:bg-neutral-800">
    <header
      ref="outerHeader"
      class="sticky top-0 flex flex-wrap md:justify-start md:flex-nowrap z-40 w-full bg-white dark:border-none border-b border-gray-200 text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showHo
        meLink="true"
        :showPatientCaseDropdown="true"
        :showChallengesAndEventsLink="false"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showSignInOrEditProfile="true"
      />
    </header>

    <div class="flex-grow w-full overflow-auto" :style="computedStyleMain">
      <main
        class="flex-col flex max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto justify-center h-fit min-h-fit overflow-hidden"
      >
        <!-- Title -->
        <div class="max-w-full mx-auto text-center mb-10 lg:mb-14">
          <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white items-center text-center">
            <!--          Medizin-->
            <!--          <span translate="no" class="material-symbols-outlined notranslate px-4"> handshake </span>-->
            <!--          KI-->
            <!--          <span translate="no" class="material-symbols-outlined notranslate px-4"> handshake </span>-->
            <!--          Sprachdidaktik-->
            Unser <a class="text-blue-600">Team</a>
          </h2>
          <p class="text-lg text-gray-800 dark:text-neutral-200 text-center mt-4">
            Aus Medizin, KI und Sprachdidaktik: Für KI in der medizinischen Sprachdidaktik.
          </p>
        </div>
        <!-- End Title -->

        <!-- Grid -->
        <div class="grid grid-cols-2 gap-8 md:gap-12 align-left">
          <TeamMember
            firstName="Rüdiger"
            lastName="Schmitz"
            position="Gründer"
            professionalBackground="Arzt & KI-Forscher"
            mail="ruediger@casuu.care"
            :socialMedia="[
              { brand: 'linkedin', url: 'https://www.linkedin.com/in/dr-ruediger-schmitz/' },
              { brand: 'x', url: 'https://x.com/schmitzruediger' },
              { brand: 'orcid', url: 'https://orcid.org/my-orcid?orcid=0000-0002-2414-9817' },
            ]"
          />
          <TeamMember
            firstName="Frederic"
            lastName="Madesta"
            position="Gründer"
            professionalBackground="Physiker & KI-Forscher"
            mail="frederic@etaio.dev"
            :socialMedia="[
              { brand: 'linkedin', url: 'https://www.linkedin.com/in/frederic-madesta/' },
              { brand: 'github', url: 'https://github.com/dericdesta' },
              { brand: 'google-scholar', url: 'https://scholar.google.de/citations?user=hu4mVyEAAAAJ&hl=de&oi=ao' },
            ]"
          />
          <TeamMember
            firstName="Maria"
            lastName="Schmitz"
            position="Buchhaltung"
            professionalBackground="Steuerfachangestellte"
            mail="hello@casuu.care"
          />
          <TeamMember
            firstName="Olha"
            lastName="Demjanjuk"
            position="Medizinstudentin & Pflege"
            professionalBackground="Ukraine"
            mail="olha@casuu.care"
            :socialMedia="[{ brand: 'linkedin', url: 'https://www.linkedin.com/in/olha-demianiuk-163717234/' }]"
          />
          <TeamMember
            firstName="Karin"
            lastName="Ransberger"
            position="Advisor"
            professionalBackground="e-Learning Expertin"
            mail="hello@casuu.care"
            :socialMedia="[{ brand: 'website', url: 'https://www.deutsch-am-arbeitsplatz.de/fachstelle/das-team/' }]"
          />
          <TeamMember
            firstName="Birthe"
            lastName="Scheffler"
            position="Advisor"
            professionalBackground="Sprachdidaktikerin"
            mail="hello@casuu.care"
            :socialMedia="[{ brand: 'website', url: 'https://www.deutsch-am-arbeitsplatz.de/fachstelle/das-team/' }]"
          />
          <!--          <TeamMember-->
          <!--            firstName="Thomas"-->
          <!--            lastName="Clausen"-->
          <!--            position="Advisor"-->
          <!--            professionalBackground="Unternehmensberater"-->
          <!--            mail="mail@thomasclausen.eu"-->
          <!--            :socialMedia="[-->
          <!--              { brand: 'linkedin', url: 'https://www.linkedin.com/in/dr-thomas-clausen/' },-->
          <!--              // { brand: 'website', url: '/advisory-board' },-->
          <!--              { brand: 'x', url: 'https://x.com/DrThomasClausen' },-->
          <!--              // { brand: 'website', url: 'https://www.telegraph.co.uk/authors/t/tf-tj/thomas-clausen/' }-->
          <!--            ]"-->
          <!--          />-->
          <TeamMember
            firstName="René"
            lastName="Werner"
            position="Advisor"
            professionalBackground="Prof. für medizinische KI"
            mail="r.werner@uke.de"
            :socialMedia="[
              { brand: 'linkedin', url: 'https://www.linkedin.com/in/rené-werner-3594043a/' },
              {
                brand: 'website',
                url: 'https://www.uke.de/allgemein/arztprofile-und-wissenschaftlerprofile/rené.html',
              },
              { brand: 'google-scholar', url: 'https://scholar.google.de/citations?user=aC-0LkAAAAAJ&hl=de&oi=ao' },
            ]"
          />
        </div>
        <!-- End Grid -->

        <div class="mt-8 md:mt-12 justify-center">
          <div class="gap-x-4 justify-center">
            <!--      <img class="rounded-lg w-20 h-20" src="../assets/img/160x160/img1.jpg" alt="Image Description">-->

            <div class="flex-col flex justify-center">
              <h3 class="font-medium text-gray-800 dark:text-gray-200 text-center">We are hiring</h3>
              <div class="text-xs uppercase text-gray-500 dark:text-neutral-500 text-center">
                Talents and Mavericks Wanted. Mavericks preferred.
              </div>
              <a
                class="mt-2.5 text-sm text-blue-600 decoration-2 hover:underline dark:text-blue-600 text-center"
                href="mailto:hello@casuu.care"
              >
                <div class="inline-flex items-center gap-x-2">
                  <div translate="no" class="material-symbols-outlined notranslate text-md">mail</div>
                  <div>Hier bewerben</div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="mt-8 md:mt-12 justify-center">
          <div class="gap-x-4 justify-center">
            <!--      <img class="rounded-lg w-20 h-20" src="../assets/img/160x160/img1.jpg" alt="Image Description">-->

            <div class="flex-col flex justify-center">
              <h3 class="font-medium text-gray-800 dark:text-gray-200 text-center">
                Als Patient:in die Medizin gestalten
              </h3>
              <div class="text-xs uppercase text-gray-500 dark:text-neutral-500 text-center">
                Wir suchen Ihre Eindrücke und Erfahrungen in unserem Patient:innenbeirat.
              </div>
              <a
                class="mt-2.5 text-sm text-blue-600 decoration-2 hover:underline dark:text-blue-600 text-center"
                href="/patient-advisory-board"
              >
                <div class="inline-flex items-center gap-x-2">
                  <div translate="no" class="material-symbols-outlined notranslate text-md">groups</div>
                  <div>Mitmachen</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>
      <!-- End Team -->

      <footer class="pt-20 h-fit min-h-fit flex relative bottom-0 mx-auto overflow-visible">
        <PublicFooter />
      </footer>
    </div>
  </div>
</template>

<style scoped></style>
