<script setup>
import { useAlertStore, useAuthStore } from '@/stores';
import { ref } from 'vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useForm } from 'vee-validate';
import ProgressButton from '@/components/ProgressButton.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const isSubmitting = ref(false);
const alertStore = useAlertStore();

const initialValues = {
  email: '',
  password: '',
};

function handleLogin(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }
  isSubmitting.value = true;

  console.debug('username: ' + controlledValues.email, 'password: ' + controlledValues.password);
  authStore
    .login(controlledValues.email, controlledValues.password, false, t)
    .then(() => {
      console.debug('login successful');
      isSubmitting.value = false;
    })
    .catch((error) => {
      console.debug('login failed');
      isSubmitting.value = false;
      alertStore.error(t('status.error.emailOrPasswordIncorrectHelp'), t('status.error.emailOrPasswordIncorrect'));
      setErrors({
        email: t('status.error.emailOrPasswordIncorrect'),
        password: t('status.error.emailOrPasswordIncorrect'),
      });
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}
</script>

<template>
  <main class="max-w-md h-full mx-auto p-6 flex items-center justify-center">
    <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">
            {{ $t('message.signInWithYourAccount') }}
          </h1>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.noAccountYet') }}

            <router-link to="/account/sign-up" class="text-blue-600 decoration-2 hover:underline font-medium">
              {{ $t('message.signUpNow') }}
            </router-link>
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <Form @submit="handleLogin" v-slot="{ errors }" :initial-values="initialValues" ref="loginForm">
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label for="email" class="block text-sm mb-2 dark:text-white">{{ $t('message.email') }}</label>
                <div class="relative">
                  <Field
                    :disabled="isSubmitting"
                    name="email"
                    type="email"
                    rules="email|required"
                    :class="{
                      'border-red-500': errors.email,
                    }"
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="email" />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="password" class="block text-sm mb-2 dark:text-white">{{ $t('message.password') }}</label>
                  <router-link
                    to="/account/reset-password"
                    tabindex="-1"
                    class="text-sm text-blue-600 decoration-2 hover:underline font-medium"
                    >{{ $t('message.forgotPassword') }}</router-link
                  >
                </div>
                <div class="relative">
                  <Field
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="password"
                    type="password"
                    rules="required"
                    :class="{
                      'border-red-500': errors.password,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="password" />
                </div>
              </div>
              <!-- End Form Group -->

              <ProgressButton :text="$t('message.signIn')" :showProgress="isSubmitting" type="submit" />
            </div>
          </Form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
