<script setup lang="ts">
import CardPair from '@/components/didactics/pair_of_terms/CardPair.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { PairOfTermsItemPair, PairOfTermsItemType } from '@/helper/typing';
import { v4 as uuidv4 } from 'uuid';
import { useAlertStore, useCourseStore } from '@/stores';
import { is } from '@vee-validate/rules';
import { Ref } from 'vue/dist/vue';
import Card from '@/components/didactics/pair_of_terms/Card.vue';

const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'scrollBy']);
const container = ref(null);
const pairContainerRefs = ref([] as Ref<HTMLElement | null>[]);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const fetchCompleted = ref(false);

const props = defineProps({
  allowEdit: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const truePairs = ref([] as PairOfTermsItemPair[]);
const currentData = ref([] as PairOfTermsItemPair[]);

const prompt = ref('');

const pairwiseResults = ref<boolean[]>([]);
const showResults = ref(false);
const isLoading = ref(false);
const isSubmitting = ref(false);
const unsavedChanges = ref(false);
const isEditing = ref(props.allowEdit);

const currentlyDraggingItem1Id = ref<string | null>(null);
const currentlyDraggingItem2Id = ref<string | null>(null);
const currentlyDraggingOver = ref([] as boolean[]);

const unfilledPairs = computed(() => {
  return currentData.value.some((pair) => {
    return pair.pair_of_terms_items[0].content === '' || pair.pair_of_terms_items[1].content === '';
  });
});

const unfilledInstructions = computed(() => {
  return prompt.value === '';
});

const unfilledData = computed(() => {
  return unfilledPairs.value || unfilledInstructions.value;
});

// const trySolve = () => {
//   pairwiseResults.value = currentData.value.map((pair) => {
//     return truePairs.value.some(
//       (truePair) =>
//         pair.pair_of_terms_items[0].id === truePair.pair_of_terms_items[0].id &&
//         pair.pair_of_terms_items[1].id === truePair.pair_of_terms_items[1].id,
//     );
//   });
//   showResults.value = !showResults.value;
// };

const trySolve = () => {
  pairwiseResults.value = currentData.value.map((pair) => {
    return truePairs.value.some((truePair) => {
      const [pairItem1, pairItem2] = pair.pair_of_terms_items;
      const [truePairItem1, truePairItem2] = truePair.pair_of_terms_items;

      // Check if the contents match for both items in the pair, regardless of IDs
      const isMatch =
        (pairItem1.content === truePairItem1.content && pairItem2.content === truePairItem2.content) ||
        (pairItem1.content === truePairItem2.content && pairItem2.content === truePairItem1.content);

      return isMatch;
    });
  });

  showResults.value = !showResults.value;
};

const handleItem2Dragging = (isDragging: boolean, index: number, item2Id: string) => {
  if (isDragging) {
    currentlyDraggingItem2Id.value = item2Id;
  } else {
    currentlyDraggingItem2Id.value = null;
  }
};

const handleItem1Dragging = (isDragging: boolean, index: number, item1Id: string) => {
  if (isDragging) {
    currentlyDraggingItem1Id.value = item1Id;
  } else {
    currentlyDraggingItem1Id.value = null;
  }
};

const handleDragOver = (event: Event, index: number) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
  currentlyDraggingOver.value.splice(index, 1, true);
};

const handleDragLeave = (event: Event, index: number) => {
  currentlyDraggingOver.value.splice(index, 1, false);
};

const handleDrop = (event: Event, index: number) => {
  console.log('drop');
  showResults.value = false;
  // event.preventDefault();
  console.log('currentlyDraggingItem1Id.value', currentlyDraggingItem1Id.value);
  console.log('currentlyDraggingItem2Id.value', currentlyDraggingItem2Id.value);
  console.log('target index', index);

  if (currentlyDraggingItem1Id.value == null && currentlyDraggingItem2Id.value == null) {
    currentlyDraggingOver.value[index] = false;
    return;
  }

  if (currentlyDraggingItem1Id.value != null) {
    console.log('swapping item1');
    // Find the index of the item1 currently being dragged
    const draggingItem1Index = currentData.value.findIndex(
      (pair) => pair.pair_of_terms_items[0].id === currentlyDraggingItem1Id.value,
    );

    // Swap item1 of dragging index with item1 of target index
    if (draggingItem1Index !== -1) {
      const temp = currentData.value[index].pair_of_terms_items[0];
      currentData.value[index].pair_of_terms_items[0] = currentData.value[draggingItem1Index].pair_of_terms_items[0];
      currentData.value[draggingItem1Index].pair_of_terms_items[0] = temp;
    }

    // Clear the currentlyDraggingItem1Id
    currentlyDraggingItem1Id.value = null;
  } else {
    console.log('swapping item2');
    // Find the index of the item2 currently being dragged
    const draggingItem2Index = currentData.value.findIndex(
      (pair) => pair.pair_of_terms_items[1].id === currentlyDraggingItem2Id.value,
    );

    // Swap item2 of dragging index with item2 of target index
    if (draggingItem2Index !== -1) {
      const temp = currentData.value[index].pair_of_terms_items[1];
      currentData.value[index].pair_of_terms_items[1] = currentData.value[draggingItem2Index].pair_of_terms_items[1];
      currentData.value[draggingItem2Index].pair_of_terms_items[1] = temp;
    }

    // Clear the currentlyDraggingItem2Id
    currentlyDraggingItem2Id.value = null;
  }

  currentlyDraggingOver.value[index] = false;
};

const addPair = () => {
  isSubmitting.value = true;
  currentData.value.push({
    id: uuidv4(),
    pair_of_terms_items: [
      {
        id: uuidv4(),
        pair_of_terms_item_type: 'TEXT',
        content: '',
        description: null,
        media_item_id: null,
      },
      {
        id: uuidv4(),
        pair_of_terms_item_type: 'TEXT',
        content: '',
        description: null,
        media_item_id: null,
      },
    ],
  });
  pairwiseResults.value.push(false);
  currentlyDraggingOver.value.push(false);
  isSubmitting.value = false;
};

const deletePair = (index: number) => {
  isSubmitting.value = true;
  currentData.value.splice(index, 1);
  pairwiseResults.value.splice(index, 1);
  currentlyDraggingOver.value.splice(index, 1);
  isSubmitting.value = false;
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const shuffle = () => {
  const firstItems = currentData.value.map((pair) => pair.pair_of_terms_items[0]);
  const secondItems = currentData.value.map((pair) => pair.pair_of_terms_items[1]);

  shuffleArray(firstItems);
  shuffleArray(secondItems);

  for (let i = 0; i < currentData.value.length; i++) {
    currentData.value[i].pair_of_terms_items[0] = firstItems[i];
    currentData.value[i].pair_of_terms_items[1] = secondItems[i];
  }
};

watch(
  () => currentData.value?.length,
  () => {
    pairwiseResults.value = Array(currentData.value.length).fill(false);
    currentlyDraggingOver.value = Array(currentData.value.length).fill(false);
    currentData.value.forEach((pair: any) => {
      pairContainerRefs.value.push(ref<HTMLElement | null>(null));
    });
  },
);

onMounted(() => {
  // currentData.value = JSON.parse(JSON.stringify(props.pairOfTermsGameTuple));
  let tuples = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  currentData.value = JSON.parse(JSON.stringify(tuples));

  prompt.value = props.contentItem.pair_of_terms_game.task_instructions;

  // currentData.value = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  truePairs.value = JSON.parse(JSON.stringify(tuples));

  pairwiseResults.value = Array(tuples.length).fill(false);
  currentlyDraggingOver.value = Array(tuples.length).fill(false);
  tuples.forEach((pair: any) => {
    pairContainerRefs.value.push(ref<HTMLElement | null>(null));
  });

  if (!props.allowEdit) {
    shuffle();
  } else if (tuples.length === 0) {
    addPair();
  }

  document.addEventListener('click', handleClickOutside);
  fetchCompleted.value = true;
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const savePairOfTermsGame = async () => {
  // const pairOfTermsUpdate = {
  //   id: props.contentItem.pair_of_terms_game.id,
  //   pair_of_terms_item_tuples: currentData.value.map((item: any) => ({
  //     id: item.id,
  //     pair_of_terms_items: [
  //       {...item.item1},  // Add item1 to pair_of_terms_items
  //       {...item.item2}   // Add item2 to pair_of_terms_items
  //     ]
  //   }))
  // };
  isSubmitting.value = true;
  const pairOfTermsUpdate = {
    id: props.contentItem.pair_of_terms_game.id,
    task_instructions: prompt.value,
    pair_of_terms_item_tuples: currentData.value,
  };
  console.log(pairOfTermsUpdate);

  await courseStore
    .updatePairOfTermsGame(props.contentItem.section_id, props.contentItem.id, pairOfTermsUpdate)
    .then(() => {
      alertStore.success('status.success.changesSaved');
    })
    .catch((error) => {
      alertStore.error('status.error.saveChangesError');
      console.error(error);
    });
  unsavedChanges.value = false;
  isSubmitting.value = false;
};

const handleClickOutside = (event: Event) => {
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const handleDraggingAtPosition = (position: { x: number; y: number }) => {
  for (let i = 0; i < pairContainerRefs.value.length; i++) {
    const pairContainer = pairContainerRefs.value[i]?.value?.[0];

    if (!pairContainer) {
      currentlyDraggingOver.value[i] = false;
      continue;
    }

    const rect = pairContainer.getBoundingClientRect();

    currentlyDraggingOver.value[i] =
      position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
  }
};

const handleDroppedAtPosition = (position: { x: number; y: number }) => {
  for (let i = 0; i < pairContainerRefs.value.length; i++) {
    const pairContainer = pairContainerRefs.value[i]?.value?.[0];
    if (!pairContainer) {
      continue;
    }

    const rect = pairContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(new Event('drop'), i);
    }
  }
};
</script>

<template>
  <div
    ref="container"
    class="w-full p-0.5 overflow-hidden"
    :class="{
      'h-fit': isMaximized,
      'h-[295px]': !isMaximized,
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
  >
    <h2 class="text font-semibold text-center">Übung: Paare bilden</h2>
    <div>
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="isEditing">
        <span translate="no" class="material-symbols-outlined notranslate text-2xl">info</span>
        <h3 class="text-sm text-justify">
          Lege Paare in der korrekten Zuordnung an. Dem User wird eine zufällige Sortierung angezeigt.
        </h3>
      </div>
      <h2
        class="bg-white pt-2 pb-1 w-full rounded-lg text-center"
        :class="{ 'border border-gray-200 shadow-sm': isEditing }"
      >
        <span v-if="!isEditing" class="text-xs md:text-sm">{{ prompt }}</span>
        <textarea
          v-if="isEditing"
          v-model="prompt"
          @input="unsavedChanges = true"
          placeholder="Aufgabenstellung, die dem User angezeigt werden soll, z.B. 'Ordne die Begriffe den Definitionen zu.'"
          class="w-full font-normal text-center border-gray-200 rounded-lg resize-none min-w-2"
        />
      </h2>
    </div>

    <div class="w-full gap-y-1 overflow-y-scroll md:gap-y-4 relative">
      <div
        class=""
        v-for="(pair, index) in currentData"
        :key="pair.id"
        @dragover.prevent="handleDragOver($event, index)"
        @dragleave.prevent="handleDragLeave($event, index)"
        @drop.prevent="handleDrop($event, index)"
      >
        <div :ref="pairContainerRefs[index]">
          <CardPair
            :items="pair.pair_of_terms_items"
            :allowDrag="!allowEdit"
            :isEditing="isEditing"
            :isCorrect="pairwiseResults[index]"
            :showResults="showResults"
            :item1DraggedOver="currentlyDraggingOver[index] && currentlyDraggingItem1Id != null"
            :item2DraggedOver="currentlyDraggingOver[index] && currentlyDraggingItem2Id != null"
            @isLoading="isLoading = $event"
            @isDraggingItem2="
              (isDragging, item2Id) => {
                handleItem2Dragging(isDragging, index, item2Id);
              }
            "
            @isDraggingItem1="
              (isDragging, item1Id) => {
                handleItem1Dragging(isDragging, index, item1Id);
              }
            "
            @inputItem1="
              (newItem) => {
                unsavedChanges = true;
                pair.pair_of_terms_items[0] = newItem;
              }
            "
            @inputItem2="
              (newItem) => {
                unsavedChanges = true;
                pair.pair_of_terms_items[1] = newItem;
              }
            "
            @delete="
              () => {
                unsavedChanges = true;
                deletePair(index);
              }
            "
            @currentDraggingPosition="handleDraggingAtPosition"
            @droppedAtPosition="handleDroppedAtPosition"
            @scrollBy="(distance) => emit('scrollBy', distance)"
          />
        </div>
      </div>
    </div>

    <div class="w-fit items-center mx-auto">
      <div class="pt-1">
        <ProgressButton
          v-show="!allowEdit"
          @click="trySolve"
          :text="showResults ? 'Retry' : 'Check'"
          :disabled="isLoading"
          :show-progress="isLoading"
        />
      </div>
      <div class="pt-1 mx-auto justify-center flex">
        <ProgressButton
          v-show="isEditing"
          :disabled="isSubmitting || !fetchCompleted"
          @click="
            () => {
              unsavedChanges = true;
              addPair();
            }
          "
          icon="add"
          iconSize="text-2xl"
          :text="'Add pair'"
        />
      </div>
      <div class="pt-1 mx-auto justify-center flex">
        <ProgressButton
          v-show="allowEdit"
          @click="savePairOfTermsGame"
          :showProgress="isSubmitting"
          :disabled="!unsavedChanges || isSubmitting || !fetchCompleted || unfilledData"
          :text="isEditing ? 'Save' : 'Edit'"
          icon="save"
          iconSize="text-2xl"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
