<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import DOMPurify from 'dompurify';

const { t } = useI18n();
import { v4 as uuidv4 } from 'uuid';
import { HSCollapse, HSStaticMethods } from 'preline';

const props = defineProps({
  learningObjective: {
    type: String,
    default: '',
  },
  feedback: {
    type: String,
    default: '',
  },
  possibleImprovements: {
    type: Array<String>,
    default: [],
  },
  score: {
    type: Number,
    default: 12,
  },
});

const isLoading = ref(false);

const componentId = uuidv4();
const collapseId = 'hs-improvements-collapse-' + componentId;

const style = computed(() => {
  // choose icon based on score
  if (props.score >= 90) {
    return { icon: 'celebration', color: 'bg-blue-300', feedbackShort: t('message.excellent') };
  } else if (props.score >= 75) {
    return { icon: 'sentiment_very_satisfied', color: 'bg-green-300', feedbackShort: t('message.veryGood') };
  } else if (props.score >= 60) {
    return { icon: 'sentiment_satisfied', color: 'bg-green-200', feedbackShort: t('message.good') };
  } else if (props.score >= 40) {
    return { icon: 'thumb_up', color: 'bg-green-100', feedbackShort: t('message.youAreImproving') };
  } else {
    return { icon: 'self_improvement', color: 'bg-white', feedbackShort: t('message.keepGoing') };
  }
});

const formattedEarnedXP = computed(() => {
  let text = t('message.earnedExperiencePoints', props.score);
  // replace +XXX number where X can be any number with <span class="text-green-800">+XXX</span>
  text = text.replace(/\+(\d+)/g, '<span class="text-green-800 font-bold">+$1</span>');
  return DOMPurify.sanitize(text);
});

const formattedTips = computed(() => {
  let text = props.possibleImprovements.join('<br>');
  return DOMPurify.sanitize(text);
});

onMounted(async () => {
  // init collapse
  await nextTick();
  // HSCollapse.autoInit();
});
</script>

<template>
  <!-- Card -->
  <div
    class="w-[120vH] mx-auto p-5 space-y-4 flex flex-col border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
    :class="style.color"
  >
    <!-- Header -->
    <div class="flex justify-between w-[120vH] mx-auto pr-6">
      <div class="inline-flex justify-center items-center text-center gap-x-2 text-gray-500 dark:text-neutral-500">
        <span translate="no" class="material-symbols-outlined notranslate text-3xl text-center">
          {{ style.icon }}
        </span>
        <span class="text-center font-medium text-lg">
          {{ style.feedbackShort }}
        </span>
      </div>

      <div>
        <!-- Checkbox Button -->
        <div
          class="relative py-2 px-2.5 gap-x-2 w-full sm:w-auto text-center items-center flex sm:text-start rounded-lg cursor-pointer text-xs font-medium focus:outline-none"
        >
          <span class="text-lg font-medium text-gray-500 dark:text-neutral-500 text-center" v-html="formattedEarnedXP">
          </span>
          <span
            translate="no"
            class="material-symbols-outlined notranslate text-3xl text-gray-500 dark:text-neutral-500 text-center"
          >
            exercise
          </span>
        </div>
        <!-- End Checkbox Button -->
      </div>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div>
      <h3 class="font-medium text-gray-800 dark:text-neutral-200">
        {{ props.learningObjective }}
      </h3>

      <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">
        {{ props.feedback }}
      </p>
    </div>
    <!-- End Body -->

    <button
      type="button"
      class="hs-collapse-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
      id="hs-basic-collapse"
      :id="collapseId"
      aria-expanded="false"
      :aria-controls="`#${collapseId}-content`"
      :data-hs-collapse="`#${collapseId}-content`"
    >
      <span class="block hs-collapse-open:hidden">{{ t('message.viewImprovements') }}</span>
      <span class="hidden hs-collapse-open:block">{{ t('message.hideImprovements') }}</span>
      <svg
        class="hs-collapse-open:rotate-180 size-4"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m6 9 6 6 6-6"></path>
      </svg>
    </button>
    <div
      class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
      :id="`${collapseId}-content`"
      :aria-labelledby="`${collapseId}-content`"
    >
      <div class="mt-1 bg-white rounded-lg py-3 px-4 dark:bg-neutral-800">
        <div class="text-gray-500 text-sm dark:text-neutral-400" v-html="formattedTips"></div>
      </div>
    </div>
  </div>
  <!-- End Card -->
</template>
