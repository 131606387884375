import { ERWaitingRoom } from '@/views/er-waiting-room';
import { LoggedInRoot } from '@/views/root';
import { Profile } from '@/views/home';
import { CourseChapter, CourseDetails, CourseInteractionList, CourseLibrary, EditCourseChapter } from '@/views/courses';
import { CreateNewCase } from '@/views/new-case';
import VocabDashboard from '@/components/didactics/VocabDashboard.vue';
import DailyVocabExercise from '@/components/didactics/vocab/DailyVocabExercise.vue';
import FormTest from '@/components/FormTest.vue';
import CategorizationExercise from '@/components/didactics/categorization/CategorizationExercise.vue';

export default {
  path: '/home',
  component: LoggedInRoot,
  children: [
    { path: '', component: CourseLibrary },
    { path: '/profile', component: Profile },
    {
      name: 'new-or-edit-case',
      path: '/new-case',
      component: CreateNewCase,
      props: (route: {
        query: {
          isCopy: string;
          isEdit: string;
          isViewOnly: string;
          fromCaseId: string;
          inCaseListId: string;
        };
      }) => ({
        fromCaseId: route.query.fromCaseId,
        editCase: route.query.isEdit === 'true',
        copyCase: route.query.isCopy === 'true',
        viewOnly: route.query.isViewOnly === 'true',
        caseListId: route.query.inCaseListId,
      }),
    },
    // course library
    { path: '/course/:courseId', component: CourseDetails },
    { path: '/chapter/:chapterId', component: CourseChapter },
    { path: '/edit-chapter/:chapterId', component: EditCourseChapter },
    { path: '/my-courses/:userId', component: CourseInteractionList },
    // cases
    { path: '/wartebereich-zna', component: ERWaitingRoom },
    // vocab
    {
      path: '/vocab-dashboard',
      component: VocabDashboard,
      name: 'VocabDashboard',
      props: (route: {
        query: {
          startInPracticeMode: string;
          hideTabularNav: string;
        };
      }) => ({
        startInPracticeMode: route.query.startInPracticeMode === 'true',
        hideTabularNav: route.query.hideTabularNav === 'true',
      }),
    },
    {
      path: '/dev',
      component: FormTest,
    },
    {
      path: '/dev2',
      component: CategorizationExercise,
    },
  ],
};
