<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { HSStaticMethods, HSStepper, HSTooltip } from 'preline';
import QuizDropdown from '@/components/dropdowns/QuizDropdown.vue';
import { useAlertStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import ProfileImage from '@/components/ProfileImage.vue';
import { storeToRefs } from 'pinia';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const caseInteractionStore = useCaseInteractionStore();
const patientInteractionStore = usePatientInteractionStore();
const alertStore = useAlertStore();
const emits = defineEmits(['onOralExam', 'onMcExam', 'onTranslateVocab', 'onExplainVocab']);

const { currentCaseInteractionTasks: allTasks, currentTaskIndex } = storeToRefs(caseInteractionStore);
const stepperId = ref('case-interaction-stepper_items');
const stepperHandle = ref<typeof HSStepper | null>(null);
const openStepIndex = ref(0);
const lastOpenedStepIndex = ref(null as null | number);

const openStepInProcess = ref(false);

const popoverRefs = ref<Array<any>>([]);
const popoverHandles = ref<Array<any>>([]);

const props = defineProps({
  originChapterId: {
    type: String,
    required: false,
    default: '',
  }, // if case started from any section of a chapter: used to route back to the origin chapter after evaluation
});

onMounted(async () => {
  await nextTick();
  // await HSStaticMethods.autoInit();
  await nextTick();
  await new Promise((resolve) => setTimeout(resolve, 100));
  const el = HSStepper.getInstance('#' + stepperId.value);
  console.log('HSStepper: ' + JSON.stringify(el));
  el.on('next', (currentIndex: number) => {
    console.log('next stepper_items index: ', currentIndex, ' ( = array index ', currentIndex - 1, ')');
    openStep(currentIndex - 1);
  });
  el.on('reset', () => {
    console.log('reset stepper_items');
    openStep(0);
  });
  stepperHandle.value = el;

  while (openStepIndex.value < currentTaskIndex?.value) {
    await nextTick();
    await openStep(openStepIndex.value + 1, false);
  }

  // // init popover refs and get handles
  // for (let i = 0; i < currentCaseInteractionTasks.value.length; i++) {
  //   popoverRefs.value.push(ref(null));
  //   popoverHandles.value.push(new HSTooltip(document.getElementById(`${i}-taskDetailsPopover`)));
  // }
});

watch(
  () => allTasks.value,
  async () => {
    await nextTick();

    // init popover refs and get handles
    for (let i = 0; i < allTasks.value.length; i++) {
      popoverRefs.value.push(ref(null));
      popoverHandles.value.push(new HSTooltip(document.getElementById(`${i}-taskDetailsPopover`)));
    }
  },
);

const indexString = (index: number) => `{"index": ${index + 1}}`;

async function openStep(index: number, triggerEvents: boolean = true) {
  // triggerEvents: if true, signal stepping to case interaction store to let persons react. If false: directly proceed
  // from step to step w/o any signalling or reaction (e.g. on loading of old case)
  if (openStepInProcess.value) {
    console.log('openStepInProcess is true. Ignoring. ');
    alertStore.info('Bitte warte, bis die Szene vollständig geladen ist.');
    return;
  }

  if (!stepperHandle.value) {
    console.error('stepperHandle not set');
    return;
  }
  if (index == lastOpenedStepIndex.value) {
    console.log('already opened step #', index + '. Ignoring. ');
    return; // preventing multiple clicks
  }
  if (caseInteractionStore.somthingIsStreaming) {
    console.log('something is streaming - ignore task change click');
    alertStore.info('Bitte warte die Interaktion ab, ehe du zur nächsten Szene wechselst.');
    return;
  }
  if (index > stepperHandle.value.currentIndex) {
    // block jumping ahead (ie skipping intermediate step)
    alertStore.info(
      'Bitte fahre Schritt für Schritt fort und begib dich zuerst in die nächste Szene (' +
        'Szene ' +
        (stepperHandle.value.currentIndex + 1) +
        ': ' +
        allTasks.value[stepperHandle.value.currentIndex].title +
        ').',
    );
    console.log('blocked: ', index, ' > ', stepperHandle.value.currentIndex);
    return;
  }

  // TODO: unclear why reactivity is lost here
  await nextTick(() => {
    openStepInProcess.value = true;
  });
  // wait 0 ms
  await new Promise((resolve) => setTimeout(resolve, 0));
  await nextTick();

  if (index === stepperHandle.value.currentIndex) {
    // note: the stepperHandle indexing starts at 1 (thanks preline...)
    await stepperHandle.value.goToNext();
  }
  lastOpenedStepIndex.value = index;
  console.log('new index ', index, 'stepperHandle currentIndex ', stepperHandle.value.currentIndex);

  console.log('opening step #', index);
  openStepIndex.value = index;

  if (!triggerEvents) {
    console.log('triggerEvents is false. Skipping event.');
    openStepInProcess.value = false;
    return;
  }
  await caseInteractionStore.goToTask(index);
  await nextTick(() => {
    openStepInProcess.value = false;
  });
  await new Promise((resolve) => setTimeout(resolve, 0));
  await nextTick();
}

async function openTaskDetailsPopover(index: number) {
  if (!popoverHandles.value[index]) {
    console.warn('popoverHandle not set');
    return;
  }
  popoverHandles.value[index].show();
}

async function checkIfCurrentTaskActive(event: any) {
  console.log('checkIfCurrentTaskActive');
  if (openStepIndex.value < stepperHandle.value.currentIndex - 1) {
    console.log('blocked');
    openStepIndex.value = stepperHandle.value.currentIndex - 1;
    await event.stopPropagation();
    return false;
  }
  console.log('not blocked');
  return true;
}

async function proceedIfActive(event: any) {
  console.log('proceedIfActive');
  if (!(await checkIfCurrentTaskActive(event))) {
    alertStore.info(
      'Bitte fahre Schritt für Schritt fort und begib dich zuerst in die nächste Szene (' +
        (openStepIndex.value + 1) +
        ').',
    );
    return;
  }
  console.log('proceeding');
  await openStep(openStepIndex.value + 1, true);
}

const onOralExam = async () => {
  emits('onOralExam');
};

const onMcExam = async () => {
  emits('onMcExam');
};

const onTranslateVocab = async () => {
  emits('onTranslateVocab');
};

const onExplainVocab = async () => {
  emits('onExplainVocab');
};

const profileImagePathOfFirstNonNullPersonForTask = (taskIndex: number) => {
  if (!allTasks.value) {
    return '';
  }
  let person = caseInteractionStore.getFirstNonNullPerson(taskIndex);
  return person?.profileImageSmall || '';
};

async function gotoEvaluation() {
  console.log('go to evaluation of case interaction ', caseInteractionStore.currentCaseInteractionId);
  if (!!props.originChapterId) {
    await router.push({
      path: '/case-interactions/' + caseInteractionStore.currentCaseInteractionId + '/evaluation',
      query: { originChapterId: props.originChapterId },
    });
  } else {
    await router.push('/case-interactions/' + caseInteractionStore.currentCaseInteractionId + '/evaluation');
  }
}
</script>

<template>
  <nav class="relative bg-gray-100 border-b border-gray-200 py-2 dark:bg-neutral-900 dark:border-neutral-800 z-20">
    <div class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 py-2.5" aria-label="CaseInteraction">
      <!-- Nav Links -->
      <div class="basis-full grow md:basis-auto md:grow-0 md:w-full">
        <!-- Collapse -->
        <div id="navbar-collapse-with-animation" class="hs-collapse overflow-x-auto transition-all duration-300 block">
          <!-- Stepper -->
          <div data-hs-stepper :id="stepperId">
            <!-- Stepper Nav -->
            <div class="relative flex flex-row gap-x-2 min-w-full w-full items-center">
              <ul class="relative flex flex-row gap-x-2 grow">
                <li
                  v-for="(task, index) in allTasks"
                  class="flex items-center gap-x-2 shrink basis-0 flex-1 group"
                  :data-hs-stepper-nav-item="indexString(index as number)"
                >
                  <button
                    class="min-w-7 min-h-7 rounded-full group pr-4 inline-flex items-center text-xs align-middle"
                    @click="openStep(index as number)"
                    @mouseover="openTaskDetailsPopover(index as number)"
                    @mouseleave="popoverHandles[index].hide()"
                    :class="{ 'bg-white': index === openStepIndex }"
                  >
                    <div
                      class="hs-tooltip inline-block [--trigger:hover] sm:[--placement:bottom]"
                      :ref="popoverRefs[index]"
                      :id="`${index}-taskDetailsPopover`"
                      :key="'popover-' + index"
                    >
                      <div class="hs-tooltip-toggle">
                        <!-- Popover Content -->
                        <div
                          role="tooltip"
                          class="pt-6 hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible hidden opacity-0 transition-opacity absolute invisible z-10 max-w-xs w-full"
                        >
                          <div
                            class="bg-white border border-gray-100 text-start rounded-xl shadow-md after:absolute after:top-0 after:-start-4 after:w-4 after:h-full dark:bg-neutral-800 dark:border-neutral-700"
                          >
                            <!-- Popover Header -->
                            <div
                              class="inline-flex items-start py-3 px-4 gap-x-3 border-b border-gray-200 dark:border-neutral-700"
                            >
                              <div class="items-center">
                                <ProfileImage :image="profileImagePathOfFirstNonNullPersonForTask(index)" />
                              </div>
                              <!-- TODO: profile image(s) from respective interaction partner(s) -->
                              <div class="flex flex-col">
                                <p class="flex grow text-sm text-gray-500 dark:text-neutral-500">
                                  Aufgabe {{ index + 1 }}
                                </p>
                                <h4 class="flex grow text-sm font-semibold text-gray-800 dark:text-white">
                                  {{ task.title }}
                                </h4>
                                <p class="flex grow text-sm text-gray-500 dark:text-neutral-500">
                                  {{ task.details.description ? task.details.description : '' }}
                                </p>
                              </div>
                            </div>
                            <!-- End Popover Header -->

                            <!-- Popover List -->
                            <ul class="py-3 px-4 space-y-0.5">
                              <li v-for="(subtask, subtask_index) in task.subtasks">
                                <div
                                  class="inline-flex items-center gap-x-3 text-sm text-gray-800 dark:text-neutral-200"
                                >
                                  <span translate="no" class="material-symbols-outlined notranslate text-xl">
                                    <span translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
                                  </span>
                                  {{ subtask.details.target }}
                                </div>
                              </li>
                            </ul>
                            <!-- End Popover List -->

                            <!-- Popover Footer -->
                            <div class="py-2 px-4 flex justify-between items-center bg-gray-100 dark:bg-neutral-800">
                              <div></div>
                              <!--                              <a-->
                              <!--                                class="inline-flex items-center gap-x-1.5 text-xs text-gray-500 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-white"-->
                              <!--                                href="#"-->
                              <!--                              >-->
                              <!--                                <svg-->
                              <!--                                  class="flex-shrink-0 h-3 w-3"-->
                              <!--                                  xmlns="http://www.w3.org/2000/svg"-->
                              <!--                                  width="24"-->
                              <!--                                  height="24"-->
                              <!--                                  viewBox="0 0 24 24"-->
                              <!--                                  fill="none"-->
                              <!--                                  stroke="currentColor"-->
                              <!--                                  stroke-width="2"-->
                              <!--                                  stroke-linecap="round"-->
                              <!--                                  stroke-linejoin="round"-->
                              <!--                                >-->
                              <!--                                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>-->
                              <!--                                  <line x1="4" x2="4" y1="22" y2="15"></line>-->
                              <!--                                </svg>-->
                              <!--                                Problem berichten-->
                              <!--                                &lt;!&ndash; TODO: Problem mit dieser Aufgabe an Case-Creator berichten &ndash;&gt;-->
                              <!--                              </a>-->

                              <button
                                type="button"
                                class="py-1.5 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                                @click="proceedIfActive"
                              >
                                Weiter
                              </button>
                            </div>
                            <!-- End Popover Footer -->
                          </div>
                          <!-- End Popover Content -->
                        </div>
                      </div>
                    </div>

                    <span
                      class="h-7 w-7 flex justify-center items-center border-gray-500 border flex-shrink-0 bg-gray-100 font-medium text-gray-500 rounded-full hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:hs-stepper-active:bg-blue-600 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600"
                    >
                      <span class="hs-stepper-completed:hidden" :class="{ hidden: task.success }">
                        <span
                          v-if="task.icon"
                          translate="no"
                          class="material-symbols-outlined notranslate font-semibold text-xl"
                        >
                          {{ task.icon }}
                        </span>
                        <span v-else class="font-semibold">{{ index + 1 }}</span>
                      </span>
                      <svg
                        class="hidden flex-shrink-0 h-3 w-3"
                        :class="{ block: task.success }"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span class="ms-2 text-sm font-semibold text-gray-500 hover:text-gray-500 dark:text-neutral-200">
                      {{ task.title }}
                    </span>
                  </button>
                  <div
                    class="w-full h-px flex-1 bg-gray-500 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"
                  ></div>
                </li>

                <!-- End Item -->
              </ul>

              <!-- Button Group -->
              <div class="flex justify-between items-center gap-x-2 pr-1">
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center text-gray-500 hover:text-gray-400 gap-x-1 text-sm font-semibold rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none"
                  data-hs-stepper-next-btn=""
                >
                  <div class="flex justify-between items-center" @click="checkIfCurrentTaskActive">
                    Weiter
                    <span translate="no" class="material-symbols-outlined notranslate text-2xl"> chevron_right </span>
                  </div>
                </button>
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center text-gray-500 hover:text-gray-400 gap-x-1 text-sm font-semibold rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none"
                  data-hs-stepper-finish-btn=""
                  style="display: none"
                >
                  Finish
                </button>
                <button type="button" data-hs-stepper-back-btn=""></button>
                <button
                  type="reset"
                  class="py-2 px-3 inline-flex items-center text-gray-500 hover:text-gray-400 gap-x-1 text-sm font-semibold rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none"
                  data-hs-stepper-reset-btn=""
                  style="display: none"
                >
                  Reset
                </button>

                <!-- Prüfungen und Quizze -->
                <div v-show="!!caseInteractionStore.currentCaseInteractionId" class="snap-center shrink-0">
                  <div class="hs-dropdown --trigger:click py-3">
                    <button
                      type="button"
                      class="gap-x-0.5 hs-dropdown-toggle flex items-center w-full text-sm font-semibold text-gray-500 hover:text-gray-400"
                    >
                      <!--                  <ProfileImage :image="oralExamInteractionStore.examinerProfileImageSmall" showInitial="false" />-->
                      <!-- Quiz me -->
                      <a> Quiz </a>
                      <span
                        translate="no"
                        class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                      >
                        expand_more
                      </span>
                    </button>
                    <QuizDropdown
                      ref="quizDropdownHandle"
                      @startMc="onMcExam"
                      @startOral="onOralExam"
                      @startTranslateVocab="onTranslateVocab"
                      @startExplainVocab="onExplainVocab"
                    />
                    <!-- TODO: add evaluation (grammar, vocab, CASE TASKS/ SUBTASKS !!!) to eval => then re-enable this button if not coming from a course chapter -->
                    <!-- - before that, we enable evaluation only for cases startd from a chapter -->
                    <button
                      v-show="!!originChapterId"
                      type="button"
                      @click="gotoEvaluation"
                      class="gap-x-0.5 hs-dropdown-toggle flex items-center w-full text-sm font-semibold text-gray-500 hover:text-gray-400"
                    >
                      <a> {{ t('message.evaluate') }} </a>
                      <span
                        translate="no"
                        class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                      >
                        checklist
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- End Button Group -->
            </div>
            <!-- End Stepper Nav -->

            <!-- Stepper dummy content -->
            <div>
              <div v-for="(task, index) in allTasks" :data-hs-stepper-content-item="indexString(index)"></div>
              <div data-hs-stepper-content-item='{"isFinal": true}'></div>
            </div>
            <!-- End Stepper Content -->
          </div>
          <!-- End Stepper -->
        </div>
        <!-- End Collapse -->
      </div>
      <!-- End Nav Links -->
    </div>
  </nav>
</template>

<style scoped></style>
