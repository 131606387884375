import { ApiClient } from './ApiClient';
import { useAuthStore } from '@/stores';

export async function getApiClient(validateToken: boolean = true) {
  // this call will also try to refresh the token if it is expired
  const authStore = useAuthStore();
  if (validateToken) {
    await authStore.getValidatedToken();
  }
  const token = localStorage.getItem('token');
  const accessToken = token ? JSON.parse(token)['access_token'] : null;

  return new ApiClient({
    BASE: import.meta.env.VITE_REST_API_BASE_URL,
    TOKEN: accessToken,
  });
}
