<script setup lang="ts">
import Card from '@/components/didactics/pair_of_terms/Card.vue';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { PairOfTermsItemType } from '@/apiclient';
import { PairOfTermsItem } from '@/helper/typing';

const props = defineProps({
  items: {
    type: Array as PropType<PairOfTermsItem[]>,
    required: true,
  },
  solveTimerMs: {
    type: Number,
    default: 150,
  },
  isCorrect: {
    type: Boolean,
    default: false,
  },
  showResults: {
    type: Boolean,
    default: false,
  },
  item1DraggedOver: {
    type: Boolean,
    default: false,
  },
  item2DraggedOver: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  allowDrag: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'isLoading',
  'isDraggingItem1',
  'isDraggingItem2',
  'inputItem1',
  'inputItem2',
  'currentDraggingPosition',
  'droppedAtPosition',
  'scrollBy',
  'delete',
]);

const outerContainer = ref(null);
const showingResults = ref(false);

const fullWidth = computed(() => {
  return outerContainer.value?.offsetWidth;
});
const maxCardWidth = computed(() => {
  return fullWidth.value * 0.4;
});

const progress = ref(0);

const signalSolved = async () => {
  // over solveTimerMs, lt progress grow 0->100
  emit('isLoading', true);
  const intervalId = setInterval(async () => {
    progress.value += 1;
    if (progress.value >= 99) {
      showingResults.value = true;
      emit('isLoading', false);
      clearInterval(intervalId);
    }
  }, props.solveTimerMs / 100);
};

onMounted(async () => {});

watch(
  () => props.showResults,
  async (newVal) => {
    if (!!newVal) {
      await signalSolved();
    } else {
      progress.value = 0;
      showingResults.value = false;
    }
  },
);

defineExpose({
  signalSolved,
});
</script>

<template>
  <div ref="outerContainer" class="inline-flex w-full h-fit items-center justify-between py-1 md:py-2">
    <div :style="{ width: maxCardWidth + 'px' }" class="">
      <Card
        v-if="!!props.items?.[0]"
        :isItem1="true"
        :item="props.items[0]"
        :isEditing="isEditing"
        :allowDrag="allowDrag"
        :isCorrect="isCorrect"
        :showResults="showingResults"
        :draggedOver="item1DraggedOver"
        :width="maxCardWidth"
        @isDragging="(isDragging, itemId) => emit('isDraggingItem1', isDragging, itemId)"
        @inputItem="(newItem) => emit('inputItem1', newItem)"
        @currentDraggingPosition="(position) => emit('currentDraggingPosition', position)"
        @droppedAtPosition="(position) => emit('droppedAtPosition', position)"
        @scrollBy="(distance) => emit('scrollBy', distance)"
      />
    </div>
    <div class="flex-grow select-none items-center">
      <div
        class="relative flex w-full h-2 bg-gray-200 overflow-visible dark:bg-neutral-700"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          class="flex flex-col justify-center overflow-hidden text-xs text-white text-center whitespace-nowrap"
          :class="{
            'bg-gray-500': !showingResults,
            'bg-teal-500': showingResults && props.isCorrect,
            'bg-red-500': showingResults && !props.isCorrect,
          }"
          :style="{ width: progress + '%' }"
        ></div>
        <span class="absolute cursor-pointer w-full -mt-2 text-center text-red-600 hover:text-red-800">
          <span
            translate="no"
            class="material-symbols-outlined notranslate bg-white w-fit"
            v-if="isEditing"
            @click.prevent="emit('delete')"
            >delete</span
          >
        </span>
      </div>
      <span class="sr-only"></span>
    </div>
    <div :style="{ width: maxCardWidth + 'px' }">
      <Card
        v-if="!!props.items?.[1]"
        :isItem1="false"
        :item="props.items[1]"
        :isEditing="isEditing"
        :allowDrag="allowDrag"
        :isCorrect="isCorrect"
        :showResults="showingResults"
        :draggedOver="item2DraggedOver"
        :width="maxCardWidth"
        @isDragging="(isDragging, itemId) => emit('isDraggingItem2', isDragging, itemId)"
        @inputItem="(newItem) => emit('inputItem2', newItem)"
        @currentDraggingPosition="(position) => emit('currentDraggingPosition', position)"
        @droppedAtPosition="(position) => emit('droppedAtPosition', position)"
        @scrollBy="(distance) => emit('scrollBy', distance)"
      />
    </div>
  </div>
</template>

<style scoped></style>
