<script setup lang="ts">
import { nextTick, onMounted } from 'vue';
import { HSTooltip } from 'preline';

const { message } = defineProps(['message']);

onMounted(async () => {
  await nextTick();

  setTimeout(() => {
    HSTooltip.autoInit();
  }, 200);
});
</script>

<template>
  <div class="hs-tooltip inline-block items-center justify-center z-40">
    <button type="button" class="hs-tooltip-toggle flex items-center justify-center">
      <svg
        class="inline-block w-3 h-3 text-gray-400 dark:text-gray-600"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path
          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
        />
      </svg>
    </button>
    <span
      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-justify z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded-md shadow-sm dark:bg-neutral-700"
      role="tooltip"
    >
      {{ message }}
    </span>
  </div>
</template>

<style scoped></style>
