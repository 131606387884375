<!--
// TODO: explain and exam column sometimes get blocked when ending cases and reloading WHILE streming (main column likewise?) -- FIX THIS
-->

<script setup>
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useAuthStore, useMcExamInteractionStore } from '@/stores';
import ChatBubbleMcQuestion from '@/components/chat_bubbles/ChatBubbleMcQuestion.vue';
import { getApiClient } from '@/apiclient/client';
import ChatInput from '@/components/inputs/InteractionInput.vue';
import ChatBubbleProfessor from '@/components/chat_bubbles/ChatBubbleProfessor.vue';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import { getStreamingClient } from '@/apistreamer/streamingclient';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const mcExamInteractionStore = useMcExamInteractionStore();
const { currentMcExamInteractionId, chatIsStreaming, chatMessages } = storeToRefs(mcExamInteractionStore);

async function handleSubmit(inputText) {
  console.debug('User input: ' + inputText.value);
  console.debug(' for mc exam interaction ' + currentMcExamInteractionId.value);
  const content = inputText.value;

  await mcExamInteractionStore.say(content);

  console.debug(JSON.stringify(chatMessages.value));
}
</script>

<template>
  <div class="overflow-y-auto flex-col-reverse flex p-4">
    <!-- this fixes scroll to bottom; source: https://stackoverflow.com/a/72644230 -->
    <div class="translate-z-0">
      <!-- part of the scroll stick -->

      <div class="mt-5 space-y-2" v-for="message in chatMessages">
        <!-- TODO: handle MC question and feedback separately -->
        <div v-if="message.type === 'MC_REACT'" class="space-y-2">
          <div>
            <ChatBubbleMcQuestion :message="message" />
          </div>
        </div>
        <div v-if="message.type === 'MC'" class="space-y-2">
          <div>
            <ChatBubbleUser :message="message" conversation="MC-EXAM" />
          </div>
          <div>
            <ChatBubbleMcQuestion :message="message" />
          </div>
          <!--        <div>-->
          <!--          <ChatBubbleMcFeedback :message="message"/>-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </div>

  <!-- TODO: als Input nur fünf Felder A-E zum Anklicken: Abgrenzung zur mdl Prüfung -->
  <div>
    <ChatInput
      :placeholderMessage="[mcExamInteractionStore.examinerName] + ' etwas antworten...'"
      :disabled="chatIsStreaming"
      @onSubmit="handleSubmit"
    />
  </div>
</template>

<style scoped></style>
