<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import AudioRecorderSST from '@/components/inputs/AudioRecorderSTT.vue';
import ButtonChevronShowHide from '@/components/inputs/ButtonChevronShowHide.vue';
import { useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import ChatInput from '@/components/inputs/ChatInput.vue';
import InteractionInputToggleGroup from '@/components/inputs/InteractionInputToggleGroup.vue';

const patientInteractionStore = usePatientInteractionStore();
const { descIsStreaming } = storeToRefs(patientInteractionStore);

const caseInteractionStore = useCaseInteractionStore();
const { audioIsStreaming } = storeToRefs(caseInteractionStore);

const authStore = useAuthStore();
const { currentChatEnabled: chatEnabled } = storeToRefs(authStore);

const props = defineProps({
  placeholderMessage: {
    type: String,
  },
  disabled: {
    default: false,
  },
  allowAudioInput: {
    default: false,
  },
});

const emit = defineEmits([
  'onSubmit',
  'onShowHistory',
  'onShowActionColumn',
  'onToggleSubtitles',
  'onToggleMockDesktop',
]);
const inputText = ref('');
const resetAudioInput = ref(false);

const mockProgress = ref(0);

onMounted(() => {});

async function handleSubmit() {
  if (props.disabled) {
    return;
  }
  if (inputText.value.trim() === '') {
    return;
  }
  emit('onSubmit', inputText.value);
  inputText.value = '';

  // linearly increment mockProgress from 0 to 100 over 3 seconds
  mockProgress.value = 0;
  const interval = setInterval(() => {
    mockProgress.value += 1;
    if (mockProgress.value >= 100) {
      clearInterval(interval);
      mockProgress.value = 0;
    }
  }, 40);
}

function showHistory(show: boolean) {
  emit('onShowHistory', show);
}

function showActionColumn(show: boolean) {
  emit('onShowActionColumn', show);
}

// const isLargeScreen = ref(true);
//
// window.onresize = async function () {
//   console.log(window.document.body.clientWidth)
//   const newVal = window.document.body.clientWidth >= 1024
//   console.log(isLargeScreen.value)
//   if (newVal !== isLargeScreen.value) {
//     isLargeScreen.value = newVal;
//     await new Promise((resolve) => setTimeout(resolve, 100));
//   }
//   isLargeScreen.value = newVal;
//
//   await new Promise((resolve) => setTimeout(resolve, 100));
// };
//
// onMounted(async () => {
// });

const isLargeScreen = computed(() => {
  // Check if the screen size is large (greater than or equal to lg breakpoint)
  console.log('width: ' + window.document.body.clientWidth);
  const screenWidth = window.document.body.clientWidth;
  return screenWidth >= 1024;
});

// const isLargeScreen = computed(() => {
//   // Check if the screen size is large (greater than or equal to lg breakpoint)
//   console.log('window.innerWidth: ' + window.innerWidth);
//   const screenWidth = window.innerWidth;
//   return screenWidth >= 1024;
// });

watch(
  () => inputText.value,
  (newVal, oldVal) => {
    if (oldVal !== '' && newVal === '') {
      // input reset to '', so either submitted or deleted by user => reset audio recorder to await new input
      resetAudioInput.value = true;
    }
    if (newVal !== '' && !chatEnabled.value) {
      // chat is disabled and non-trivial input received from AudioRecorder => SUBMIT and reset!
      console.log('Chat disabled, non-trivial input received from AudioRecorder => SUBMIT and reset!');
      console.log('Direct submission from audio is: ' + inputText.value);
      handleSubmit();
    }
  },
);

function resetAudioInputCompleted() {
  resetAudioInput.value = false;
}

function updateInputTextByTranscription(transcription: string) {
  inputText.value = transcription;
}

function openMockDesktop() {
  emit('onToggleMockDesktop');
}
</script>

<template>
  <div class="flex min-w-full w-full min-h-full z-[90] max-h-fit">
    <div class="items-center min-w-full w-full min-h-full grid grid-rows-1 grid-cols-10">
      <!-- Toggle action column (if screen size < sm only!). If larger: shown by action column itself -->
      <div class="pl-1 sm:pl-0 sm:hidden min-w-full min-h-full row-start-1 col-start-1 inline-flex col-span-2 py-6">
        <ButtonChevronShowHide
          id="showActionColumn"
          @showTarget="showActionColumn"
          label="Aktionen"
          showIcon="chevron_right"
          :autoShowTarget="descIsStreaming"
          :autoCloseTarget="audioIsStreaming"
        />
        <ButtonToggle
          id="InteractionInputMockDesktopButton"
          label="KIS-PC"
          labelAbbreviation="KIS"
          icon="desktop_windows"
          @onToggle="openMockDesktop"
        />
      </div>
      <!-- End of toggle action column -->

      <!-- Audio & chat input -->
      <div
        class="min-h-full row-start-1 items-center"
        :class="
          chatEnabled
            ? 'col-start-4 col-span-6 sm:col-span-8 grid grid-rows-1 grid-cols-10 min-h-full'
            : 'col-start-1 col-span-10 md:col-span-8 2xl:col-span-7 row-start-1 justify-center flex'
        "
      >
        <!-- Audio input -->
        <div
          class="flex -mt-6 -mx-6 max-h-full h-full min-h-full"
          :class="
            chatEnabled
              ? 'row-start-1 col-start-1 sm:col-start-2 md:col-start-1 justify-start'
              : 'min-w-full w-full items-center justify-center'
          "
        >
          <AudioRecorderSST
            :disabled="props.disabled"
            @newTranscription="updateInputTextByTranscription"
            :reset="resetAudioInput"
            @resetComplete="resetAudioInputCompleted"
            :mockProgress="mockProgress"
          />
        </div>
        <!-- End Audio input -->
        <!-- Text input -->
        <div
          class="row-start-1 col-start-2 sm:col-start-2 sm:mr-0 col-span-8 sm:col-span-8 2xl:col-span-7 grid grid-cols-1 grid-rows-1 justify-start"
          v-show="chatEnabled"
        >
          <ChatInput
            v-model="inputText"
            :placeholderMessage="props.placeholderMessage"
            :disabled="props.disabled"
            @onSubmit="handleSubmit"
          />
        </div>
        <!-- End Text input -->
      </div>
      <!-- Enf of Audio & chat input -->

      <!-- Right button group -->
      <div
        class="pr-1 row-start-1 col-start-11 col-span-2 sm:col-start-10 sm:col-span-3 flex items-center justify-end sm:px-2"
      >
        <!-- toggle button group (closed captions + keyboard input on/ off) -->
        <InteractionInputToggleGroup />
        <!-- end of toggle button group -->
        <ButtonChevronShowHide id="showHistory" @showTarget="showHistory" label="Verlauf" showIcon="chevron_left" />
      </div>
      <!-- End of right button group -->
    </div>
  </div>
</template>

<style scoped></style>
