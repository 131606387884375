import { defineStore } from 'pinia';
import { Chapter, Course, CourseCreate, LearningObjective, PairOfTermsGameUpdate } from '@/apiclient';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores/alert.store';

interface UpdateSectionOptions {
  title?: string | null;
  learningObjectives?: LearningObjective[] | null;
  internalIndex?: number | null;
  textContents?: any[] | null;
  publishedAt?: string | null;
}

interface VocabItem {
  term: string;
  explanation?: string | null;
}

const VocabItemDefault: VocabItem = {
  term: '',
  explanation: null,
};

// interface PairOfTermsItemCreate {
//     id: string | null;
//     type: string | null;
//     content: string | null;
//     description: string | null;
//     media_item_id: string | null;
// }
//
// interface PairOfTermsItemTupleUpdate {
//     id: string;
//     pair_of_terms_items: PairOfTermsItemCreate[];
// }

// interface PairOfTermsGameUpdate {
//     pair_of_terms_item_tuples: PairOfTermsItemTupleUpdate[];
// }

export const useCourseStore = defineStore({
  id: 'course',
  state: () => ({
    currentCourse: null as Course | null,
    settingCoursePromise: null as Promise<Boolean> | null,
    currentChapter: null as Chapter | null,
    currentChapterIndex: null as number | null,
    settingChapterPromise: null as Promise<Boolean> | null,
  }),
  getters: {
    currentCourseTitle: (state) => (state.currentCourse ? state.currentCourse.title : null),
    currentCourseDescription: (state) => (state.currentCourse ? state.currentCourse.description : null),
    currentChapterTitle: (state) => (state.currentChapter ? state.currentChapter.title : null),
    currentChapterId: (state) => (state.currentChapter ? state.currentChapter.id : null),
    currentCourseChapters: (state) => (state.currentCourse ? state.currentCourse.chapters : []),
    currentChapterSortedSections: (state) => state.currentChapter?.sections?.sort((a, b) => a.index - b.index) || [],
    numberOfChapters: (state) => (state.currentCourse ? state.currentCourse.chapters.length : 0),
    numberOfCases: (state) =>
      !!state.currentCourse && !!state.currentCourse.chapters
        ? state.currentCourse.chapters.reduce((totalCases, chapter) => {
            if (chapter.sections == undefined) {
              return totalCases;
            }
            return (
              totalCases +
              chapter.sections.reduce((sectionTotal, section) => {
                return sectionTotal + (!!section.n_cases ? section.n_cases : 0);
              }, 0)
            );
          }, 0)
        : 0,
  },
  actions: {
    isOwnerOfCurrentCourse(userId: string) {
      if (!this.currentCourse) {
        return false;
      }
      console.log('Current course user id is: ', this.currentCourse.user.id);
      return this.currentCourse.user.id === userId;
    },
    isEditorOfCurrentCourse(userId: string) {
      // return true if userId is in this.currentCourse.course_user_editors.user_id
      if (!this.currentCourse) {
        return false;
      }
      if (!this.currentCourse.course_user_editorships) {
        return false;
      }
      return this.currentCourse.course_user_editorships.some(
        (editor) => editor.user.id === userId && editor.deactivated_at == null,
      );
    },
    isOwnerOfCurrentChapter(userId: string) {
      if (!this.currentChapter) {
        return false;
      }
      return this.currentChapter.user.id === userId;
    },
    async setCurrentCourse(courseId: string) {
      if (this.settingCoursePromise) {
        console.log('setCurrentCourse: already setting course, waiting for promise');
        // TODO: wait or return ?
        await this.settingCoursePromise;
        console.log('setCurrentCourse: setting course promise resolved');
      }
      // load the course incl chapters from the API
      this.settingCoursePromise = new Promise(async (resolve, reject) => {
        try {
          console.log('setCurrentCourse: loading course details for courseId: ' + courseId);
          this.currentCourse = await (await getApiClient()).courses.getCourse(courseId, 5);
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
      return this.settingCoursePromise;
    },
    async setCurrentChapter(chapterId: string) {
      if (this.settingChapterPromise) {
        console.log('setCurrentChapter: already setting chapter, waiting for promise');
        // TODO: wait or return ?
        await this.settingChapterPromise;
        console.log('setCurrentChapter: setting chapter promise resolved');
      }
      console.log('setCurrentChapter: loading chapter details for chapterId: ' + chapterId);
      await this.settingCoursePromise;
      console.log('setCurrentChapter: setting course promise resolved');

      this.settingChapterPromise = new Promise(async (resolve, reject) => {
        try {
          this.currentChapter = await (await getApiClient()).chapters.getChapter(chapterId);

          if (!this.currentCourse || this.currentCourse.id !== this.currentChapter.course_id) {
            if (!this.currentCourse) {
              console.log('setting course from setting chapter because: no current course');
            } else {
              console.log(
                'setting course from setting chapter because: ' +
                  this.currentCourse.id +
                  ' !== ' +
                  this.currentChapter.course_id,
              );
            }
            await this.setCurrentCourse(this.currentChapter.course_id);
          }

          this.currentChapterIndex = this.currentChapter.index;

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
      return this.settingChapterPromise;
    },
    async listCourses() {
      return (await getApiClient()).courses.listCourses();
    },
    async setCurrentChapterByIndex(chapterIndex: number) {
      if (!this.currentCourse) {
        return;
      }
      this.currentChapterIndex = chapterIndex;
      await this._setChapterForCurrentIndex();
    },
    async _setChapterForCurrentIndex() {
      if (!this.currentCourse) {
        return;
      }
      if (this.currentChapterIndex === null) {
        await this.nextChapter();
      }
      let currentChapter = await (
        await getApiClient()
      ).chapters.getChapterByCourse(this.currentCourse.id, this.currentChapterIndex);
      if (currentChapter.id === this.currentChapter?.id) {
        return this.currentChapter; // do not overwrite, will lose detailed information on sections already loaded
      }
      this.currentChapter = currentChapter;
      console.log(
        'loaded chapter details for chapterIndex: ' +
          this.currentChapterIndex +
          ' of courseId: ' +
          this.currentCourse.id,
      );
      console.log('nextChapter is now: ' + this.currentChapter.title + ' @ ' + this.currentChapter.id);
      return this.currentChapter;
    },
    async appendEmptySection() {
      if (!this.currentChapter) {
        return;
      }
      const newSection = await (
        await getApiClient()
      ).sections.createSection({
        title: 'Überschrift',
        contents: 'Inhalt mit Erläuterungen, Tabellen, Beispielfällen, ... .',
        index: this.currentChapter.sections ? this.currentChapter.sections.length : 0,
        chapter_id: this.currentChapter.id,
      });
      if (!this.currentChapter.sections) {
        this.currentChapter.sections = [];
      }
      this.currentChapter.sections.push(newSection);
    },
    async appendEmptyChapter() {
      if (!this.currentCourse) {
        return;
      }
      const newChapter = await (
        await getApiClient()
      ).chapters.createChapter({
        title: 'Chapter Title',
        description: 'Chapter description.',
        index: this.currentCourse.chapters ? this.currentCourse.chapters.length : 0,
        course_id: this.currentCourse.id,
      });
      if (!this.currentCourse.chapters) {
        this.currentCourse.chapters = [];
      }
      this.currentCourse.chapters.push(newChapter);
    },
    async appendEmptyCourse() {
      const newCourse = await (
        await getApiClient()
      ).courses.createCourse({
        title: 'Course Title',
        description: 'Course description.',
      } as CourseCreate);
      this.currentCourse = newCourse;
    },
    async moveChapter(oldIndex: number, newIndex: number) {
      if (!this.currentCourse) {
        return;
      }
      if (!this.currentCourse.chapters || this.currentCourse.chapters.length <= 1) {
        return;
      }

      // Ensure indices are within the valid range
      if (
        oldIndex < 0 ||
        oldIndex >= this.currentCourse.chapters.length ||
        newIndex < 0 ||
        newIndex >= this.currentCourse.chapters.length
      ) {
        return;
      }

      // Remove the chapter from the old position
      const [movedChapter] = this.currentCourse.chapters.splice(oldIndex, 1);

      // Insert the chapter at the new position
      this.currentCourse.chapters.splice(newIndex, 0, movedChapter);

      // Update the 'index' property of each chapter to reflect the new order
      this.currentCourse.chapters.forEach((chapter, index) => {
        chapter.index = index; // Update the 'index' to match the new order
      });

      // make persistent changes
      this.currentCourse.chapters.forEach(async (chapter) => {
        await (await getApiClient()).chapters.updateChapter(chapter.id, { index: chapter.index });
      });

      // await this.setCurrentCourse(this.currentCourse.id);
      // TODO do updates by hand to avoid reloading the whole course
    },
    async moveSection(oldIndex: number, newIndex: number) {
      if (!this.currentChapter) {
        return;
      }
      if (!!this.currentChapter.sections && this.currentChapter.sections.length <= 1) {
        return;
      }

      // make a list of tuples with [sectionId, updated index]
      const indexedIds = this.currentChapter.sections.map((section) => [section.id, section.index]);
      // move the section to the new index
      indexedIds.splice(newIndex, 0, indexedIds.splice(oldIndex, 1)[0]);
      // update the index of each section
      for (let i = 0; i < indexedIds.length; i++) {
        await (await getApiClient()).sections.updateSection(indexedIds[i][0], { index: i });
      }
      await this.setCurrentChapter(this.currentChapter.id);
      // TODO do updates by hand to avoid reloading the whole course
    },
    async deleteChapter(index: number) {
      if (!this.currentCourse) {
        return;
      }
      if (!!this.currentCourse.chapters && this.currentCourse.chapters.length <= 1) {
        return;
      }

      const chapterId = this.currentCourse.chapters.find((chapter) => chapter.index === index)?.id;
      await (await getApiClient()).chapters.deleteChapter(chapterId);
      await this.setCurrentCourse(this.currentCourse.id);
      // TODO do updates by hand to avoid reloading the whole course
    },
    async removeContentItem(sectionId: string, contentItemId: string) {
      console.log('Deleting content item: ' + contentItemId);
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.deleteSectionContentItem(contentItemId);
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async refetchSelectedSection(externalSectionIndex: number) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      if (!this.currentChapter.sections[externalSectionIndex]) {
        throw new Error('No section at external index ' + externalSectionIndex);
      }
      if (this.settingCoursePromise) {
        console.log('refetchSelectedSection: setting course still in progress, waiting for promise');
        await this.settingCoursePromise;
        console.log('refetchSelectedSection: setting course promise resolved. Continue refetching section.');
      }
      if (this.settingChapterPromise) {
        console.log('refetchSelectedSection: setting chapter still in progress, waiting for promise');
        await this.settingChapterPromise;
        console.log('refetchSelectedSection: setting chapter promise resolved. Continue refetching section.');
      }
      console.log('refreshing section #', externalSectionIndex);
      this.currentChapter.sections[externalSectionIndex] = await (
        await getApiClient()
      ).sections.getSection(this.currentChapter.sections[externalSectionIndex].id);
    },
    async addCase(sectionId: string, caseId: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.appendSectionContentItemWithCase(sectionId, caseId);
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async addTextSnippet(sectionId: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithTextContent(sectionId, {
        content: 'Ein Text mit Erläuterungen, Tabellen, ... .',
      });
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async addEmptyVocabList(sectionId: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.appendSectionContentItemWithEmtpyVocabList(sectionId);
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async addVocabItemToContentItemWithVocabList(
      sectionId: string,
      contentItemId: string,
      vocabItem: VocabItem = VocabItemDefault,
    ) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.addVocabItemToContentItemWithVocabList(contentItemId, vocabItem);
      // refetch section item's vocab list
      let sectionIndex = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      let section = this.currentChapter.sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex((contentItem) => contentItem.id === contentItemId);
      this.currentChapter.sections[sectionIndex].section_content_items[contentItemIndex].vocab_list = await (
        await getApiClient()
      ).courseSectionItems.getVocabListForContentItem(contentItemId);
    },
    async updateVocabItemInContentItemWithVocabList(
      contentItemId: string,
      vocabItemId: string,
      vocabItemUpdate: VocabItem,
    ) {
      const alertStore = useAlertStore();
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems
        .updateVocabItemInContentItemWithVocabList(contentItemId, vocabItemId, vocabItemUpdate)
        .catch((error) => {
          alertStore.error('Fehler beim Speichern der Vokabel', 'Fehler', error);
        });
    },
    async addVocabListFromExtractions(sectionId: string, textToExtractFrom: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithVocabListExtractingFromString(sectionId, textToExtractFrom);
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async generateAndAddMoreVocab(sectionId: string, contentItemId: string, context: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      let updatedContentItem = await (
        await getApiClient()
      ).courseSectionItems.addMoreVocabOfSameContextToVocabList(contentItemId, context);
      let sectionIndex = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      let section = this.currentChapter.sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex((contentItem) => contentItem.id === contentItemId);
      console.log('updated content item: ', JSON.stringify(updatedContentItem));
      this.currentChapter.sections[sectionIndex].section_content_items[contentItemIndex].vocab_list =
        updatedContentItem.vocab_list;
    },
    async addPairOfTermsGame(sectionId: string) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.appendSectionContentItemWithPairOfTermsGame(sectionId);
      // refetch section
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async updatePairOfTermsGame(
      sectionId: string,
      contentItemId: string,
      pairOfTermsGameUpdate: PairOfTermsGameUpdate,
    ) {
      if (!this.currentChapter) {
        throw new Error('No current chapter set');
      }
      if (!this.currentChapter.sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.updateSectionContentItemWithPairOfTermsGame(contentItemId, pairOfTermsGameUpdate, true);
      // refetch section
      // TODO improve performance - do not refetch entire section, only updated game!
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = await (await getApiClient()).sections.getSection(sectionId);
    },
    async publishSection(sectionId: string, publish: boolean) {
      if (!this.currentChapter) {
        return;
      }
      await this.updateSection(sectionId, { publishedAt: publish ? new Date().toISOString() : null });
    },
    async updateSection(sectionId: string, options: UpdateSectionOptions) {
      if (!this.currentChapter) {
        return;
      }

      const {
        title = null,
        learningObjectives = null,
        internalIndex = null,
        textContents = null,
        publishedAt = null,
      } = options;

      // text content updates, if any
      if (!!textContents && textContents.length > 0) {
        await Promise.all(
          textContents.map(async (textContent) => {
            console.log('updating content item: ' + textContent.contentItemId);
            await (
              await getApiClient()
            ).courseSectionItems.updateSectionContentItemWithTextContent(textContent.contentItemId, {
              content: textContent.content,
            });
          }),
        );
      }
      // todo same for media and other content items

      // all global section updates (everything apart from content items)
      const updatedSection = await (
        await getApiClient()
      ).sections.updateSection(sectionId, {
        title: title,
        learning_objectives: learningObjectives,
        published_at: publishedAt,
      });
      if (!this.currentChapter.sections) {
        this.currentChapter.sections = [];
      }
      let index = this.currentChapter.sections.findIndex((section) => section.id === sectionId);
      this.currentChapter.sections[index] = updatedSection;
    },
    async deleteSection(sectionId: string) {
      if (!this.currentChapter) {
        return;
      }
      await (await getApiClient()).sections.deleteSection(sectionId);

      // TODO do updates by hand to avoid reloading the whole course
      await this.setCurrentChapter(this.currentChapter.id);
    },
    async requestSectionTranslations(sectionId: string, sourceLanguageCode: string, targetLanguageCodes: string[]) {
      if (!this.currentChapter) {
        return;
      }
      await (
        await getApiClient()
      ).sections.requestSectionTranslations(sectionId, sourceLanguageCode, targetLanguageCodes);
    },
    async extractSectionVocabularyAndExampleFormulations(sectionId: string) {
      if (!this.currentChapter) {
        return;
      }
      await (await getApiClient()).sections.extractSectionVocabularyAndExampleFormulations(sectionId);
    },
    async updateChapterTitleAndDescription(chapterId: string, title: string, description: string) {
      if (!this.currentCourse) {
        return;
      }
      const updatedChapter = await (
        await getApiClient()
      ).chapters.updateChapter(chapterId, {
        title,
        description,
      });
      if (!this.currentCourse.chapters) {
        this.currentCourse.chapters = [];
      }
      // updateChapter is not fully loaded, so we move the old section to the new index and only updated the
      // properties that we actually updated, i.e. title and content
      const index = this.currentCourse.chapters.findIndex((chapter) => chapter.id === chapterId);
      this.currentCourse.chapters[index].title = updatedChapter.title;
      this.currentCourse.chapters[index].description = updatedChapter.description;
    },
    async updateCourseTitleAndDescription(courseId: string, title: string, description: string) {
      console.log(
        'updateCourseTitleAndDescription: courseId: ' +
          courseId +
          ', title: ' +
          title +
          ', description: ' +
          description,
      );
      if (!this.currentCourse) {
        console.log('updateCourseTitleAndDescription: No current course set');
        return;
      }
      const updatedCourse = await (
        await getApiClient()
      ).courses.updateCourse(courseId, {
        title,
        description,
      });
      console.log(
        'updateCourseTitleAndDescription: updatedCourse: ' + updatedCourse.title + ', ' + updatedCourse.description,
      );
      // updateCourse is not fully loaded, so we only updated the
      // properties that we actually updated, i.e. title and content
      this.currentCourse.title = updatedCourse.title;
      this.currentCourse.description = updatedCourse.description;
    },
  },
});
