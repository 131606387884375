<!-- VocabListEntry.vue -->
<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { FlexRender } from '@tanstack/vue-table';
import { debounce } from 'lodash';
import { is } from '@vee-validate/rules';

const row = defineModel('row');
const emit = defineEmits(['isDragging', 'scrollBy', 'currentDraggingPosition', 'droppedAtPosition']);
const isDragging = ref(false);
const cardRef = ref(null);
const initialOffset = ref({ x: 0, y: 0 });
const initialHeight = ref(0);
const initialWidth = ref(0);
const touchX = ref(0);
const touchY = ref(0);
const scrollInterval = ref<ReturnType<typeof setInterval> | null>(null);

const signalStartDragging = () => {
  if (isDragging.value) {
    return;
  }
  isDragging.value = true;
  emit('isDragging', isDragging.value, row.value.original['id']);
};

const signalEndDragging = () => {
  isDragging.value = false;
  emit('isDragging', isDragging.value, null);
};

const handleTouchStart = (event: Event) => {
  const touch = event.touches[0];
  const rect = cardRef.value.getBoundingClientRect();
  initialOffset.value.x = touch.clientX - rect.left;
  initialOffset.value.y = touch.clientY - rect.top;
  initialHeight.value = rect.height;
  initialWidth.value = rect.width;

  signalStartDragging();
};

const handleTouchEnd = (event: Event) => {
  // this also signal end of dropping. Do not send signal drop end before sending drop position as this would reset ID
  // of dragged item in parent (not necessary at all, but in any case send position first)
  emit('droppedAtPosition', { x: touchX.value, y: touchY.value });
  cardRef.value.style.position = 'static';

  signalEndDragging(); // resets currently dragged item, so do this after sending drop position
};

const handleTouchMove = (event: Event) => {
  // Prevent the default scroll behavior; needs @touchmove instead of v-touch:drag for access to event
  event.preventDefault();

  // Get the current touch position
  const touch = event.touches[0];
  touchX.value = touch.clientX;
  touchY.value = touch.clientY;

  // Set the element's position to follow the touch point directly
  cardRef.value.style.position = 'fixed';
  cardRef.value.style.left = `${touchX.value - initialOffset.value.x}px`;
  cardRef.value.style.top = `${touchY.value - initialOffset.value.y}px`;
  cardRef.value.style.height = `${initialHeight.value}px`;
  cardRef.value.style.width = `${initialWidth.value}px`;

  debounceSignalCurrentPosition();
};

const signalCurrentPosition = () => {
  emit('currentDraggingPosition', { x: touchX.value, y: touchY.value });
};

const debounceSignalCurrentPosition = debounce(signalCurrentPosition, 50);
</script>

<template>
  <tr
    ref="cardRef"
    class=""
    :class="{
      'bg-gray-100 dark:bg-neutral-800 overflow-visible z-40': isDragging,
      'overflow-visible': !isDragging,
    }"
    draggable="true"
    @drag.prevent="signalStartDragging"
    @dragend.prevent="signalEndDragging"
  >
    <td
      v-for="cell in row.getVisibleCells()"
      :key="cell.id"
      class="pl-4 pr-10 md:py-2 text-start items-start size-px text-xs md:text-sm text-gray-800 dark:text-neutral-200"
      :style="{ width: `${cell.column.getSize()}px` }"
    >
      <div class="inline-flex fallback-break" v-if="cell.column.id === 'term'">
        <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
        <span
          class="'hidden -pl-4 text-gray-400 hover:text-gray-800 flex items-center'"
          v-touch:press="handleTouchStart"
          v-touch:release="handleTouchEnd"
          @touchmove="handleTouchMove"
        >
          <span translate="no" class="material-symbols-outlined notranslate pl-1 text-gray-400 -mb-2 text-lg"
            >drag_pan</span
          >
        </span>
      </div>
      <div v-else class="fallback-break" :style="{ width: `${cell.column.getSize()}px` }">
        <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
      </div>
    </td>
  </tr>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
