/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttendingInteractionMessage } from '../models/AttendingInteractionMessage';
import type { AttendingInteractionMessageCreate } from '../models/AttendingInteractionMessageCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AttendingInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Attending Interaction Messages
   * This will only return user or attending messages, not system messages.
   *
   * :param session:
   * :param user:
   * :param attending_interaction_id:
   * :return:
   * @param attendingInteractionId
   * @returns AttendingInteractionMessage Successful Response
   * @throws ApiError
   */
  public listAttendingInteractionMessages(
    attendingInteractionId: string,
  ): CancelablePromise<Array<AttendingInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/attending-interactions/{attending_interaction_id}',
      path: {
        attending_interaction_id: attendingInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Attending Interaction Message
   * @param attendingInteractionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createAttendingInteractionMessage(
    attendingInteractionId: string,
    requestBody: AttendingInteractionMessageCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/attending-interactions/{attending_interaction_id}',
      path: {
        attending_interaction_id: attendingInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
