/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicalNote } from '../models/ClinicalNote';
import type { ClinicalNoteEdit } from '../models/ClinicalNoteEdit';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClinicalNotesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Patient Interaction Clinical Notes
   * Return clinical_notes.
   *
   * :param session:
   * :param user:
   * :param patient_interaction_id:
   * :return:
   * @param patientInteractionId
   * @returns ClinicalNote Successful Response
   * @throws ApiError
   */
  public listPatientInteractionClinicalNotes(patientInteractionId: string): CancelablePromise<Array<ClinicalNote>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/clinical-notes/{patient_interaction_id}',
      path: {
        patient_interaction_id: patientInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Clinical Note
   * :param session:
   * :param user:
   * :param clinical_note_id:
   * :return:
   * @param clinicalNoteId
   * @returns ClinicalNote Successful Response
   * @throws ApiError
   */
  public getClinicalNote(clinicalNoteId: string): CancelablePromise<ClinicalNote> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/clinical-notes/single/{clinical_note_id}',
      path: {
        clinical_note_id: clinicalNoteId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Clinical Note
   * Edit clinical_notes.
   *
   * :param session:
   * :param user:
   * :param clinical_note_id:
   * :param clinical_note:
   * :return:
   * @param clinicalNoteId
   * @param requestBody
   * @returns ClinicalNote Successful Response
   * @throws ApiError
   */
  public editClinicalNote(clinicalNoteId: string, requestBody: ClinicalNoteEdit): CancelablePromise<ClinicalNote> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/clinical-notes/edit/{clinical_note_id}',
      path: {
        clinical_note_id: clinicalNoteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Clinical Note Translation
   * @param clinicalNoteId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestClinicalNoteTranslation(
    clinicalNoteId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/clinical-notes/translation/{clinical_note_id}',
      path: {
        clinical_note_id: clinicalNoteId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
