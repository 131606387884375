<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import VocabList from '@/components/didactics/VocabList.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { nextTick } from 'vue';
import { debounce } from 'lodash';

const authStore = useAuthStore();
const alertStore = useAlertStore();
const courseStore = useCourseStore();

const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'generateAndAddMoreVocab']);
const container = ref(null);
const vocabListRefs = ref([] as Ref<HTMLElement | null>[]); // user's personal lists
const mainVocabListRef = ref(null); // list of vocab items in content item

const isDraggingTo = ref([] as boolean[]);
const isDragging = ref(false);
const isDraggingItemId = ref('');
const practiceMode = ref(false);
const isSubmitting = ref(false);
const showingInset = ref(true);

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  showNative: {
    type: Boolean,
    default: false,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  vocabListLoading: {
    type: Boolean,
    default: false,
  },
});

const maxHeight = computed(() => {
  return `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`;
});

// const adjustMaxHeights = () => {
//   if (!container.value) {
//     return;
//   }
//   let maxHeight = window.innerHeight - props.outerHeaderHeight;
//   console.log('maxHeight', maxHeight);
//   container.value.style.setProperty('--max-height', `${maxHeight}px`);
// };
//
// const debouncedAdjustMaxHeight = debounce(adjustMaxHeights, 200);

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  document.addEventListener('click', handleClickOutside);
  isDraggingTo.value = new Array(authStore.user.vocab_lists.length).fill(false);

  authStore.user.vocab_lists.forEach(() => {
    vocabListRefs.value.push(ref(null));
  });
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event: Event) => {
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const fullHeight = computed(() => {
  if (!container.value) {
    return 150;
  }
  // TODO add extra height for each personal vocab list appearing in top window inset
  if (!!props.contentItem?.vocab_list?.vocab_items?.length && !!authStore.user?.vocab_lists?.length) {
    return `${props.contentItem.vocab_list.vocab_items.length * 35 + authStore.user.vocab_lists.length * 50 + 200}px`;
  }
  console.warn('fullHeight: no vocab items or vocab lists. Fallback to 800 px.');
  console.warn('test vocab items', props.contentItem?.vocab_list?.vocab_items?.length);
  console.warn('personal vocab lists', authStore.user?.vocab_lists?.length);
  return `800px`;
});

const computedMaxHeight = computed(() => {
  console.log('maxHeight', maxHeight.value, 'fullHeight', fullHeight.value);
  return `min(${maxHeight.value}, ${fullHeight.value})`;
});

const handleIsDraggingVocabItem = (isDraggingNow: boolean, vocabItemId: string) => {
  isDragging.value = isDraggingNow;
  isDraggingItemId.value = vocabItemId;
};

const handleDragOver = (event: Event, index: number) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'copy';
  isDraggingTo.value[index] = true;
};

const handleDragLeave = (event: Event, index: number) => {
  isDraggingTo.value[index] = false;
};

const handleDrop = async (index: number) => {
  console.log('dropped', isDraggingItemId.value, ' to ', index);
  let vocabListId = authStore.user.vocab_lists[index].id;
  await authStore.copyVocabItemToPersonalVocabList(vocabListId, isDraggingItemId.value, true, true);
  isDragging.value = false;
  isDraggingTo.value[index] = false;
  isDraggingItemId.value = '';
};

const addVocabItem = async () => {
  isSubmitting.value = true;
  await courseStore
    .addVocabItemToContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, {
      term: 'Begriff',
      explanation: 'Erläuterung',
    })
    .then(() => {
      alertStore.success('Vokabel erfolgreich hinzugefügt');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Fehler', error);
      console.error(error);
    });
  isSubmitting.value = false;
};

const updateItem = async (vocabItemId: string, columnName: string, newValue: any) => {
  let updateObject = {};
  updateObject[columnName] = newValue;

  await courseStore
    .updateVocabItemInContentItemWithVocabList(props.contentItem.id, vocabItemId, updateObject)
    .then(() => {
      console.log('Vokabel erfolgreich aktualisiert');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht aktualisiert werden', 'Fehler', error);
      console.error(error);
    });
};

const handleDroppedAtPosition = (position) => {
  for (let i = 0; i < vocabListRefs.value.length; i++) {
    const navItemContainer = vocabListRefs.value[i]?.value?.[0];
    if (!navItemContainer) {
      continue;
    }

    const rect = navItemContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(i);
    }
  }
};

watch(
  () => props.isMaximized,
  () => {
    if (!props.isMaximized) {
      showingInset.value = false;
    }
  },
);

watch(
  () => props.vocabListLoading,
  () => {
    mainVocabListRef.value?.scrollToBottom();
  },
);
</script>

<template>
  <div
    ref="container"
    class="w-full p-2 transition-1000 transition-all ease-in-out overflow-auto relative flex-col flex"
    :style="{
      height: props.isMaximized ? computedMaxHeight : '200px',
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
  >
    <!-- vocab lists card -->
    <div
      v-show="!props.allowEditing"
      class="z-20 text-sm flex-col top-1 right-1 transition-all transform duration-300 ease-in-out overflow-hidden absolute divide-y divide-gray-500 bg-white rounded-md text-gray-800 dark:text-neutral-200"
      :class="{
        'hd-[200px] md:h-[300px] p-4 mb-2 border border-gray-200 ': showingInset && props.isMaximized,
        'h-[0px] max-h-[0px]': !showingInset || !props.isMaximized,
      }"
    >
      <div class="pt-0 pb-1 md:pb-4 pl-2 mr:pr-8">
        <a class="hidden md:block hover:text-blue-600 hover:underline cursor-pointer" href="/vocab-dashboard"
          >Your personal vocab lists</a
        >
        <a class="block md:hidden hover:text-blue-600 hover:underline cursor-pointer" href="/vocab-dashboard"
          >Vocab lists</a
        >
        <div class="text-gray-400 rounded-md dark:text-neutral-600">Drag vocabs here to add</div>
      </div>
      <div class="pt-1 md:pt-4 pb-0 flex-col flex md:gap-y-2">
        <div v-for="(personalVocabList, index) in authStore.user.vocab_lists">
          <div
            :ref="vocabListRefs[index]"
            class="inline-flex items-center w-full md:p-1 rounded-lg"
            @dragover.prevent="handleDragOver($event, index)"
            @dragleave.prevent="handleDragLeave($event, index)"
            @drop.prevent="handleDrop(index)"
            :class="{
              'bg-gray-200 dark:bg-neutral-700': isDraggingTo[index],
              'bg-transparent dark:bg-neutral-800 border-dashed': !isDraggingTo[index],
              'text-blue-600': isDragging,
            }"
          >
            <span
              v-if="index === 0"
              translate="no"
              class="material-symbols-outlined notranslate items-center flex pr-2 text-xl"
            >
              join
            </span>
            <span
              translate="no"
              class="material-symbols-outlined notranslate items-center flex pr-2 text-xl"
              :style="{ fontVariationSettings: '\'FILL\' 1' }"
              v-else-if="index === 1"
            >
              star
            </span>
            <span v-else translate="no" class="material-symbols-outlined notranslate items-center flex pr-2 text-xl">
              list
            </span>
            {{ personalVocabList.name }}
            <div class="pl-4 material-symbols-outlined relative" v-show="isDragging">
              content_copy
              <span
                translate="no"
                class="material-symbols-outlined notranslate absolute top-[-13px] end-[-6px] text-lg font-medium"
                >add</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="props.isMaximized"
      class="absolute top-1 right-1 z-30 my-1 mx-1 md:mx-2 flex items-center text-sm cursor-pointer bg-white rounded-md text-gray-500 hover:text-gray-400 font-medium dark:text-gray-400 dark:hover:text-gray-500"
      @click.prevent="showingInset = !showingInset"
    >
      <span class="md:pr-2 select-none" v-show="!showingInset">Meine Vokabellisten</span>
      <span
        translate="no"
        class="material-symbols-outlined notranslate transition-all transform duration-300 ease-in-out select-none"
        :style="{
          transform: showingInset ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
        >expand_more</span
      >
      <span translate="no" class="material-symbols-outlined notranslate select-none">list</span>
    </div>
    <!-- end vocab lists card -->

    <div class="w-full h-full flex-col flex">
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="props.allowEditing">
        <span
          translate="no"
          class="material-symbols-outlined notranslate text-2xl"
          :class="{ 'pb-32': props.isMaximized }"
          >info</span
        >
        <div class="w-full items-start flex-col">
          <h3 class="text-xs md:text-sh text-justify">
            Ergänze und überarbeite die Vokabelliste.
            <span v-show="props.isMaximized">Der User kann diese Vokabeln</span>
          </h3>
          <ul
            v-show="props.isMaximized"
            class="text-xs md:text-sh text-start pt-2 pb-2"
            style="list-style-type: disc; padding-left: 20px"
          >
            <li>Lesen und lernen,</li>
            <li>In einem Aufdeckspiel üben und</li>
            <li>In seine eigene Vokabelliste übernehmen</li>
          </ul>
          <span v-show="props.isMaximized" class="text-xs md:text-sh text-start flex pb-8">
            Dies alles wird automatisch aus deiner Vokabelliste erzeugt.
          </span>
        </div>
      </div>
      <div v-show="props.isMaximized && !props.allowEditing" class="inline-flex w-full justify-between h-fit pb-4">
        <div class="flex mx-0 lg:mx-auto" @click="practiceMode = !practiceMode">
          <ProgressButton :text="practiceMode ? 'Lösungen aufdecken' : 'Vokabeln üben'" />
        </div>
      </div>

      <!-- TODO add usage examples! -->
      <VocabList
        ref="mainVocabListRef"
        :key="props.isMaximized && practiceMode"
        :show-searchbar="false"
        :vocab-list="props.contentItem.vocab_list.vocab_items"
        :view-fullscreen="false"
        :show-column-selected="false"
        :show-column-context-links="false"
        :show-column-usage-examples="false"
        :show-column-translations="props.showNative"
        :show-column-tags="false"
        :show-column-remarks="props.isMaximized"
        :show-column-results-at-tests="false"
        :show-column-is-favourite="false"
        :allow-editing="props.allowEditing"
        :hide-random-but-one-column="practiceMode ? ['term', 'explanation'] : null"
        @isDragging="
          (isDragging, vocabItemId) => {
            handleIsDraggingVocabItem(isDragging, vocabItemId);
          }
        "
        @updatedCell="
          (vocabItemId, columnName, newValue) => {
            updateItem(vocabItemId, columnName, newValue);
          }
        "
        @droppedAtPosition="handleDroppedAtPosition"
      />
      <div class="p-4 pt-8 gap-x-6 w-full flex items-center justify-center" v-show="allowEditing && props.isMaximized">
        <ProgressButton
          @click="addVocabItem"
          text="Vokabel hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="add"
          iconSize="text-2xl"
        />
        <ProgressButton
          @click="emit('generateAndAddMoreVocab')"
          text="Fünf weitere generieren"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="manufacturing"
          iconSize="text-2xl"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
