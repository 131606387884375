<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive, watch, defineProps, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useOrganizationStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import UserListItem from '@/components/dashboard/UserListItem.vue';
import _ from 'lodash';
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { debounce } from 'lodash';
import ListUsersTableHead from '@/views/dashboard/ListUsersTableHead.vue';
import { HSTabs } from 'preline';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const router = useRouter();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const initialFetchCompleted = ref(false);

const memberSearchTerm = ref('');

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await new Promise((resolve) => setTimeout(resolve, 200)); // wait for App.vue's HSStaticMethods.autoInit() to finish
  HSTabs.autoInit();

  await Promise.all([
    organizationStore.loadOrganization(),
    organizationStore.loadSeats(),
    organizationStore.loadMembers(),
  ]);

  initialFetchCompleted.value = true;
});

const debouncedMemberSearch = debounce(
  (searchTerm) => {
    // Load users and managers with the search term
    // This will be debounced to prevent too many requests
    // from being sent to the server
    organizationStore.loadMembers(searchTerm);
  },
  500,
  { maxWait: 1000 },
);

watch(memberSearchTerm, (newValue, oldValue) => {
  debouncedMemberSearch(newValue);
});

const allUsers = computed(() => {
  return organizationStore.members.filter((member) => {
    return member.is_manager === false;
  });
});

const allManagers = computed(() => {
  return organizationStore.members.filter((member) => {
    return member.is_manager === true;
  });
});

const allInvited = computed(() => {
  return organizationStore.members.filter((member) => {
    return member.deactivated_at === null && member.user.auth_user.confirmed_at === null;
  });
});

const allDeactivated = computed(() => {
  return organizationStore.members.filter((member) => {
    return member.deactivated_at !== null;
  });
});
</script>
<template>
  <main :style="computedStyleMain" id="content" class="py-[59px] px-20 flex-grow overflow-y-auto">
    <div v-show="!initialFetchCompleted" class="min-w-full pt-[184px] flex items-center justify-center">
      <LoadingSpinnerLarge />
    </div>
    <div v-show="initialFetchCompleted" class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
      <!-- Page Header -->
      <div class="flex justify-between items-center gap-x-5">
        <div class="flex justify-end items-center gap-x-2">
          <ProgressButton
            icon="person_add"
            iconSize="text-2xl"
            text="Benutzer:in hinzufügen"
            routerLinkTo="/dashboard/users/add"
          />
        </div>
      </div>
      <!-- End Page Header -->

      <!-- Stats Grid -->
      <!-- TODO i18n -->
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 xl:gap-5">
        <SimpleStatsCard
          title="Benutzer:innen"
          :value="organizationStore.numberActiveUsers"
          :maxValue="organizationStore.numberUserSeats"
        />
        <SimpleStatsCard
          title="Manager:innen"
          :value="organizationStore.numberActiveManagers"
          :maxValue="organizationStore.numberManagerSeats"
        />
        <SimpleStatsCard title="Eingeladen" :value="organizationStore.numberActiveInvitedUsers" />
        <SimpleStatsCard
          title="Deaktiviert"
          :value="organizationStore.numberDeactivatedUsers + organizationStore.numberDeactivatedManagers"
        />
      </div>
      <!-- End Stats Grid -->

      <!-- Users Table Card -->
      <div
        class="p-5 space-y-4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      >
        <!-- Nav Tab -->
        <nav
          class="relative flex space-x-1 after:absolute after:bottom-0 after:inset-x-0 after:border-b-2 after:border-gray-200 dark:after:border-neutral-700"
          aria-label="Tabs"
          role="tablist"
          aria-orientation="horizontal"
        >
          <button
            type="button"
            class="hs-tab-active:after:bg-gray-800 hs-tab-active:text-gray-800 px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active"
            id="hs-pro-tabs-dut-item-all"
            aria-selected="true"
            data-hs-tab="#hs-pro-tabs-dut-all"
            aria-controls="hs-pro-tabs-dut-all"
            role="tab"
          >
            Alle
          </button>
          <button
            type="button"
            class="hs-tab-active:after:bg-gray-800 hs-tab-active:text-gray-800 px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            id="hs-pro-tabs-dut-item-users"
            aria-selected="false"
            data-hs-tab="#hs-pro-tabs-dut-users"
            aria-controls="hs-pro-tabs-dut-users"
            role="tab"
          >
            Benutzer:innen
          </button>
          <button
            type="button"
            class="hs-tab-active:after:bg-gray-800 hs-tab-active:text-gray-800 px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            id="hs-pro-tabs-dut-item-managers"
            aria-selected="false"
            data-hs-tab="#hs-pro-tabs-dut-managers"
            aria-controls="hs-pro-tabs-dut-managers"
            role="tab"
          >
            Manager:innen
          </button>
          <button
            type="button"
            class="hs-tab-active:after:bg-gray-800 hs-tab-active:text-gray-800 px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            id="hs-pro-tabs-dut-item-invited"
            aria-selected="false"
            data-hs-tab="#hs-pro-tabs-dut-invited"
            aria-controls="hs-pro-tabs-dut-invited"
            role="tab"
          >
            Eingeladen
          </button>
          <button
            type="button"
            class="hs-tab-active:after:bg-gray-800 hs-tab-active:text-gray-800 px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            id="hs-pro-tabs-dut-item-deactivated"
            aria-selected="false"
            data-hs-tab="#hs-pro-tabs-dut-deactivated"
            aria-controls="hs-pro-tabs-dut-deactivated"
            role="tab"
          >
            Deaktiviert
          </button>
        </nav>
        <!-- End Nav Tab -->

        <!-- Filter Group -->
        <div class="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5">
          <div>
            <!-- Search Input -->
            <div class="relative">
              <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                <svg
                  class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="11" cy="11" r="8" />
                  <path d="m21 21-4.3-4.3" />
                </svg>
              </div>
              <input
                v-model="memberSearchTerm"
                type="text"
                class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                placeholder="Benutzer durchsuchen"
              />
            </div>
            <!-- End Search Input -->
          </div>
          <!-- End Col -->

          <div class="flex justify-end items-center gap-x-2"></div>
          <!-- End Col -->
        </div>
        <!-- End Filter Group -->

        <div>
          <!-- TODO: fix this: tab does not properly initialize w/o manually setting block/ hidden in beginnen. Otherwise, all tabs are shown before first click. Why that? Init seems to be incomplete -->
          <!-- Tab Content -->
          <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all" class="block">
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table
                  class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700"
                  v-show="organizationStore.members.length > 0"
                >
                  <thead>
                    <ListUsersTableHead />
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in organizationStore.members"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null && member.user.auth_user.confirmed_at != null"
                    />
                  </tbody>
                </table>
                <div v-show="organizationStore.members.length === 0">
                  <p class="text-center text-gray-500 dark:text-neutral-500">Keine Benutzer:innen gefunden</p>
                </div>
                <!-- End Table -->
              </div>
            </div>
            <!-- End Table Section -->
          </div>
          <!-- End Tab Content -->

          <div id="hs-pro-tabs-dut-users" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-users" class="hidden">
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700" v-show="allUsers.length > 0">
                  <thead>
                    <ListUsersTableHead />
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in allUsers"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null && member.user.auth_user.confirmed_at != null"
                    />
                  </tbody>
                </table>
                <div v-show="allUsers.length === 0">
                  <p class="text-center text-gray-500 dark:text-neutral-500">Keine Benutzer:innen gefunden</p>
                </div>
                <!-- End Table -->
              </div>
            </div>
          </div>

          <div
            id="hs-pro-tabs-dut-managers"
            role="tabpanel"
            aria-labelledby="hs-pro-tabs-dut-item-managers"
            class="hidden"
          >
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table
                  class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700"
                  v-show="allManagers.length > 0"
                >
                  <thead>
                    <ListUsersTableHead />
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in allManagers"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null && member.user.auth_user.confirmed_at != null"
                    />
                  </tbody>
                </table>
                <div v-show="allManagers.length === 0">
                  <p class="text-center text-gray-500 dark:text-neutral-500">Keine Benutzer:innen gefunden</p>
                </div>
                <!-- End Table -->
              </div>
            </div>
          </div>

          <div
            id="hs-pro-tabs-dut-invited"
            role="tabpanel"
            aria-labelledby="hs-pro-tabs-dut-item-invited"
            class="hidden"
          >
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table
                  class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700"
                  v-show="allInvited.length > 0"
                >
                  <thead>
                    <ListUsersTableHead />
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in allInvited"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null && member.user.auth_user.confirmed_at != null"
                    />
                  </tbody>
                </table>
                <div v-show="allInvited.length === 0">
                  <p class="text-center text-gray-500 dark:text-neutral-500">Keine Benutzer:innen gefunden</p>
                </div>
                <!-- End Table -->
              </div>
            </div>
          </div>

          <div
            id="hs-pro-tabs-dut-deactivated"
            role="tabpanel"
            aria-labelledby="hs-pro-tabs-dut-item-deactivated"
            class="hidden"
          >
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table
                  class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700"
                  v-show="allDeactivated.length > 0"
                >
                  <thead>
                    <ListUsersTableHead />
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in allDeactivated"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null && member.user.auth_user.confirmed_at != null"
                    />
                  </tbody>
                </table>
                <div v-show="allDeactivated.length === 0">
                  <p class="text-center text-gray-500 dark:text-neutral-500">Keine Benutzer:innen gefunden</p>
                </div>
                <!-- End Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Users Table Card -->
    </div>
  </main>
</template>
