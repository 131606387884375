<script setup>
import ProfileImage from '@/components/ProfileImage.vue';
import { computed, nextTick, onMounted, ref } from 'vue';
import { mapTitle } from '@/helper';
import CourseEditorsTooltip from '@/views/courses/CourseEditorsTooltip.vue';
import { HSStaticMethods, HSTooltip } from 'preline';
import { router } from '@/router';

const emits = defineEmits(['createNewCourse']);

const props = defineProps({
  course: {
    type: Object,
    default: null,
  },
  imgPath: {
    type: String,
    default: null,
  },
});

const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
};

const courseReady = computed(() => {
  return true;
});

const courseEditors = computed(() => {
  if (props.course.course_user_editorships == null) return [];
  // return array of users
  return props.course.course_user_editorships.map((el) => el.user);
});

onMounted(async () => {
  await nextTick();
  // await HSStaticMethods.autoInit();
  // wait 200 ms for HSStaticMethods.autoInit() to finish
  await new Promise((resolve) => setTimeout(resolve, 200));
  await HSTooltip.autoInit();
});

const routeToCourse = async () => {
  if (props.course.id) {
    // i.e. course exists: behaves as a simple router-link in this case
    await router.push('/course/' + props.course.id);
  }
  emits('createNewCourse');
};
</script>

<template>
  <div
    @click.prevent="routeToCourse"
    class="group relative cursor-pointer items-start rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
    :class="{ disabled: !courseReady }"
  >
    <div
      class="flex-shrink-0 relative rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]"
    >
      <img
        v-if="!!props.imgPath"
        class="w-full h-full absolute top-0 start-0 group-hover:scale-105 transition-transform duration-500 ease-in-out object-cover"
        :src="imgPath"
        alt="patient profile image"
      />
      <div v-else-if="!props.imgPath && !course.id" class="flex items-center justify-center">
        <div class="grid grid-cols-1 grid-rows-1 group-hover:scale-105 transition-transform duration-500 ease-in-out">
          <span
            class="pt-20 row-start-1 col-start-1 flex items-center justify-center material-symbols-outlined text-[148pt]"
          >
            school
          </span>
          <div
            class="pb-16 pl-32 row-start-1 col-start-1 flex items-center justify-center"
            style="
              font-variation-settings:
                'FILL' 0,
                'wght' 600,
                'GRAD' 0,
                'opsz' 48;
            "
          >
            <span translate="no" class="material-symbols-outlined notranslate text-[48pt]"> add </span>
          </div>
        </div>
      </div>
    </div>

    <div class="absolute top-0 inset-x-0 z-20">
      <div class="p-4 flex flex-col z-0 h-full sm:p-6 relative">
        <!-- Avatar -->
        <div class="flex items-center">
          <!--          <div class="flex-shrink-0">-->
          <!--            <span v-if="props.course.user?.profile_image">-->
          <!--              <ProfileImage :image="props.course.user.profile_image.small" :initials="''" :showIngameLevel="true"/>-->
          <!--            </span>-->
          <!--          </div>-->
          <div class="ms-2.5 sm:ms-4 text-sm font-semibold gap-x-1 text-white inline-flex">
            <div class="font-light z-10">Course by</div>
            <div class="font-semibold text-white gap-x-1 inline-flex">
              <div class="z-20 hs-tooltip [--placement:bottom] inline-block">
                <button type="button" class="hs-tooltip-toggle pointer-events-auto z-20 justify-center items-center">
                  <span v-if="props.course.user?.display_clear_name" class="z-10">
                    {{ mapTitle(props.course.user.academic_title) }}
                    {{ props.course.user.first_name }}
                    {{ props.course.user.last_name }}
                  </span>
                  <span v-else-if="props.course.user" class="z-10">{{ props.course.user.username }}</span>
                  <span v-else class="z-10">Anonymous User</span>
                  <span v-show="!!props.course.course_user_editorships?.length" class="z-10">
                    <span class="font-light"> and</span>
                    Others
                  </span>
                  <span
                    class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-20"
                    role="tooltip"
                  >
                    <CourseEditorsTooltip :author="props.course.user" :editors="courseEditors" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End Avatar -->
      </div>
    </div>

    <div class="absolute bottom-0 inset-x-0 z-10">
      <div class="flex flex-col h-full p-4 sm:p-6">
        <h3 class="text-lg sm:text-3xl font-semibold text-white">
          {{ props.course.title }}
        </h3>
        <p class="mt-2 text-white/[.8] text-sm font-light">
          {{ props.course.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
