<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { HSDropdown, HSStaticMethods } from 'preline';
import PersonEdit from '@/components/new_case/sections/PersonEdit.vue';
import { mapTitle, updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, Field, useField, useForm } from 'vee-validate';

import * as yup from 'yup';

const { value: localPersonalConcern, errors: personalConcernErrors } = useField('personal_concern', 'max:500|required');

const localPersonTaskInvolvement = ref('IN_THE_ROOM');

const person = defineModel({
  type: Object,
  required: false,
  default: () => ({
    create_id: null,
    profile_image: null,
    details: {
      freehand_description: '',
      age: null,
    },
  }),
});

const props = defineProps({
  isSelected: {
    type: Boolean,
    default: false,
  },
  allowEdit: {
    type: Boolean,
    default: true,
  },
  allowRemove: {
    type: Boolean,
    default: true,
  },
  allowSelect: {
    type: Boolean,
    default: false,
  },
  requireCompleteness: {
    type: Boolean,
    default: false,
  },
  startingFromCompleteCase: {
    type: Boolean,
    default: false,
  },
  alwaysShowDetails: {
    type: Boolean,
    default: false,
  },
  alwaysShowPatientDetails: {
    type: Boolean,
    default: false,
  },
  validateEdits: {
    type: Boolean,
    default: true,
  },
  validatePersonalConcern: {
    type: Boolean,
    default: false,
  },
  initialPersonTaskConcern: {
    type: Object,
    default: null,
  },
  initialPersonTaskInvolvement: {
    type: String,
    default: 'IN_THE_ROOM',
  },
});

const isEditing = ref(false);
const editTouched = ref(false);
const selectTouched = ref(false);
const generateFinished = ref(false);
const emit = defineEmits([
  'addPerson',
  'removePerson',
  'toggleSelect',
  'editedPersonalConcern',
  'editedPersonTaskInvolvement',
  'click',
  'centerMe',
  'scrollToBottom',
  'isValid',
]);

const personEditIsValid = ref(props.startingFromCompleteCase); // false if new case, unless role is selected

const allValid = computed(() => {
  let valid =
    (personEditIsValid.value || !props.validateEdits) &&
    ((personalConcernErrors.value.length === 0 && !!localPersonalConcern.value) || !props.validatePersonalConcern);
  console.log('PersonCard: isValid', valid);
  console.log('startingFromCompleteCase', props.startingFromCompleteCase);
  console.log('personEditIsValid', personEditIsValid.value);
  console.log('props.validateEdits', props.validateEdits);
  console.log('personalConcernErrors', personalConcernErrors.value);
  console.log('localPersonalConcern', localPersonalConcern.value);
  console.log('length errors == 0', personalConcernErrors.value.length === 0);
  console.log('props.validatePersonalConcern', props.validatePersonalConcern);
  console.log('length errors', personalConcernErrors.value.length);
  console.log('Part 1', personEditIsValid.value || !props.validateEdits);
  console.log('Part 2a', personalConcernErrors.value.length === 0 && !!localPersonalConcern.value);
  console.log('Part 2b', !props.validatePersonalConcern);
  console.log(
    'Part 2',
    (personalConcernErrors.value.length === 0 && !!localPersonalConcern.value) || !props.validatePersonalConcern,
  );
  // console.log('first', ( personEditIsValid.value || !props.validateEdits));
  // console.log('second', personalConcernErrors.value.length === 0 || !props.validatePersonalConcern));

  return valid;
});

watch(
  () => allValid.value,
  (value) => {
    console.log('PersonCard: isValid (watcher) emitting', value);
    emit('isValid', value);
  },
);

const someDiseaseFilled = computed(() => {
  return (
    (person.value?.details.main_medical_conditions?.length > 0 &&
      !!person.value?.details.main_medical_conditions[0].name) ||
    person.value?.details.known_medical_conditions?.length > 0 ||
    person.value?.details.unknown_medical_conditions?.length > 0
  );
});

// compose male/female string based on patient sex
const personSummary = computed(() => {
  let sexSymbol = '';
  let personSummary = '';
  if (!person.value.details) {
    return '';
  }
  switch (person.value.details.sex) {
    case 'FEMALE':
      sexSymbol = '♀';
      break;
    case 'MALE':
      sexSymbol = '♂';
      break;
    case 'DIVERSE':
      sexSymbol = '⚧';
      break;
  }

  const sepStringAdd = (str1, str2) => {
    str1 = !!str1 ? str1.trim() : null;
    str2 = !!str2 ? str2.trim() : null;
    return !!str1 && !!str2 ? str1 + ' | ' + str2 : str1 || str2;
  };

  console.log('role', person.value.details.role?.display_name);

  personSummary = sepStringAdd(personSummary, `${sexSymbol}`);
  personSummary = sepStringAdd(personSummary, !!person.value.details.age ? `${person.value.details.age} Jahre` : '');
  personSummary = sepStringAdd(
    personSummary,
    !!person.value.details.role ? person.value.details.role.display_name : '',
  );

  return personSummary;
});

const dropdownId = computed(() => {
  return 'hs-dropdown-person-create-trigger-' + (person.value.create_id ? person.value.create_id : 'create');
});

async function emitRemovePerson(id) {
  emit('removePerson', id);
}

function editPersonDetails() {
  if (person.value.create_id === null) {
    return;
  }
  if (isEditing.value) {
    // i.e. closed editing for the first time
    editTouched.value = true;
  }
  isEditing.value = !isEditing.value;
  person.value.placeholderName = 'Person #' + (person.value.create_id + 1);
}

function emitToggleSelect(id) {
  selectTouched.value = false;
  emit('toggleSelect', id, localPersonalConcern.value, localPersonTaskInvolvement.value);
}

function emitAddPerson() {
  emit('addPerson');
}

function emitEditedPersonalConcern() {
  // console.log('editedPersonalConcern');
  // console.log('errors', personalConcernErrors.value);
  emit('editedPersonalConcern', localPersonalConcern.value);
}

function emitEditedPersonTaskInvolvement() {
  // console.log('editedPersonTaskInvolvement');
  emit('editedPersonTaskInvolvement', localPersonTaskInvolvement.value);
}

const isDummy = computed(() => {
  return person.value.create_id === null;
});

function emitCenterMeIfOpened(event) {
  if (!isEditing.value) {
    emit('centerMe', event);
  }
}

function emitCenterMeIfSelected(event) {
  if (!props.isSelected) {
    console.log('centerMe');
    emit('centerMe', event);
  }
}

onMounted(async () => {
  console.log('PersonCard mounted with startingFromCompleteCase', props.startingFromCompleteCase);
  await nextTick(() => {
    // HSStaticMethods.autoInit();
    const el = HSDropdown.getInstance('#' + dropdownId.value);
  });
  if (!!props.initialPersonTaskInvolvement) {
    localPersonTaskInvolvement.value = props.initialPersonTaskInvolvement.type;
  }
  if (!!props.initialPersonTaskConcern) {
    localPersonalConcern.value = props.initialPersonTaskConcern.details.concern;
  }
  emitEditedPersonTaskInvolvement();
});

onBeforeUnmount(() => {
  console.log('PersonCard unmounted');
});

const isPatient = computed(() => {
  let check = !!person.value.details?.role && person.value.details.role.value === 'PATIENT';
  console.log('isPatient', check);
  console.log('props.alwaysShowPatientDetails', props.alwaysShowPatientDetails);
  return check;
});

const showingDetails = toRef(props.alwaysShowDetails);

function personEditValidityChanged(value) {
  console.log('personEditValidityChanged to ', value);
  personEditIsValid.value = value;
}

defineExpose({
  allValid,
});
</script>

<template>
  <!-- highlight errors only if touched -->
  <div
    class="group/person cursor-pointer transition-all min-h-fit h-fit max-h-fit duration-75 flex flex-col border rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-visible"
    :class="{
      'min-w-[200px] w-[200px]': !isEditing,
      'min-w-[482px] w-[482px] hover:bg-white': isEditing && !isPatient,
      'min-w-[484px] w-[484px] hover:bg-white': isEditing && isPatient && !generateFinished,
      'min-w-[884px] w-[884px] hover:bg-white': isEditing && isPatient && generateFinished,
      'bg-white border-gray-200': !isDummy,
      'hover:bg-gray-50': !isDummy && !isEditing,
      'bg-blue-50 hover:bg-blue-100 border-blue-200': isDummy,
      'border-red-600':
        !allValid && !isDummy && ((validateEdits && editTouched) || (validatePersonalConcern && selectTouched)),
      'border-blue-600':
        !allValid && !isDummy && ((validateEdits && !editTouched) || (validatePersonalConcern && !selectTouched)),
    }"
    @click.prevent="
      async (event) => {
        event.stopPropagation();
        isDummy
          ? emitAddPerson()
          : allowSelect
          ? emit('click', event) || emitCenterMeIfSelected(event) || emitToggleSelect(person.create_id)
          : emitCenterMeIfOpened(event) || editPersonDetails();
      }
    "
  >
    <!-- Header -->
    <div class="p-3 md:pt-5 md:px-5 grid grid-cols-3 gap-x-2 overflow-visible">
      <div>
        <!-- TODO: room for a tag / badge would be here -->
        <!--      <span-->
        <!--          class="hidden md:inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-gray-100 text-gray-800 rounded-full dark:bg-neutral-700 dark:text-neutral-200">-->
        <!--        <span class="h-1.5 w-1.5 flex-shrink-0 inline-block bg-gray-800 rounded-full dark:bg-neutral-200"></span>-->
        <!--        Online-->
        <!--      </span>-->
      </div>

      <div class="col-start-2 flex-shrink-0 relative h-16 w-16 md:w-[62px] md:h-[62px] mx-auto overflow-visible">
        <img
          v-if="!isDummy && !!person?.profile_image"
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full"
          :class="{ 'group-hover/person:scale-105': allowEdit || allowSelect }"
          :src="person.profile_image.image_urls.small"
        />
        <div
          v-else-if="!isDummy && !person?.profile_image"
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full flex items-center justify-center text-sm font-medium text-white bg-gray-600"
          :class="{ 'group-hover/person:scale-105': allowEdit || allowSelect }"
        >
          <!-- dummy icon if no image (will be isSelected later) -->
          <span translate="no" class="material-symbols-outlined notranslate text-5xl"> person </span>
        </div>
        <div
          v-else
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full flex items-center justify-center text-sm font-medium text-white bg-blue-600 group-hover/person:bg-blue-700 group-hover/person:scale-105"
        >
          <span translate="no" class="material-symbols-outlined notranslate text-5xl"> person_add </span>
        </div>
      </div>

      <div class="ms-auto overflow-visible" v-if="!isDummy">
        <div
          @click.prevent="
            async (event) => {
              event.stopPropagation();
              await emitToggleSelect(person.create_id);
            }
          "
          v-if="allowSelect"
          class="h-7 w-7 rounded-lg border border-gray-200 flex items-center justify-center"
        >
          <span
            v-show="isSelected"
            translate="no"
            class="material-symbols-outlined notranslate text-blue-600 group-hover/person:text-blue-700"
            :style="{ 'font-weight': '400' }"
          >
            check
          </span>
        </div>

        <div class="top-0 end-0 flex-col sticky flex justify-center overflow-visible -mt-3 -mr-2">
          <!-- Delete button -->
          <button
            v-if="allowRemove"
            @click.prevent="emitRemovePerson(person.create_id)"
            class="z-10 flex items-center justify-items-center w-full font-semibold text-start text-gray-500 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
          >
            <span
              translate="no"
              class="material-symbols-outlined notranslate text-xl text-red-600 hover:text-red-800 block"
            >
              delete
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div class="p-3 pt-0 md:px-5 md:pb-5 text-center overflow-visible">
      <h3 class="md:text-lg font-medium text-gray-800 dark:text-neutral-200">
        {{ person.details ? mapTitle(person.details.academic_title) : '' }}
        <span v-if="person.create_id === null" class="text-blue-600 group-hover/person:text-blue-700"
          >Person hinzufügen</span
        >
        <span v-else-if="!person?.details?.first_name && !person?.details?.last_name">
          {{ person.placeholderName || 'Zum Bearbeiten klicken' }}
        </span>
        <span v-else>
          {{ person.details ? person.details.first_name : '' }}
          {{ person.details ? person.details.last_name : '' }}
        </span>
      </h3>

      <p class="text-sm text-gray-500 dark:text-neutral-500 overflow-visible">
        {{ personSummary }}
      </p>
      <div v-if="!!person?.role" class="inline-flex justify-center items-center gap-x-2">
        <svg
          class="flex-shrink-0 h-4 w-4 text-gray-500 dark:text-neutral-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
          <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
          <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
          <path d="M10 6h4" />
          <path d="M10 10h4" />
          <path d="M10 14h4" />
          <path d="M10 18h4" />
        </svg>
        <p class="text-sm text-gray-500 dark:text-neutral-500">Arzt</p>
      </div>
    </div>
    <!-- End Body -->

    <!-- Footer 1: personal concern -->
    <div
      @click="
        (event) => {
          event.stopPropagation();
          emit('click');
        }
      "
      class="transition-height overflow-hidden ease-in-out duration-75 flex flex-grow flex-col text-start border-gray-200 dark:border-neutral-700"
      :class="{ 'max-h-0': !isSelected, 'border-t max-h-[500px] px-1 py-1': isSelected }"
    >
      <div class="flex text-xs text-gray-500 px-0.5 pb-1 pt-2">Beteiligung *</div>
      <div class="flex-grow">
        <select
          v-model="localPersonTaskInvolvement"
          @click="
            (event) => {
              event.stopPropagation();
              emit('click');
            }
          "
          class="py-2 px-0.5 block w-full border-gray-200 text-gray-500 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
          @change="emitEditedPersonTaskInvolvement"
        >
          <option selected="" value="IN_THE_ROOM">Vor Ort im selben Raum</option>
          <option value="ON_THE_PHONE">Am Telefon</option>
        </select>
      </div>

      <div class="flex text-xs text-gray-500 px-0.5 pb-1 pt-2">Anliegen *</div>
      <div class="flex-grow">
        <!-- the click is internally stopped to avoid add/edit/select, then re-reraised to signal parent that clicked somewhere inside PersonCard -->
        <textarea
          @click="
            (event) => {
              event.stopPropagation();
              emit('click');
            }
          "
          @keyup.space.prevent="(event) => event.stopPropagation()"
          v-model="localPersonalConcern"
          :placeholder="
            'Was ist das Anliegen ' +
            (person?.details?.first_name && person.details?.last_name
              ? 'von ' + person.details.first_name + ' ' + person.details.last_name
              : 'der Person') +
            ' in dieser Situation?'
          "
          class="resize-none text-gray-800 text-sm px-0.5 py-0.5 my-0.5 mr-0.5 cursor-text border border-gray-200 rounded-lg bg-white"
          @input="
            async (event) => {
              await updateMinTextareaHeight(event.target);
              emitEditedPersonalConcern();
            }
          "
          rows="3"
          :style="{
            width: '100%',
            'box-sizing': 'border-box',
            height: '100%',
          }"
          :class="{
            'focus:border-red-500 focus:ring-red-500': personalConcernErrors.length > 0,
          }"
        />
        <p class="text-xs text-red-600 -mt-1">{{ personalConcernErrors[0] }}</p>
        <p
          v-if="localPersonalConcern == null"
          class="text-xs -mt-1"
          :class="{ 'text-red-600': selectTouched, 'text-blue-600': !selectTouched }"
        >
          Dies ist ein Pflichtfeld
        </p>
      </div>
    </div>
    <!--     End Footer 1 -->

    <!-- Footer 2: edit person/ patient details -->
    <div
      @click="
        (event) => {
          event.stopPropagation();
          emit('click');
        }
      "
      class="transition-height overflow-hidden ease-in-out duration-75 flex flex-grow flex-col text-start border-gray-200 dark:border-neutral-700"
      :class="{
        'max-h-0': !isEditing,
        'border-t max-h-[1000px] px-1 py-1': isEditing && !isPatient,
        'border-t max-h-[2000px] px-1 py-1': isEditing && isPatient,
      }"
    >
      <!-- This should ideally have v-model="person.details", however, cf.: https://github.com/eta-io/casuu/issues/69. TODO adapt once fixed in Vue -->
      <PersonEdit
        :model-value="person.details"
        @update:model-value="(value) => (person.details = value)"
        v-if="!!person && person.details !== undefined"
        :requireFullValidity="props.requireCompleteness"
        :startingFromCompleteCase="props.startingFromCompleteCase"
        :alwaysShowDetails="props.alwaysShowDetails"
        :alwaysShowPatientDetails="props.alwaysShowPatientDetails && isPatient"
        :editTouched="editTouched"
        :selectTouched="selectTouched"
        @scrollToBottom="emit('scrollToBottom')"
        @detailsToggled="(value) => (showingDetails = value)"
        @generateFinished="(value) => (generateFinished = true)"
        @isValid="personEditValidityChanged"
        @setProfileImage="person.profile_image = $event"
      />
    </div>
    <!--     End Footer 2 -->
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
