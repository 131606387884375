<script setup lang="ts">
import { onMounted, PropType } from 'vue';
import { updateMinTextareaHeight } from '@/helper';

const location = defineModel('location', {
  type: String,
  required: true,
});
const date = defineModel('date', {
  type: String,
  required: true,
});
const time = defineModel('time', {
  type: String,
  required: true,
});

const { omitLabels } = defineProps({
  omitLabels: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  console.log('mounted SettingInput');
  const numToDate = (num: number) => String(Math.floor(num)).padStart(2, '0');
  console.log('location', location.value);

  location.value = location.value || 'Krankenhaus';
  date.value =
    date.value ||
    `${new Date().getFullYear()}-${numToDate(new Date().getMonth() + 1)}-${numToDate(new Date().getDate())}`;
  time.value = time.value || '10:30';
});
</script>

<template>
  <div>
    <div class="grid grid-cols-12 gap-2">
      <div class="col-span-12">
        <div v-if="!omitLabels">
          <label class="block text-sm mb-2 text-gray-800 dark:text-gray-200"> Ort </label>
        </div>
        <div>
          <textarea
            @input="
              (event) => {
                updateMinTextareaHeight(event.target, 1);
              }
            "
            @keyup.space.prevent="(event) => event.stopPropagation()"
            rows="2"
            class="resize-none overflow-hidden px-0 py-0.5 -mb-1 mr-0.5 text-xs sm:text-sm text-gray-800"
            :class="{
              'cursor-text border border-gray-200 rounded-lg bg-white': true,
              'border-transparent bg-transparent': !true,
              'border-red-500 focus:border-red-500 focus:ring-red-500': false,
            }"
            :style="{
              width: '100%',
              'box-sizing': 'border-box',
            }"
            placeholder="Ort"
            v-model="location"
          />
        </div>
      </div>

      <div class="col-span-6">
        <div v-if="!omitLabels">
          <label for="date" class="block text-sm mb-2 text-gray-800 dark:text-gray-200"> Datum </label>
        </div>
        <div>
          <input
            id="date"
            class="resize-none px-0 py-0.5 -mb-1 mr-0.5 text-xs sm:text-sm text-gray-800"
            :class="{
              'cursor-text border border-gray-200 rounded-lg bg-white': true,
              'border-transparent bg-transparent': !true,
              'border-red-500 focus:border-red-500 focus:ring-red-500': false,
            }"
            :style="{
              width: '100%',
              'box-sizing': 'border-box',
            }"
            placeholder=""
            v-model="date"
            type="date"
          />
        </div>
      </div>

      <div class="col-span-6">
        <div v-if="!omitLabels">
          <label for="time" class="block text-sm mb-2 text-gray-800 dark:text-gray-200"> Uhrzeit </label>
        </div>
        <div>
          <input
            id="time"
            class="resize-none px-0 py-0.5 -mb-1 mr-0.5 text-xs sm:text-sm text-gray-800"
            :class="{
              'cursor-text border border-gray-200 rounded-lg bg-white': true,
              'border-transparent bg-transparent': !true,
              'border-red-500 focus:border-red-500 focus:ring-red-500': false,
            }"
            :style="{
              width: '100%',
              'box-sizing': 'border-box',
            }"
            placeholder="12:00"
            v-model="time"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
