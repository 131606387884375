<script setup>
import { ref, computed, nextTick, onMounted, onBeforeUnmount } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useCaseInteractionStore } from '@/stores';
import ChatBubbleSolveFeedback from '@/components/chat_bubbles/ChatBubbleSolveFeedback.vue';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { storeToRefs } from 'pinia';
import DOMPurify from 'dompurify';

const props = defineProps({
  promptMessage: {
    type: String,
    default: 'Was lautet Ihre Verdachtsdiagnose? Wie erklären Sie sich diesen Fall?',
  },
  submitMessage: {
    type: String,
    default: 'Gib mir Feedback',
  },
  closeMessage: {
    type: String,
    default: 'Zurück zum Fall',
  },
  width: {
    type: Number,
    default: 0.3,
  },
  height: {
    type: Number,
    default: 0.7,
  },
});

const caseInteractionStore = useCaseInteractionStore();
const { currentTask } = storeToRefs(caseInteractionStore);

const resolvePromise = ref(undefined);
const rejectPromise = ref(undefined);
const closed = ref(false);
const inputText = ref('');
const feedbackDictString = ref('');
const feedbackMessageParagraphs = ref([]);
const currentObserverInteractionId = ref(undefined);
const isLoading = ref(false);
const feedbackMessage = ref({
  plainContent: '',
  formattedContent: '',
  type: 'SOLVE-FEEDBACK',
  conversation: 'OBSERVER',
});

const modalEl2 = ref(null);

const closeCallback = (evt) => {
  console.debug('closing dialog');
  console.debug('closed thru backdrop/ esc');
  resolvePromise.value(true);
};

onMounted(() => {
  console.debug('ref: ' + modalEl2.value);
  nextTick(() => {
    modalEl2.value.addEventListener('close.hs.overlay', closeCallback);
  });
});

onBeforeUnmount(async () => {
  console.debug('onBeforeUnmount()');
  modalEl2.value.removeEventListener('close.hs.overlay', closeCallback);
  await window.HSOverlay.close(modalEl2.value);
  resolvePromise.value = true;
});

const beforeFirstParagraphReceived = computed(() => feedbackMessageParagraphs.value.length === 0);

const pauseForMetaChat = async (currentObserverInteractionIdParam) => {
  console.debug('Initiating meta chat dialog for observer interaction' + currentObserverInteractionIdParam);
  console.debug('ref: ' + modalEl2.value);
  currentObserverInteractionId.value = currentObserverInteractionIdParam;
  await window.HSOverlay.open(modalEl2.value);
  console.debug('Returning promise');
  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve;
    rejectPromise.value = reject;
  });
};

const handleSubmit = async () => {
  console.debug('Submitting input text: ' + inputText.value);
  if (inputText.value === '') {
    console.log('Empty input, not sending');
    return;
  }
  isLoading.value = true;
  await sendGuess(inputText.value);
  inputText.value = '';
};

const appendToFeedbackMessage = (chunk) => {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    feedbackDictString.value += chunk_['content'];
    feedbackMessageParagraphs.value = parseFeedback();

    const wrappedStrings = feedbackMessageParagraphs.value.map((p) => `<p class="mb-2">${p}</p>`);

    feedbackMessage.value.plainContent = feedbackMessageParagraphs.value.join('');
    feedbackMessage.value.formattedContent = DOMPurify.sanitize(wrappedStrings.join(''));
  } else if (chunk_.type === 'id') {
    isLoading.value = false;
  }
};

const parseFeedback = () => {
  let feedback;
  const lastFeedback = feedbackMessageParagraphs.value;

  if (feedbackDictString.value === '') {
    return [];
  }

  let response = feedbackDictString.value.replace('```json', '').replace('```', '');

  if (isLoading.value) {
    response = response + '"}';
  }

  try {
    feedback = JSON.parse(response);
  } catch (e) {
    if (isLoading.value) {
      return lastFeedback;
    }
    console.error('Error parsing feedback message: ' + e);
    feedback = {
      error: 'Error parsing feedback message',
    };
  }
  if (feedback['solved']) {
    delete feedback['solved'];
  }
  feedback = Object.values(feedback);

  return feedback;
};

const sendGuess = async (guess) => {
  const url = '/observer-interactions/' + currentObserverInteractionId.value;
  console.debug('Sending guess to ' + url);
  console.log(currentTask.value);
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    url,
    {
      type: 'OBSERVER_REACT',
      content: guess,
      event: {
        type: 'SUBMIT_DIAGNOSIS',
      },
      current_task: currentTask.value,
    },
    appendToFeedbackMessage,
  );
};

const close = () => {
  console.debug('stopped');
  resolvePromise.value(true);
};

defineExpose({
  pauseForMetaChat,
  close,
});
</script>

<template>
  <div
    ref="modalEl2"
    id="hs-slide-up-animation-modal2"
    class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all sm:max-w-3xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]"
    >
      <div
        class="max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <!-- upper right corner "X" button -->
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
          <button
            type="button"
            class="pointer-events-auto hs-dropup-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            data-hs-overlay="#hs-slide-up-animation-modal2"
            @click.stop="close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <!-- End of close button top -->

        <!-- Content -->
        <textarea
          v-model="inputText"
          @keyup.enter="handleSubmit"
          class="pointer-events-auto p-4 pb-12 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
          :placeholder="props.promptMessage"
          :disabled="isLoading"
          v-show="!isLoading"
        >
        </textarea>

        <div v-show="isLoading && beforeFirstParagraphReceived">
          <div class="flex justify-center items-center h-20">
            <div
              class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
              role="status"
              aria-label="loading"
            />
          </div>
        </div>

        <ChatBubbleSolveFeedback
          :message="feedbackMessage"
          :showFooter="!isLoading && feedbackDictString.length > 0"
          ref="feedbackCpt"
        />
        <!-- End of content -->

        <!-- Bottom buttons -->
        <div class="pointer-events-auto flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
          <!-- Submit button bottom -->
          <ProgressButton
            :onclick="handleSubmit"
            color="blue"
            :disabled="isLoading"
            type="button"
            :text="props.submitMessage"
          />
          <!-- Close button bottom -->
          <a
            class="py-2 px-3 text-sm min-w-24 min-h-10 inline-flex justify-center items-center gap-2 rounded-lg border border-transparent font-medium bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
            href="#"
            data-hs-overlay="#hs-slide-up-animation-modal2"
            @click.stop="close"
          >
            {{ props.closeMessage }}
          </a>
        </div>
        <!-- End of bottom buttons -->
      </div>
    </div>
  </div>
</template>
