/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Language } from '../models/Language';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LanguagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Available Languages
     * @returns Language Successful Response
     * @throws ApiError
     */
    public getAvailableLanguages(): CancelablePromise<Array<Language>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/languages/',
        });
    }

}
