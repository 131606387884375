/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientInteractionMessage } from '../models/PatientInteractionMessage';
import type { PatientInteractionMessageCreate } from '../models/PatientInteractionMessageCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PatientInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Delete Patient Interaction
   * @param patientInteractionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deletePatientInteraction(patientInteractionId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/patient-interactions/{patient_interaction_id}',
      path: {
        patient_interaction_id: patientInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Patient Interaction Messages
   * This will only return user or patient messages, not system messages.
   *
   * :param session:
   * :param user:
   * :param patient_interaction_id:
   * :return:
   * @param patientInteractionId
   * @returns PatientInteractionMessage Successful Response
   * @throws ApiError
   */
  public listPatientInteractionMessages(
    patientInteractionId: string,
  ): CancelablePromise<Array<PatientInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/patient-interactions/{patient_interaction_id}',
      path: {
        patient_interaction_id: patientInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Patient Interaction Message
   * Create a new patient interaction message for the given patient
   * interaction.
   *
   * :param session:
   * :param user:
   * :param request:
   * :param patient_interaction_id:
   * :param patient_interaction_message:
   * :param attending_id: if attending addresses patient/ writes note
   * :param third_person_id: if third person addresses patient/ writes note
   * :return:
   * @param patientInteractionId
   * @param requestBody
   * @param attendingId
   * @param thirdPersonId
   * @returns any Successful Response
   * @throws ApiError
   */
  public createPatientInteractionMessage(
    patientInteractionId: string,
    requestBody: PatientInteractionMessageCreate,
    attendingId?: string,
    thirdPersonId?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/patient-interactions/{patient_interaction_id}',
      path: {
        patient_interaction_id: patientInteractionId,
      },
      query: {
        attending_id: attendingId,
        third_person_id: thirdPersonId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
