/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { McExamInteractionMessage } from '../models/McExamInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class McExamMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Mc Exam Interaction Message
   * :param session:
   * :param user:
   * :param mc_exam_interaction_message_id:
   * :return:
   * @param mcExamInteractionMessageId
   * @returns McExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public getMcExamInteractionMessage(mcExamInteractionMessageId: string): CancelablePromise<McExamInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/mc-exam-messages/mc-exam-message/{mc_exam_interaction_message_id}',
      path: {
        mc_exam_interaction_message_id: mcExamInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Mc Exam Interaction Message
   * @param mcExamInteractionMessageId
   * @param requestBody
   * @returns McExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForMcExamInteractionMessage(
    mcExamInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<McExamInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/mc-exam-messages/{mc_exam_interaction_message_id}',
      path: {
        mc_exam_interaction_message_id: mcExamInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Mc Exam Message Translation
   * :param session:
   * :param user:
   * :param mc_exam_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param mcExamInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestMcExamMessageTranslation(
    mcExamInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/mc-exam-messages/{mc_exam_interaction_message_id}/translation',
      path: {
        mc_exam_interaction_message_id: mcExamInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
