/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { McExaminer } from '../models/McExaminer';
import type { McExaminerCreate } from '../models/McExaminerCreate';
import type { OralExaminer } from '../models/OralExaminer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class McExaminersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Mc Examiner
   * @returns McExaminer Successful Response
   * @throws ApiError
   */
  public listMcExaminer(): CancelablePromise<Array<McExaminer>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/mc-examiners/',
    });
  }

  /**
   * Get Mc Examiner
   * @param examinerId
   * @returns OralExaminer Successful Response
   * @throws ApiError
   */
  public getMcExaminer(examinerId: string): CancelablePromise<OralExaminer> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/mc-examiners/{examiner_id}',
      path: {
        examiner_id: examinerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Mc Examiner
   * @param requestBody
   * @returns McExaminer Successful Response
   * @throws ApiError
   */
  public createMcExaminer(requestBody: McExaminerCreate): CancelablePromise<McExaminer> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/mc-examiners/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
