/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StarredCaseDetails } from '../models/StarredCaseDetails';
import type { StarredCaseListDetails } from '../models/StarredCaseListDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StarringsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Starred Case
   * @param starredCaseId
   * @returns StarredCaseDetails Successful Response
   * @throws ApiError
   */
  public getStarredCase(starredCaseId: string): CancelablePromise<StarredCaseDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/starrings/cases/{starred_case_id}',
      path: {
        starred_case_id: starredCaseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Starred Case
   * @param starredCaseId
   * @returns StarredCaseDetails Successful Response
   * @throws ApiError
   */
  public deleteStarredCase(starredCaseId: string): CancelablePromise<StarredCaseDetails> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/starrings/cases/{starred_case_id}',
      path: {
        starred_case_id: starredCaseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Starred Case List
   * @param starredCaseListId
   * @returns StarredCaseListDetails Successful Response
   * @throws ApiError
   */
  public getStarredCaseList(starredCaseListId: string): CancelablePromise<StarredCaseListDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/starrings/case-lists/{starred_case_list_id}',
      path: {
        starred_case_list_id: starredCaseListId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Starred Case List
   * @param starredCaseListId
   * @returns StarredCaseListDetails Successful Response
   * @throws ApiError
   */
  public deleteStarredCaseList(starredCaseListId: string): CancelablePromise<StarredCaseListDetails> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/starrings/case-lists/{starred_case_list_id}',
      path: {
        starred_case_list_id: starredCaseListId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
