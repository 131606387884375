<script setup>
import ProfileImage from '@/components/ProfileImage.vue';
import { computed, nextTick, onMounted } from 'vue';
import { mapTitle } from '@/helper';
import { HSDropdown, HSStaticMethods } from 'preline';
import Badge from '@/components/Badge.vue';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps({
  case: {
    type: Object,
    default: null,
  },
  sectionId: {
    type: String,
    default: null,
  },
  chapterId: {
    type: String,
    default: null,
  },
  asPartOfCaseList: {
    type: String,
    default: null,
  },
  openCaseSelectionListIfCaseIsNull: {
    type: Boolean,
    default: false,
  },
  allowRouteToCase: {
    type: Boolean,
    default: true,
  },
  allowRemoveFromCaseList: {
    type: Boolean,
    default: true,
  },
  allowRemoveCaseFromSection: {
    type: Boolean,
    default: false,
  },
  allowCopyCase: {
    type: Boolean,
    default: true,
  },
  allowEditCase: {
    type: Boolean,
    default: true,
  },
  allowViewCase: {
    type: Boolean,
    default: true,
  },
  displayCreator: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: 'rounded-xl',
  },
});

const emit = defineEmits(['removeCaseFromList', 'selectCases', 'caseStarted']);
// const badges = ['Task task task task 1', 'Task task task 2', 'Task 3'];
const badges = computed(() => {
  let taskTitles = [];
  if (props.case?.student_details?.role) {
    taskTitles.push({
      short:
        props.case.student_details.role.length > 12
          ? props.case.student_details.role.substring(0, 12) + '...'
          : props.case.student_details.role,
      long: props.case.student_details.role,
    });
  }
  if (!props.case?.tasks) {
    return taskTitles;
  }
  props.case.tasks.forEach((task) => {
    taskTitles.push({
      short: task.title.length > 12 ? task.title.substring(0, 12) + '...' : task.title,
      long: task.title,
    });
  });
  return taskTitles;
});

const titlePerson = computed(() => {
  if (!!props.case.patient) {
    return props.case.patient;
  }
  if (!props.case.third_persons) {
    console.error('CaseHighlightCard.vue: case ' + props.case.id + 'has no patient and third_persons');
    // error card will be displayed instead of throwing error which is breaking here
    return;
  }
  return props.case.third_persons[0];
});

// compose male/female string based on patient sex
let sexSymbol = '';
switch (titlePerson.value?.details.sex) {
  case 'FEMALE':
    sexSymbol = '♀';
    break;
  case 'MALE':
    sexSymbol = '♂';
    break;
  case 'DIVERSE':
    sexSymbol = '⚧';
    break;
}
const caseSetting =
  `${sexSymbol}` +
  (!!titlePerson.value?.details.age ? ` | ${titlePerson.value.details.age} Jahre` : '') +
  (!!props.case?.tasks
    ? !!props.case.tasks[0]?.details?.location
      ? ` | ${props.case.tasks[0].details.location}`
      : ''
    : '');
// console.log(props.case);

const caseReady = computed(() => {
  return !!props.case.is_ready;
});

const dropdownId = computed(() => {
  return 'hs-dropdown-custom-icon-trigger-' + props.case.id;
});

function emitRemoveCase(caseId) {
  emit('removeCaseFromList', caseId);
}

function signalCaseStartedToParent() {
  console.log('signal case started');
  emit('caseStarted', props.case.id);
}

async function selectOrCreateNewCase(event) {
  // open case selection list if no case and prop set to select cases; else let the click propagate

  if (props.case.id) {
    return;
  }

  if (props.openCaseSelectionListIfCaseIsNull) {
    event.preventDefault();
    emit('selectCases');
  }

  // await router.push(
  //     {
  //         name: 'new-or-edit-case',
  //         query: {
  //           isViewOnly: false,
  //           isCopy: false,
  //           isEdit: false,
  //           fromCaseId: props.case.id,
  //           inCaseListId: props.asPartOfCaseList,
  //         }
  //       }
  // );
}

onMounted(async () => {
  if (!props.case.id) {
    return; // no need to init dropdown if no case
  }
  await nextTick(async () => {
    await new Promise((resolve) => setTimeout(resolve, 200)); // wait for App.vue's HSStaticMethods.autoInit() to finish
    HSDropdown.autoInit();
  });
  // DO NOT DELETE THIS COMMENT:
  // We use HSDropdown.autoInit() to initialize the dropdowns in the case highlight card.
  // We use it instead of HSStaticMethods.autoInit(), as this leads to the following error I do not understand (seems
  // to break some mounting order stuff or so):
  /*  [Vue warn]: Error in mounted hook: "TypeError: Cannot read properties of null (reading 'cloneNode')"

  found in

  ---> <CaseHighlightCard> at /Users/rschmitz/Privat/Projekte/smart-med/frontend/src/components/CaseHighlightCard.vue
         <EditCourseSection> at /Users/rschmitz/Privat/Projekte/smart-med/frontend/src/views/courses/EditCourseSection.vue... (1 recursive calls)
           <RouterView>
             <LoggedInRoot> at /Users/rschmitz/Privat/Projekte/smart-med/frontend/src/views/root/LoggedInRoot.vue
               <RouterView>
                 <Root>
   */
  // End of fuckerino comment.
});
</script>

<template>
  <!-- if case has no id, so does not exist => route to new case -->
  <!-- else: route to case detail view (to start it) -->
  <div
    v-if="!props.case.patient && !(props.case.third_persons?.length > 0)"
    class="items-center justify-center text-red-600 h-[350px] flex py-3 bg-gray-100 gap-x-2"
    :class="rounded"
  >
    <span translate="no" class="material-symbols-outlined notranslate">error</span>Error loading case
    <Tooltip :message="`Try refreshing page. Please submit error report if problem persists.`" />
  </div>
  <router-link
    v-else
    :to="
      props.case.id
        ? {
            path: '/cases/' + props.case.id,
            query: !!props.sectionId
              ? {
                  sectionId: props.sectionId,
                  chapterId: props.chapterId,
                }
              : {},
          }
        : {
            name: 'new-or-edit-case',
            query: {
              isViewOnly: false,
              isCopy: false,
              isEdit: false,
              fromCaseId: props.case.id,
              inCaseListId: asPartOfCaseList,
            },
          }
    "
    class="group relative rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
    :class="{ disabled: !caseReady || !allowRouteToCase }"
  >
    <div
      @click="signalCaseStartedToParent"
      v-if="!!titlePerson?.profile_image"
      class="flex-shrink-0 relative w-full overflow-hidden h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]"
      :class="rounded"
    >
      <img
        class="w-full h-full absolute top-0 start-0 transition-transform duration-500 ease-in-out object-cover"
        :class="{
          'group-hover:scale-105': caseReady,
          'opacity-50': !caseReady,
        }"
        :src="titlePerson.profile_image.image_urls.large"
        alt="patient profile image"
      />
    </div>
    <div
      v-else
      class="flex-shrink-0 bg-gray-400 items-center relative w-full rounded-xl overflow-hidden h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:z-[1]"
      @click="selectOrCreateNewCase"
    >
      <!-- dummy icon if no patient image (e.g. for new case) -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="bi bi-person fill-white transition-transform duration-500 ease-in-out group-hover:scale-105"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
        />
      </svg>
    </div>

    <div class="absolute top-0 inset-x-0">
      <div class="px-2 sm:px-4 pt-1 sm:pt-1 flex flex-col h-full">
        <!-- Avatar -->
        <div class="flex items-start justify-between">
          <div>
            <div class="flex-shrink-0" :class="{ 'opacity-50': !caseReady }">
              <span v-if="props.case.user?.profile_image">
                <ProfileImage :image="props.case.user.profile_image.small" :initials="null" :showIngameLevel="true" />
              </span>
            </div>
            <div :class="{ 'opacity-50': !caseReady }">
              <p class="flex-wrap">
                <span
                  v-for="badge in badges"
                  class="flex-nowrap group-hover:flex-wrap inline-flex overflow-hidden items-center gap-x-1.5 mx-0.5 my-0.5 py-0.5 px-1.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-800/30 dark:text-yellow-500"
                >
                  <span class="hidden group-hover:block">
                    <Badge :text="badge.long" color="yellow" />
                  </span>
                  <span class="block group-hover:hidden">
                    <Badge :text="badge.short" color="yellow" />
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div class="flex items-start">
            <!-- @click.prevent is used to avoid the click being propagated to the parent router -->
            <div
              v-if="!!props.case.id"
              class="hs-dropdown z-[20] relative inline-block [--placement:bottom-right] pointer-events-auto"
              @click.prevent="console.log('clicked')"
            >
              <button
                :id="dropdownId"
                type="button"
                class="hs-dropdown-toggle -mr-2.5 flex justify-center items-center h-8 w-8 text-sm font-semibold rounded-md bg-gray-200/30 hover:bg-gray-200/80 text-gray-800 hover:text-gray-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
              >
                <span translate="no" class="material-symbols-outlined notranslate"> more_vert </span>
              </button>

              <div
                class="hs-dropdown-menu z-[90] transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-gray-700"
                :aria-labelledby="dropdownId"
              >
                <div v-if="asPartOfCaseList === null || allowViewCase">
                  <router-link
                    :to="{
                      name: 'new-or-edit-case',
                      query: {
                        isViewOnly: true,
                        isCopy: false,
                        isEdit: false,
                        fromCaseId: props.case.id,
                        inCaseListId: asPartOfCaseList,
                      },
                    }"
                  >
                    <div
                      class="flex z-[90] items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                    >
                      Betrachten
                    </div>
                  </router-link>
                </div>
                <div v-if="asPartOfCaseList !== null && allowRemoveFromCaseList">
                  <div
                    class="flex z-[90] text-red-500 items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-red-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                    @click.prevent="emitRemoveCase(props.case.id)"
                  >
                    Aus Liste entfernen
                  </div>
                </div>
                <div v-if="allowRemoveCaseFromSection">
                  <div
                    class="flex z-[90] text-red-500 items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-red-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                    @click.prevent="emit('removeItem')"
                  >
                    Aus diesem Lehrbuchabschnitt entfernen
                  </div>
                </div>
                <router-link
                  v-if="allowEditCase"
                  :to="{
                    name: 'new-or-edit-case',
                    query: {
                      isViewOnly: false,
                      isCopy: false,
                      isEdit: true,
                      fromCaseId: props.case.id,
                      inCaseListId: asPartOfCaseList,
                    },
                  }"
                >
                  <div
                    class="flex z-[90] items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                  >
                    Bearbeiten
                  </div>
                </router-link>
                <router-link
                  v-if="allowCopyCase"
                  :to="{
                    name: 'new-or-edit-case',
                    query: {
                      isViewOnly: false,
                      isCopy: true,
                      isEdit: false,
                      fromCaseId: props.case.id,
                      inCaseListId: asPartOfCaseList,
                    },
                  }"
                >
                  <div
                    class="flex z-[90] items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                  >
                    Kopieren
                  </div>
                </router-link>
              </div>

              <!-- Dropdown menu content -->
            </div>
          </div>
          <!-- End Avatar -->
        </div>
      </div>
    </div>

    <div class="absolute bottom-0 inset-x-0 z-10" :class="{ 'opacity-50': !caseReady }">
      <div class="flex flex-col h-full p-2 sm:p-4 pt-1 sm:pt-1">
        <div class="flex-row">
          <!--          <span-->
          <!--            v-show="!!props.case.details.heading"-->
          <!--            class="inline-flex items-center gap-x-1.5 mx-0.5 py-0.5 mr-2 px-1.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800/30 dark:text-teal-500"-->
          <!--          >-->
          <!--            {{ props.case.details.heading }}-->
          <!--          </span>-->
          <p class="mb-2 text-white/[.8]">
            {{ props.case.details.heading }}
          </p>
          <span v-if="displayCreator" class="inline-flex items-center py-0.5">
            <h4 v-if="props.case.user?.display_clear_name" class="font-medium text-xs text-white">
              by {{ mapTitle(props.case.user.academic_title) }} {{ props.case.user.first_name }}
              {{ props.case.user.last_name }}
            </h4>
            <h4 v-else-if="props.case.user" class="font-medium text-xs text-white">
              by {{ props.case.user.username }}
            </h4>
            <h4 v-else class="font-medium text-xs text-white">by anonymous user</h4>
            <!--              <p class="text-xs text-white/[.8]">{{ formatDate(props.case.created_at) }}</p>-->
          </span>
        </div>
        <h3 class="text-lg sm:text-3xl font-semibold text-white" :class="{ 'text-center': !props.case.id }">
          {{ titlePerson?.details.first_name }} {{ titlePerson?.details.last_name }}
        </h3>
        <p class="mt-2 text-white/[.8]">
          {{ caseSetting }}
        </p>
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
