<script setup lang="ts"></script>

<template>
  <tr class="border-t border-gray-200 divide-x divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
    <th scope="col" class="min-w-[250px]">
      <!-- Sort Dropdown -->
      <div class="relative w-full">
        <p
          class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
        >
          Name
        </p>
      </div>
      <!-- End Sort Dropdown -->
    </th>

    <th scope="col" class="min-w-48">
      <!-- Sort Dropdown -->
      <div class="relative w-full">
        <p
          class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
        >
          Muttersprache
        </p>
      </div>
      <!-- End Sort Dropdown -->
    </th>

    <th scope="col" class="min-w-36">
      <!-- Sort Dropdown -->
      <div class="relative w-full">
        <p
          class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
        >
          Rolle
        </p>
      </div>
      <!-- End Sort Dropdown -->
    </th>

    <th scope="col" class="min-w-36">
      <!-- Sort Dropdown -->
      <div class="relative w-full">
        <p
          class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
        >
          Aktiv
        </p>
      </div>
      <!-- End Sort Dropdown -->
    </th>

    <th scope="col">
      <!-- Sort Dropdown -->
      <div class="relative w-full">
        <p
          class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
        >
          E-Mail
        </p>
      </div>
      <!-- End Sort Dropdown -->
    </th>

    <th scope="col"></th>
  </tr>
</template>

<style scoped></style>
