/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OralExamInteractionMessageCreate } from '../models/OralExamInteractionMessageCreate';
import type { ThirdPersonInteractionMessage } from '../models/ThirdPersonInteractionMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ThirdPersonInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Third Person Interaction Messages
   * This will only return user or third_person messages, not system
   * messages.
   *
   * :param session:
   * :param user:
   * :param third_person_interaction_id:
   * :return:
   * @param thirdPersonInteractionId
   * @returns ThirdPersonInteractionMessage Successful Response
   * @throws ApiError
   */
  public listThirdPersonInteractionMessages(
    thirdPersonInteractionId: string,
  ): CancelablePromise<Array<ThirdPersonInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/third-person-interactions/{third_person_interaction_id}',
      path: {
        third_person_interaction_id: thirdPersonInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Third Person Interaction Message
   * @param thirdPersonInteractionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createThirdPersonInteractionMessage(
    thirdPersonInteractionId: string,
    requestBody: OralExamInteractionMessageCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/third-person-interactions/{third_person_interaction_id}',
      path: {
        third_person_interaction_id: thirdPersonInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
