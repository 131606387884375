<script setup lang="ts">
import { ErrorMessage, Field, useField, useForm, useValidateField } from 'vee-validate';
import PatientDetails from '@/components/new_case/sections/PatientDetails.vue';
import { updateMinTextareaHeight } from '@/helper';
import { PersonDetailsForm } from '@/components/new_case/typing';
import { ref, watch, computed, nextTick } from 'vue';
import { required, max } from '@vee-validate/rules';

const details = defineModel({
  type: Object as () => PersonDetailsForm,
  required: true,
});
const { showDetails, showPatientDetails, isLoading, requireCompleteness, startingFromCompleteCase } = defineProps([
  'showDetails',
  'showPatientDetails',
  'isLoading',
  'requireCompleteness',
  'startingFromCompleteCase',
]);
// const {errors, validate, controlledValues} = useForm<PersonDetailsForm>();
const patientDetailsAreValid = ref(startingFromCompleteCase);
const emit = defineEmits(['click', 'scrollToBottom', 'isValid']);
const biographyTextarea = ref(null);

const {
  value: localBiography,
  errors: biographyErrors,
  validate: validateBio,
} = useField('biography', 'max:1500|required', {
  initialValue: details.value.biography,
});
const {
  value: localSos,
  errors: sosErrors,
  validate: validateSos,
} = useField('style_of_speech', 'max:300|required', {
  initialValue: details.value.style_of_speech,
});

const isValid = computed(() => {
  let isPatient = details?.value.role?.value === 'PATIENT';
  console.log('isPatient', isPatient);
  console.log('patientDetailsAreValid.value', patientDetailsAreValid.value);
  console.log('bioErrors', biographyErrors.value);
  console.log(
    'PersonDetails: isValid',
    biographyErrors.value.length === 0,
    sosErrors.value.length === 0,
    patientDetailsAreValid.value || !isPatient,
  );
  let isValid =
    biographyErrors.value.length === 0 && sosErrors.value.length === 0 && (patientDetailsAreValid.value || !isPatient);
  console.log('PersonDetails: isValid (computed)', isValid);
  emit('isValid', isValid);
  console.log('emittted');
  return isValid;
});

watch(
  () => isValid.value,
  (value) => {
    console.log('PersonDetails: isValid (watcher)', value);
    emit('isValid', value);
    console.log('emitted');
  },
);

watch(
  () => details.value?.biography,
  (value) => {
    console.log('biography changed to ', value);
    updateMinTextareaHeight(biographyTextarea.value);
  },
);

function patientDetailsValidityChanged(isValid: boolean) {
  console.log('patientDetailsValidityChanged', isValid);
  patientDetailsAreValid.value = isValid;
}
</script>

<template>
  <div class="divide-y divide-gray-200 pt-1" v-show="!!showDetails">
    <div></div>
    <div></div>
  </div>

  <!-- Grid Biographie -->
  <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
    <div class="sm:col-span-12 row-start-1">
      <div
        class="w-full flex justify-center transition-all duration-1000"
        :class="{ 'h-0 min-h-0': !isLoading, 'h-[40px] min-h-[40px]': isLoading }"
      >
        <div
          class="animate-spin overflow-hidden inline-block border-[3px] border-current border-t-transparent text-blue-600 rounded-full transition-all duration-1000"
          :class="{ 'h-0 w-0 opacity-0': !isLoading, 'h-6 w-6 opacity-100': isLoading }"
          role="status"
          aria-label="loading"
        />
      </div>
    </div>
    <div class="sm:col-span-3 row-start-2">
      <label for="hs-pro-daufph" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
        Biographie{{ requireCompleteness ? '*' : '' }}
      </label>
    </div>
    <!-- End Col -->

    <div class="sm:col-span-9 space-y-3 row-start-2">
      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12">
          <textarea
            ref="biographyTextarea"
            v-model="details.biography"
            class="py-2 resize-none px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': biographyErrors.length > 0,
            }"
            placeholder=""
            @click="
              (event) => {
                event.stopPropagation();
                emit('click');
              }
            "
            @keyup.space.prevent="(event) => event.stopPropagation()"
            @input="
              async (event) => {
                await updateMinTextareaHeight(event.target);
                localBiography = event.target.value;
                if (biographyErrors.length === 0) {
                  details.biography = event.target.value;
                }
              }
            "
            rows="1"
          />
          <ErrorMessage class="text-xs text-red-600" name="biography" />
        </div>
      </div>
    </div>
  </div>

  <!-- Grid Ausdrucksweise -->
  <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
    <div class="sm:col-span-3">
      <label for="hs-pro-daufph" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
        Ausdrucksweise{{ requireCompleteness ? '*' : '' }}
      </label>
    </div>
    <!-- End Col -->

    <div class="sm:col-span-9 space-y-3">
      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12">
          <input
            v-model="details.style_of_speech"
            @click="
              (event) => {
                event.stopPropagation();
                emit('click');
              }
            "
            type="text"
            class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': sosErrors.length > 0,
            }"
            placeholder="z.B. freundlich, mürrisch, fordernd"
            @input="
              async (event) => {
                localSos = event.target.value;
                if (sosErrors.length === 0) {
                  details.style_of_speech = event.target.value;
                }
              }
            "
          />
          <ErrorMessage class="text-xs text-red-600" name="style_of_speech" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="px-0 py-0 space-y-5 transition-height duration-1000"
    :class="{
      '-mt-5 max-h-0 h-0 overflow-hidden opacity-100': !showPatientDetails,
      'mt-0 max-h-[1000px] overflow-visible opacity-100': showPatientDetails,
    }"
  >
    <PatientDetails
      v-model="details"
      :showPatientDetails="showPatientDetails"
      @isValid="patientDetailsValidityChanged"
    />
  </div>

  <!-- Grid Avatar -->
  <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
    <div class="sm:col-span-3">
      <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"> Avatar </label>
    </div>
    <!-- End Col -->

    <div class="sm:col-span-9">
      <!-- Logo Upload Group -->
      <div class="flex flex-wrap items-center gap-3 sm:gap-5">
        <div class="grow">
          <div class="flex items-center gap-x-2">
            <button
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              type="button"
              :disabled="isLoading"
              class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <span translate="no" class="material-symbols-outlined notranslate"> refresh </span>
              Neues Photo
            </button>
          </div>
        </div>
      </div>
      <!-- End Logo Upload Group -->
    </div>
    <!-- End Col -->
  </div>
  <!-- End Grid -->
</template>

<style scoped></style>
