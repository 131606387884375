<script setup lang="ts">
const emit = defineEmits(['removeItem']);
// NOTE: use in class="relative" container !

const { top } = defineProps({
  top: {
    type: Boolean,
    default: true,
  },
  halfDistance: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <button
    class="absolute h-8 w-8 flex items-center text-center justify-center bg-red-600 hover:bg-red-700 shadow-md text-white rounded-[4px] text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium"
    :class="{
      'end-1': !halfDistance,
      'end-0.5': halfDistance,
      'top-1': top && !halfDistance,
      'top-0.5': top && halfDistance,
      'bottom-1': !top && !halfDistance,
      'bottom-0.5': !top && halfDistance,
    }"
    @click.prevent="
      (event) => {
        event.stopPropagation();
        emit('removeItem');
      }
    "
  >
    <span translate="no" class="material-symbols-outlined notranslate text-2xl">delete</span>
  </button>
</template>

<style scoped></style>
