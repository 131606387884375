import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';

export const useOrganizationStore = defineStore({
  id: 'organization',
  state: () => ({
    organization: null,
    activeSubscription: null,
    pastSubscriptions: [],
    seats: null,
    members: [],
    countryStats: [],
    nativeLanguageStats: [],
  }),
  getters: {
    name(state) {
      return state.organization ? state.organization.name : '';
    },
    id(state) {
      return state.organization ? state.organization.id : '';
    },
    activeSubscriptionCreationDate(state) {
      return state.activeSubscription ? state.activeSubscription.created_at : null;
    },
    activeSubscriptionExpirationDate(state) {
      return state.activeSubscription ? state.activeSubscription.expires_at : null;
    },
    activeSubscriptionPlan(state) {
      return state.activeSubscription ? state.activeSubscription.subscription_plan : null;
    },
    numberUserSeats(state) {
      return state.seats ? state.seats.n_user_seats : 0;
    },
    numberManagerSeats(state) {
      return state.seats ? state.seats.n_manager_seats : 0;
    },
    numberFreeUserSeats(state) {
      return state.seats ? state.seats.n_free_user_seats : 0;
    },
    numberFreeManagerSeats(state) {
      return state.seats ? state.seats.n_free_manager_seats : 0;
    },
    numberActiveUsers(state) {
      return state.seats ? state.seats.n_active_users : 0;
    },
    numberActiveInvitedUsers(state) {
      return state.seats ? state.seats.n_active_invited_users : 0;
    },
    numberActiveManagers(state) {
      return state.seats ? state.seats.n_active_managers : 0;
    },
    numberDeactivatedUsers(state) {
      return state.seats ? state.seats.n_deactivated_users : 0;
    },
    numberDeactivatedInvitedUsers(state) {
      return state.seats ? state.seats.n_deactivated_invited_users : 0;
    },
    numberDeactivatedManagers(state) {
      return state.seats ? state.seats.n_deactivated_managers : 0;
    },
    maxCountForSingleCountry(state) {
      // compute the max count of users for a single country over all countries in the countryStats
      let maxCount = 0;
      state.countryStats.forEach((countryStat) => {
        if (countryStat.count > maxCount) {
          maxCount = countryStat.count;
        }
      });
      return maxCount;
    },
  },
  actions: {
    async loadOrganization() {
      // get the authStore
      const authStore = useAuthStore();
      const client = await getApiClient();

      // get the single managed organization for the user
      this.organization = await client.users
        .listManagedOrganizationsOfUser(authStore.userId)
        .then((organizations) => {
          const organization = organizations[0];
          return organization;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadSubscriptions() {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      // get the active subscription for the organization
      await client.organizations
        .listOrganizationSubscriptions(this.id, true)
        .then((subscriptions) => {
          this.activeSubscription = subscriptions[0];
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      // get the past subscriptions for the organization
      await client.organizations
        .listOrganizationSubscriptions(this.id, false)
        .then((subscriptions) => {
          this.pastSubscriptions = subscriptions;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadSeats() {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      // get the seats for the organization
      await client.organizations
        .getOrganizationSeats(this.id)
        .then((seats) => {
          this.seats = seats;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadMembers(searchTerm: string | null = null) {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      await client.organizations
        .listOrganizationMembers(this.id, searchTerm)
        .then((members) => {
          this.members = members;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadOrganizationCountryStats() {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      await client.organizations
        .getOrganizationCountryStats(this.id)
        .then((countryStats) => {
          console.log('Country stats:', countryStats);
          this.countryStats = countryStats;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadOrganizationNativeLanguageStats() {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      await client.organizations
        .getOrganizationLanguageStats(this.id, 'native_language')
        .then((nativeLanguageStats) => {
          this.nativeLanguageStats = nativeLanguageStats;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async deactivateMember(userId: string) {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      await client.organizations.editMemberOfOrganization(this.id, userId, { deactivated_at: 'now' });
      // refersh the members list
      this.loadMembers();
      this.loadSeats();
    },

    async activateMember(userId: string) {
      if (!this.organization) {
        await this.loadOrganization();
      }
      const client = await getApiClient();
      await client.organizations.editMemberOfOrganization(this.id, userId, { deactivated_at: null });
      // refersh the members list
      this.loadMembers();
      this.loadSeats();
    },
  },
});
