import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore, useAlertStore } from '@/stores';
import { Landing, Rickroll } from '@/views/landing';
import { Impressum } from '@/views/impressum';
import { AboutUs } from '@/views/about-us';
import { Privacy } from '@/views/privacy';
import accountRoutes from './account.routes';
import homeRoutes from '@/router/home.routes';
import casesRoutes from './cases.routes';
import caseInteractionsRoutes from './caseInteractions.routes';
import dashboardRoutes from './dashboard.routes';
import onboardingRoutes from './onboarding.routes';

import { type IStaticMethods } from 'preline/preline';
import { PatientAdvisoryBoard } from '@/views/patient-advisory-board';
import { NurseAdvisoryBoard } from '@/views/nurse-advisory-board';
import { NotFound } from '@/views/not-found';
import { ValuesAndPurpose } from '@/views/values-and-purpose';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    { path: '/', component: Landing },
    { ...accountRoutes },
    { ...homeRoutes },
    { ...casesRoutes },
    { ...caseInteractionsRoutes },
    { ...dashboardRoutes },
    { ...onboardingRoutes },
    { path: '/impressum', component: Impressum },
    { path: '/privacy-and-terms', component: Privacy },
    { path: '/about-us', component: AboutUs },
    { path: '/patient-advisory-board', component: PatientAdvisoryBoard },
    { path: '/nurse-expert-board', component: NurseAdvisoryBoard },
    { path: '/values-and-purpose', component: ValuesAndPurpose },
    // catch all redirect to home page
    //{ path: "/:pathMatch(.*)*", redirect: "/" },
    { path: '/advisory-board', component: Rickroll },
  ],
});

router.beforeEach(async (to) => {
  // clear alert on route change
  // const alertStore = useAlertStore();
  // alertStore.clear();

  console.debug(`route to ${to.path}`);

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/',
    '/account/invite/accept',
    '/account/sign-in',
    '/account/sign-up',
    '/account/reset-password',
    '/impressum',
    '/privacy-and-terms',
    '/about-us',
    '/patient-advisory-board',
    '/nurse-expert-board',
    '/values-and-purpose',
    '/advisory-board',
  ];

  const redirectIfLoggedInPages = ['/account/sign-in', '/account/sign-up'];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  // redirect to '/home' if user is logged in and trying to access sign-in, sign-up page, etc.
  if (redirectIfLoggedInPages.includes(to.path) && (await authStore.getValidatedToken())) {
    console.debug('user is already logged in, redirect to /home');
    return '/home';
  }

  if (authRequired && !(await authStore.getValidatedToken())) {
    console.debug(`user is not logged in but page ${to.path} requires auth, redirect to login page`);
    authStore.returnUrl = to.fullPath;
    return '/account/sign-in';
  }
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100);
  }
});
