/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Observer } from '../models/Observer';
import type { ObserverCreate } from '../models/ObserverCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ObserversService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Observers
   * @returns Observer Successful Response
   * @throws ApiError
   */
  public listObservers(): CancelablePromise<Array<Observer>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/observers/',
    });
  }

  /**
   * Get Observer
   * @param observerId
   * @returns Observer Successful Response
   * @throws ApiError
   */
  public getObserver(observerId: string): CancelablePromise<Observer> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/observers/{observer_id}',
      path: {
        observer_id: observerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Observer
   * @param requestBody
   * @returns Observer Successful Response
   * @throws ApiError
   */
  public createObserver(requestBody: ObserverCreate): CancelablePromise<Observer> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/observers/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
