<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive } from 'vue';
import { getApiClient } from '@/apiclient/client';
import ProgressButton from '@/components/ProgressButton.vue';
import { ErrorMessage, configure, useForm, useIsSubmitting } from 'vee-validate';
import { useAlertStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import { router } from '@/router';
import _ from 'lodash';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const alertStore = useAlertStore();
const { meta, errors, handleSubmit, defineField, resetForm } = useForm({
  validationSchema: {
    firstName: 'required|max:255',
    lastName: 'required|max:255',
    email: 'required|email',
  },
});

const [firstName, firstNameAttrs] = defineField('firstName');
const [lastName, lastNameAttrs] = defineField('lastName');
const [email, emailAttrs] = defineField('email');

const onSubmit = handleSubmit.withControlled(async (values) => {
  isSubmitting.value = true;
  // submit invite via API
  const apiClient = await getApiClient();
  apiClient.authentication
    .invite({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
    })
    .then((response) => {
      console.log('Invite sent:', response);
      alertStore.success('Einladung erfolgreich versendet');
    })
    .catch((error) => {
      console.error('Error sending invite:', error);
      alertStore.error('Fehler beim Einladen des Benutzers');
    })
    .finally(() => {
      isSubmitting.value = false;
    });
});

const isSubmitting = ref(false); // useIsSubmitting();
</script>
<template>
  <main id="content" class="lg:ps-[260px]">
    <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
      <!-- Create New User Card Form -->
      <div class="max-w-xl mx-auto">
        <!-- Header -->
        <div class="my-5 flex gap-x-3">
          <svg
            class="flex-shrink-0 size-10 text-gray-400 dark:text-neutral-600"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <circle cx="12" cy="10" r="3" />
            <path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662" />
          </svg>

          <div class="grow">
            <h1 class="font-semibold text-xl text-gray-800 dark:text-neutral-200">Einen neuen Benutzer einladen</h1>

            <p class="text-sm text-gray-500 dark:text-neutral-500">
              Lade einen neuen Benutzer zu deiner Organisation ein.
            </p>
          </div>
        </div>
        <!-- End Header -->

        <!-- Card -->
        <div class="bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
          <form @submit="onSubmit">
            <div class="py-2 sm:py-4 px-2">
              <div class="p-4 space-y-5">
                <!-- Grid -->
                <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                  <div class="sm:col-span-3">
                    <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"> Name </label>
                  </div>
                  <!-- End Col -->
                  <div class="sm:col-span-9">
                    <div class="space-y-3">
                      <div class="grid grid-cols-12 gap-2">
                        <div class="col-span-6">
                          <input
                            type="text"
                            v-model="firstName"
                            v-bind="firstNameAttrs"
                            class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                            :class="{
                              'border-red-500 focus:border-red-500 focus:ring-red-500': errors.firstName,
                            }"
                            placeholder="Vorname"
                          />
                          <p class="text-sm text-red-600 mt-2">{{ errors.firstName }}</p>
                        </div>
                        <div class="col-span-6">
                          <input
                            type="text"
                            v-model="lastName"
                            v-bind="lastNameAttrs"
                            class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                            :class="{
                              'border-red-500 focus:border-red-500 focus:ring-red-500': errors.lastName,
                            }"
                            placeholder="Nachname"
                          />
                          <p class="text-sm text-red-600 mt-2">{{ errors.lastName }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Grid -->

                <!-- Grid -->
                <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                  <div class="sm:col-span-3">
                    <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"> E-Mail </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-9">
                    <input
                      type="email"
                      v-model="email"
                      v-bind="emailAttrs"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                      :class="{
                        'border-red-500 focus:border-red-500 focus:ring-red-500': errors.email,
                      }"
                      placeholder="E-Mail-Adresse"
                    />
                    <p class="text-sm text-red-600 mt-2">{{ errors.email }}</p>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Grid -->
              </div>
            </div>

            <!-- Footer -->
            <div class="p-6 pt-0 flex justify-end gap-x-2">
              <div class="w-full flex justify-end items-center gap-x-2">
                <router-link
                  to="/dashboard/users"
                  tag="button"
                  class="py-2 px-3 inline-flex justify-center items-center text-start bg-white border border-gray-200 text-gray-800 text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                >
                  <span>Abbrechen</span>
                </router-link>

                <ProgressButton
                  size="sm"
                  text="Einladen"
                  :showProgress="isSubmitting"
                  :disabled="!meta.valid || isSubmitting"
                  type="submit"
                />
              </div>
            </div>
            <!-- End Footer -->
          </form>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Create New User Card Form -->
    </div>
  </main>
</template>
