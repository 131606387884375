<script setup>
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const alertStore = useAlertStore();
const { alerts } = storeToRefs(alertStore);

const firstThreeAlerts = computed(() => alerts.value.slice(0, 3)); // new alerts are added to the beginning of the array
</script>

<template>
  <div class="container">
    <div v-for="alert in firstThreeAlerts" :key="alert.id" class="relative mb-4">
      <div
        class="bg-white border cursor-pointer border-gray-200 rounded-lg shadow-lg p-1 md:p-4 dark:bg-neutral-800 dark:border-neutral-700"
        :class="{
          'animate-bounce-in': alert.visible,
          'animate-fade-out': !alert.visible,
        }"
        role="alert"
        tabindex="-1"
        aria-labelledby="hs-discovery-label"
        @click.prevent="alertStore.clearAlert(alert.id)"
      >
        <div class="flex">
          <div class="shrink-0">
            <span
              :class="{
                'text-red-400': alert.type === 'error',
                'text-green-400': alert.type === 'success',
                'text-blue-400': alert.type === 'info',
                'text-orange-500': alert.type === 'xp',
              }"
              translate="no"
              class="material-symbols-outlined notranslate"
            >
              {{
                alert.type === 'error'
                  ? 'error'
                  : alert.type === 'success'
                  ? 'check_circle'
                  : alert.type === 'xp'
                  ? 'exercise'
                  : 'info'
              }}
            </span>
          </div>
          <div class="md:ms-3">
            <h3 id="hs-discovery-label" class="text-gray-800 text-sm md:text-base font-semibold dark:text-white">
              {{ t(alert.title) }}
            </h3>
            <p class="md:mt-2 text-sm text-gray-700 dark:text-neutral-400">
              {{ t(alert.message) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.animate-bounce-in {
  animation: bounce-in 0.5s ease-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.animate-fade-out {
  animation: fade-out 0.5s ease-out forwards;
}
</style>
