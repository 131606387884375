/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExaminationReport } from '../models/ExaminationReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExaminationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Patient Interaction Examinations
   * Return examinations as dict of the form ingame_tim : examinations report
   *
   * :param session:
   * :param user:
   * :param patient_interaction_id:
   * :return:
   * @param patientInteractionId
   * @returns ExaminationReport Successful Response
   * @throws ApiError
   */
  public getPatientInteractionExaminations(patientInteractionId: string): CancelablePromise<Array<ExaminationReport>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/examinations/{patient_interaction_id}',
      path: {
        patient_interaction_id: patientInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Patient Interaction Examination
   * Return examinations as dict of the form ingame_tim : examinations report
   *
   * :param session:
   * :param user:
   * :param examination_report_id:
   * :return:
   * @param examinationReportId
   * @returns ExaminationReport Successful Response
   * @throws ApiError
   */
  public getPatientInteractionExamination(examinationReportId: string): CancelablePromise<ExaminationReport> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/examinations/single/{examination_report_id}',
      path: {
        examination_report_id: examinationReportId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Examination Report Translation
   * @param examinationReportId
   * @param targetLanguage
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestExaminationReportTranslation(
    examinationReportId: string,
    targetLanguage: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/examinations/translation/{examination_report_id}',
      path: {
        examination_report_id: examinationReportId,
      },
      query: {
        target_language: targetLanguage,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
