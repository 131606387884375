<script setup lang="ts">
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import TextEditor from '@/views/courses/TextEditor.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import PairOfTerms from '@/components/didactics/pair_of_terms/PairOfTerms.vue';
import { ref, defineProps, defineEmits, computed, PropType, watch } from 'vue';
import { nextTick, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlertStore, useCourseInteractionStore, useAuthStore, useLanguageStore } from '@/stores';
import { debounce } from 'lodash';
import DOMPurify from 'dompurify';
import { toRef } from 'vue';
import VocabExercise from '@/components/didactics/vocab/VocabExercise.vue';
import VocabList from '@/components/didactics/VocabList.vue';

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  chapterId: {
    type: String,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    required: false,
  },
  aboutToDeleteContentItemWithId: {
    type: [String, null] as PropType<string | null>, // can be null or String
    required: false,
  },
  aboutToDeleteTextItemAtIndex: {
    type: Array,
    required: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  externalFetchCompleted: {
    type: Boolean,
    required: true,
  },
  showNative: {
    type: Boolean,
    required: false,
  },
  outerWidth: {
    type: Number,
    required: true,
  },
  vocabListLoading: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits([
  'signalUnsavedChangesLearningObjectives',
  'signalUnsavedChangesText',
  'signalChangesTextCleared',
  'onExtractLearningObjectives',
  'onDeleteContentItem',
  'showNativeToggled',
  'generateAndAddMoreVocab',
  'scrollBy',
]);

const contentEditors = ref<Array<Ref<HTMLElement | null>>>([]);
const fullWidthIndex = ref(-1);
const fullHeightIndex = ref(-1);
const learningObjectives = ref(null);

// refs for translation column
const reactiveShowNative = toRef(props, 'showNative');
const reactiveOuterWidth = toRef(props, 'outerWidth');
const germanContentContainers = ref<Array<Ref<HTMLElement | null>>>([]); // Array of refs for each text item container
const germanContentContainerHeights = ref([]); // Array for each item’s height
let germanContentContainerResizeObservers = []; // Array of ResizeObservers for each container
const languageStore = useLanguageStore();
const authStore = useAuthStore();
const { userNativeLanguage, userIngameLanguage } = storeToRefs(authStore);

// computes for translation column
const gap = computed(() => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    return 8;
  } else if (screenWidth < 1024) {
    return 20;
  }
  return Math.min(50, Math.round(screenWidth * 0.025));
});

const relativeCharacterLength = computed(() => {
  if (userNativeLanguage.value === 'aeb') return 0.85;
  if (userNativeLanguage.value === 'pes') return 0.85;
  if (userNativeLanguage.value === 'rus' || userNativeLanguage.value === 'tgk') return 1.1;
  return 1.0;
});

const sanitizedSectionContentsHtml = computed(() => {
  return textContentItems.value.map((item) => DOMPurify.sanitize(item.content));
});

const translationAvailable = computed(() => {
  return textContentItems.value.map(
    (item) => !!props.section.content_translations && !!props.section.content_translations[userNativeLanguage.value],
  );
});

const germanContainerWidths = computed(() => {
  return sanitizedSectionContentsHtml.value.map((content, index) => {
    if (!props.outerWidth) return 0;
    const translatedContent = translatedContentIfAny.value[index];
    if (!(content.length + translatedContent.length)) return 0;

    if (!reactiveShowNative.value) {
      return props.outerWidth;
    }
    if (!translationAvailable.value[index]) {
      // marginal column to display 'no translation available'
      return 0.7 * props.outerWidth;
    }
    if (content.length === 0) {
      return 0.7 * props.outerWidth;
    }
    // show German original and translation side by side
    return Math.floor(
      ((props.outerWidth - 2 * gap.value) * content.length) /
        (content.length + translatedContent.length * relativeCharacterLength.value),
    );
  });
});

const translatedContentIfAny = computed(() => {
  return textContentItems.value.map((item) => {
    if (!textContentItems.value.content_translations) return 'No translation available';
    const translation = textContentItems.value.content_translations[userNativeLanguage.value];
    return translation ? DOMPurify.sanitize(translation) : 'No translation available';
  });
});

const nativeContainerInnerWidths = computed(() => {
  return germanContainerWidths.value.map((germanWidth) => {
    if (!props.outerWidth) return 0;
    return props.outerWidth - 2 * gap.value - germanWidth;
  });
});

const nativeContainerOuterWidths = computed(() => {
  return nativeContainerInnerWidths.value.map((innerWidth) => innerWidth + gap.value);
});

const toggleFullWidth = (index) => {
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

// functions for translation column
const getHeights = () => {
  germanContentContainers.value.forEach((container, index) => {
    if (!container.value) {
      console.log(`germanContentContainer for index ${index} is not set. Ok if unmounting.`);
      return;
    }
    germanContentContainerHeights.value[index] = container.value[0].offsetHeight;
  });
};

const nativeContainerTranslations = computed(() => {
  console.log(reactiveShowNative.value);

  return germanContainerWidths.value.map((germanWidth, index) => {
    if (!reactiveShowNative.value) {
      console.log(`showNative false for item ${index}`);
      return props.outerWidth;
    }

    console.log(`showNative true for item ${index}`);
    // Translation width considering the width with the native language shown
    return props.outerWidth - nativeContainerOuterWidths.value[index];
  });
});

// const getWidth = () => {
//   const screenWidth = window.innerWidth;
//   widthWithNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.75)); // % of screen width with minimum
//   widthWithoutNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.62));
// };

const fetchCompleted = computed(() => {
  return props.externalFetchCompleted;
});

const debounceGetHeights = debounce(getHeights, 100);

watch(reactiveShowNative, () => {
  debounceGetHeights();
});

watch(reactiveOuterWidth, () => {
  debounceGetHeights();
});

const textContentItems = computed(() => {
  return props.section.section_content_items
    .filter((contentItem) => contentItem.content_type === 'TEXT')
    .map((contentItem) => contentItem.text_item);
});

const textItemIndex = (index) => {
  // map index in props.section.section_content_items to index in textContentItems
  return textContentItems.value.findIndex(
    (textItem) => textItem.id === props.section.section_content_items[index].text_item.id,
  );
};

// functions for editing mode
const setEditorRefs = async () => {
  if (!props.section) {
    return;
  }
  if (!props.section.section_content_items) {
    return;
  }
  if (props.section.section_content_items) {
    contentEditors.value.length = 0;
    germanContentContainers.value.length = 0;
    for (let contentItem of props.section.section_content_items) {
      if (contentItem.content_type === 'TEXT') {
        contentEditors.value.push(ref<HTMLElement | null>(null));
        germanContentContainers.value.push(ref<HTMLElement | null>(null));
      }
    }
  }
  console.log('Now holding # content editors: ' + contentEditors.value.length);
  await nextTick();
};

const onBeforeUnmount = () => {};

const onMounted = async () => {
  await setEditorRefs();
  getHeights();
};

// const contentEditorsByTextItemId = computed(() => {
//   let editors = [];
//   if (!props.section) {
//     return editors;
//   }
//   for (let [index, contentItem] of props.section.section_content_items.entries()) {
//     if (contentItem.content_type === 'TEXT') {
//       console.log('registering content editor', contentEditors.value[textItemIndex(index)]);
//       editors.push({
//         sectionContentItemId: contentItem.id,
//         contentEditor: contentEditors.value[textItemIndex(index)],
//       });
//     }
//   }
//   return editors;
// });

const resetTextEditorEmitStates = async () => {
  // wait 10 ms
  await new Promise((resolve) => setTimeout(resolve, 10));
  console.log('length of contentEditors: ' + contentEditors.value.length);
  contentEditors.value.forEach((editor) => {
    console.log(editor);
    console.log(editor.value);
  });
  contentEditors.value.forEach((editor) => {
    editor.value[0].resetEmitState();
  });
};

defineExpose({
  setEditorRefs,
  resetTextEditorEmitStates,
  learningObjectives,
  contentEditors,
});
</script>

<template>
  <!-- Learning objectives section -->
  <p class="w-full text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8 mb-3">Lernziele</p>
  <div class="w-full space-y-3">
    <LearningObjectives
      ref="learningObjectives"
      :maxNumber="5"
      :editable="allowEditing"
      @requestCompute="emit('onExtractLearningObjectives')"
      @change="emit('signalUnsavedChangesLearningObjectives')"
    />
  </div>

  <!-- Content -->
  <div class="w-full mx-auto grid grid-flow-row grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-6 gap-y-24 pb-3">
    <div
      v-for="(contentItem, index) in props.section.section_content_items"
      :key="contentItem.id"
      class="transition-all auto-rows-min duration-500 ease-in-out"
      :class="{
        'col-span-2 md:col-span-3': contentItem.content_type !== 'MEDIA' && contentItem.content_type !== 'CASE',
        'col-span-2 md:col-span-3 overflow-hidden':
          (contentItem.content_type === 'MEDIA' || contentItem.content_type === 'CASE') && fullWidthIndex === index,
        'col-span-1':
          (contentItem.content_type === 'MEDIA' || contentItem.content_type === 'CASE') && fullWidthIndex !== index,
      }"
    >
      <div
        v-if="contentItem.content_type === 'TEXT'"
        class="grid grid-rows-1 max-h-fit h-fit w-full mx-auto divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden"
        :key="contentItem.text_item.id"
        :style="{ width: props.outerWidth + 'px' }"
        :class="{
          'text-sm md:text-base': showNative,
          'text-base': !showNative,
        }"
      >
        <!-- GERMAN -->
        <div
          :ref="germanContentContainers[textItemIndex(index)]"
          class="col-start-1 row-start-1 overflow-hidden text-justify text-gray-800 dark:text-gray-200 relative rounded-lg"
          :class="{
            'border-red-600 bg-red-100': aboutToDeleteTextItemAtIndex
              ? aboutToDeleteTextItemAtIndex[textItemIndex(index)]
              : false,
          }"
          :style="{ width: `${germanContainerWidths[textItemIndex(index)]}px` }"
        >
          <TextEditor
            :ref="contentEditors[textItemIndex(index)]"
            :content="sanitizedSectionContentsHtml[textItemIndex(index)]"
            :allowList="true"
            :allowTable="true"
            :allowEdit="allowEditing"
            @unsavedChanges="
              (updatedContent) => {
                emit('signalUnsavedChangesText', contentItem.id, updatedContent);
              }
            "
            @changesCleared="emit('signalChangesTextCleared', contentItem.id)"
            :width="germanContainerWidths[textItemIndex(index)] + 'px'"
          />
          <RemoveButton
            v-if="allowEditing"
            @removeItem="emit('onDeleteContentItem', contentItem.id, 'TEXT', contentItem.index)"
            :top="false"
          />
        </div>
        <!-- NATIVE -->
        <div
          class="col-start-1 row-start-1 inline-flex text-justify items-justify text-gray-400 dark:text-gray-200 transition-all duration-500 ease-in-out overflow-hidden"
          :style="{
            height: showNative ? `auto` : `${germanContentContainerHeights[textItemIndex(index)]}px`,
            transform: `translateX(${nativeContainerTranslations[textItemIndex(index)]}px)`,
            width: `${nativeContainerOuterWidths[textItemIndex(index)]}px`,
            gap: `${gap}px`,
          }"
        >
          <p class="border-s border-s-gray-100"></p>
          <p :style="{ width: `${nativeContainerInnerWidths[textItemIndex(index)]}px` }">
            <TextEditor :content="translatedContentIfAny[textItemIndex(index)]" :allowEdit="false" />
          </p>
        </div>
      </div>

      <div class="w-full h-full" v-else-if="contentItem.content_type === 'MEDIA'" :key="index">
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <MediaHighlightCard
          :contentItemId="contentItem.id"
          :url="contentItem.media_item.media_url"
          :type="contentItem.media_item.media_item_type"
          :description="contentItem.media_item.description"
          :allowEdit="true"
          :maxOnClick="true"
          :isMaximized="fullWidthIndex === index"
          @removeMediaItem="emit('onDeleteContentItem', contentItem.id, 'MEDIA', null)"
          @toggleFullWidth="toggleFullWidth(index)"
        />
        <button
          v-show="fullHeightIndex !== index"
          @click="
            (event) => {
              event.stopPropagation();
              fullHeightIndex = <number>index;
            }
          "
          class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
        >
          {{
            contentItem.media_item.media_item_type === 'IMAGE'
              ? 'Bild'
              : contentItem.media_item.media_item_type === 'VIDEO'
              ? 'Video'
              : contentItem.media_item.media_item_type === 'AUDIO'
              ? 'Audio'
              : 'Medieninhalt'
          }}
          (Klicken für Vollansicht)
        </button>
      </div>

      <div
        class="w-full md:w-2/3 mx-auto h-full border-2 border-gray-200 rounded-lg relative"
        v-else-if="contentItem.content_type === 'PAIR_OF_TERMS_GAME'"
        :key="contentItem.pair_of_terms_game.id"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <PairOfTerms
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :index="<number>index"
              :allowEdit="allowEditing"
              :isMaximized="fullHeightIndex === index"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @scrollBy="(distance) => emit('scrollBy', distance)"
            />
            <RemoveButton
              v-if="allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'PAIR_OF_TERMS_GAME', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
          <button
            v-show="fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Übung (Klicken zum Ausklappen)
          </button>
        </div>
      </div>

      <!--           v-else-if="contentItem.content_type === 'VOCAB_LIST'" -->
      <!--          :key="contentItem.vocab_list.id" -->
      <div
        class="mx-auto h-full border-2 border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'VOCAB_LIST'"
        :class="{
          'w-full md:w-2/3': fullHeightIndex !== index,
          'w-full': fullHeightIndex === index,
        }"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <VocabExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :index="<number>index"
              :showNative="props.showNative"
              :isMaximized="fullHeightIndex === index"
              :allowEditing="allowEditing"
              :outerHeaderHeight="props.outerHeaderHeight"
              :vocab-list-loading="props.vocabListLoading"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @generateAndAddMoreVocab="emit('generateAndAddMoreVocab', contentItem.id)"
            />
            <RemoveButton
              v-if="allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'VOCAB_LIST', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
          <button
            v-show="fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Vokabeln (Klicken zum Ausklappen & Üben)
          </button>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full flex-col flex relative"
        v-else-if="contentItem.content_type === 'CASE'"
        :key="contentItem.case.id"
      >
        <div class="z-0">
          <CaseHighlightCard
            v-if="fetchCompleted"
            :case="contentItem.case"
            :sectionId="props.section.id"
            :chapterId="<string>props.chapterId"
            rounded="rounded-xl"
            @removeItem="emit('onDeleteContentItem', contentItem.id, 'CASE', null)"
            :allowRemoveCaseFromSection="allowEditing"
            :allowEditCase="false"
            :allowCopyCase="false"
            :allowViewCase="true"
          />
        </div>
        <label class="w-full mx-auto text-center text-sm text-gray-500 dark:text-gray-400"
          >Fall-Simulation (Klicken zum Starten)</label
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>
