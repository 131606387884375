import { defineStore } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { LearningObjectiveEvaluation } from '@/apiclient/models/LearningObjectiveEvaluation';

export const useEvaluationStore = defineStore({
  id: 'evaluation',
  state: () => ({
    caseInteractionId: null as string | null,
    learningObjectivesEvaluation: null as LearningObjectiveEvaluation[] | null,
  }),
  getters: {},
  actions: {
    reset() {
      this.caseInteractionId = null;
      this.learningObjectivesEvaluation = null;
    },
    setCaseInteractionId(caseInteractionId: string) {
      this.caseInteractionId = caseInteractionId;
    },
    async evaluateLearningObjectives() {
      const client = await getApiClient();
      this.learningObjectivesEvaluation = await client.caseInteractions
        .evaluateLearningObjectivesOfCaseInteraction(this.caseInteractionId)
        .then((evaluation) => {
          return evaluation;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  },
});
