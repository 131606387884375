<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  category: { type: Object, required: true },
});

const emit = defineEmits(['dropItem']);

const handleDrop = (event) => {
  const itemId = event.dataTransfer.getData('itemId');
  emit('dropItem', itemId, props.category.id);
  event.target.style.backgroundColor = 'initial';
};

const handleDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

const handleDragEnter = (event) => {
  event.target.style.backgroundColor = '#e0e0e0';
};

const handleDragLeave = (event) => {
  event.target.style.backgroundColor = 'initial';
};
</script>

<template>
  <div
    class="p-4 w-48 h-48 rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-start"
    @drop.prevent="handleDrop"
    @dragover.prevent="handleDragOver"
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
  >
    <span class="text-center font-semibold mb-2">{{ category.name }}</span>
    <slot></slot>
  </div>
</template>

<style scoped>
/* Styling for hover and drop feedback */
</style>
