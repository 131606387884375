<script setup lang="ts">
import { onMounted, ref } from 'vue';
import MainHeader from '@/components/headers/MainHeader.vue';
import PublicFooter from '@/views/landing/PublicFooter.vue';

const imgRuediger = ref();
const imgPatient = ref();

onMounted(() => {
  import('@/assets/images/brand-illustrations/patient_nurse.png').then((img) => {
    imgPatient.value = img.default;
  });
  import('@/assets/images/about-us/ruediger.jpg').then((img) => {
    imgRuediger.value = img.default;
  });
});
</script>

<template>
  <div class="w-full h-screen bg-gray-50 dark:bg-neutral-800">
    <header
      class="sticky top-0 flex-none flex-wrap md:justify-start md:flex-nowrap z-40 w-full border-b dark:border-none border-gray-200 bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="true"
        :showChallengesAndEventsLink="false"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showSignInOrEditProfile="true"
      />
    </header>

    <div class="overflow-auto min-h-full h-full flex-shrink">
      <main class="flex-col flex overflow-visible min-h-fit max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <!-- Title -->
        <div class="flex-col flex overflow-visible min-h-fit max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <!-- Content -->
          <div class="flex-col flex overflow-visible min-h-fit h-fit space-y-5 md:space-y-8">
            <div class="space-y-3">
              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Der casuu<span class="text-blue-600">-Patient:innenbeirat</span>
              </h2>

              <p class="text-lg text-gray-800 dark:text-neutral-200">
                Wir freuen uns über Patient:innen, die unsere Mission teilen:<br />bestmögliche Kommunikation zwischen
                Patient:innen und medizinischem Personal.
              </p>
            </div>

            <p class="text-lg text-gray-800 dark:text-neutral-200">
              Sie haben die Möglichkeit, Ihre Perspektiven, Eindrücke, Ideen einzubringen.<br />Die direkt in unsere
              Trainings für Ärzt:innen und Pflegekräfte einfließen. So können unsere Trainings ihr Ziel erreichen: dass
              die Kommunikation zwischen Patient:in und Pflegekraft funktioniert. Deshalb danken wir allen
              Patient:innen, die schon dabei sind und uns mit ihren Ideen und Erfahrungen unterstützen!
            </p>

            <blockquote class="text-center p-4 sm:px-7">
              <p
                class="text-xl font-medium text-gray-800 md:text-2xl md:leading-normal xl:text-2xl xl:leading-normal dark:text-neutral-200"
              >
                Gute Medizin stellt den Patienten <span class="text-blue-600">in den Mittelpunkt.</span><br />Gute
                Kommunikation stellt den Patienten in den Mittelpunkt. Und deshalb tut das auch casuu.
              </p>
              <div class="inline-flex items-center gap-4 mt-5">
                <div class="text-gray-800 dark:text-neutral-200">
                  <div>
                    <router-link to="/about-us">Rüdiger Schmitz</router-link>
                  </div>
                  <div class="text-sm text-gray-500 dark:text-neutral-500">Arzt & Mitgründer von casuu</div>
                </div>
                <div class="flex-shrink-0">
                  <img class="h-16 w-16 rounded-full" :src="imgRuediger" alt="ruediger@casuu.care" />
                </div>
              </div>
            </blockquote>

            <p class="text-lg text-gray-800 dark:text-neutral-200">
              Wir freuen uns über jede:n, der/die sich einbringen möchte - zum Beispiel mit einem Erfahrungsbericht, was
              gut oder schlecht gelaufen ist. Selbstverständlich ist die Mitarbeit freiwillig und ohne Verpflichtungen.
            </p>

            <figure>
              <img class="w-full object-cover rounded-xl" :src="imgPatient" />
              <figcaption class="mt-3 text-sm text-center text-gray-500 dark:text-neutral-500">
                Medizin ist Kommunikation.
              </figcaption>
            </figure>

            <div class="space-y-3">
              <h3 class="text-2xl font-semibold dark:text-white">Mitmachen</h3>

              <p class="text-lg text-gray-800 dark:text-neutral-200 pb-8">
                Wenn Sie Interesse haben, sich einzubringen, schreiben Sie uns bitte eine E-Mail an
                <a class="text-blue-600 hover:text-blue-700 dark:text-blue 800" href="mailto:hello@casuu.care"
                  >hello@casuu.care</a
                ><br />Wir freuen uns!
              </p>
            </div>

            <div>
              <a
                class="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-200 text-gray-800 hover:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
                href="#"
              >
                Patient:innenbeirat
              </a>
              <a
                class="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-200 text-gray-800 hover:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
                href="#"
              >
                casuu
              </a>
              <a
                class="m-1 inline-flex items-center gap-1.5 py-2 px-3 rounded-full text-sm bg-gray-200 text-gray-800 hover:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
                href="#"
              >
                Mitgestalten
              </a>
            </div>
          </div>
          <!-- End Content -->
        </div>
        <!-- End Grid -->
      </main>

      <footer class="h-fit min-h-fit flex relative bottom-0 overflow-visible">
        <PublicFooter />
      </footer>
    </div>
  </div>
</template>

<style scoped></style>
