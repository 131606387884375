<script setup>
import { useAuthStore, useOrganizationStore } from '@/stores';
import { ref, onMounted, onBeforeMount, defineProps, computed } from 'vue';
import { getApiClient } from '@/apiclient/client';
import ProfileImage from '@/components/ProfileImage.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import LanguageDropdown from '@/components/LanguageDropdown.vue';
import { useForm } from 'vee-validate';
import 'vue-advanced-cropper/dist/style.css';
import { router } from '@/router';
import _ from 'lodash';
import Tooltip from '@/components/Tooltip.vue';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const authStore = useAuthStore();
const organizationStore = useOrganizationStore();

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await organizationStore.loadOrganization();
});
</script>

<template>
  <!-- ========== MAIN SIDEBAR ========== -->
  <aside
    id="hs-pro-sidebar"
    :style="{ paddingTop: `${outerHeaderHeight}px` }"
    class="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] hidden fixed inset-y-0 start-0 z-[20] bg-white border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700 dark:hs-overlay-backdrop-open:bg-neutral-900/90"
  >
    <div class="flex flex-col h-full max-h-full py-3">
      <!-- Profile -->
      <header class="pb-5 text-center border-b border-gray-200 dark:border-neutral-700">
        <span class="font-semibold text-gray-800 dark:text-neutral-300">
          {{ authStore.userFullName }}
        </span>
        <p v-if="organizationStore.organization" class="text-sm text-gray-500 dark:text-neutral-500">
          {{ organizationStore.organization.name }}
        </p>
      </header>
      <!-- End Profile -->

      <!-- Content -->
      <div
        class="h-[calc(100%-35px)] lg:h-[calc(100%-93px)] overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
      >
        <!-- Nav -->
        <nav class="hs-accordion-group pb-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
          <ul>
            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard"
              >
                <span translate="no" class="material-symbols-outlined notranslate">team_dashboard</span>
                Dashboard
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/users"
              >
                <span translate="no" class="material-symbols-outlined notranslate">group</span>
                Benutzer:innen
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/subscription"
              >
                <span translate="no" class="material-symbols-outlined notranslate">credit_card</span>
                Abonnement
              </router-link>
            </li>
            <!-- End Link -->
          </ul>
        </nav>
        <!-- End Nav -->
      </div>
      <!-- End Content -->

      <div class="lg:hidden absolute top-3 -end-3 z-10">
        <!-- Sidebar Close -->
        <button
          type="button"
          class="w-6 h-7 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
          data-hs-overlay="#hs-pro-sidebar"
          aria-controls="hs-pro-sidebar"
          aria-label="Toggle navigation"
        >
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
        </button>
        <!-- End Sidebar Close -->
      </div>
    </div>
  </aside>
  <!-- ========== END MAIN SIDEBAR ========== -->

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" :style="computedStyleMain" class="lg:ps-[260px] w-full flex-grow overflow-hidden">
    <router-view :outer-header-height="props.outerHeaderHeight" />
  </main>
  <!-- ========== END MAIN CONTENT ========== -->
</template>
