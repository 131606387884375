/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DebugService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Courses
   * @param statusCode
   * @returns any Successful Response
   * @throws ApiError
   */
  public listCourses(statusCode: number): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/debug/return-status-code/{status_code}',
      path: {
        status_code: statusCode,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
