<script setup lang="ts"></script>

<template>
  <div class="">
    <svg
      class="flex-shrink-0 mx-0.5 md:mx-2 overflow-hidden size-4 text-gray-400 dark:text-neutral-600"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="m9 18 6-6-6-6"></path>
    </svg>
  </div>
</template>

<style scoped></style>
