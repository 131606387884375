<script setup>
import { ref, watch, onMounted } from 'vue';
import { PCMPlayer } from '@/audio';
import { useCaseInteractionStore } from '@/stores';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

const emit = defineEmits(['playbackFinished']);

// Props
const props = defineProps({
  message: String,
  messageIsComplete: {
    type: Boolean,
    default: true,
  },
  /**
   * @property {String} messageId - The unique identifier for the message.
   * Once this is a valid UUID, the backend will store the audio stream.
   * The messageId is sent to the backend with each chunk of the message.
   * @default 'not_yet_in_database'
   */
  messageId: {
    type: String,
    default: 'not_yet_in_database',
  },
  bufferFirstNChunks: {
    type: Number,
    default: 0,
  },
  voice: {
    type: Object, // Assuming Voice is an object type
    required: false,
  },
});

// State
const websocket = ref(null);
const prevMessage = ref('');
const pcmPlayer = ref(null);
const fileReader = ref(null);
const audioDataBuffer = ref([]);
const audioDataQueue = ref([]);
const totalReceivedDuration = ref(0);
const fileReaderBusy = ref(false);
const numberOfChunksReceived = ref(0);
const streamingStarted = ref(false);
const playbackStarted = ref(false);
const waitingForPlayback = ref(false);
const audioPlaying = ref(false);
const playbackFinished = ref(false);
const allAudioBytesReceived = ref(false);
const uuid = ref('');

const caseInteractionStore = useCaseInteractionStore();
const { audioAutoplayEnabled: autoplay } = storeToRefs(caseInteractionStore);

// Watchers
watch(
  () => props.message,
  (newMessage) => {
    if (props.messageIsComplete) {
      console.debug('Message is complete. Do not send new message part.');
      return;
    }

    const newPart = newMessage.slice(prevMessage.value.length);
    sendStringChunk(newPart);

    prevMessage.value = newMessage;
  },
);

// watch(
//     () => playbackFinished.value,
//     (newStatus) => {
//       if (newStatus) {
//         console.debug('Playback finished. Emitting event.');
//         emit('playbackFinished');
//       }
//     },
//     {immediate: true},
// )

// TODO: this does not fire for some reason if leaving case interation:
// watch(
//     () => caseInteractionStore.currentCaseInteraction,
//     (newValue) => {
//       if (!newValue) {
//         console.warn('currentCaseInteraction changed to null. Stopping audio playback.');
//         onStopAudio();
//       }
//     },
//     {immediate: true},
// )

// Methods
const startStreaming = async () => {
  console.log(uuid.value + ': startStreaming');
  caseInteractionStore.setAudioIsStreaming(true);
  streamingStarted.value = true;
  console.log(
    'Creating websocket with voice provider: ' +
      props.voice.voice_provider +
      ', voice model: ' +
      props.voice.voice_model +
      ', voice id: ' +
      props.voice.voice_id +
      '.',
  );
  websocket.value = (await getStreamingClient()).createWebSocket(
    'tts/',
    props.voice.voice_provider,
    props.voice.voice_model,
    props.voice.voice_id,
  );

  websocket.value.onmessage = (event) => {
    if (!caseInteractionStore.currentCaseInteraction) {
      console.warn('currentCaseInteraction changed to null. Refusing reception of audio chunk.');
      return;
    }
    let data = JSON.parse(event.data);
    let readingChars = data['aligned_chars'];
    let readingDurations = data['char_durations_ms'];

    const binaryData = atob(data['audio_b64']);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'audio/wav' });

    audioDataQueue.value.push(blob);
    caseInteractionStore.addChunkToReadingQueue({ readingChars, readingDurations });
    caseInteractionStore.setFirstAudioChunkReceived(true);
    numberOfChunksReceived.value += 1;

    if (numberOfChunksReceived.value <= props.bufferFirstNChunks || !autoplay.value) {
      console.debug('Received chunk #' + numberOfChunksReceived.value + '. Adding to buffer.');
      return;
    }

    processNextAudioChunk();
  };

  websocket.value.onclose = () => {
    console.log(uuid.value + ': WebSocket closed externally');
    if (props.messageIsComplete) {
      console.log(
        uuid.value + ': Text streaming completed and websocket closed externally, so all audio bytes received.',
      );
      console.log(uuid.value + ': Decreasing PCMPlayer timeout interval');
      pcmPlayer.value.setTimeoutInterval(500);
      console.log(uuid.value + ': Playback started: ' + playbackStarted.value);
      console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
      allAudioBytesReceived.value = true;
    }
    if (!playbackStarted.value && autoplay.value) {
      console.log(uuid.value + ': No playback started yet. Initiating immediate playback.');
      processNextAudioChunk();
    }
  };

  websocket.value.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  sendStringChunk(props.message);
};

const processNextAudioChunk = () => {
  console.log(uuid.value + ': Processing next audio chunk');
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Breaking audio playback.');
    return;
  }
  if (fileReaderBusy.value) {
    console.warn('FileReader is busy.');
    return;
  }
  if (audioDataQueue.value.length === 0) {
    console.log(uuid.value + ': No audio chunks in queue.');
    return;
  }
  const blob = audioDataQueue.value.shift();
  waitingForPlayback.value = false;
  playbackStarted.value = true;
  audioPlaying.value = true;
  fileReaderBusy.value = true;

  fileReader.value.onload = () => {
    const arrayBuffer = fileReader.value.result;
    const audioData = new Int16Array(arrayBuffer);

    audioDataBuffer.value.push(audioData);
    totalReceivedDuration.value += audioData.length / 16000;

    playAudio(audioData);

    if (audioDataQueue.value.length === 0) {
      console.log(uuid.value + ': Queue emptied.');
      if (allAudioBytesReceived.value) {
        console.debug('All audio bytes received and played.');
        audioPlaying.value = false;
        playbackFinished.value = true;
      }
    }
    console.log(uuid.value + ': After play audio in process next audio chunk');

    processNextAudioChunk();
    fileReaderBusy.value = false;
  };

  fileReader.value.onerror = (error) => {
    console.error('Error reading Blob:', error);
    fileReaderBusy.value = false;
    processNextAudioChunk();
  };

  fileReader.value.readAsArrayBuffer(blob);
  console.log(uuid.value + ': End of process next audio chunk');
};

const onPlaybackFinished = () => {
  console.log(uuid.value + ': Playback finished');
  console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
  console.log(uuid.value + ': All audio bytes received: ' + allAudioBytesReceived.value);
  if (audioDataQueue.value.length > 0) {
    console.debug('But there are more audio chunks to play in the queue, so resuming.');
    processNextAudioChunk();
  } else {
    console.debug('All audio bytes received and nothing more to play.');
    audioPlaying.value = false;
    playbackFinished.value = true;
    caseInteractionStore.setAudioIsStreaming(false);
    emit('playbackFinished');
  }
};

const playAudio = (audioData) => {
  pcmPlayer.value.feed(audioData);
};

const onReplayAudio = () => {
  if (audioPlaying.value) {
    onStopAudio();
  }
  if (audioDataBuffer.value.length === 0) {
    startStreaming();
    console.log(props.message);
    return;
  }
  console.log(uuid.value + ': Replaying audio');
  audioPlaying.value = true;
  playbackFinished.value = false;
  for (const audioDataChunk of audioDataBuffer.value) {
    playAudio(audioDataChunk);
  }
};

const onStopAudio = () => {
  pcmPlayer.value.destroy();
  pcmPlayer.value = new PCMPlayer({
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 16000,
    flushingTime: 200,
  });
  audioPlaying.value = false;
  playbackFinished.value = true;
};

const sendFinished = () => {
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    console.debug('Sending finished message');
    websocket.value.send(
      JSON.stringify({
        text: '',
        is_finished: true,
        message_id: props.messageId,
      }),
    );
  } else {
    console.error('WebSocket not open when trying to send finished message.');
  }
};

const sendStringChunk = (chunk) => {
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Aborting string send to TTS.');
    return;
  }
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    waitingForPlayback.value = true;
    playbackFinished.value = false;
    console.debug('Sending message: ' + chunk);
    websocket.value.send(
      JSON.stringify({
        text: chunk,
        fetched_complete_message_from_db: false,
        is_finished: false,
        message_id: props.messageId,
      }),
    );
  } else {
    console.log(uuid.value + ': WebSocket not open yet. Waiting for connection...');
    setTimeout(() => {
      sendStringChunk(chunk);
    }, 500);
  }
};

watch(
  () => props.messageIsComplete,
  (newStatus) => {
    console.log(uuid.value + ': messageIsComplete changed to: ' + newStatus + '.');
    if (newStatus && streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Send finished message.');
      sendFinished();
    }
    if (!newStatus && !streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Start streaming.');
      startStreaming();
    }
  },
  { immediate: true },
);

watch(
  fileReaderBusy,
  (newStatus) => {
    if (!newStatus) {
      console.debug('fileReaderBusy changed to: ' + newStatus + '. Resume playback.');
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

watch(
  autoplay,
  (newVal) => {
    console.log(uuid.value + ': autoplay is now ' + newVal);
    console.log(autoplay.value);
    if (newVal) {
      console.debug('autoplay enabled. Starting from queue of length ' + audioDataQueue.value.length);
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

// Mounted
onMounted(() => {
  uuid.value = uuidv4();
  console.log(uuid.value + ': AudioPlayerTTS mounted');
  playbackFinished.value = true;
  pcmPlayer.value = new PCMPlayer({
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 16000,
    flushingTime: 200,
    playbackFinishedCallback: onPlaybackFinished,
  });
  fileReader.value = new FileReader();
  caseInteractionStore.resetCurrentlyReadingChatMessageText();
  caseInteractionStore.setFirstAudioChunkReceived(false);
});
</script>

<template>
  <div></div>
  <!--  <div-->
  <!--    class="text-xs text-left py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none transition-all dark:hover:bg-neutral-800 dark:hover:text-gray-400 dark:hover:border-gray-900"-->
  <!--  >-->
  <!--    <div v-show="waitingForPlayback">-->
  <!--      <div class="flex items-center gap-x-1">-->
  <!--        <div class="w-3 h-3 bg-gray-300 rounded-full animate-pulse" />-->
  <!--        <div class="w-3 h-3 bg-gray-300 rounded-full animate-pulse" />-->
  <!--        <div class="w-3 h-3 bg-gray-300 rounded-full animate-pulse" />-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div v-show="audioPlaying">-->
  <!--      <div>-->
  <!--        <button @click="onStopAudio">-->
  <!--          <div class="inline-flex">-->
  <!--            <svg-->
  <!--              xmlns="http://www.w3.org/2000/svg"-->
  <!--              width="16"-->
  <!--              height="16"-->
  <!--              fill="currentColor"-->
  <!--              class="bi bi-stop"-->
  <!--              viewBox="0 0 16 16"-->
  <!--            >-->
  <!--              <path-->
  <!--                d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5z"-->
  <!--              />-->
  <!--            </svg>-->
  <!--            Stop-->
  <!--          </div>-->
  <!--        </button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div v-show="playbackFinished">-->
  <!--      <button @click="onReplayAudio">-->
  <!--        <div class="inline-flex">-->
  <!--          <svg-->
  <!--            xmlns="http://www.w3.org/2000/svg"-->
  <!--            width="16"-->
  <!--            height="16"-->
  <!--            fill="currentColor"-->
  <!--            class="bi bi-play"-->
  <!--            viewBox="0 0 16 16"-->
  <!--          >-->
  <!--            <path-->
  <!--              d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"-->
  <!--            />-->
  <!--          </svg>-->
  <!--          Replay-->
  <!--        </div>-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->
</template>
