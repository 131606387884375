import { defineStore } from 'pinia';
import { getApiClient } from '@/apiclient/client';

export const useLanguageStore = defineStore({
  id: 'language',
  state: () => ({
    languages: [],
  }),
  getters: {},
  actions: {
    async loadLanguages(forceReload = true) {
      const client = await getApiClient();
      if (!client) {
        return;
      }
      if (this.languages.length > 0 && !forceReload) {
        return;
      }
      this.languages = await client.languages
        .getAvailableLanguages()
        .then((languages) => {
          return languages;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    getLanguageNameFromCode(code) {
      const language = this.languages.find(
        (language) => language.iso_alpha_2 === code || language.iso_alpha_3 === code,
      );
      return language ? language.iso_name : code;
    },
  },
});
