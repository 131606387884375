<script lang="ts">
// TODO: replace icons with proper ones for tags

import { ref, onMounted, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { HSSelect, HSStaticMethods } from 'preline';

interface Tag {
  name: string;
  description: string;
  color: string;
}

interface TagInputProps {
  value: Tag[];
}

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: Array as () => Tag[],
      required: false,
    },
  },

  setup(props: TagInputProps, { emit }: { emit: (event: 'input', value: TagInputProps['value']) => void }) {
    const tags = ref<Tag[]>([]);
    // const tags = ref<string[]>(['a', 'b', 'c']);
    const tagsFetched = ref<boolean>(false);
    const tagsSelected = ref<Tag[]>([]);
    const tagSelectRef = ref<HTMLElement | null>(null);

    const fetchTags = async () => {
      console.log('fetching tags');
      try {
        const response = await (await getApiClient()).tags.getAvailableTags();
        console.log('tags fetched');
        response.forEach((tag: any) => {
          tags.value.push({
            name: tag.name,
            description: tag.description,
            color: tag.color,
          });
        });
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
      // console.log('Tags are: ' + JSON.stringify(tags.value));
      tagsFetched.value = true;
    };

    onMounted(async () => {
      await fetchTags();
      emit('input', []);
      setTimeout(() => {
        console.log('HSS init');
        // HSStaticMethods.autoInit();
        const el = HSSelect.getInstance('#tagSelect');
        if (el) {
          // check if the element exists on dom
          el.on('change', (indices: number[]) => {
            tagsSelected.value = [];
            indices.forEach((index) => {
              tagsSelected.value.push(tags.value[index]);
            });
            console.log('change');
            console.log(indices);
            updateValue(el);
          });
        } else {
          console.log('no element found');
        }
      }, 100);
    });

    watch(
      () => props.value,
      (value) => {
        tagsSelected.value = value;
        // TODO: how to update the select?
      },
      { immediate: true, deep: true },
    );

    const isSelected = (tag: any) => {
      if (!tagsSelected.value) {
        return false;
      }
      const selectedTagNames = tagsSelected.value.map((tag) => tag.name);
      return selectedTagNames.includes(tag.name);
    };

    const updateValue = (el: any) => {
      // Emit 'input' event when the input fields change
      console.log('Emitted input event with value 0: ' + JSON.stringify(tagsSelected.value[0]));
      emit('input', [...tagsSelected.value]);
    };

    const handleTagRemoveClick = (el: any) => {
      // el is PointerClick event, el.target is the element that was clicked
      // path from (X) to string of associated tag is: el.target.parentElement.parentElement.innerText
      const elt = el.target.parentElement?.parentElement?.innerText;
      // ugly fix to check whether the click was on the remove button: get its html
      const targetHtml = el.target.innerHTML;
      const removeButtonHtml1 = '<path d="M18 6 6 18"></path><path d="m6 6 12 12"></path>';
      const removeButtonHtml2 =
        '<svg class="flex-shrink-0 w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>';
      //
      // console.log(el.target);
      // console.log(elt);
      // console.log(targetHtml);

      if (targetHtml != removeButtonHtml1 && targetHtml != removeButtonHtml2) {
        return;
      }
      if (elt) {
        console.log('Got a click to remove element: ' + elt + '.');
        // remove from tagsSelected
        const index = tagsSelected.value.indexOf(elt);
        tagsSelected.value.splice(index, 1);
        emit('input', tagsSelected.value);
      }
    };

    const getSelectOption = (tag: Tag) => {
      const optionObject = {
        icon: `<img class="inline-block rounded-full" src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" />`,
        description: tag.description,
      };
      return JSON.stringify(optionObject);
    };

    return {
      tags,
      updateValue,
      tagsFetched,
      tagsSelected,
      handleTagRemoveClick,
      tagSelectRef,
      getSelectOption,
      isSelected,
    };
  },
};
</script>

<template>
  <div class="relative" v-if="tagsFetched" @click="handleTagRemoveClick">
    <!--  <div class="relative">-->
    <!-- NOTE: the v-if is important, otherwise rendered before fetch compeleted => empty. Does not update tho reactive. -->
    <!-- TODO: why? -->

    <!--    Selected: {{ tagsSelected }}. Props: {{ value }}.-->

    <select
      id="tagSelect"
      ref="tagSelectRef"
      multiple
      data-hs-select='{
          "placeholder": "Auswählen...",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-neutral-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-50 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-gray-200 dark:focus:bg-neutral-800",
          "mode": "tags",
          "tagsClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-gray-700\"><div class=\"h-6 w-6 me-1\" data-icon></div><div class=\"whitespace-nowrap\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center h-5 w-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-gray-700/50 dark:hover:bg-gray-700 dark:text-gray-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 w-3 h-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
          "tagsInputClasses": "absolute inset-0 w-full py-3 px-4 pe-9 flex-1 text-sm rounded-lg focus-visible:ring-0 dark:bg-neutral-900 dark:text-gray-400",
          "optionTemplate": "<div class=\"flex items-center\"><div class=\"h-8 w-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-gray-200\" data-title></div><div class=\"text-xs text-gray-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-4 h-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>"
        }'
      class="hidden"
    >
      <option
        v-for="(tag, index) in tags"
        :value="index"
        :data-hs-select-option="getSelectOption(tag)"
        :selected="isSelected(tag)"
      >
        {{ tag.name }}
      </option>
    </select>

    <div class="absolute top-1/2 end-3 -translate-y-1/2">
      <svg
        class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m7 15 5 5 5-5" />
        <path d="m7 9 5-5 5 5" />
      </svg>
    </div>

    <!--    <select-->
    <!--      id="hs-select-symptom-onset"-->
    <!--      class="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"-->
    <!--      v-model="tagsSelected"-->
    <!--      @change="updateValue"-->
    <!--      multiple-->
    <!--    >-->
    <!--      <option v-for="tag in tags" :value="tag" :key="tag">-->
    <!--        {{ tag }}-->
    <!--      </option>-->
    <!--    </select>-->
  </div>
</template>

<style scoped></style>
