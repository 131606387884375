<!-- Like Translation, but for content not in any store: translate and forget -->
<!-- Note: stores only used to get language of user and patient -->
<!-- Note 2: currently, only for direct translations -- no recommendations; see Translation.vue for this logic -->
<!-- Note 3: "message" is a plain string here, not the message object from the store and Translation.vue -->

<script>
import { ref } from 'vue';
import DOMPurify from 'dompurify';

import { usePatientInteractionStore, useAuthStore } from '@/stores';
import { countNumberOfSentences, splitSentences, unobfuscateUserName } from '@/helper';
import { storeToRefs } from 'pinia';
import { getStreamingClient } from '@/apistreamer/streamingclient';

export default {
  setup: () => {
    const patientInteractionStore = usePatientInteractionStore();
    const authStore = useAuthStore();

    const { patientLanguage } = storeToRefs(patientInteractionStore);
    const { userNativeLanguage: userNative, userFirstName, userLastName } = storeToRefs(authStore);

    const translation = ref('');
    const highlightedDirectTranslation = ref('');

    return {
      userNative,
      userFirstName,
      userLastName,
      patientLanguage,
      translation,
    };
  },
  data: () => ({
    showTranslation: false,
    translationLanguage: '',
    translationOption: '',
  }),
  computed: {
    languagePair() {
      return [this.userNative, this.patientLanguage];
    },
    highlightedDirectTranslation() {
      return this.highlightSentence(
        unobfuscateUserName(this.translation, this.userFirstName, this.userLastName),
        this.markedSentencesOrigin,
        this.totalNumberOfSentencesOriginalChatMessage,
      );
    },
    totalNumberOfSentencesOriginalChatMessage() {
      return countNumberOfSentences(this.message);
    },
  },
  props: {
    markedSentencesOrigin: {
      type: Array,
    },
    message: {
      type: String,
    },
  },
  methods: {
    highlightSentence(text, markedSentences, totalNumberOfSentences) {
      console.log('text is ', text);
      console.log('markedSentences is ', markedSentences);
      console.log('totalNumberOfSentences is ', totalNumberOfSentences);
      if (text === undefined) {
        return '';
      }
      if (!markedSentences || markedSentences.length === 0) {
        return text;
      }
      const sentences = splitSentences(text);
      const numSentencesOrig = totalNumberOfSentences;
      const numSentencesTarget = sentences.length;
      const ratio = numSentencesTarget / numSentencesOrig;
      const startSentence = Math.floor(markedSentences[0] * ratio);
      const endSentence = Math.ceil(markedSentences[markedSentences.length - 1] * ratio);
      const highlightedSentences = [];
      // console.log('markedSentences: ' + markedSentences);
      // console.log('sentences: ' + sentences);
      // console.log('startSentence: ' + startSentence);
      // console.log('endSentence: ' + endSentence);
      // TODO: highlight arabaic etc in reversed direction? How to handle?
      for (let i = 0; i < sentences.length; i++) {
        if (i >= startSentence && i <= endSentence) {
          highlightedSentences.push('<span class="bg-yellow-200">' + sentences[i] + '</span>');
        } else {
          highlightedSentences.push(sentences[i]);
        }
      }
      return DOMPurify.sanitize(highlightedSentences.join('')); // to prevent XSS attacks (smuggled into translation)
    },

    async toggleTranslation(message) {
      console.debug('Toggle translation hit');
      this.showTranslation = !this.showTranslation;
      console.debug(this.showTranslation);
      if (!this.showTranslation) {
        return;
      }
      if (this.translation) {
        console.log('Translation already exists');
        return;
      }
      await this.getTranslation(message, this.userNative);
    },

    async getTranslation(message, targetLanguage) {
      console.log('message: ' + message);
      console.log('targetLanguage: ' + targetLanguage);
      this.translationOption = 'system_response_translation';
      // this.translation = 'TODO: trigger translation.';
      const url = '/translations/';
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          original_message: message,
          target_language: targetLanguage,
          translation_option: this.translationOption,
        },
        this.appendToTranslation,
      );
      this.showTranslation = true;
    },

    async appendToTranslation(chunk) {
      let chunk_ = JSON.parse(chunk);
      // console.debug(chunk_)
      if (chunk_.type === 'message') {
        this.translation += chunk_['content'];
      } else if (chunk_.type === 'finished') {
        console.debug('Finished translation');
      }
    },
  },
};
</script>

<template>
  <button
    type="button"
    @click="toggleTranslation(this.message, this.languagePair)"
    class="text-xs text-left py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none transition-all text-sm dark:hover:bg-neutral-800 dark:hover:text-gray-400 dark:hover:border-gray-900"
  >
    <div v-if="!this.showTranslation">
      <span translate="no" class="material-symbols-outlined notranslate"> language </span>
    </div>
    <div v-if="this.showTranslation">
      <div v-html="this.highlightedDirectTranslation"></div>
    </div>
    <div v-else class="space-x-4">Übersetzen</div>
    <!-- <p>Selected sentences: {{ markedSentencesOrigin }}</p> -->
  </button>
</template>

<style scoped></style>
