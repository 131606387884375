/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OralExamInteractionMessage } from '../models/OralExamInteractionMessage';
import type { OralExamInteractionMessageCreate } from '../models/OralExamInteractionMessageCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OralExamInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Oral Exam Interaction Messages
   * This will only return user or oral_exam messages, not system messages.
   *
   * :param session:
   * :param user:
   * :param oral_exam_interaction_id:
   * :return:
   * @param oralExamInteractionId
   * @returns OralExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public listOralExamInteractionMessages(
    oralExamInteractionId: string,
  ): CancelablePromise<Array<OralExamInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/oral-exam-interactions/{oral_exam_interaction_id}',
      path: {
        oral_exam_interaction_id: oralExamInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Oral Exam Interaction Message
   * @param oralExamInteractionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createOralExamInteractionMessage(
    oralExamInteractionId: string,
    requestBody: OralExamInteractionMessageCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/oral-exam-interactions/{oral_exam_interaction_id}',
      path: {
        oral_exam_interaction_id: oralExamInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
