/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SectionInteractionUpdate } from '../models/SectionInteractionUpdate';
import type { UpdateSectionInteractionResponse } from '../models/UpdateSectionInteractionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SectionInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update Section Interaction
   * @param sectionInteractionId
   * @param requestBody
   * @param forceOverwriteCompletedAt
   * @returns UpdateSectionInteractionResponse Successful Response
   * @throws ApiError
   */
  public updateSectionInteraction(
    sectionInteractionId: string,
    requestBody: SectionInteractionUpdate,
    forceOverwriteCompletedAt: boolean = false,
  ): CancelablePromise<UpdateSectionInteractionResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/section-interactions/{section_interaction_id}',
      path: {
        section_interaction_id: sectionInteractionId,
      },
      query: {
        force_overwrite_completed_at: forceOverwriteCompletedAt,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
