<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const alertStore = useAlertStore();

const fetchFinished = ref(false);
const uploadInProgress = ref(false);

const approved = ref(false);
const isDragging = ref(false);

const allowedMediaTypes = ref([]);
const allowedMediaFormats = ref([]);
const allowedMediaSizes = ref([]);

const emit = defineEmits(['uploadedItem']);

const props = defineProps({
  sectionId: {
    type: String,
    required: true,
  },
});

const hashtagOverlayId = computed(() => `#${props.overlayId}`);

onMounted(async () => {
  let typesAndFormats = await (await getApiClient()).media.getAllowedSectionMediaTypes();
  let sizes = await (await getApiClient()).media.getAllowedSectionMediaFileSizes();

  allowedMediaTypes.value = Object.keys(typesAndFormats);
  allowedMediaFormats.value = typesAndFormats;
  allowedMediaSizes.value = sizes;

  fetchFinished.value = true;

  // console.debug('mounted');
  // console.debug('ref: ' + refs[refName.value]);
  // nextTick(() => {  // nextTick: deferred to wait for next DOM update
  //   refs[refName.value].addEventListener('close.hs.overlay', (evt) => {
  //     console.debug('closing dialog')
  //     if (approved.value) {
  //       console.debug('was approved before close')
  //       resolvePromise.value(true)
  //       return
  //     }
  //     console.debug('declined or closed thru backdrop/ esc')
  //     resolvePromise.value(false)
  //     // emit('declined');
  //   })
  // });
});

async function uploadFiles(event) {
  uploadInProgress.value = true;
  console.log(event.target.files || event.dataTransfer.files);
  const files = event.target.files || event.dataTransfer.files;
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    console.log('Uploading file:', file.name);
    console.log('FormData contents:', Array.from(formData.entries()));

    await (
      await getApiClient()
    ).courseSectionItems
      .appendSectionMediaItem(props.sectionId, { file }, 'Klicken, um Beschreibung hinzuzufügen')
      .then((response) => {
        alertStore.success('Upload successful');
        emit('uploadedItem');
      })
      .catch((error) => {
        alertStore.error(error);
      })
      .finally(() => {
        uploadInProgress.value = false;
      });
    // note to self: { file } is shorthand for { 'file': file }
  }
}

const handleDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'copy';
  isDragging.value = true;
};

const handleDragLeave = (event) => {
  isDragging.value = false;
};

const handleDrop = (event) => {
  console.log('drop');
  event.preventDefault();
  isDragging.value = false;
  uploadFiles(event);
};
</script>

<template>
  <!-- Media Card -->
  <div
    class="flex flex-col overflow-hidden dark:bg-neutral-800 dark:border-neutral-700"
    :class="{ 'pointer-events-none opacity-50': uploadInProgress }"
  >
    <!-- Body -->
    <!-- Grid -->
    <!--            <div class="grid grid-cols-2 md:grid-cols-4 gap-3">-->
    <!--              &lt;!&ndash; Card &ndash;&gt;-->
    <!--              <div id="dismiss-img1" class="relative">-->
    <!--                <div class="shrink-0 relative rounded-xl overflow-hidden w-full h-44">-->
    <!--                  <img class="size-full absolute top-0 start-0 object-cover rounded-xl"-->
    <!--                       src="https://images.unsplash.com/photo-1549298916-f52d724204b4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=320&q=80"-->
    <!--                       alt="Avatar">-->
    <!--                </div>-->
    <!--                <div class="absolute top-2 end-2 z-10">-->
    <!--                  <button type="button"-->
    <!--                          class="size-7 inline-flex justify-center items-center gap-x-1.5 font-medium text-sm rounded-full border border-gray-200 bg-white text-gray-600 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"-->
    <!--                          data-hs-remove-element="#dismiss-img1">-->
    <!--                    <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"-->
    <!--                         viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"-->
    <!--                         stroke-linejoin="round">-->
    <!--                      <path d="M18 6 6 18"/>-->
    <!--                      <path d="m6 6 12 12"/>-->
    <!--                    </svg>-->
    <!--                  </button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              &lt;!&ndash; End Card &ndash;&gt;-->
    <!--            </div>-->
    <!-- End Grid -->

    <!-- Drag 'n Drop -->
    <div
      v-if="!uploadInProgress"
      class="p-4 pt-[54px] md:pt-6 lg:pt-6 h-56 flex justify-center rounded-xl overflow-hidden border-2 border-gray-200"
      :class="{
        'bg-gray-50 dark:bg-neutral-700': isDragging,
        'bg-white dark:bg-neutral-800 border-dashed': !isDragging,
      }"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div class="text-center text-gray-400 transition-all duration-300" :class="{ 'scale-105': isDragging }">
        <svg
          class="w-16 mx-auto"
          width="70"
          height="46"
          viewBox="0 0 70 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <path
            d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <rect
            x="17.0656"
            y="1.62305"
            width="35.8689"
            height="42.7541"
            rx="5"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <path
            d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z"
            stroke="currentColor"
            stroke-width="3"
            class="stroke-gray-400 dark:stroke-neutral-500"
          />
          <circle
            cx="39.5902"
            cy="14.9672"
            r="4.16393"
            stroke="currentColor"
            stroke-width="3"
            class="stroke-gray-400 dark:stroke-neutral-500"
          />
        </svg>

        <div class="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
          <span class="pe-1 font-medium text-gray-400 dark:text-neutral-200"> Medien: Drag'n'Drop/ </span>
          <label
            for="hs-pro-epdupb"
            class="relative cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
          >
            <span class="">PC durchsuchen</span>
            <input id="hs-pro-epdupb" type="file" class="sr-only" name="hs-pro-deuuf" multiple @change="uploadFiles" />
          </label>
        </div>

        <div class="gap-y-1">
          <p
            v-for="mediaType in allowedMediaTypes"
            class="mt-1 hidden md:inline-flex text-xs text-gray-400 dark:text-neutral-400 lg:whitespace-nowrap"
          >
            <span class="font-semibold text-gray-600 text-xs">{{ mediaType }}:</span>
            &nbsp;{{ allowedMediaFormats[mediaType].join(' ').replaceAll(mediaType + '/', '') }}
            <span class="hidden lg:block text-xs">&nbsp;({{ allowedMediaSizes[mediaType] / 1024 / 1024 }}MB)</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="p-4 h-56 flex justify-center items-center rounded-xl overflow-hidden border-2 border-gray-200">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <!-- End Drag 'n Drop -->

  <!-- End Body -->
  <!-- End Media Card -->
</template>
