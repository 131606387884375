<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, watch } from 'vue';
import { PairOfTermsItemType } from '@/helper/typing';
import { updateMinTextareaHeight } from '@/helper';
import { is } from '@vee-validate/rules';
import { debounce } from 'lodash';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  allowDrag: {
    type: Boolean,
    default: true,
  },
  isCorrect: {
    type: Boolean,
    default: false,
  },
  showResults: {
    type: Boolean,
    default: false,
  },
  draggedOver: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  isItem1: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['isDragging', 'inputItem', 'currentDraggingPosition', 'droppedAtPosition', 'scrollBy']);
const isDragging = ref(false);
const textareaRef = ref(null);
const cardRef = ref(null);
const initialOffset = ref({ x: 0, y: 0 });
const touchX = ref(0);
const touchY = ref(0);
const scrollInterval = ref<ReturnType<typeof setInterval> | null>(null);

const signalCurrentPosition = () => {
  emit('currentDraggingPosition', { x: touchX.value, y: touchY.value });
};

const debounceSignalCurrentPosition = debounce(signalCurrentPosition, 50);

const signalStartDragging = () => {
  cardRef.value.style.backdropBrightness = '0.5';
  if (isDragging.value) {
    return;
  }
  isDragging.value = true;
  emit('isDragging', isDragging.value, props.item.id);
};

const signalEndDragging = () => {
  isDragging.value = false;
  emit('isDragging', isDragging.value, null);
};

const handleTouchStart = (event: Event) => {
  const touch = event.touches[0];
  const rect = cardRef.value.getBoundingClientRect();
  initialOffset.value.x = touch.clientX - rect.left;
  initialOffset.value.y = touch.clientY - rect.top;

  signalStartDragging();
};

const handleTouchEnd = (event: Event) => {
  isDragging.value = false;
  stopAutoScroll();
  // this also signal end of dropping. Do not send signal drop end before sending drop position as this would reset ID
  // of dragged item in parent (not necessary at all, but in any case send position first)
  emit('droppedAtPosition', { x: touchX.value, y: touchY.value });
  cardRef.value.style.position = 'static';
};

const handleTouchMove = (event: Event) => {
  // Prevent the default scroll behavior; needs @touchmove instead of v-touch:drag for access to event
  event.preventDefault();

  // Get the current touch position
  const touch = event.touches[0];
  touchX.value = touch.clientX;
  touchY.value = touch.clientY;

  // Trigger auto-scroll if near the top or bottom of the viewport
  autoScroll();

  // Set the element's position to follow the touch point directly
  cardRef.value.style.position = 'fixed';
  cardRef.value.style.left = `${touchX.value - initialOffset.value.x}px`;
  cardRef.value.style.top = `${touchY.value - initialOffset.value.y}px`;

  debounceSignalCurrentPosition();
};

// Function to handle auto-scrolling when the user drags near screen edges
const autoScroll = () => {
  // If close to the top, scroll up
  if (touchY.value < 170) {
    startAutoScroll(-5); // Scroll up by 5px
  }
  // If close to the bottom, scroll down
  else if (touchY.value > window.innerHeight - 70) {
    startAutoScroll(5); // Scroll down by 5px
  } else {
    stopAutoScroll(); // Stop scrolling if in the middle area
  }
};

// Starts the auto-scroll at the specified speed (positive for down, negative for up)
const startAutoScroll = (distance: number) => {
  if (scrollInterval.value) return; // Prevent multiple intervals
  scrollInterval.value = setInterval(() => {
    emit('scrollBy', distance);
  }, 20);
};

// Stops the auto-scrolling
const stopAutoScroll = () => {
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
};

watch(
  () => props.isEditing,
  () => {
    if (props.isEditing) {
      updateMinTextareaHeight(textareaRef.value);
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (props.isEditing) {
    updateMinTextareaHeight(textareaRef.value);
  }
});
</script>

<template>
  <!-- Card -->
  <a
    ref="cardRef"
    class="group z-10 w-full flex flex-col border shadow-sm rounded-xl hover:shadow-md focus:outline-none focus:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800"
    :class="{
      'bg-teal-50 dark:bg-teal-900 border-teal-500 text-teal-500 dark:border-teal-500':
        props.isCorrect && props.showResults,
      'bg-red-50 dark:bg-red-900 border-red-500 text-red-500 dark:border-red-500':
        !props.isCorrect && props.showResults,
      'text-gray-800 hover:text-gray-500': !props.showResults,
      'cursor-move': !isEditing || (props.allowDrag && !props.showResults) || (props.showResults && !props.isCorrect),
      'bg-white ': isEditing || (!isDragging && !props.draggedOver && !props.showResults),
      'bg-gray-100 dark:bg-neutral-800': isDragging || props.draggedOver,
    }"
    :style="{
      width: props.width + 'px',
      opacity: isDragging ? 0.4 : 1,
    }"
    :draggable="(props.allowDrag && !props.showResults) || (props.showResults && !props.isCorrect)"
    @drag.prevent="signalStartDragging"
    @dragend.prevent="signalEndDragging"
    v-touch:press="handleTouchStart"
    v-touch:release="handleTouchEnd"
    @touchmove="handleTouchMove"
  >
    <div v-if="!!props.item" class="py-1 md:py-4 px-1 md:px-2 flex h-fit" :style="{ width: props.width + 'px' }">
      <div class="flex gap-x-5">
        <span
          v-show="!isEditing"
          translate="no"
          class="material-symbols-outlined notranslate md:pl-2 select-none text-lg md:text-xl -mr-4 md:mr-0"
        >
          {{
            props.showResults && props.isCorrect
              ? 'check_circle'
              : props.showResults && !props.isCorrect
              ? 'dangerous'
              : 'drag_pan'
          }}
        </span>

        <!-- TODO images and audio -->
        <div
          class="flex max-w-full items-center"
          v-if="props.item.pair_of_terms_item_type === PairOfTermsItemType.TEXT"
        >
          <span
            v-show="!isEditing"
            class="select-none text-xs md:text-sm fallback-break"
            :style="{ whiteSpace: 'normal' }"
            >{{ props.item.content }}</span
          >
          <textarea
            ref="textareaRef"
            v-show="isEditing"
            type="text"
            class="resize-none p-2 border border-gray-200 rounded-md dark:bg-neutral-800 dark:border-neutral-700"
            :value="props.item.content"
            :placeholder="props.isItem1 ? 'z.B. ein Begriff' : 'z.B. seine Erklärung'"
            :style="{ width: props.width - 16 + 'px' }"
            @input="
              async (event) => {
                await updateMinTextareaHeight(event.target);
                $emit('inputItem', {
                  id: props.item.id,
                  pair_of_terms_item_type: PairOfTermsItemType.TEXT,
                  content: event.target.value,
                });
              }
            "
            :rows="1"
          />
        </div>
      </div>
    </div>
  </a>
  <!-- End Card -->
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
