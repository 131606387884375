<!--
TODO: "AZ" => User initals
TODO: Fetch text from DB
TODO: Align patient messages + icon right (as in typical messenger)
-->

<script setup>
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore } from '@/stores';
import SystemMessageFooter from '@/components/SystemMessageFooter.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import AudioRecorderSST from '@/components/inputs/AudioRecorderSTT.vue';

const authStore = useAuthStore();

const { message, conversation } = defineProps(['message', 'conversation']);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter } from '@/helper';

const { selectedSentenceIndcs, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectableDiv = ref(null);

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  if (!selectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.content['processed_model_output'], characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <li class="max-w-2xl ml-auto flex justify-end gap-x-2 sm:gap-x-4">
    <div>
      <div class="grow text-end space-y-3">
        <!-- Content -->
        <div class="inline-block bg-blue-600 rounded-lg p-4 shadow-sm">
          <p class="text-sm text-white" @mouseup="onSelectedText" ref="selectableDiv">
            {{ message.content['user_message'] }}
          </p>
        </div>
      </div>
      <!-- End Content -->

      <!-- Button Group -->
      <SystemMessageFooter
        :message="message"
        message-type="SAY-USER"
        :conversation="conversation"
        :hideEdit="true"
        :markedSentenceIndcs="selectedSentenceIndcs"
      />

      <!-- End Button Group -->
    </div>

    <!-- Icon -->
    <span
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage
          :image="authStore.userProfileImageSmall"
          :initials="authStore.userInitials"
          :showIngameLevel="true"
        />
      </span>
    </span>
    <!-- End of icon -->
  </li>
</template>

<style scoped></style>
