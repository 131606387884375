<script setup lang="ts">
import { onMounted, ref } from 'vue';
import IngameProgressBadge from '@/views/home/IngameProgressBadge.vue';

const props = defineProps({
  image: {
    type: String,
    default: null,
  },
  firstName: {
    type: String,
    default: null,
  },
  lastName: {
    type: String,
    default: null,
  },
  initials: {
    type: String,
    default: null,
  },
  cacheImage: {
    type: Boolean,
    default: true,
  },
  showInitials: {
    type: Boolean,
    default: true,
  },
  showIngameLevel: {
    type: Boolean,
    default: false,
  },
  includeIngameProgressCardAsTooltip: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: '2.375rem',
  },
});

const initials = ref('');

onMounted(() => {
  if (!props.showInitials) {
    initials.value = '';
  }
  if (props.initials) {
    initials.value = props.initials;
  } else {
    if (props.firstName && props.firstName.length > 0) {
      initials.value += props.firstName[0].toUpperCase();
    }
    if (props.lastName && props.lastName.length > 0) {
      initials.value += props.lastName[0].toUpperCase();
    }
  }
});
</script>

<template>
  <span
    class="relative"
    :class="'inline-flex items-center justify-center h-[' + size + '] w-[' + size + '] rounded-full bg-gray-600'"
  >
    <!-- set the user's profile image if image is given, i.e. not null. Otherwise, just use userInitials if given -->
    <!-- h-[2.375rem] w-[2.375rem] -->
    <div v-if="props.image">
      <img
        :class="'inline-block h-[' + size + '] w-[' + size + '] rounded-full ring-2 ring-white dark:ring-gray-800'"
        :src="props.image"
        alt="profile image"
      />
    </div>
    <div v-else-if="initials">
      <span class="text-sm font-medium text-white leading-none">{{ initials }}</span>
    </div>
    <div v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        class="bi bi-person fill-white"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
        />
      </svg>
    </div>
    <div class="absolute top-0 right-0" v-if="showIngameLevel">
      <IngameProgressBadge :includeIngameProgressCardAsTooltip="includeIngameProgressCardAsTooltip" />
    </div>
  </span>
</template>
