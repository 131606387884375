<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref, watch, Ref, onBeforeUnmount, reactive } from 'vue';
import { useAlertStore, useAuthStore, useCaseStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumpElement from '@/components/BreadcrumpElement.vue';
import EditCourseSection from '@/views/courses/EditCourseSection.vue';
import EditCourseTitleAndDescription from '@/views/courses/EditCourseTitleAndDescription.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
import { DEBUG } from '@/helper/debugging';
import { debounce } from 'lodash';
import CourseSection from '@/views/courses/CourseSection.vue';

const { t } = useI18n();

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const courseStore = useCourseStore();
const authStore = useAuthStore();
const { currentCourse, currentChapter, currentChapterSortedSections } = storeToRefs(courseStore);
const { user, userId, isAdmin: userIsAdmin } = storeToRefs(authStore);
const alertStore = useAlertStore();
const isLoading = ref(false);
const scrollContainer = ref();
const confirmDiscardChangesModal = ref(null);
const confirmSectionDeleteModal = ref(null);
const innerHeader = ref(null);

const editCourseTitleAndDescription = ref();
const editCourseTitleAndDescriptionUnsavedChangesCounter = ref(0);
const editCourseSections = ref<Array<any>>([]);

const scrollspyElements = ref([]);
const activeScrollspyElement = ref(0);
const callbackQueue = ref<(() => Promise<void>)[]>([]);

const chapterId = ref('');
const aboutToDeleteSection = ref(null as number | null);
const keepMounted = ref(true);
const fullWidth = ref(1024);

const unsavedChangesTextItemChangesBySection = reactive({});
const unsavedChangesTitleBySection = reactive({});
const sectionIndicesWithUnsavedChangesLearningObjectives = reactive([]);

const unsavedChangesTitleAndDescription = computed(() => {
  return editCourseTitleAndDescriptionUnsavedChangesCounter.value > 0;
});

const unsavedChangesLearningObjectives = computed(() => {
  return sectionIndicesWithUnsavedChangesLearningObjectives.length > 0;
});

const unsavedChangesTextItems = computed(() => {
  return Object.keys(unsavedChangesTextItemChangesBySection).length > 0;
});

const unsavedChangesSectionTitles = computed(() => {
  return Object.keys(unsavedChangesTitleBySection).length > 0;
});

const unsavedChanges = computed(() => {
  return (
    unsavedChangesTitleAndDescription.value ||
    unsavedChangesSectionTitles.value ||
    unsavedChangesLearningObjectives.value ||
    unsavedChangesTextItems.value
  );
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  fullWidth.value = Math.round(screenWidth * 0.75);
};

const debounceGetWidth = debounce(getWidth, 100);

onBeforeMount(async () => {
  await router.isReady();
  chapterId.value = router.currentRoute.value.params.chapterId;
  console.log('Premount for chapter: #' + chapterId.value + '#');

  setTimeout(async () => {
    await HSScrollspy.autoInit();
  }, 200);

  await courseStore.settingCoursePromise;
  await courseStore.setCurrentChapter(chapterId.value);

  // check user permissions
  if (!isCourseOwnerOrEditorOrAdmin.value && !isChapterOwnerOrAdmin.value) {
    alertStore.error('status.error.noPermission');
    await router.push('/home');
    return;
  }
});

function mountingHelper(resetChangesCounter: boolean = false) {
  if (!storeLoaded.value) {
    return;
  }
  scrollspyElements.value = document.querySelectorAll('[data-hs-scrollspy-group]');
  // scrollspyElementActive.value = Array(scrollspyElements.value.length).fill(false);
  activeScrollspyElement.value = 0;
  // console.log('scrollspyElements', scrollspyElements.value);

  editCourseSections.value = currentChapterSortedSections.value.map(() => ref(null));
  if (resetChangesCounter) editCourseTitleAndDescriptionUnsavedChangesCounter.value = 0;
  console.log('editCourseSections', editCourseSections.value);

  isLoading.value = false;
}

onMounted(async () => {
  isLoading.value = true;

  await nextTick(async () => {
    mountingHelper(true);
  });

  watch(
    () => storeLoaded.value,
    async (newVal: boolean) => {
      if (newVal) {
        mountingHelper(false);
      }
    },
    { immediate: true },
  );

  // watcher: if changes to sections completed, trigger scroll
  watch(
    () => currentChapterSortedSections.value.length,
    async (newLength: number, oldLength: number) => {
      await mountingHelper(false);
      console.debug('section number changed from ' + oldLength + ' to ' + newLength);

      for (const func of callbackQueue.value) {
        console.log('executing callback');
        await func();
      }
      callbackQueue.value = [];
    },
  );

  getWidth();
  window.addEventListener('resize', debounceGetWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

const scrollspyId = () => {
  return '#scrollspy-' + pageId();
};

const headingToId = (heading: string) => {
  // Replace special characters with their corresponding CSS escaped characters
  let selector = heading.replace(/[^a-zA-Z0-9-]/g, (match) => {
    return match.charCodeAt(0).toString(16).toUpperCase() + ' ';
  });

  selector = selector.trim();
  selector = selector.replace(/\s+/g, '-');
  selector = 'section-' + selector; // Add a prefix to avoid starting with a number

  return selector;
};

async function scrollToBottom() {
  console.log('scrolls: ' + document.body.scrollHeight);
  await nextTick();
  setTimeout(() => {
    scrollContainer.value.scrollTo({
      top: scrollContainer.value.scrollHeight,
      behavior: 'smooth',
    });
  }, 1000);
}

async function onNewSection() {
  console.debug('Adding new section');
  isLoading.value = true;

  callbackQueue.value.push(scrollToBottom);
  await courseStore.appendEmptySection();
  await mountingHelper(false);
  isLoading.value = false;
}

const onDeleteSection = async (sectionId: number) => {
  console.debug('Deleting section: ' + sectionId);
  aboutToDeleteSection.value = sectionId;

  await confirmSectionDeleteModal.value.promptUserConformation();
};

const deleteSection = async () => {
  isLoading.value = true;

  await courseStore.deleteSection(aboutToDeleteSection.value);
  await mountingHelper(false);

  aboutToDeleteSection.value = null;
  isLoading.value = false;
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

function isActive(index: number) {
  // Check if the element has the hs-scrollspy-active class
  // console.log('isActive:', scrollspyElements.value[index] ? scrollspyElements.value[index].classList : 'no element');
  // if (scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active')) {
  //   console.log('Active scrollspy element: ' + index);
  // }
  return scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active');
}

function monitorScroll() {
  for (let i = 0; i < scrollspyElements.value.length; i++) {
    if (isActive(i)) {
      activeScrollspyElement.value = i;
    }
  }
}

async function onSaveChanges() {
  if (!unsavedChanges.value) {
    return;
  }
  if (!currentChapter.value) {
    alertStore.error('Internal error: Chapter not set.');
    return;
  }
  isLoading.value = true;

  try {
    console.log('num changes chapter:', editCourseTitleAndDescriptionUnsavedChangesCounter.value);
    if (unsavedChangesTitleAndDescription.value) {
      let id = currentChapter.value.id;
      let title = editCourseTitleAndDescription.value.titleEditor.getRawTextContent();
      let subtitle = editCourseTitleAndDescription.value.subtitleEditor.getRawTextContent();
      console.log('Would save: ' + title + ' and ' + subtitle + ' for id ' + id);
      await courseStore.updateChapterTitleAndDescription(id, title, subtitle);
      editCourseTitleAndDescription.value.titleEditor.resetEmitState();
      editCourseTitleAndDescription.value.subtitleEditor.resetEmitState();
      editCourseTitleAndDescriptionUnsavedChangesCounter.value = 0;
    }

    if (unsavedChangesTextItems.value) {
      for (const [sectionIndex, textItemChanges] of Object.entries(unsavedChangesTextItemChangesBySection)) {
        let textContentUpdates = Object.entries(textItemChanges).map(([contentItemId, newHtml]) => {
          return {
            contentItemId: contentItemId,
            content: newHtml,
          };
        });
        await courseStore.updateSection(currentChapterSortedSections.value[sectionIndex].id, {
          textContents: textContentUpdates,
        });
        await nextTick();
        await editCourseSections.value[sectionIndex].value[0].resetUnsavedChangesTextItems();
        delete unsavedChangesTextItemChangesBySection[sectionIndex];
      }
    }

    if (unsavedChangesSectionTitles.value) {
      for (let [sectionIndex, title] of Object.entries(unsavedChangesTitleBySection)) {
        await courseStore.updateSection(currentChapterSortedSections.value[sectionIndex].id, {
          title: title,
        });
        await nextTick();
        await editCourseSections.value[sectionIndex].value[0].resetUnsavedChangesTitle();
        delete unsavedChangesTitleBySection[sectionIndex];
      }
    }

    if (unsavedChangesLearningObjectives.value) {
      for (const sectionIndex of sectionIndicesWithUnsavedChangesLearningObjectives) {
        await courseStore.updateSection(currentChapterSortedSections.value[sectionIndex].id, {
          learningObjectives: editCourseSections.value[sectionIndex].value[0].getLearningObjectives(),
        });
        await nextTick();
        await editCourseSections.value[sectionIndex].value[0].resetUnsavedChangesLearningObjective();
        sectionIndicesWithUnsavedChangesLearningObjectives.splice(
          sectionIndicesWithUnsavedChangesLearningObjectives.indexOf(sectionIndex),
          1,
        );
      }
    }

    alertStore.success('status.success.changesSaved');
  } catch (error) {
    console.log(error);
    alertStore.error('status.error.saveChangesError');
    throw error;
  } finally {
    isLoading.value = false;
  }
}

async function remount() {
  console.log('remounting');
  keepMounted.value = false;
  await nextTick();
  keepMounted.value = true;
}

async function onDiscardChanges() {
  if (!unsavedChanges.value) {
    return;
  }
  console.debug('Discarding changes');
  await confirmDiscardChangesModal.value.promptUserConformation();
}

const discardChanges = async () => {
  isLoading.value = true;
  await nextTick();
  editCourseTitleAndDescriptionUnsavedChangesCounter.value = 0;
  console.log('Resetting...');
  console.log('...now');
  await remount();
  isLoading.value = false;
  console.log('done');
};

const isChapterOwnerOrAdmin = computed(() => {
  return currentChapter.value && (courseStore.isOwnerOfCurrentChapter(userId.value) || userIsAdmin.value);
});

const isCourseOwnerOrEditorOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

// const setListRef = (el, index) => {
//   // editCourseSections.value = editCourseSections.value.filter(ref => ref !== null);
//   if (!!el) {
//     // Add the reference to the corresponding index
//     editCourseSections.value[index] = el;
//   } else {
//     // When the element is removed (null), clear the reference
//     editCourseSections.value[index] = null;
//   }
// };
</script>

<template>
  {{ DEBUG ? editCourseSections.length : null }}
  <div
    :id="pageId()"
    class="max-h-full justify-center min-w-full min-h-full h-full flex-col overflow-auto"
    @scroll="monitorScroll()"
    ref="scrollContainer"
    v-if="storeLoaded && keepMounted"
  >
    <!-- Navigation + save button -->
    <h2
      ref="innerHeader"
      class="min-w-full sticky top-0 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-4 justify-center flex z-20"
    >
      <!-- breadcrumb + scrollspy -->
      <div class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 flex justify-between">
        <ol class="flex items-center whitespace-nowrap">
          <!-- level 1 breadcrump: all courses -->
          <BreadcrumpElement label="Kurse" to="/home" />
          <!-- level 2 breadcrump: current course -->
          <BreadcrumpElement :label="currentCourse.title" :to="'/course/' + currentCourse.id" />
          <!-- level 3 breadcump: current section with scrollspy -->
          <li class="inline-flex items-center min-w-fit w-fit text-sm z-40">
            <div class="hs-dropdown relative inline-flex min-w-fit w-fit [--placement:top-left]">
              <button
                id="hs-breadcrumb-dropdown"
                type="button"
                class="z-20 hs-dropdown-toggle py-1.5 px-2 inline-flex min-w-fit w-fit items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 shadow-sm hover:bg-gray-50 text-gray-500 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:text-blue-600"
              >
                {{ currentChapter.title }}:
                {{
                  currentChapterSortedSections[activeScrollspyElement]
                    ? currentChapterSortedSections[activeScrollspyElement].title
                    : 'No section'
                }}
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                </svg>
              </button>
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 min-w-fit w-fit hidden z-20 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-2 dark:bg-neutral-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                aria-labelledby="hs-breadcrumb-dropdown"
              >
                <div
                  class="sticky top-20 mt-1"
                  :data-hs-scrollspy="scrollspyId()"
                  :data-hs-scrollspy-scrollable-parent="hashtagedId()"
                >
                  <a
                    v-for="(section, index) in currentChapterSortedSections"
                    :key="section.index"
                    data-hs-scrollspy-group=""
                    :id="scrollspyElements[index]"
                    :href="'#' + headingToId(section.title)"
                    class="text-sm font-medium leading-6 text-neutral-700 hover:text-neutral-900 focus:outline-none hs-scrollspy-active:text-blue-600 focus:text-blue-600 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-blue-600 dark:hs-scrollspy-active:text-blue-400 active': true"
                  >
                    <p>
                      <!-- NOTE: does not work without the href! -->
                      {{ section.title }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <!-- discard/ save buttons -->
        <div class="flex justify-right gap-x-2">
          <button
            v-show="unsavedChanges"
            type="button"
            class="py-2 px-3 flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-400 text-white hover:bg-red-500 disabled:opacity-50 disabled:pointer-events-none"
            @click="onDiscardChanges"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading</span>
            </div>
            <div class="flex gap-x-3" v-else>{{ $t('message.discardChanges') }}</div>
          </button>
          <button
            v-show="unsavedChanges"
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="onSaveChanges"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">{{ $t('message.loading') }}</span>
            </div>
            <div class="flex gap-x-3" v-else>{{ $t('message.saveChanges') }}</div>
          </button>
        </div>
      </div>
    </h2>
    <!-- end of header -->

    <!-- sections -->
    <div class="py-4 mx-auto" :style="{ width: fullWidth + 'px' }">
      <EditCourseTitleAndDescription
        ref="editCourseTitleAndDescription"
        :chapter="currentChapter"
        @unsavedChanges="editCourseTitleAndDescriptionUnsavedChangesCounter += 1"
        @changesCleared="editCourseTitleAndDescriptionUnsavedChangesCounter -= 1"
        :allow-editing="true"
      />
    </div>
    <div id="scrollspy-2" class="space-y-4 justify-center">
      <div
        class="mx-auto flex-col justify-center"
        :style="{ width: fullWidth + 'px' }"
        :class="{
          'border-2 border-red-600': aboutToDeleteSection === section.id,
          'border-none': aboutToDeleteSection !== section.id,
        }"
        v-for="(section, index) in currentChapterSortedSections"
        :key="section.index"
      >
        <EditCourseSection
          :ref="editCourseSections[index]"
          :full-width="fullWidth"
          v-if="section.id !== null"
          :index="index"
          :chapterId="chapterId"
          :chapterIndex="currentChapter.index"
          :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
          @unsavedChangesLearningObjectives="sectionIndicesWithUnsavedChangesLearningObjectives.push(index)"
          @unsavedChangesTitle="
            (newRawText) => {
              unsavedChangesTitleBySection[index] = newRawText;
            }
          "
          @changesTitleCleared="delete unsavedChangesTitleBySection[index]"
          @unsavedChangesText="
            (contentItemId, newHtml) => {
              if (!unsavedChangesTextItemChangesBySection[index]) {
                unsavedChangesTextItemChangesBySection[index] = {};
              }
              unsavedChangesTextItemChangesBySection[index][contentItemId] = newHtml;
            }
          "
          @changesTextCleared="
            (contentItemId) => {
              delete unsavedChangesTextItemChangesBySection[index][contentItemId];
              // If no unsaved changes remain in the section, remove the section entry
              if (Object.keys(unsavedChangesTextItemChangesBySection[index]).length === 0) {
                delete unsavedChangesTextItemChangesBySection[index];
              }
            }
          "
          @onDeleteSection="onDeleteSection(section.id)"
        />
      </div>

      <!--      <draggable-->
      <!--        v-if="sortedSections.length > 0"-->
      <!--        v-model="sortedSections"-->
      <!--        item-key="index"-->
      <!--        tag="div"-->
      <!--        @end="onMoveSection"-->
      <!--        class="mx-auto flex-col justify-center max-w-6xl"-->
      <!--        :disabled="false"-->
      <!--        :scroll-sensitivity="200"-->
      <!--        :force-fallback="true"-->
      <!--      >-->
      <!--        <template #item="{ element: section, index }">-->
      <!--          <div>-->
      <!--            <EditCourseSection-->
      <!--              :ref="(el) => setListRef(el, index)"-->
      <!--              :index="index"-->
      <!--              v-if="section.id !== null"-->
      <!--              :section="section"-->
      <!--              @unsavedChanges="editCourseSectionsUnsavedChangesCounter[index] += 1"-->
      <!--              @changesCleared="editCourseSectionsUnsavedChangesCounter[index] -= 1"-->
      <!--              @onDeleteSection="onDeleteSection(section.id)"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </draggable>-->
      <div class="mx-auto flex-col justify-center w-full">
        <div
          class="mx-auto px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
          :style="{ width: fullWidth + 'px' }"
        >
          <button
            type="button"
            class="rounded-lg border border-transparent min-w-full py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="onNewSection"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">{{ $t('message.loading') }}</span>
            </div>
            <div class="flex min-w-full gap-x-3 items-center justify-center" v-else>
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
              {{ $t('message.addParagraph') }}
            </div>
          </button>
        </div>
      </div>

      <UserConfirmationModal
        ref="confirmDiscardChangesModal"
        prompt_message=""
        :approve_message="$t('message.yes')"
        :discard_message="$t('message.no')"
        overlayId="confirmDiscardChangesModal"
        approve_color="bg-red-500 hover:bg-red-600"
        @approved="discardChanges"
        @declined=""
      >
        {{ $t('message.discardChangesConfirmation') }}
      </UserConfirmationModal>
      <UserConfirmationModal
        ref="confirmSectionDeleteModal"
        prompt_message=""
        :approve_message="$t('message.delete')"
        :discard_message="$t('message.back')"
        overlayId="confirmSectionDeleteModal"
        approve_color="bg-red-500 hover:bg-red-600"
        @approved="deleteSection"
        @declined="aboutToDeleteSection = null"
      >
        {{ $t('message.deleteSectionConfirmation') }}
      </UserConfirmationModal>
    </div>
  </div>
  <div v-else>
    <div class="pt-20 flex justify-center items-center h-full">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
</template>

<style scoped></style>
