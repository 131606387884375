/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseInteractionDetailed } from '../models/CaseInteractionDetailed';
import type { GrammarEvaluation } from '../models/GrammarEvaluation';
import type { LearningObjectiveEvaluation } from '../models/LearningObjectiveEvaluation';
import type { ObserverInteractionMessage } from '../models/ObserverInteractionMessage';
import type { PatientInteractionMessage } from '../models/PatientInteractionMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CaseInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Delete Case Interaction
   * @param caseInteractionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCaseInteraction(caseInteractionId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/case-interactions/{case_interaction_id}',
      path: {
        case_interaction_id: caseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Case Interaction
   * Return the case interaction with the given id.
   *
   * :param database_client:
   * :param user:
   * :param case_interaction_id:
   * :param full_load:
   * :param eager_load_case:
   * :param eager_load_interactions:
   * :return:
   * @param caseInteractionId
   * @param fullLoad
   * @param eagerLoadCase
   * @param eagerLoadInteractions
   * @returns CaseInteractionDetailed Successful Response
   * @throws ApiError
   */
  public getCaseInteraction(
    caseInteractionId: string,
    fullLoad?: boolean | null,
    eagerLoadCase?: boolean | null,
    eagerLoadInteractions?: boolean | null,
  ): CancelablePromise<CaseInteractionDetailed> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/case-interactions/{case_interaction_id}',
      path: {
        case_interaction_id: caseInteractionId,
      },
      query: {
        full_load: fullLoad,
        eager_load_case: eagerLoadCase,
        eager_load_interactions: eagerLoadInteractions,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Patient Messages And Scene Descriptions
   * List all patient interaction messages and all scene descriptions,
   * ordered by creation date.
   *
   * :param database_client:
   * :param user:
   * :param case_interaction_id:
   * :return:
   * @param caseInteractionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public listPatientMessagesAndSceneDescriptions(
    caseInteractionId: string,
  ): CancelablePromise<Array<PatientInteractionMessage | ObserverInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/case-interactions/{case_interaction_id}/messages',
      path: {
        case_interaction_id: caseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Set Current Task Interaction Index
   * @param caseInteractionId
   * @param currentTaskInteractionIndex
   * @returns any Successful Response
   * @throws ApiError
   */
  public setCurrentTaskInteractionIndex(
    caseInteractionId: string,
    currentTaskInteractionIndex: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/case-interactions/{case_interaction_id}/current-task-interaction-index',
      path: {
        case_interaction_id: caseInteractionId,
      },
      query: {
        current_task_interaction_index: currentTaskInteractionIndex,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Select Person For Interaction
   * @param caseInteractionId
   * @param personTablename
   * @param personIndex
   * @returns any Successful Response
   * @throws ApiError
   */
  public selectPersonForInteraction(
    caseInteractionId: string,
    personTablename: string,
    personIndex?: number | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/case-interactions/{case_interaction_id}/select-person',
      path: {
        case_interaction_id: caseInteractionId,
      },
      query: {
        person_tablename: personTablename,
        person_index: personIndex,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Learning Objectives Of Case Interaction
   * @param caseInteractionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public evaluateLearningObjectivesOfCaseInteraction(
    caseInteractionId: string,
  ): CancelablePromise<Array<LearningObjectiveEvaluation> | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/case-interactions/{case_interaction_id}/evaluate/learning-objectives',
      path: {
        case_interaction_id: caseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Grammar Of Case Interaction
   * @param caseInteractionId
   * @returns GrammarEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateGrammarOfCaseInteraction(caseInteractionId: string): CancelablePromise<Array<GrammarEvaluation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/case-interactions/{case_interaction_id}/evaluate/grammar',
      path: {
        case_interaction_id: caseInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
