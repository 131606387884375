<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive, watch, defineProps, computed, nextTick, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useOrganizationStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import UserListItem from '@/components/dashboard/UserListItem.vue';
import _ from 'lodash';
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { debounce } from 'lodash';
import ListUsersTableHead from '@/views/dashboard/ListUsersTableHead.vue';
import VocabList from '@/components/didactics/VocabList.vue';
import VocabDashboardStatsHeader from '@/components/didactics/VocabDashboardStatsHeader.vue';
import { HSTabs } from 'preline';
import { storeToRefs } from 'pinia';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const router = useRouter();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const initialFetchCompleted = ref(false);

const showSearchbar = ref(false);
const vocabDashboardStatsHeader = ref(null);
const takeTestButtonContainer = ref(null);
const tabularNav = ref(null);
const takeTestButtonContainerHeight = ref(0);
const tabularNavHeight = ref(0);
const viewingList = ref(0);
const viewingListRefs = ref([]);
const vocabListAll = ref(null);
const practiceMode = ref(false);
const fetchComplete = ref(false);
const vocabListContainer = ref(null);
const vocabListContainerWidth = ref(1024);
const vocabListNavRefs = ref([]);

const { vocabLists } = storeToRefs(authStore);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  startInPracticeMode: {
    type: Boolean,
    default: false,
  },
  hideTabularNav: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => initialFetchCompleted.value,
  async (value) => {
    if (value) {
      await nextTick();
      adjustWidth();
      adjustHeights();
    }
  },
);

const computedVocabStatsHeaderHeight = computed(() => {
  let height = !!vocabDashboardStatsHeader.value
    ? Math.max(
        0,
        vocabDashboardStatsHeader.value.fullHeight -
          (viewingListRefs.value[viewingList.value].value?.[0]?.currentYScroll || 0),
      )
    : null;
  if (height <= 4 && height != null) height = 0;
  return height;
});

const computedVocabStatsHeaderHeightStyle = computed(() => {
  return computedVocabStatsHeaderHeight.value != null
    ? `${computedVocabStatsHeaderHeight.value + takeTestButtonContainerHeight.value}px`
    : 'auto';
});

const computedVocabStatsOpacity = computed(() => {
  return computedVocabStatsHeaderHeight.value != null
    ? computedVocabStatsHeaderHeight.value / vocabDashboardStatsHeader.value.fullHeight
    : 1.0;
});

const addedHeaderHeights = computed(() => {
  return props.outerHeaderHeight + computedVocabStatsHeaderHeight.value + takeTestButtonContainerHeight.value;
});

const adjustHeights = () => {
  if (takeTestButtonContainer.value) {
    takeTestButtonContainerHeight.value = takeTestButtonContainer.value?.offsetHeight;
  }
  if (tabularNav.value) {
    tabularNavHeight.value = tabularNav.value?.offsetHeight;
  }
};

const adjustWidth = () => {
  vocabListContainerWidth.value = window.innerWidth - 16;
};

const debouncedAdjustHeight = debounce(adjustHeights, 500);
const debouncedAdjustWidth = debounce(adjustWidth, 500);

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

const computedStyleMainCard = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${addedHeaderHeights.value}px - 32px )`,
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
  window.removeEventListener('resize', debouncedAdjustWidth);
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await authStore.fetchUserVocabLists();

  initialFetchCompleted.value = true;
  viewingListRefs.value = vocabLists.value.map(() => ref(null));
  isDraggingFrom.value = new Array(vocabLists.value.length).fill(false);
  isDraggingTo.value = new Array(vocabLists.value.length).fill(false);

  await new Promise((resolve) => setTimeout(resolve, 200)); // wait for App.vue's HSStaticMethods.autoInit() to finish
  HSTabs.autoInit();

  await nextTick(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    adjustHeights();
    adjustWidth();
    window.addEventListener('resize', debouncedAdjustHeight);
    window.addEventListener('resize', debouncedAdjustWidth);
  });

  practiceMode.value = !!props.startInPracticeMode;

  fetchComplete.value = true;

  vocabLists.value.forEach((list) => {
    vocabListNavRefs.value.push(ref(null));
  });
});

const isDraggingTo = ref([]);
const isDraggingFrom = ref([]);
const isDraggingItemId = ref(null);
const isDraggingFromAnywhere = computed(() => isDraggingFrom.value.some((value) => value));

const handleIsDraggingVocabItem = (newValue, index, vocabItemId) => {
  // console.log('received isDragging', newValue);
  // console.log('from index', index);
  // console.log('vocabItemId', vocabItemId);
  // console.log(isDraggingTo.value);

  // // check if any isDraggingTo is True
  // let draggingToIndex = isDraggingTo.value.findIndex((value) => value);
  // if (draggingToIndex === index) draggingToIndex = null;
  //
  // if (!newValue && !!draggingToIndex) {
  //   handleDrop(draggingToIndex);
  // }

  isDraggingFrom.value[index] = newValue;
  isDraggingItemId.value = vocabItemId;
};

const handleDragOver = (event, index) => {
  if (isDraggingFrom.value[index]) {
    return;
  }
  event.preventDefault();
  event.dataTransfer.dropEffect = 'copy';
  isDraggingTo.value[index] = true;
};

const handleDragLeave = (event, index) => {
  if (isDraggingFrom.value[index]) {
    return;
  }
  isDraggingTo.value[index] = false;
};

const handleDrop = async (index) => {
  if (isDraggingFrom.value[index]) {
    return;
  }
  console.log('dropped', isDraggingItemId.value, ' to ', index);

  let vocabListId = vocabLists.value[index].id;
  await authStore.copyVocabItemToPersonalVocabList(vocabListId, isDraggingItemId.value, false, false);
  isDraggingTo.value[index] = false;
  isDraggingFrom.value = new Array(vocabLists.value.length).fill(false);
  isDraggingItemId.value = null;
};

const handleDroppedAtPosition = (position) => {
  for (let i = 0; i < vocabListNavRefs.value.length; i++) {
    const navItemContainer = vocabListNavRefs.value[i]?.value?.[0];
    if (!navItemContainer) {
      continue;
    }

    const rect = navItemContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(i);
    }
  }
};

const numVocabLists = computed(() => vocabLists.value.length);
</script>

<template>
  <main
    :key="$route.fullPath"
    :style="computedStyleMain"
    id="content"
    class="px-0.5 md:px-2 py-2 xl:px-10 xl:py-3 min-w-full overflow-hidden"
    v-if="fetchComplete"
  >
    <div v-if="!initialFetchCompleted" class="min-w-full pt-[184px] flex items-center justify-center">
      <LoadingSpinnerLarge />
    </div>
    <div v-else class="px-0.5 md:p-2 flex-col flex">
      <div
        class="relative space-y-1 md:space-y-3 xl:space-y-3 overflow-visible"
        :style="{ height: computedVocabStatsHeaderHeightStyle }"
      >
        <div
          class="flex-shrink-0 overflow-visible"
          :style="{
            opacity: computedVocabStatsOpacity,
          }"
        >
          <VocabDashboardStatsHeader ref="vocabDashboardStatsHeader" />
        </div>

        <div
          class="absolute end-0 bottom-0 py-1 md:py-3 z-10 min-w-full flex-shrink-0 flex justify-between items-center"
        >
          <div class="flex mx-auto justify-end items-center" @click="practiceMode = !practiceMode">
            <ProgressButton :text="practiceMode ? 'Lösungen aufdecken' : 'Vokabeln üben'" />
          </div>
        </div>
      </div>

      <!--      scroll: {{ !!vocabListAll ? vocabListAll.currentYScroll : 'null' }}-->
      <!--      full header height: {{ !!vocabDashboardStatsHeader ? vocabDashboardStatsHeader.fullHeight : 'null' }}-->
      <!--      target header height: {{ computedVocabStatsHeaderHeightStyle }}-->
      <!--      opactiy: {{ computedVocabStatsOpacity }}-->

      <!-- Table Card -->
      <div
        class="px-1 md:px-5 mt-2 z-10 overflow-hidden space-y-4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
        :style="computedStyleMainCard"
      >
        <!-- Nav Tab -->
        <nav
          v-show="!props.hideTabularNav"
          ref="tabularNav"
          class="relative overflow-x-auto min-h-fit pt-0.5 md:pt-5 flex space-x-1 after:absolute after:bottom-0 after:inset-x-0 after:border-b-2 after:border-gray-200 dark:after:border-neutral-700"
          aria-orientation="horizontal"
        >
          <button
            @click.prevent="showSearchbar = !showSearchbar"
            class="flex items-center hover:bg-gray-100 text-gray-500 hover:text-gray-800 md:px-2.5 md:py-1.5 mb-2 rounded-lg"
          >
            <span translate="no" class="material-symbols-outlined notranslate">search</span>
            <span
              translate="no"
              class="material-symbols-outlined notranslate transition-all transform duration-300 ease-in-out"
              :class="{ 'rotate-180': showSearchbar }"
              >expand_more</span
            >
          </button>
          <button
            type="button"
            class="active px-1 md:px-2.5 py-1.5 mb-2 relative inline-flex text-gray-500 hover:text-gray-800 justify-center items-center gap-x-2 hover:bg-gray-100 text-xs md:text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            :class="{ 'after:bg-gray-800 dark:after:bg-neutral-400': viewingList === 0 }"
            aria-selected="false"
            @click.capture="viewingList = 0"
          >
            <span translate="no" class="material-symbols-outlined notranslate"> join </span>
            {{ vocabLists[0].name }}
          </button>
          <div
            v-for="(vocabList, index) in vocabLists.slice(1)"
            :ref="vocabListNavRefs[index + 1]"
            :key="`button-${index + 1}`"
            type="button"
            class="cursor-pointer px-2.5 py-1.5 mb-2 relative flex-col flex justify-center items-center gap-2 hover:bg-gray-100 text-xs md:text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            aria-selected="false"
            :class="{
              'after:bg-gray-800 dark:after:bg-neutral-400': viewingList === index + 1,
              'bg-gray-200 dark:bg-neutral-700': isDraggingTo[index + 1] && !isDraggingFrom[index + 1],
              'bg-white dark:bg-neutral-800 border-dashed': !isDraggingTo[index + 1] && !isDraggingFrom[index + 1],
              'text-blue-600': isDraggingFromAnywhere && !isDraggingFrom[index + 1],
              'hs-tab-active:text-gray-800 text-gray-500 hover:text-gray-800 dark:hs-tab-active:text-neutral-200 dark:hover:text-neutral-300':
                !(isDraggingFromAnywhere && !isDraggingFrom[index + 1]),
            }"
            @dragover.prevent="handleDragOver($event, index + 1)"
            @dragleave.prevent="handleDragLeave($event, index + 1)"
            @drop.prevent="handleDrop(index + 1)"
            @click.capture="viewingList = index + 1"
          >
            <div class="inline-flex items-center">
              <span
                translate="no"
                class="material-symbols-outlined notranslate pr-1.5"
                :style="{ fontVariationSettings: '\'FILL\' 1' }"
                v-if="index + 1 === 1"
              >
                star
              </span>
              <span>
                <input
                  v-if="viewingList === index + 1"
                  type="text"
                  class="text-xs md:text-sm py-1 pl-1 pr-0 -mr-3 border-none rounded-lg bg-transparent"
                  v-model="vocabList.name"
                  :style="{ width: `${vocabList.name.length}ch` }"
                  @change="
                    authStore
                      .updateUserVocabList(vocabList.id, vocabList.name)
                      .then(() => {
                        alertStore.success('Vokabelliste erfolgreich umbenannt');
                      })
                      .catch((error) => {
                        alertStore.error('Fehler beim Umbenennen der Vokabelliste');
                      })
                  "
                />
                <span v-else class="flex my-1 mx-1">
                  {{ vocabLists[index + 1].name }}
                </span>
              </span>
            </div>
            <div
              v-show="isDraggingFromAnywhere && !isDraggingFrom[index + 1]"
              class="w-full h-fit md:-mt-2 md:-mb-2 md:pt-3 -pb-1 text-center border-2 border-dashed border-gray-200 dark:border-gray-700 rounded-lg"
            >
              <div translate="no" class="material-symbols-outlined notranslate relative text-base">
                content_copy
                <span
                  v-show="isDraggingFromAnywhere && !isDraggingFrom[index + 1]"
                  translate="no"
                  class="material-symbols-outlined notranslate absolute top-[-9px] md:top-[-13px] end-[-6px] text-base md:text-lg md:font-medium"
                  >add</span
                >
              </div>
            </div>
          </div>
          <div
            :key="`button-${numVocabLists + 1}`"
            type="button"
            class="cursor-pointer px-2.5 py-1.5 mb-2 relative flex-col flex justify-center items-center gap-2 hover:bg-gray-100 text-xs md:text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-0 after:z-10 after:h-0.5 after:pointer-events-none dark:text-neutral-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            aria-selected="false"
            :class="{
              'after:bg-gray-800 dark:after:bg-neutral-400': viewingList === numVocabLists + 1,
              'bg-gray-200 dark:bg-neutral-700': isDraggingTo[numVocabLists + 1] && !isDraggingFrom[numVocabLists + 1],
              'bg-white dark:bg-neutral-800 border-dashed':
                !isDraggingTo[numVocabLists + 1] && !isDraggingFrom[numVocabLists + 1],
              'text-blue-600': isDraggingFromAnywhere && !isDraggingFrom[numVocabLists + 1],
              'hs-tab-active:text-gray-800 text-gray-500 hover:text-gray-800 dark:hs-tab-active:text-neutral-200 dark:hover:text-neutral-300':
                !(isDraggingFromAnywhere && !isDraggingFrom[numVocabLists + 1]),
            }"
            @dragover.prevent="handleDragOver($event, numVocabLists + 1)"
            @dragleave.prevent="handleDragLeave($event, numVocabLists + 1)"
            @drop.prevent="handleDrop(numVocabLists + 1)"
            @click.capture="
              async () => {
                await authStore.createNewUserVocabList('Neue Vokabelliste');
                viewingListRefs = vocabLists.map(() => ref(null));
                vocabListNavRefs = vocabLists.map(() => ref(null));
                isDraggingFrom = new Array(vocabLists.length).fill(false);
                isDraggingTo = new Array(vocabLists.length).fill(false);
              }
            "
          >
            <span translate="no" class="material-symbols-outlined notranslate"> add </span>
          </div>
        </nav>
        <!-- End Nav Tab -->

        <div ref="vocabListContainer" class="min-h-full min-w-full overflow-hidden">
          <div
            v-for="(vocabList, index) in vocabLists"
            class="min-w-full overflow-hidden"
            :class="{
              'h-0': viewingList !== index,
              'min-h-full': viewingList === index,
            }"
          >
            <!-- Table Section -->
            <div
              class="overflow-x-hidden [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full h-full inline-block align-middle overflow-hidden text-xs">
                <!-- Table -->
                <VocabList
                  v-if="fetchComplete"
                  :ref="viewingListRefs[index]"
                  :show-searchbar="showSearchbar"
                  :vocab-list="vocabLists[index].vocab_items"
                  :addedHeaderHeights="addedHeaderHeights + tabularNavHeight"
                  :full-width="vocabListContainerWidth"
                  @isDragging="
                    (isDragging, vocabItemId) => {
                      handleIsDraggingVocabItem(isDragging, index, vocabItemId);
                    }
                  "
                  @droppedAtPosition="handleDroppedAtPosition"
                  :key="vocabLists[index].id + vocabLists[index].vocab_items?.length + practiceMode + $route.fullPath"
                  :hideRandomButOneColumn="practiceMode ? ['term', 'explanation'] : null"
                />
                <!-- End Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Users Table Card -->
    </div>
  </main>
</template>
