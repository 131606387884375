<script setup lang="ts">
import { ref, computed, reactive, onMounted } from 'vue';
import CaseInteractionList from '@/components/CaseInteractionList.vue';
import { router } from '@/router';
import { useAuthStore, useCaseInteractionStore, useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { CaseInteraction } from '@/apiclient';

const caseStore = useCaseStore();
const authStore = useAuthStore();
const { currentCase } = storeToRefs(caseStore);

const caseInteractions = ref([]);
const isLoading = ref(false);

async function loadCaseInteractionsOfUser(user_id: string) {
  isLoading.value = true;
  const client = await getApiClient();
  caseInteractions.value = await client.users
    .listCaseInteractionsOfUser(authStore.userId)
    .then((caseInteractions: CaseInteraction[]) => {
      return caseInteractions;
    })
    .catch((error) => {
      console.error('Error loading case interactions of user: ' + error);
      return [];
    })
    .finally(() => {
      isLoading.value = false;
    });

  console.log('caseInteractions', caseInteractions.value);
}

onMounted(async () => {
  await router.isReady();
  await loadCaseInteractionsOfUser(authStore.userId);
});
</script>

<template>
  <!--  <button-->
  <!--    class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"-->
  <!--    @click="loadCaseInteractionsOfUser(authStore.userId)">-->
  <!--    Reload-->
  <!--  </button>-->
  <div class="min-w-full min-h-full h-full flex overflow-auto">
    <CaseInteractionList :caseInteractions="caseInteractions" :isLoading="isLoading"> </CaseInteractionList>
  </div>
</template>

<style></style>
