export interface OtherCaseDetails {
  heading: string;
  specialties: string[];
  tags: string[];
}

export interface StudentDetails {
  role: string;
}

export interface PersonDetailsForm {
  first_name: string;
  last_name: string;
  sex: string;
  age: string;
  freehand_description: string;
  biography: string;
  style_of_speech: string;
  main_medical_conditions: Disease[];
  known_medical_conditions: Disease[];
  unknown_medical_conditions: Disease[];
}

export interface DiseaseInputForm {
  names: string[];
  onsets: string[];
  stages: string[];
  remarks: string[];
}

export interface Disease {
  name: string;
  stage: string;
  remark: string;
  onset: string;
}

export interface DiseaseInputProps {
  // valueChangedExternally: any;
  value: Disease[];
}

export const roleTypes = [
  { value: 'PHYSICIAN', display_name: 'Ärzt:in' },
  { value: 'NURSE', display_name: 'Pfleger:in' },
  { value: 'PATIENT', display_name: 'Patient:in' },
  { value: 'RELATIVE', display_name: 'Angehöriger' },
  { value: 'OTHER', display_name: 'Andere' },
]; // TODO load from backend (ThirdPersonType enum + PATIENT)
