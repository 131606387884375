<script>
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import { useAlertStore, useCaseInteractionStore, useLanguageStore, usePatientInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import ProgressButton from '@/components/ProgressButton.vue';
import { VocabMode } from '@/helper';

export default {
  components: { ProgressButton },
  setup: () => {
    const patientInteractionStore = usePatientInteractionStore();
    const caseInteractionStore = useCaseInteractionStore();
    const alertStore = useAlertStore();
    const languageStore = useLanguageStore();
    const { userNativeLanguage: userNative, patientLanguage, currentPatient } = storeToRefs(patientInteractionStore);
    const { currentCaseInteractionId: caseInteractionId } = storeToRefs(caseInteractionStore);
    const vocabs = ref([]);
    const hideIndividualVocab = ref([]);
    const hideIndividualSolution = ref([]);
    const initiallyHideIndividualVocab = ref([]);
    const showingSolutions = ref(false);
    const showingFeedback = ref(false);
    const difficulty = ref(0);
    const isLoadingNewVocab = ref(false);
    const isLoadingFeedback = ref(false);
    return {
      alertStore,
      userNative,
      patientLanguage,
      caseInteractionId,
      currentPatient,
      showingSolutions,
      showingFeedback,
      difficulty,
      languageStore,
      vocabs,
      isLoadingNewVocab,
      isLoadingFeedback,
      initiallyHideIndividualVocab: initiallyHideIndividualVocab,
      hideIndividualVocab: hideIndividualVocab,
      hideIndividualSolution: hideIndividualSolution,
    };
  },
  data: () => ({
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  props: {
    overlayId: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: 'Fallbezogene Vokabeln & praktische Wendungen',
    },
    promptMessage: {
      type: String,
      default: 'Chat window goes here',
    },
    closeMessage: {
      type: String,
      default: 'Zurück zum Fall',
    },
    submitMessage: {
      type: String,
      default: 'Lösungen zeigen',
    },
    submitAlternativeMessage: {
      type: String,
      default: 'Lösungen verbergen',
    },
    getNewVocabsMessage: {
      type: String,
      default: 'Neue Vokabeln',
    },
    width: {
      type: Number,
      default: 0.3,
    },
    height: {
      type: Number,
      default: 0.7,
    },
    showSolutions: {
      // show at beginning or not
      type: Boolean,
      default: false,
    },
    nVocabs: {
      type: Number,
      default: 8,
    },
    defaultDifficulty: {
      type: Number,
      default: 2, // 0 to 4
    },
    mode: {
      type: String,
      default: VocabMode.TRANSLATE,
      validator: function (value) {
        return Object.values(VocabMode).includes(value);
      },
    },
  },
  computed: {
    hashtagOverlayId() {
      return '#' + this.overlayId;
    },
    refName() {
      return 'modalEL_' + this.overlayId;
    },
    showOrHideSolutionsMessage() {
      return this.showingSolutions ? this.submitAlternativeMessage : this.submitMessage;
    },
    hsOverlayClass() {
      // wider if showing solutions . TODO: comment once actual feedback message shown implemented
      // if (this.showingSolutions) {
      //   return 'hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]';
      // }
      return 'hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-3xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]';
    },
    isLoading() {
      return this.isLoadingNewVocab || this.isLoadingFeedback;
    },
    patientLanguageName() {
      return this.languageStore.getLanguageNameFromCode(this.patientLanguage);
    },
    userNativeLanguageName() {
      return this.languageStore.getLanguageNameFromCode(this.userNative);
    },
  },
  async mounted() {
    // console.debug('mounted');
    this.showingSolutions = this.showSolutions;
    this.difficulty = this.defaultDifficulty;
    await this.languageStore.loadLanguages();
    console.debug('showingSolutions: ' + this.showingSolutions + '.');
    await nextTick(async () => {
      // nextTick: deferred to wait for next DOM update
      console.debug('Added event listener ');
      this.$refs[this.refName].addEventListener('close.hs.overlay', this.closeCallback);
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await this.getNewVocabs();
  },
  async beforeUnmount() {
    this.$refs[this.refName].removeEventListener('close.hs.overlay', this.closeCallback);
    await window.HSOverlay.close(this.$refs[this.refName]);
    this.resolvePromise = true;
  },
  methods: {
    closeCallback(evt) {
      console.debug('close.hs.overlay event detected');
      this.resolvePromise = true;
    },
    async pauseForMetaChat() {
      console.debug('Initiating meta chat dialog');
      await window.HSOverlay.open(this.$refs[this.refName]);
      console.debug('Returning promise');
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async handleShowOrHide() {
      this.showingSolutions = !this.showingSolutions;
      this.showingFeedback = !this.showingFeedback;
      if (!this.showingSolutions) {
        this.hideIndividualVocab = this.initiallyHideIndividualVocab.map((x) => x); // deep copy
        this.hideIndividualSolution = this.initiallyHideIndividualVocab.map((x) => !x);
        return;
      }
      this.isLoadingFeedback = true;
      console.debug('Submitting input text: ' + this.inputText);
      // TODO: call to fapi endpoint to get feedback
      this.isLoadingFeedback = false;
    },
    async getNewVocabs() {
      console.debug('Getting new vocabs');
      if (!this.caseInteractionId) {
        console.warn('No caseInteractionId set. Cannot get vocabs.');
        return;
      }
      this.isLoadingNewVocab = true;
      console.debug('Loading: ' + this.isLoadingNewVocab + '.');
      this.showingSolutions = this.showSolutions; // reset this for new vocabs
      this.showingFeedback = false;
      console.debug('Resetting showingSolutions: ' + this.showingSolutions + '.');

      // TODO only difference is getTranslateVocabTest vs getExplainVocabTest with different parameters -- beautify this code fragment!
      if (this.mode === VocabMode.TRANSLATE) {
        console.debug(
          'Requesting new vocabs to translate for' +
            this.caseInteractionId +
            ', ' +
            this.patientLanguage +
            ', ' +
            this.userNative +
            ', ' +
            this.nVocabs +
            '.',
        );
        (await getApiClient()).vocabs
          .getTranslateVocabTest(this.caseInteractionId, this.patientLanguage, this.userNative, this.nVocabs)
          .then(
            (response) => {
              console.debug('Received vocabs: ' + JSON.stringify(response));
              this.vocabs = response;

              if (!this.showingSolutions) {
                // for each vocab pair: random 0.5 to hide native (askForPatientLanguage=false) or patient language
                // first (true) and replace by text input
                this.initiallyHideIndividualVocab = Array.from(
                  { length: this.vocabs.length },
                  () => Math.random() >= 0.5,
                );
                this.hideIndividualVocab = this.initiallyHideIndividualVocab.map((x) => x); // deep copy
                this.hideIndividualSolution = this.initiallyHideIndividualVocab.map((x) => !x);
              }
            },
            (error) => {
              console.error('Error while getting vocabs: ' + error);
              this.alertStore.error('Fehler beim Laden der Vokabeln: ' + error);
            },
          )
          .catch((error) => {
            console.error('Error while getting vocabs: ' + error);
            this.alertStore.error('Fehler beim Laden der Vokabeln: ' + error);
          })
          .finally(() => {
            this.isLoadingNewVocab = false;
          });
      } else if (this.mode === VocabMode.EXPLAIN) {
        // explain expert terms
        console.debug(
          'Requesting new expert terms to explain for' +
            this.caseInteractionId +
            ', ' +
            this.patientLanguage +
            ', ' +
            this.nVocabs +
            '.',
        );
        (await getApiClient()).vocabs
          .getExplainVocabTest(this.caseInteractionId, this.patientLanguage, this.nVocabs)
          .then(
            (response) => {
              // console.debug('Received vocabs: ' + JSON.stringify(response));
              this.vocabs = response;

              if (!this.showingSolutions) {
                // for each vocab pair: random 0.5 to hide native (askForPatientLanguage=false) or patient language
                // first (true) and replace by text input. > 1.0 : off
                this.initiallyHideIndividualVocab = Array.from(
                  { length: this.vocabs.length },
                  () => Math.random() > 1.0,
                );
                this.hideIndividualVocab = this.initiallyHideIndividualVocab.map((x) => x); // deep copy
                this.hideIndividualSolution = this.initiallyHideIndividualVocab.map((x) => !x);
              }
            },
            (error) => {
              console.error('Error while getting vocabs: ' + error);
              this.alertStore.error('Fehler beim Laden der Vokabeln: ' + error);
            },
          )
          .catch((error) => {
            console.error('Error while getting vocabs: ' + error);
            this.alertStore.error('Fehler beim Laden der Vokabeln: ' + error);
          })
          .finally(() => {
            this.isLoadingNewVocab = false;
          });
      } else {
        console.error('Unknown mode: ' + this.mode);
      }
    },
    async close() {
      console.debug('close()');
      // I do not understand why window.HSOverlay.close is not needed anymore in preline 2.0.x,
      // but the resolvePromise is sufficient. Neither do I understand why the close makes
      // the modal re-open instantly.
      // ** Please leave this comment and the following line for reference! **
      // await window.HSOverlay.close(this.$refs[this.refName]);
      this.resolvePromise(true);
    },
  },
};
</script>

<template>
  <div
    :ref="refName"
    :id="overlayId"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    >
    <div :class="hsOverlayClass">
      <div
        class="max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <!-- upper right corner "X" button -->
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
          <h3 class="font-bold text-gray-800 dark:text-white">
            {{ heading }}
          </h3>
          <button
            type="button"
            class="pointer-events-auto hs-dropup-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
            @click.stop="close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <!-- end of "X" button -->

        <!-- modal contents -->
        <div class="autofocus pointer-events-auto">
          <table class="table-fixed min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead class="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400"
                >
                  {{ mode === 'TRANSLATE' ? patientLanguageName : 'Fachbegriff' }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400"
                >
                  {{ mode === 'TRANSLATE' ? userNativeLanguageName : 'Erläuterung' }}
                </th>
                <!--              <th-->
                <!--                  v-show="showingFeedback"-->
                <!--                  scope="col"-->
                <!--                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400"-->
                <!--              >-->
                <!--                Feedback-->
                <!--              </th>-->
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
              <tr v-for="(item, index) in vocabs">
                <td class="px-6 py-2 whitespace-normal text-sm font-medium text-gray-800 dark:text-gray-200">
                  <!-- if clicked, set hidePatientLanguage[index] to false -->
                  <a @click="hideIndividualVocab[index] = !hideIndividualVocab[index]">
                    {{ !hideIndividualVocab[index] || showingFeedback || showingSolutions ? item['vocab'] : '*****' }}
                  </a>
                </td>
                <td class="px-6 py-2 whitespace-normal text-sm text-gray-800 dark:text-gray-200">
                  <a @click="hideIndividualSolution[index] = !hideIndividualSolution[index]">
                    {{
                      !hideIndividualSolution[index] || showingFeedback || showingSolutions ? item['solution'] : '*****'
                    }}
                  </a>
                </td>
                <!--              <td-->
                <!--                  v-show="showingFeedback"-->
                <!--                  class="w-1/2 px-6 py-2 whitespace-normal text-sm text-gray-800 dark:text-gray-200"-->
                <!--              >-->
                <!--                {{ item.feedback }}-->
                <!--              </td>-->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end of modal contents -->

        <!-- modal footer buttons -->
        <div class="pointer-events-auto flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
          <!--TODO: add backend functionality for selection for difficulty. DO NOT REMOVE THIS! -->
          <!--          &lt;!&ndash; Difficulty slider &ndash;&gt;-->
          <!--          <div class="hs-tooltip inline-block [&#45;&#45;trigger:hover]">-->
          <!--            &lt;!&ndash;            <label for="difficulty-slider" class="sr-only border-red-600 bg-red-600">Schwierigkeit</label>&ndash;&gt;-->
          <!--            <a class="hs-tooltip-toggle">-->
          <!--              <span-->
          <!--                class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-400"-->
          <!--                role="tooltip"-->
          <!--              >-->
          <!--                Schwierigkeit-->
          <!--              </span>-->
          <!--              <input-->
          <!--                v-model="difficulty"-->
          <!--                type="range"-->
          <!--                class="w-full bg-transparent cursor-pointer appearance-none disabled:opacity-50 disabled:pointer-events-none focus:outline-none [&::-webkit-slider-thumb]:w-2.5 [&::-webkit-slider-thumb]:h-2.5 [&::-webkit-slider-thumb]:-mt-0.5 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:border-4 [&::-webkit-slider-thumb]:shadow-[0_0_0_4px_rgba(220,38,38,1)] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-150 [&::-webkit-slider-thumb]:ease-in-out [&::-webkit-slider-thumb]:dark:bg-neutral-700 [&::-moz-range-thumb]:w-5.0 [&::-moz-range-thumb]:h-2.5 [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:bg-white [&::-moz-range-thumb]:border-4 [&::-moz-range-thumb]:border-blue-600 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:transition-all [&::-moz-range-thumb]:duration-150 [&::-moz-range-thumb]:ease-in-out [&::-webkit-slider-runnable-track]:w-full [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:bg-red-300 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:dark:bg-gray-700 [&::-moz-range-track]:w-full [&::-moz-range-track]:h-2 [&::-moz-range-track]:bg-gray-100 [&::-moz-range-track]:rounded-full"-->
          <!--                id="difficulty-slider"-->
          <!--                min="0"-->
          <!--                max="4"-->
          <!--                step="1.0"-->
          <!--              />-->
          <!--            </a>-->
          <!--          </div>-->
          <!-- TODO: DO NOT REMOVE THIS COMMENTED OUT CODE FRAGMENT! -->
          <!-- End of difficulty slider -->

          <!-- Get new button -->
          <div>
            <ProgressButton
              :showProgress="isLoadingNewVocab"
              :onclick="getNewVocabs"
              color="red"
              :disabled="isLoading"
              type="button"
              :text="getNewVocabsMessage"
            />
          </div>
          <!-- End of get new button -->

          <!-- Submit button -->
          <div>
            <ProgressButton
              :showProgress="isLoadingFeedback"
              :onclick="handleShowOrHide"
              color="blue"
              :disabled="isLoading"
              type="button"
              :text="showOrHideSolutionsMessage"
            />
          </div>
          <!-- End of submit button -->

          <!-- back to case bottom button -->
          <div>
            <a
              class="py-2 px-3 text-sm min-w-24 min-h-10 inline-flex justify-center items-center gap-2 rounded-lg border border-transparent font-medium bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
              href="#"
              :data-hs-overlay="hashtagOverlayId"
              @click.stop="close"
            >
              {{ closeMessage }}
            </a>
          </div>
          <!-- end of back to case bottom button -->
        </div>
        <!-- end of modal footer buttons -->
      </div>
    </div>
  </div>
</template>
