function filter(text: string) {
  // fixing chrome bug w/ only string reference rather than val: https://stackoverflow.com/a/31733628
  let text_ = (' ' + text).slice(1);

  // filter out "[number]." as used in dates etc. through regexp
  // TODO: this could be improved
  text_ = text_.replace(/(\d)\./g, '$1');

  // replace x1.x2. ... xn. with x1x2...xn. for all xi single characters
  let pattern = /\.(\w)\./g;
  text_ = text_.replace(pattern, '$1');

  // filter out "." from titles (Dr., Prof., etc.)
  text_ = text_.replace(/(Dr)\./g, '$1');
  text_ = text_.replace(/(Prof)\./g, '$1');

  return text_;
}

export function splitSentences(text: string) {
  // fixing chrome bug w/ only string reference rather than val: https://stackoverflow.com/a/31733628
  let text_ = (' ' + text).slice(1);
  text_ = filter(text_);

  let splitted = text_
    .split('?')
    .join('?#')
    .split('!')
    .join('!#')
    .split(':')
    .join(':#')
    .split('.')
    .join('.#')
    .split('#');

  return splitted;
}

export function countNumberOfSentences(text: string) {
  return splitSentences(text).length;
}

export function getSentenceForCharacter(text: string, characterIdx: number) {
  let sentences = splitSentences(text);
  console.log('sentences', sentences);
  let sentenceIdx = 0;

  for (let i = 0; i < sentences.length; i++) {
    sentenceIdx += sentences[i].length;
    console.log('sentenceIdx', sentenceIdx, 'characterIdx', characterIdx, 'i', i, 'sentences[i]', sentences[i]);
    if (sentenceIdx > characterIdx) {
      return i;
    }
  }
}
