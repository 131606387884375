<script setup lang="ts">
import {
  getCoreRowModel,
  useVueTable,
  createColumnHelper,
  ColumnOrderState,
  Column,
  ColumnResizeMode,
  ColumnVisibility,
  ColumnResizeDirection,
} from '@tanstack/vue-table';
import { computed, onMounted, ref, h, reactive, watch } from 'vue';
import VocabListEntry from '@/components/didactics/VocabListEntry.vue';
import VocabListTableHead from '@/components/didactics/VocabListTableHead.vue';
import VocabListSearchbar from '@/components/didactics/VocabListSearchbar.vue';
import { useAlertStore, useCourseStore } from '@/stores';
import { faker } from '@faker-js/faker';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { updateMinTextareaHeight } from '@/helper';

const { t } = useI18n();

const alertStore = useAlertStore();
const courseStore = useCourseStore();

const props = defineProps({
  showSearchbar: {
    type: Boolean,
    default: true,
  },
  addedHeaderHeights: {
    type: Number,
    default: 0,
  },
  fullWidth: {
    type: Number,
    default: 300,
  },
  vocabList: {
    type: Array,
    default: [],
  },
  viewFullscreen: {
    type: Boolean,
    default: true,
  },
  showColumnSelected: {
    type: Boolean,
    default: false,
  },
  showColumnTerm: {
    type: Boolean,
    default: true,
  },
  showColumnUsageExamples: {
    type: Boolean,
    default: true,
  },
  showColumnExplanation: {
    type: Boolean,
    default: true,
  },
  showColumnContextLinks: {
    type: Boolean,
    default: true,
  },
  showColumnTranslations: {
    type: Boolean,
    default: true,
  },
  showColumnRemarks: {
    type: Boolean,
    default: true,
  },
  showColumnTags: {
    type: Boolean,
    default: true,
  },
  showColumnResultsAtTests: {
    type: Boolean,
    default: true,
  },
  showColumnIsFavourite: {
    type: Boolean,
    default: true,
  },
  allowEditing: {
    type: Boolean,
    default: true,
  },
  hideRandomButOneColumn: {
    type: Array,
    default: [],
  }, // give the column name of which all but one shall be starred. All others are hidden.
});

const emit = defineEmits(['isDragging', 'updatedCell', 'currentDraggingPosition', 'droppedAtPosition']);

const scrollContainerVocabList = ref<HTMLElement | null>(null);
const currentYScroll = ref(0);
const outerContainer = ref<HTMLElement | null>(null);

const editModeState = reactive(new Map());
const visibleColumnsMap = reactive(new Map());

onMounted(() => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  if (scrollContainerVocabList.value) {
    scrollContainerVocabList.value.addEventListener(
      'scroll',
      () => {
        currentYScroll.value = scrollContainerVocabList.value.scrollTop;
      },
      { passive: true },
    );
  }

  // Initialize edit mode state for each cell
  props.vocabList.forEach((row, rowIndex) => {
    Object.keys(row).forEach((columnId) => {
      editModeState.set(`${rowIndex}-${columnId}`, false);
    });
  });

  data.value = props.vocabList;

  initializeRandomVisibleColumns();
});

watch(
  () => props.vocabList,
  () => {
    props.vocabList.forEach((row, rowIndex) => {
      Object.keys(row).forEach((columnId) => {
        editModeState.set(`${rowIndex}-${columnId}`, false);
      });
    });
    data.value = props.vocabList;
    initializeRandomVisibleColumns();
  },
);

const computedStyleMain = computed(() => {
  if (props.viewFullscreen) {
    return {
      height: `calc(var(--fallback-viewport-height, 100vh) - ${props.addedHeaderHeights}px)`,
      width: props.fullWidth + 'px',
    };
  }
});

const computedStyleTable = computed(() => {
  if (props.viewFullscreen) {
    return {
      height: `calc(var(--fallback-viewport-height, 100vh) - ${props.addedHeaderHeights}px)`,
      width: outerContainer.value ? outerContainer.value.offsetWidth + 'px' : window.innerWidth - 400 + 'px',
    };
  }
});

type Vocab = {
  index: number;
  isSelected: boolean;
  term: string;
  termAudioUrl: string;
  explanation: string;
  usageExamples: [string];
  exampleAudioUrls: [string];
  contextLinks: string[];
  translations: string[];
  imageUrls: string[];
  tags: string[];
  isFavourite: boolean;
  vocabListIds: string[];
  lastTestedAt: string;
  resultsAtTests: boolean[];
  remarks: string;
};

// Initialize random visible column for each row based on hideRandomButOneColumn
function initializeRandomVisibleColumns() {
  if (!props.hideRandomButOneColumn || props.hideRandomButOneColumn.length === 0) {
    visibleColumnsMap.clear(); // Clear any existing random selection if feature is disabled
    return;
  }

  // Pick a random column to show for each row (others are starred)
  data.value.forEach((row, rowIndex) => {
    const randomColumn = faker.helpers.arrayElement(props.hideRandomButOneColumn);
    visibleColumnsMap.set(rowIndex, new Set([randomColumn])); // Store as a set of visible columns
  });

  // Hide all columns which are not part of this game
  table.getAllLeafColumns().forEach((column) => {
    if (!props.hideRandomButOneColumn.includes(column.id)) {
      columnVisibility.value = {
        ...columnVisibility.value,
        [column.id]: false,
      };
    }
  });
}

function isEditMode(rowIndex: string, columnId: string) {
  return editModeState.get(`${rowIndex}-${columnId}`) || false;
}

function toggleEditMode(rowIndex: string, columnId: string) {
  if (!props.allowEditing) {
    return;
  }
  const key = `${rowIndex}-${columnId}`;
  editModeState.set(key, !editModeState.get(key));
}

const buildEditableTextCell = (
  editModeState: any,
  toggleEditMode: any,
  audioUrlPropertyName: string | null = null,
  imageUrlPropertyName: string | null = null,
) => {
  let editableTextCell = ({ row, column, getValue }: { row: any; column: any; getValue: any }) => {
    // Define a reactive object to toggle edit mode for each row
    const isRandomColumnVisible =
      !props.hideRandomButOneColumn ||
      props.hideRandomButOneColumn.length === 0 ||
      (visibleColumnsMap.get(row.index) && visibleColumnsMap.get(row.index).has(column.id));

    return h(
      'div',
      {
        class: 'inline-flex overflow-visible whitespace-normal fallback-break',
        style: "{ width: column.getSize() + 'px' }",
      },
      [
        isRandomColumnVisible
          ? isEditMode(row.index, column.id)
            ? h('textarea', {
                value: row.original[column.id],
                onInput: (e: Event) => {
                  const target = e.target as HTMLInputElement;
                  // target.style.width = target.value.length + 1 + 'ch';
                  updateMinTextareaHeight(target);
                },
                onChange: async (e: Event) => {
                  const target = e.target as HTMLInputElement;
                  row.original[column.id] = target.value;

                  // console.log('row.original[column.id]: ', row.original[column.id]);
                  // console.log('column.id: ', column.id);
                  // console.log('row.original.id: ', row.original.id);

                  emit('updatedCell', row.original.id, column.id, target.value);
                },
                class:
                  'border-gray-200 p-0 rows-1 bg-transparent resize-none rounded-lg text-xs md:text-sm flex w-[' +
                  column.getSize() +
                  'px]',
              })
            : h(
                'span',
                {
                  class: 'overflow-hidden whitespace-normal fallback-break',
                  style: '{ width: `min(${row.original[column.id].length + 1}ch, ${column.getSize()}px)` }',
                },
                row.original[column.id],
              )
          : h(
              'button',
              {
                onClick: () => {
                  // Reveal clicked column for this row
                  if (!visibleColumnsMap.get(row.index)) {
                    visibleColumnsMap.set(row.index, new Set());
                  }
                  visibleColumnsMap.get(row.index).add(column.id);
                },
                class: 'text-gray-400 hover:text-gray-800',
              },
              '***',
            ),
        !!audioUrlPropertyName
          ? h(
              'button',
              {
                onClick: () => {
                  alertStore.info(
                    'Noch nicht implementiert - will follow soon',
                    'Play audio: ' + row.original[audioUrlPropertyName],
                  );
                },
                class: 'hidden pl-1 text-gray-400 hover:text-gray-800 flex items-center',
              },
              [
                h(
                  'span',
                  {
                    translate: 'no',
                    class: 'material-symbols-outlined notranslate -mb-2 text-lg',
                  },
                  'volume_up',
                ),
              ],
            )
          : null,
        !!imageUrlPropertyName
          ? h(
              'button',
              {
                onClick: () => {
                  alertStore.info(
                    'Noch nicht implementiert - will follow soon',
                    'Show image: ' + row.original[imageUrlPropertyName],
                  );
                },
                class: 'hidden pl-0.5 text-gray-400 hover:text-gray-800 flex items-center',
              },
              [h('span', { class: 'material-symbols-outlined notranslate -mb-2 text-lg', translate: 'no' }, 'image')],
            )
          : null,
        !!props.allowEditing
          ? h(
              'button',
              {
                onClick: () => {
                  toggleEditMode(row.index, column.id);
                },
                class:
                  'pl-0.5 text-gray-400 hover:text-gray-800 flex items-center overflow-visible' +
                  (!props.hideRandomButOneColumn ? '' : ' hidden'),
              },
              [
                h(
                  'span',
                  { class: 'material-symbols-outlined notranslate -mb-2 text-lg overflow-visible', translate: 'no' },
                  isEditMode(row.index, column.id) ? 'done' : 'edit',
                ),
              ],
            )
          : null,
      ],
    );
  };
  return editableTextCell;
};

// TODO: for usage exmaples, same but with array of strings
// => one shown, rest on dropdown
// => add button to add new usage example
// => edit on dropdown also (all span => input then)

// tanstack table declarations
const columnHelper = createColumnHelper<Vocab>();

const columns = [
  {
    accessorKey: 'isSelected',
    header: h('input', {
      type: 'checkbox',
      class:
        'border-gray-300 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
      onChange: (e: Event) => {
        const target = e.target as HTMLInputElement;
        data.value.forEach((row: any) => {
          row.isSelected = target.checked;
        });
      },
    }),
    cell: ({ row }: { row: any }) =>
      h('input', {
        type: 'checkbox',
        class:
          'border-gray-300 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
        checked: row.original.isSelected,
        onChange: (e: Event) => {
          const target = e.target as HTMLInputElement;
          row.original.isSelected = target.checked;
        },
      }),
    enableResizing: false,
    minSize: 10,
    size: 20,
  },
  {
    accessorKey: 'term',
    header: t('message.columnTerm'),
    cell: buildEditableTextCell(editModeState, toggleEditMode, 'termAudioUrl', 'imageUrls'),
    enableResizing: true,
  },
  {
    accessorKey: 'usageExamples',
    header: t('message.columnUsageExamples'),
    // TODO build editable text cell for array of strings!
  },
  {
    accessorKey: 'explanation',
    header: t('message.columnExplanation'),
    cell: buildEditableTextCell(editModeState, toggleEditMode),
  },
  {
    accessorKey: 'contextLinks',
    header: t('message.columnContextLinks'),
    cell: ({ row }: { row: any }) =>
      h(
        'div',
        {
          class: 'flex items-center space-x-2', // Adjust spacing between buttons as needed
        },
        row.original.contextLinks.map((url: string, index: number) =>
          h(
            'button',
            {
              key: index, // Unique key for each button
              class: 'flex items-center', // Adjust button styling
              onClick: () => {
                alertStore.info('Jump to context link: ' + url); // Specific URL in the alert
              },
            },
            [
              h(
                'span',
                {
                  translate: 'no',
                  class: 'material-symbols-outlined notranslate text-2xl mx-auto -my-2 hover:text-gray-500',
                  title: 'Jump to linked context',
                },
                'jump_to_element',
              ),
            ],
          ),
        ),
      ),
  },
  {
    accessorKey: 'translations',
    header: t('message.columnTranslations'),
    cell: buildEditableTextCell(editModeState, toggleEditMode),
  },
  {
    accessorKey: 'remarks',
    header: t('message.columnRemarks'),
    cell: buildEditableTextCell(editModeState, toggleEditMode),
  },
  {
    accessorKey: 'tags',
    header: t('message.columnTags'),
  },
  {
    accessorKey: 'resultsAtTests',
    header: t('message.columnResultsAtTests'),
    // cell should be average from last 10 tests
    cell: ({ row }: { row: any }) => {
      const results = row.original.resultsAtTests;
      const average = (results.reduce((acc: number, curr: boolean) => acc + (curr ? 1 : 0), 0) / results.length) * 100;
      return h('span', { class: 'whitespace-nowrap' }, `${average.toFixed(1)} %`);
    },
  },
  {
    accessorKey: 'isFavourite',
    header: t('message.columnIsFavorite'),
    cell: ({ row }: { row: any }) =>
      h(
        'button',
        {
          class: 'w-full flex items-center',
          onClick: () => {
            row.original.isFavourite = !row.original.isFavourite;
          },
        },
        [
          h(
            'span',
            {
              translate: 'no',
              class: 'material-symbols-outlined notranslate mx-auto text-2xl -my-2 hover:text-gray-500',
              style: `font-variation-settings: 'FILL' ${row.original.isFavourite ? 1 : 0};`,
            },
            'star',
          ),
        ],
      ),
    enableResizing: false,
  },
];

const randomizeColumns = () => {
  table.setColumnOrder(faker.helpers.shuffle(table.getAllLeafColumns().map((d) => d.id)));
};

function toggleColumnVisibility(column: Column<any, any>) {
  columnVisibility.value = {
    ...columnVisibility.value,
    [column.id]: !column.getIsVisible(),
  };
}

function toggleAllColumnsVisibility() {
  table.getAllLeafColumns().forEach((column) => {
    toggleColumnVisibility(column);
  });
}

const data = ref([]);

// TODO add usage examples!
// TODO add context links
// TODO shall we use tags?
// TODO add "results at test" functionality
const columnVisibility = ref({
  isSelected: props.showColumnSelected,
  term: props.showColumnTerm,
  explanation: props.showColumnExplanation,
  usageExamples: false,
  contextLinks: false,
  translations: props.showColumnTranslations,
  remarks: props.showColumnRemarks,
  tags: false,
  resultsAtTests: false,
  isFavourite: props.showColumnIsFavourite,
});
const columnOrder = ref<ColumnOrderState>([
  'isSelected',
  'term',
  'explanation',
  'usageExamples',
  'contextLinks',
  'translations',
  'remarks',
  'tags',
  'resultsAtTests',
  'isFavourite',
]);
const columnSizing = ref({
  isSelected: 30,
  term: 250,
  usageExamples: 200,
  explanation: 500,
  contextLinks: 200,
  translations: 200,
  remarks: 200,
  tags: 200,
  resultsAtTests: 200,
  isFavourite: 30,
});

const table = useVueTable({
  get data() {
    return data.value;
  },
  columns,
  state: reactive({
    get columnVisibility() {
      return columnVisibility.value;
    },
    get columnOrder() {
      return columnOrder.value;
    },
    get columnSizing() {
      return columnSizing.value;
    },
  }),
  onColumnOrderChange: (order) => {
    columnOrder.value = order instanceof Function ? order(columnOrder.value) : order;
  },
  getCoreRowModel: getCoreRowModel(),
  enableColumnResizing: true,
  columnResizeMode: 'onChange',
  debugTable: false,
  debugHeaders: false,
  debugColumns: false,
});

const scrollToBottom = () => {
  if (scrollContainerVocabList.value) {
    scrollContainerVocabList.value.scrollTop = scrollContainerVocabList.value.scrollHeight;
  }
};

defineExpose({
  currentYScroll,
  scrollToBottom,
});
</script>

<template>
  <div ref="outerContainer" class="flex flex-col overflow-hidden relative" :style="computedStyleMain">
    <VocabListSearchbar :show="showSearchbar" />

    <table
      ref="scrollContainerVocabList"
      :style="computedStyleTable"
      class="overflow-y-auto overflow-x-auto inline-block align-middle [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2"
    >
      <thead class="sticky bg-white top-0 z-10 border-b border-gray-200 dark:border-neutral-700 overflow-visible">
        <VocabListTableHead :table="table" :columnSizing="columnSizing" />
      </thead>

      <tbody class="divide-y divide-gray-200 dark:divide-neutral-700 overflow-visible">
        <VocabListEntry
          @isDragging="
            (isDragging, vocabItemId) => {
              emit('isDragging', isDragging, vocabItemId);
            }
          "
          @currentDraggingPosition="(position) => emit('currentDraggingPosition', position)"
          @droppedAtPosition="(position) => emit('droppedAtPosition', position)"
          v-for="row in table.getRowModel().rows"
          :key="row.id"
          :row="row"
        />
      </tbody>
    </table>
  </div>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
