/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttendingInteractionMessage } from '../models/AttendingInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AttendingMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Attending Interaction Message
   * :param session:
   * :param user:
   * :param attending_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param attendingInteractionMessageId
   * @returns AttendingInteractionMessage Successful Response
   * @throws ApiError
   */
  public getAttendingInteractionMessage(
    attendingInteractionMessageId: string,
  ): CancelablePromise<AttendingInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/attending-messages/attending-message/{attending_interaction_message_id}',
      path: {
        attending_interaction_message_id: attendingInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Attending Interaction Message
   * @param attendingInteractionMessageId
   * @param requestBody
   * @returns AttendingInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForAttendingInteractionMessage(
    attendingInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<AttendingInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/attending-messages/{attending_interaction_message_id}',
      path: {
        attending_interaction_message_id: attendingInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Attending Message Translation
   * :param session:
   * :param user:
   * :param attending_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param attendingInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestAttendingMessageTranslation(
    attendingInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/attending-messages/{attending_interaction_message_id}/translation',
      path: {
        attending_interaction_message_id: attendingInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
