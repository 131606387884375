<!-- TODO: "Fill random" button zum zufälligen Vor-Ausfüllen -->
<!-- TODO: Fachbereiche vervollständigen -->
<!-- TODO: Feld für public (=kann jeder bearbeiten) vs. private case (=nur eingeladene User) -->
<!-- TODO: upload and store patient image -->
<!-- TODO: profile image von AI generieren lassen. "Generate"-button statt upload. -->
<!-- TODO: Einrückungen confirm modal list <ul> -->
<!-- TODO: choose attending and examiners from list (optional). Default: random choices -->

<script setup>
import { useAlertStore, useAuthStore } from '@/stores';
import { ref, onMounted, computed } from 'vue';
import { getApiClient } from '@/apiclient/client';
import ProgressButton from '@/components/ProgressButton.vue';
import 'vue-advanced-cropper/dist/style.css';
import { router } from '@/router';
import Tooltip from '@/components/Tooltip.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import StudentSection from '@/components/new_case/sections/Student.vue';
import PersonsSection from '@/components/new_case/sections/Persons.vue';
import ReviewSection from '@/components/new_case/sections/Review.vue';
import ScenesAndTasksSection from '@/components/new_case/sections/ScenesAndTasks.vue';
import OtherSection from '@/components/new_case/sections/Other.vue';
import StudentStepperItem from '@/components/new_case/stepper_items/Student.vue';
import PersonsStepperItem from '@/components/new_case/stepper_items/Persons.vue';
import ScenesAndTasksStepperItem from '@/components/new_case/stepper_items/ScenesAndTasks.vue';
import OtherStepperItem from '@/components/new_case/stepper_items/Other.vue';
import ReviewStepperItem from '@/components/new_case/stepper_items/Review.vue';
import { roleTypes } from '@/components/new_case/typing';

const authStore = useAuthStore();
const alertStore = useAlertStore();

const newCaseForm = ref(null);

let randomValues = {};
const isLoading = ref(true);
const isSubmitting = ref(false);
const isUploading = ref(false);
const isFetchingRandomValues = ref(false);
const yScrollContainer = ref();
const startingFromCompleteCase = ref(false);
const setMedicalConditions = ref(false);
const willBeResetting = ref(false);

const cropper = ref(null); // Reference to the cropper component
const image = ref({ src: null, type: null });
const userConformationModalHandle = ref();
const waitModalHandle = ref();

const useStructuredForm = ref(false);
const confirmFreetext = ref(false);

// appearance of form and available options
const allowFreedhandInput = ref(true);
const allowFillByExampleCase = ref(true);
const formSubtitle = ref('Erstelle einen neuen Fall für deine Lerngruppe');

import { DEBUG } from '@/helper/debugging';

const { fromCaseId, copyCase, editCase, viewOnly, caseListId, outerHeaderHeight } = defineProps({
  fromCaseId: {
    type: String,
    default: null,
  },
  copyCase: {
    type: Boolean,
    default: false,
  },
  editCase: {
    type: Boolean,
    default: false,
  },
  viewOnly: {
    type: Boolean,
    default: false,
  },
  caseListId: {
    type: String,
    default: null,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const student = ref({});
const persons = ref([]);
const tasks = ref([]);
const otherCaseDetails = ref({});

const currentStep = ref(0);
const isValid = ref([false, false, false, false, false]);

async function onGenerateExample() {
  student.value.role = 'Assistenzarzt';

  persons.value.length = 0;
  persons.value.push({
    create_id: 0,
    profile_image: null,
    details: {
      role: roleTypes.find((role) => role.value === 'PATIENT'),
      freehand_description: 'Erstdiagnose HCC bei Leberzirrhose',
    },
  });
  persons.value.push({
    create_id: 1,
    profile_image: null,
    details: {
      role: roleTypes.find((role) => role.value === 'PHYSICIAN'),
      freehand_description: 'Junger Stationsarzt',
    },
  });

  tasks.value.length = 0;
  tasks.value.push({
    index: 0,
    type: 'RECEIVE_HANDOVER',
    title: 'Patientenübergabe',
    icon: 'hearing',
    success: false,
    details: {
      description: 'Übergabe des Patienten durch den betreuenden Stationsarzt',
      starting_point:
        '... kennt den Patienten noch nicht und erhält daher vor dem Wochenenddienst eine ausführliche Übergabe',
      location: 'Arztzimmer der Station 3',
      date: null,
      time: null,
      // date: '2022-10-01',
      // time: '16:00',
    },
    subtasks: [
      {
        index: 0,
        details: { target: 'Student erfragt Stuhlfrequenz' },
      },
      {
        index: 1,
        details: { target: 'Student erfragt Enzephalopathiestatus' },
      },
      {
        index: 2,
        details: { target: 'Student erfragt aktuelle Medikation' },
      },
      {
        index: 3,
        details: { target: 'Student erfragt HE-Vorgeschichte' },
      },
    ],
    isIncomplete: true,
    person_task_concerns: [],
    person_task_involvements: [],
    // person_task_concerns: [
    //     {
    //   create_id: 1,
    //   details: {
    //     concern: "Patienten ausführlich übergeben, so dass der Kollege gut vorbereitet in seinen Wochenenddienst startet"
    //   }
    // },
    // ],
    // person_task_involvements: [
    //   {
    //     create_id: 1,
    //     type: 'IN_THE_ROOM'
    //   },
    // ],
  });

  tasks.value.push({
    index: 1,
    type: 'MAKE_OPEN_CONVERSATION',
    title: 'Wochenenddienst',
    icon: 'forum',
    success: false,
    details: {
      description: 'Visite des Patienten im Wochenenddienst',
      starting_point: '... findet den Patienten verwirrt im Bett liegend vor',
      location: 'Patientenzimmer 11 der Station 3',
      date: null,
      time: null,
      // date: '2022-10-02',
      // time: '09:30',
    },
    subtasks: [
      {
        index: 0,
        details: { target: 'Student erfragt Zeit und Volumen des letzten Stuhlgangs' },
      },
      {
        index: 1,
        details: { target: 'Student erfragt Stuhlfrequenz am Vortag' },
      },
      {
        index: 2,
        details: { target: 'Student erfragt subjective Konzentrationsstörungen' },
      },
      {
        index: 3,
        details: { target: 'Student erfragt subjektive Müdigkeit' },
      },
    ],
    isIncomplete: true,
    person_task_concerns: [],
    person_task_involvements: [],
  });

  isValid.value[4] = true;
}

function scrollToBottom() {
  const container = yScrollContainer.value;
  console.log('scrollHeight:', container.scrollHeight);

  if (!container) {
    return;
  }

  container.scroll({
    top: 1000,
    behavior: 'smooth',
  });
}

function resetAll() {
  student.value.length = 0;
  persons.value.length = 0;
  tasks.value.length = 0;
  currentStep.value = 0;
  otherCaseDetails.value = {};
  isValid.value = [false, false, false, false, false];
}

async function onSubmit() {
  if (isSubmitting.value) {
    return;
  }
  isSubmitting.value = true;

  if (editCase) {
    alertStore.info(
      'Bearbeiten von Fällen wird noch nicht unterstützt. Es wird ein neuer Fall erstellt. Der alte muss ggf. manuell gelöscht werden!',
    );
  }

  console.debug(
    'About to submit the following case (confirmation due): ' +
      'persons: ' +
      persons +
      'tasks: ' +
      tasks +
      'otherCaseDetails: ' +
      otherCaseDetails,
  );
  await userConformationModalHandle.value.promptUserConformation().catch((error) => {
    console.debug('User declined: ' + error);
    isSubmitting.value = false;
    return false;
  });
}

async function submitCase() {
  let patient = persons.value.find((person) => person.details.role.value === 'PATIENT') || null;
  let third_persons = persons.value.filter((person) => person.details.role.value !== 'PATIENT');
  third_persons.forEach((person) => {
    person.type = person.details.role.value;
  });

  (await getApiClient()).cases
    .createCase({
      details: otherCaseDetails.value,
      student_details: student.value,
      patient: patient,
      attending: null,
      oral_examiner: null,
      mc_examiner: null,
      observer: null,
      third_persons: third_persons,
      tasks: tasks.value,
    })
    .then(async (newCase) => {
      // isSubmitting.value = false;
      // await router.push("/home");
      alertStore.success('Fall erfolgreich erstellt!');
      console.debug('Finished submission.');
      console.log('caseListId is: ' + caseListId);
      console.log('newCase.id is: ' + newCase.id);
      if (!!caseListId) {
        (await getApiClient()).caseLists
          .addCasesToCaseList(caseListId, [{ id: newCase.id }])
          .then(() => {
            alertStore.success('Fall erfolgreich erstellt und zur Fallliste hinzugefügt');
          })
          .catch((error) => {
            alertStore.error('Fall erfolgreich erstellt, aber Fehler beim Hinzufügen zur Fallliste: ' + error);
          });
      }
    })
    .catch(async (error) => {
      try {
        let error_dict = JSON.stringify(error);
        let error_details = JSON.parse(error['body']['detail']);
        let error_msg = error_details['msg'];
        let error_code = error['status'];
        alertStore.error(error_msg + ' (Error ' + error_code + ')');
      } catch (e) {
        alertStore.error(error);
      }
    })
    .finally(() => {
      isSubmitting.value = false;
      // TODO: open modal and ask whether to start this case
    });
}

async function loadCase() {
  if (!fromCaseId) {
    return;
  }
  console.log('Loading case from ID: ' + fromCaseId);
  const oldCase = await (await getApiClient()).cases.getCase(fromCaseId);
  console.log('Loaded case: ' + JSON.stringify(oldCase));

  otherCaseDetails.value = oldCase.details;
  student.value = oldCase.student_details
    ? oldCase.student_details
    : {
        role: 'Arzt in der Notaufnahme',
      };

  // manage persons
  persons.value = oldCase.third_persons ? oldCase.third_persons : [];
  let patient = oldCase.patient;
  patient.type = 'PATIENT';
  patient.create_id = 0;
  persons.value.unshift(patient);
  persons.value.forEach((person, index) => {
    person.details.role = roleTypes.find((role) => role.value === person.type);
    person.create_id = index;
  });

  // manage tasks
  tasks.value = oldCase.tasks;
  tasks.value.forEach((task) => {
    task.person_task_concerns.forEach((concern) => {
      let patient = persons.value.find((person) => person.id === concern.patient_id);
      if (!!patient) {
        concern.create_id = patient.create_id;
        return;
      }
      let attending = persons.value.find((person) => person.id === concern.attending_id);
      if (!!attending) {
        concern.create_id = attending.create_id;
        return;
      }
      let third_person = persons.value.find((person) => person.id === concern.third_person_id);
      if (!!third_person) {
        concern.create_id = third_person.create_id;
      }
    });
    task.person_task_involvements.forEach((involvement) => {
      let patient = persons.value.find((person) => person.id === involvement.patient_id);
      if (!!patient) {
        involvement.create_id = patient.create_id;
      }
      let attending = persons.value.find((person) => person.id === involvement.attending_id);
      if (!!attending) {
        involvement.create_id = attending.create_id;
      }
      let third_person = persons.value.find((person) => person.id === involvement.third_person_id);
      if (!!third_person) {
        involvement.create_id = third_person.create_id;
      }
      console.log('involvement', JSON.stringify(involvement));
      console.log('involvement.create_id', involvement.create_id);
      console.log('involvement.type', involvement.type);
    });
  });
  isValid.value = [true, true, true, true, true]; // old case is valid from beginning, allow to step thru it
  startingFromCompleteCase.value = true;

  console.log('Loaded otherCaseDetails: ' + JSON.stringify(otherCaseDetails));
  console.log('Loaded student: ' + JSON.stringify(student));
  console.log('Loaded persons: ' + JSON.stringify(persons));
  console.log('Loaded tasks: ' + JSON.stringify(tasks));
}

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerHeaderHeight}px)`,
  };
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await loadCase();
  isLoading.value = false;
});
</script>

<template>
  <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    />
  </div>
  <!-- Card Section -->
  <div
    ref="yScrollContainer"
    v-if="!isLoading"
    class="flex w-full min-w-full overflow-y-scroll"
    :style="computedStyleMain"
  >
    <!-- added this outer container to fix scrolling issue -->

    <!--    persons: {{ persons }}-->
    <!--        tasks: {{ tasks }}-->
    <span v-if="DEBUG"> valid: {{ isValid }}</span>

    <div
      class="min-w-[40vH] max-w-[120vH] mb-7 py-10 px-6 lg:px-8 lg:py-14 mx-auto flex h-fit min-h-fit max-h-fit overflow-visible"
    >
      <!-- inner container with actual input field. Smaller than full page size -->

      <!-- Card -->
      <div
        class="flex-col bg-white rounded-xl shadow mb-7 p-7 h-fit min-h-fit max-h-fit dark:bg-neutral-900 overflow-visible"
      >
        <div class="justify-between items-center space-x-4">
          <div class="mb-8 justify-left gap-y-1">
            <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
              Neuer <span class="text-blue-600">Fall</span>
            </h2>
            <div class="inline-flex items-center gap-x-1">
              <p class="text-sm text-gray-600 dark:text-gray-400">
                {{ formSubtitle }}
              </p>
              <Tooltip
                message="Folge dem mehrschrittigen Prozess und wähle erst die Rolle des Studenten,
                dann die beteiltigen Personen,
                dann die Szenen und Aufgaben, welche der Student durchläuft.
                Mit Sternchen markierte Felder sind Pflichtfelder."
              />
            </div>
          </div>
          <div class="mb-8 justify-right" v-show="allowFillByExampleCase">
            <ProgressButton
              text="Beispielfall"
              :showProgress="isFetchingRandomValues"
              :isLoading="true"
              :onclick="onGenerateExample"
              type="button"
              color="white"
            />
          </div>
        </div>

        <!-- Stepper -->
        <div class="flex-col overflow-visible h-fit min-h-fit" data-hs-stepper="">
          <!-- Stepper Nav -->
          <ul class="relative flex flex-row gap-x-2">
            <li class="shrink basis-0 flex-1 group" data-hs-stepper-nav-item='{"index": 1}'>
              <StudentStepperItem />
            </li>

            <li class="shrink basis-0 flex-1 group" data-hs-stepper-nav-item='{"index": 2}'>
              <PersonsStepperItem />
            </li>

            <li class="shrink basis-0 flex-1 group" data-hs-stepper-nav-item='{"index": 3}'>
              <ScenesAndTasksStepperItem />
            </li>

            <li class="shrink basis-0 flex-1 group" data-hs-stepper-nav-item='{"index": 4}'>
              <OtherStepperItem />
            </li>

            <li class="shrink basis-0 flex-1 group" data-hs-stepper-nav-item='{"index": 5}'>
              <ReviewStepperItem />
            </li>
          </ul>
          <!-- End Stepper Nav -->

          <!-- Stepper Content -->
          <div class="mt-5 sm:mt-8 flex-col overflow-visible h-fit min-h-fit">
            <!-- First Content -->
            <div data-hs-stepper-content-item='{"index": 1}' class="overflow-visible">
              <StudentSection v-model="student" @isValid="isValid[0] = $event" />
            </div>
            <!-- End First Content -->

            <!-- Second Content -->
            <div data-hs-stepper-content-item='{"index": 2}' class="flex-col overflow-visible h-fit min-h-fit">
              <PersonsSection
                v-model="persons"
                @scrollToBottom="scrollToBottom"
                @isValid="
                  console.log('changing step 1 validity', $event);
                  isValid[1] = $event;
                "
                :requireAtLeastOnePatient="false"
                :startingFromCompleteCase="startingFromCompleteCase"
              />
            </div>
            <!-- End Second Content -->

            <!-- Third Content -->
            <div data-hs-stepper-content-item='{"index": 3}' class="overflow-visible">
              <ScenesAndTasksSection
                :startingFromCompleteCase="startingFromCompleteCase"
                v-model:tasks="tasks"
                v-model:persons="persons"
                @isValid="isValid[2] = $event"
              />
            </div>
            <!-- End Third Content -->

            <!-- Fourth Content -->
            <div data-hs-stepper-content-item='{"index": 4}' class="overflow-visible">
              <OtherSection v-model="otherCaseDetails" @isValid="isValid[3] = $event" />
            </div>
            <!-- End Fourth Content -->

            <!-- Fifth Content -->
            <div data-hs-stepper-content-item='{"index": 5}' class="overflow-visible">
              <ReviewSection
                v-if="currentStep >= 3"
                v-model="persons"
                @scrollToBottom="scrollToBottom"
                @isValid="isValid[4] = $event"
                :requireAtLeastOnePatient="false"
              />
            </div>
            <!-- End Fifth Content -->

            <!-- Final Content -->
            <div
              data-hs-stepper-content-item='{
      "isFinal": true
    }'
              style="display: none"
            >
              <div
                class="p-4 h-48 bg-gray-50 mb-4 flex flex-col justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
              >
                <!-- TODO: "Zum Fall"-Button -->
                <div
                  v-show="isSubmitting"
                  class="animate-spin ml-2 inline-block w-16 h-16 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                  role="status"
                  aria-label="loading"
                />
                <div v-show="!isSubmitting">
                  <span translate="no" class="material-symbols-outlined notranslate text-8xl"> cheer </span>
                </div>
                <h3 v-show="isSubmitting" class="text-gray-500 dark:text-neutral-500 pt-4">
                  ... Fall wird erstellt ...
                </h3>
                <h3 v-show="!isSubmitting" class="text-gray-500 dark:text-neutral-500 pt-4">Fertig</h3>
              </div>
            </div>
            <!-- End Final Content -->

            <!-- Button Group -->
            <div class="flex justify-between items-center gap-x-2">
              <button type="button" data-hs-stepper-back-btn="" @click="currentStep--">
                <ProgressButton
                  text="Zurück"
                  color="white"
                  icon="arrow_back_ios"
                  :iconLeft="true"
                  :disabled="currentStep === 0"
                  :fontMedium="true"
                />
              </button>

              <button type="button" v-show="DEBUG">
                <ProgressButton text="Force submit" color="red" :showProgress="isSubmitting" :onclick="onSubmit" />
              </button>

              <button type="button" v-if="DEBUG" data-hs-stepper-next-btn="" @click="currentStep++">
                <!-- NOTE: there can only be one button tagged data-hs-stepper-next-btn, so v-if/else, NOT v-show! -->
                <ProgressButton text="Force proceeed" color="blue" />
              </button>
              <button
                type="button"
                v-else
                data-hs-stepper-next-btn=""
                @click="currentStep++"
                :disabled="!isValid[currentStep]"
              >
                <ProgressButton
                  text="Weiter"
                  color="blue"
                  icon="arrow_forward_ios"
                  :iconLeft="false"
                  :disabled="!isValid[currentStep]"
                />
              </button>

              <button type="button" data-hs-stepper-finish-btn="" :disabled="!isValid[4]" style="display: none">
                <ProgressButton
                  text="Bestätigen & Erstellen"
                  :showProgress="isSubmitting"
                  :onclick="
                    () => {
                      currentStep++;
                      onSubmit();
                    }
                  "
                  :disabled="!isValid[4]"
                />
              </button>
              <!--              <button-->
              <!--                type="reset"-->
              <!--                class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"-->
              <!--                data-hs-stepper-reset-btn=""-->
              <!--                style="display: none"-->
              <!--                @click.prevent="-->
              <!--                  () => {-->
              <!--                    resetAll();-->
              <!--                    currentStep = 0;-->
              <!--                  }-->
              <!--                "-->
              <!--              >-->
              <!--                Neuen Fall von vorne beginnen-->
              <!--              </button>-->
              <div
                v-show="currentStep === 5 && !false"
                class="flex-col pt-4 gap-y-2 flex w-full text-sm text-gray-600 dark:text-gray-400"
              >
                <label class=""> Weiteren Fall erstellen </label>
                <div
                  type="reset"
                  data-hs-stepper-reset-btn=""
                  class="pt-2 inline-flex items-start border-gray-200 border-t gap-x-2"
                  @click.capture="
                    () => {
                      currentStep = 0;
                      if (willBeResetting) {
                        resetAll();
                      }
                    }
                  "
                >
                  <div @mousedown.prevent="willBeResetting = true">
                    <ProgressButton text="Von vorn beginnen" color="white" :fontMedium="true" />
                  </div>
                  <div @mousedown.prevent="willBeResetting = false">
                    <ProgressButton text="Von diesem Fall beginnen" color="blue" :fontMedium="true" />
                  </div>
                </div>
              </div>
            </div>
            <!-- End Button Group -->
          </div>
          <!-- End Stepper Content -->
        </div>
        <!-- End Stepper -->
      </div>
      <!-- End Card -->
    </div>
  </div>
  <!-- End of scrolling stuff -->

  <UserConfirmationModal
    ref="userConformationModalHandle"
    prompt_message="Fall abschließen"
    approve_message="Bestätigen"
    discard_message="Zurück"
    overlayId="confirmNewCaseModal"
    header_icon="warning"
    @approved="submitCase"
    @declined=""
  >
    <template v-slot:default>
      <div class="flex-col gap-y-2">
        <div class="mb-2">Bitte bestätige, dass beim Erstellen bzw. mit Veröffentlichen dieses Falls</div>
        <ul class="list-none">
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>kein</strong> urheberrechtlich geschütztes Material verwendet wurde bzw. anderen zugänglich
              gemacht wird, für das du nicht über die entsprechende Berechtigung verfügst,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>keine</strong> geschützten Patienten- oder Unternehmens- oder personenbezogenen oder
              personenbeziehbaren Daten verwendet wurden bzw. anderen zugänglich gemacht werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>keine</strong> diskriminierenden Inhalte verwendet wurden bzw. anderen zugänglich gemacht werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>nicht</strong> in anderer Weise Persönlichkeits-, Urheberrechts- oder Eigentumsrechte anderer oder
              berufliche Schweige- oder andere Pflichten verletzt wurden oder werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>nicht</strong> in anderer Weise gegen andere lokale Gesetzt verstoßen wurde oder wird oder
              Beihilfe dazu geleistet wird.
            </span>
          </li>
        </ul>
      </div>
    </template>
  </UserConfirmationModal>
</template>

<style></style>
