<!--

-->

<script setup lang="ts">
import { ref } from 'vue';

const editOn = ref(false);
const emit = defineEmits(['onToggle']);

function toggle() {
  editOn.value = !editOn.value;
  console.debug('Edit button: Edit mode toggled to: ' + editOn.value);
  emit('onToggle', editOn);
}
</script>

<template>
  <!-- Former "new answer" button, now expert edit -->
  <div class="mt-1 sm:mt-0">
    <button
      type="button"
      @click="toggle"
      class="text-xs py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none transition-all text-sm dark:hover:bg-neutral-800 dark:hover:text-gray-400 dark:hover:border-gray-900"
    >
      <svg
        class="h-2.0 w-2.0"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>
      <div v-if="editOn">Save</div>
      <div v-else>Edit</div>
      <!-- TODO: Functionality: change content to editible textarea, push to DB -->
      <!-- TODO 2: Enable in expert mode only -->
      <!-- TODO 3: Replace button by e.g. pencil symbol -->
      <!-- TODO 4: "attach" button to upload images etc. -->
    </button>
  </div>
  <!-- End of button -->
</template>

<style scoped></style>
