/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObserverInteractionMessage } from '../models/ObserverInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ObserverMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Observer Interaction Message
   * :param session:
   * :param user:
   * :param observer_interaction_message_id:
   * :return:
   * @param observerInteractionMessageId
   * @returns ObserverInteractionMessage Successful Response
   * @throws ApiError
   */
  public getObserverInteractionMessage(
    observerInteractionMessageId: string,
  ): CancelablePromise<ObserverInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/observer-messages/{observer_interaction_message_id}',
      path: {
        observer_interaction_message_id: observerInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Observer Interaction Message
   * @param observerInteractionMessageId
   * @param requestBody
   * @returns ObserverInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForObserverInteractionMessage(
    observerInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<ObserverInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/observer-messages/{observer_interaction_message_id}',
      path: {
        observer_interaction_message_id: observerInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Observer Message Translation
   * :param session:
   * :param user:
   * :param observer_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param observerInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestObserverMessageTranslation(
    observerInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/observer-messages/{observer_interaction_message_id}/translation',
      path: {
        observer_interaction_message_id: observerInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
