import { ref } from 'vue';
import { Ref } from 'vue';
import { getSentenceForCharacter } from '@/helper';

export function useChatBubbleFunctions() {
  // text highlighting / selection
  const selectedSentenceIndcs = ref([]);

  const calculateCols = (text: string) => {
    const containerWidth = window.innerWidth * 0.4;
    const estimatedCharWidth = 10; // Adjust this value based on font and styling
    return Math.floor((containerWidth / estimatedCharWidth) * 0.95);
  };

  const calculateRows = (text: string) => {
    let maxCols = calculateCols(text);
    return Math.ceil(text.length / maxCols);
  };

  const accumulatePreviousParagraphs = (range: Range): number => {
    let offset = 0;
    let previous = range.commonAncestorContainer.parentNode.previousSibling;
    while (previous) {
      offset += accumulateTextContent(previous).length;
      previous = previous.previousSibling;
    }
    return offset;
  };

  const getSelectedCharacterPositions = (ref: Ref<HTMLElement | null>): number[] => {
    const selection = window.getSelection();
    if (selection?.toString()) {
      // console.log('selection', selection.toString());
      const range = selection.getRangeAt(0);
      console.log('range', range);
      const offset = accumulatePreviousParagraphs(range);
      console.log('offset', offset);
      console.log('range.parent', range.commonAncestorContainer.parentNode.previousSibling);
      const positions: number[] = [];

      let position = 0;
      // const container = ref.value;
      const container = ref;
      console.log('container', container);

      if (container) {
        for (let i = 0; i < selection.toString().length; i++) {
          position = range.startOffset + i + offset;
          positions.push(position);
        }
      }

      return positions;
    }
    return [];
  };

  // Helper function to accumulate text content of child nodes recursively
  const accumulateTextContent = (node: Node): string => {
    let textContent = '';
    console.log('node: ', node);
    if (node.nodeType === Node.TEXT_NODE) {
      textContent += node.textContent || '';
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (const childNode of Array.from(node.childNodes)) {
        textContent += accumulateTextContent(childNode);
      }
    }
    return textContent;
  };

  return {
    selectedSentenceIndcs,
    calculateCols,
    calculateRows,
    getSelectedCharacterPositions,
  };
}
