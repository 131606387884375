/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OralExamInteractionMessage } from '../models/OralExamInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OralExamMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Oral Exam Interaction Message
   * :param session:
   * :param user:
   * :param oral_exam_interaction_message_id:
   * :return:
   * @param oralExamInteractionMessageId
   * @returns OralExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public getOralExamInteractionMessage(
    oralExamInteractionMessageId: string,
  ): CancelablePromise<OralExamInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/oral-exam-messages/oral-exam-message/{oral_exam_interaction_message_id}',
      path: {
        oral_exam_interaction_message_id: oralExamInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Oral Exam Interaction Message
   * @param oralExamInteractionMessageId
   * @param requestBody
   * @returns OralExamInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForOralExamInteractionMessage(
    oralExamInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<OralExamInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/oral-exam-messages/{oral_exam_interaction_message_id}',
      path: {
        oral_exam_interaction_message_id: oralExamInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Oral Exam Message Translation
   * :param session:
   * :param user:
   * :param oral_exam_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param oralExamInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestOralExamMessageTranslation(
    oralExamInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/oral-exam-messages/{oral_exam_interaction_message_id}/translation',
      path: {
        oral_exam_interaction_message_id: oralExamInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
