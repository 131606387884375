/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LabResult } from '../models/LabResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LabSheetsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Patient Interaction Lab Sheets
     * Return lab sheets as list of lists of individual results. Inner list is
     * one lab sheet.
     *
     * :param session:
     * :param user:
     * :param patient_interaction_id:
     * :return:
     * @param patientInteractionId
     * @returns LabResult Successful Response
     * @throws ApiError
     */
    public getPatientInteractionLabSheets(
        patientInteractionId: string,
    ): CancelablePromise<Array<Array<LabResult>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lab-sheets/{patient_interaction_id}',
            path: {
                'patient_interaction_id': patientInteractionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Patient Interaction Lab Result
     * Return individual lab result, generate translation if necessary.
     *
     * :param session:
     * :param user:
     * :param lab_result_id:
     * :return:
     * @param labResultId
     * @param targetLanguage
     * @returns LabResult Successful Response
     * @throws ApiError
     */
    public getPatientInteractionLabResult(
        labResultId: string,
        targetLanguage?: string,
    ): CancelablePromise<LabResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lab-sheets/parameter/{lab_result_id}',
            path: {
                'lab_result_id': labResultId,
            },
            query: {
                'target_language': targetLanguage,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
