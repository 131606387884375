<script setup lang="ts">
import ClinicalNote from '@/views/clinical-information-system/ClinicalNote.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAuthStore, usePatientInteractionStore, useCaseInteractionStore, useAlertStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const alertStore = useAlertStore();
const { user } = storeToRefs(authStore);
const { clinicalNotes } = storeToRefs(patientInteractionStore);
const { studentRole } = storeToRefs(caseInteractionStore);

const clinicalNoteHandles = ref<Array<any>>([]);
const localNotes = ref([]);
const rerender = ref(false);

const notes = computed(() => {
  let notes = [];

  for (let note of clinicalNotes.value) {
    if (note.created_at === 'incomplete') {
      notes.push({
        isEditing: false,
        isInDatabase: false,
        id: -1,
        content: '...lädt...',
        date: null,
        time: null,
        date_edited: null,
        time_edited: null,
        author: {
          id: null,
          details: {
            academic_title: '',
            first_name: '',
            last_name: '',
            position: '',
          },
          profile_image: null,
        },
      });
      continue;
    }

    let author = note.third_person ? note.third_person : note.attending;
    let position = note.attending ? 'Oberarzt' : note.third_person?.type.lower().replace('_', ' ');

    if (!author) {
      author = {
        id: user.value.id,
        details: {
          academic_title: user.value.academic_title,
          first_name: user.value.first_name,
          last_name: user.value.last_name,
        },
        profile_image: user.value.profile_image,
      };
      position = studentRole.value;
    }
    console.log(author);
    notes.push({
      isEditing: false,
      isInDatabase: true,
      id: note.id,
      content: note.content,
      date: note.created_at?.split('T')[0],
      time: note.created_at?.split('T')[1].split('.')[0],
      date_edited: note.last_edited_at?.split('T')[0],
      time_edited: note.last_edited_at?.split('T')[1].split('.')[0],
      author: {
        id: author.id,
        details: {
          academic_title: author.details.academic_title,
          first_name: author.details.first_name,
          last_name: author.details.last_name,
          position: position,
        },
        profile_image: author.profile_image,
      },
    });
  }

  return notes;
});

onMounted(() => {
  if (!notes) {
    return;
  }
  clinicalNoteHandles.value = notes.value.map(() => ref(null));
});

watch(
  () => notes.value,
  () => {
    if (!notes) {
      return;
    }
    clinicalNoteHandles.value = notes.value.map(() => ref(null));
    localNotes.value = notes.value;
  },
  { immediate: true, deep: true },
);

function disableAllOtherEdits(index: number) {
  for (let i = 0; i < clinicalNoteHandles.value.length; i++) {
    if (i !== index && clinicalNoteHandles.value[i].value) {
      clinicalNoteHandles.value[i].value[0].disableEdit();
    }
  }
}

async function addClinicalNote() {
  for (let i = 0; i < clinicalNoteHandles.value.length; i++) {
    if (clinicalNoteHandles.value[i].value) {
      clinicalNoteHandles.value[i].value[0].disableEdit();
    }
  }

  clinicalNoteHandles.value.push(ref(null));
  localNotes.value.push({
    isEditing: true,
    isInDatabase: false,
    id: notes.value.length + 1,
    content: '',
    author: {
      id: user.value.id,
      details: {
        academic_title: user.value.academic_title,
        first_name: user.value.first_name,
        last_name: user.value.last_name,
        position: studentRole.value,
      },
      profile_image: user.value.profile_image,
    },
    date: new Date().toISOString().split('T')[0],
    time: new Date().toISOString().split('T')[1].split('.')[0],
    date_edited: null,
    time_edited: null,
  });
}

async function saveLocalNote(inputMessage: string, index: number) {
  console.log('inputMessage', inputMessage);
  console.log('index', index);
  if (!inputMessage) {
    await alertStore.info('Bitte geben Sie einen Inhalt für die Notiz ein.');
    return;
  }

  if (localNotes.value[index].isInDatabase) {
    await patientInteractionStore.editNoteById(localNotes.value[index].id, inputMessage);
    return;
  }

  await patientInteractionStore.noteDown(inputMessage);
}

// TODO: on enter keyup , send localNotes with isInDatabase = false to DB thru patientInteractionStore.noteDown()
</script>

<template>
  <div class="min-w-full max-w-full flex-col py-10 px-2 sm:px-6 lg:py-14">
    <!-- Title -->
    <div class="mb-5 lg:mb-7">
      <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Klinische Notizen</h2>
    </div>
    <!-- End Title -->

    <!-- Contents -->
    <div class="min-w-full max-w-full flex-col overflow-y-scroll divide-y divide-gray-500 mb-5 lg:mb-7">
      <div v-for="(note, index) in localNotes" class="py-3 min-w-full w-full max-w-full">
        <ClinicalNote
          :ref="clinicalNoteHandles[index]"
          :key="note.id"
          :index="index"
          :content="note.content"
          :author="note.author"
          :date="note.date"
          :time="note.time"
          :isEditing="note.isEditing"
          :isSaved="note.isInDatabase"
          @editIsOn="disableAllOtherEdits(index)"
          @saveNote="saveLocalNote"
        />
      </div>
    </div>

    <div class="pb-5 lg:pb-7">
      <ProgressButton color="win-blue" size="md" text="Neue klinische Notiz" @click.prevent="addClinicalNote" />
    </div>
    <!-- End Contents -->
  </div>
</template>

<style scoped></style>
