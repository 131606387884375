import { defineStore, StoreDefinition, storeToRefs } from 'pinia';
import { Case, CaseInteraction, Task } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { usePatientInteractionStore } from '@/stores/patientInteraction.store';
import { useAttendingInteractionStore } from '@/stores/attendingInteraction.store';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { useAuthStore } from '@/stores/auth.store';
import { useAlertStore } from '@/stores/alert.store';

interface State {
  currentCase: Case | null;
  currentCaseInteraction: CaseInteraction | null;
  currentLearningObjectives: object;
  currentTaskIndex: number | null;
  previousTaskIndex: number | null;
  selectedPersonIndex: number | null;

  currentlyReadingChatMessageText: string;
  readingQueue: object[];
  isProcessingReadingChunk: boolean;

  audioIsStreaming: boolean;
  audioAutoplay: boolean;
  awaitingReactionOrDesc: boolean;

  waitingForResponse: boolean; // halt next reactions etc. until request is sent and before response stream begins
  firstAudioChunkReceived: boolean;
}

export const useCaseInteractionStore = defineStore({
  id: 'caseInteraction',
  state: (): State => ({
    currentCase: null as Case | null,
    currentCaseInteraction: null as CaseInteraction | null,
    currentLearningObjectives: {} as object,
    currentTaskIndex: null as number | null,
    previousTaskIndex: null as number | null,
    selectedPersonIndex: null as number | null,

    currentlyReadingChatMessageText: '',
    readingQueue: [],
    isProcessingReadingChunk: false,

    audioIsStreaming: false,
    audioAutoplay: true,
    awaitingReactionOrDesc: false,

    waitingForResponse: true,
    firstAudioChunkReceived: false,
  }),
  getters: {
    audioAutoplayEnabled(state) {
      return state.audioAutoplay;
    },
    currentCaseInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.id : null;
    },
    currentObserverInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.observer_interaction.id : '';
    },
    currentCaseInteractionLanguage(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.case_language : null;
    },
    anamnesisFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.anamnesis_finished_at);
    },
    patientInteractionFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.patient_interaction_finished_at);
    },
    reportFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.report_finished_at);
    },
    solved(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.solved_at);
    },
    currentCaseInteractionTasks(state) {
      return !!state.currentCase ? state.currentCase.tasks : [];
    },
    currentTask(state: State): Task | null {
      return state.currentTaskIndex !== null && state.currentCase
        ? state.currentCase.tasks[state.currentTaskIndex]
        : null;
    },
    previousTask(state: State): Task | null {
      return state.previousTaskIndex !== null && state.currentCase
        ? state.currentCase.tasks[state.previousTaskIndex]
        : null;
    },
    studentRole(state) {
      return state.currentCase?.student_details.role;
    },
    currentTaskInvolvements(state) {
      return;
    },
    somthingIsStreaming(state) {
      const patientInteractionStore = usePatientInteractionStore();
      const attendingInteractionStore = useAttendingInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      const caseInteractionStore = useCaseInteractionStore();

      // console.log('waitingForResponse', caseInteractionStore.waitingForResponse);
      //   console.log('awaitingReactionOrDesc', caseInteractionStore.awaitingReactionOrDesc);
      //   console.log('audioIsStreaming', caseInteractionStore.audioIsStreaming);
      //   console.log('chatIsStreaming', patientInteractionStore.chatIsStreaming);
      //   console.log('descIsStreaming', patientInteractionStore.descIsStreaming);
      //   console.log('labIsStreaming', patientInteractionStore.labIsStreaming);
      //   console.log('examinationIsStreaming', patientInteractionStore.examinationIsStreaming);
      //   console.log('chatIsStreaming', attendingInteractionStore.chatIsStreaming);
      //   console.log('anyChatIsStreaming', thirdPersonInteractionStore.anyChatIsStreaming);

      return (
        caseInteractionStore.waitingForResponse ||
        caseInteractionStore.awaitingReactionOrDesc ||
        caseInteractionStore.audioIsStreaming ||
        patientInteractionStore.chatIsStreaming ||
        patientInteractionStore.descIsStreaming ||
        patientInteractionStore.labIsStreaming ||
        patientInteractionStore.examinationIsStreaming ||
        attendingInteractionStore.chatIsStreaming ||
        thirdPersonInteractionStore.anyChatIsStreaming
      );
    },
    someChatIsStreaming(state) {
      const patientInteractionStore = usePatientInteractionStore();
      const attendingInteractionStore = useAttendingInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      return (
        patientInteractionStore.chatIsStreaming ||
        attendingInteractionStore.chatIsStreaming ||
        thirdPersonInteractionStore.anyChatIsStreaming
      );
    },
    allPersons(state, index: number | null = null) {
      console.log('allPersons');
      if (index == null && state.currentTaskIndex == null) {
        console.log('returning empty list');
        return [];
      }
      let store = useCaseInteractionStore();
      return store.getPersons(state.currentTaskIndex);
    },
    selectedPerson(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      return caseInteractionStore.allPersons[caseInteractionStore.selectedPersonIndex];
    },
    unselectedPersons(state) {
      if (state.selectedPersonIndex == null || !state.currentCaseInteraction?.case.persons) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      // return everything except at the index caseInteractionStore.selectedPersonIndex
      return caseInteractionStore.allPersons.filter(
        (_: any, index: number) => index !== caseInteractionStore.selectedPersonIndex,
      );
    },
    selectedPersonsLastChatMessage(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      if (selectedPerson?.type === 'PATIENT') {
        let store = usePatientInteractionStore();
        return store.chatMessages[store.chatMessages.length - 1];
      } else if (selectedPerson?.type === 'ATTENDING') {
        let store = useAttendingInteractionStore();
        return store.chatMessages[store.chatMessages.length - 1];
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        let store = useThirdPersonInteractionStore();
        return store.chatMessages[selectedPerson.thirdPersonIndex][
          store.chatMessages[selectedPerson.thirdPersonIndex].length - 1
        ];
      }
    },
    selectedPersonsVoice(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      if (selectedPerson?.type === 'PATIENT') {
        let store = usePatientInteractionStore();
        return store.patientVoice;
      } else if (selectedPerson?.type === 'ATTENDING') {
        let store = useAttendingInteractionStore();
        return store.attendingVoice;
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        let store = useThirdPersonInteractionStore();
        return store.thirdPersonVoice([selectedPerson.thirdPersonIndex]);
      }
    },
  },
  actions: {
    async enableAudioAutoplay() {
      console.log('Enabling audio autoplay');
      this.audioAutoplay = true;
      await this.processReadingQueue();
    },
    disableAudioAutoplay() {
      console.log('Disabling audio autoplay');
      this.audioAutoplay = false;
    },
    setFirstAudioChunkReceived(val: boolean) {
      console.log('First audio chunk received');
      this.firstAudioChunkReceived = val;
    },
    /**
     * Sets the current case interaction and fetches the case from the API if necessary.
     * Using this setter it is ensured that the case interaction and case are consistent.
     * @param {CaseInteraction} caseInteraction - The case interaction to set.
     */
    getPersons(taskIndex: number) {
      let store = useCaseInteractionStore();
      console.log(taskIndex);
      let involvements =
        taskIndex !== null && store.currentCase ? store.currentCase.tasks[taskIndex].person_task_involvements : [];
      console.log(involvements);
      let involvedPatientIds = involvements
        .filter((involvement: any) => involvement.patient_id !== null)
        .map((involvement: any) => involvement.patient_id);
      console.log(involvedPatientIds);
      let patientInvolvementTypes = involvements
        .filter((involvement: any) => involvement.patient_id !== null)
        .map((involvement: any) => involvement.type);
      let involvedAttendingIds = involvements
        .filter((involvement: any) => involvement.attending_id !== null)
        .map((involvement: any) => involvement.attending_id);
      let attendingInvolvementTypes = involvements
        .filter((involvement: any) => involvement.attending_id !== null)
        .map((involvement: any) => involvement.type);
      let involvedThirdPersonIds = involvements
        .filter((involvement: any) => involvement.third_person_id !== null)
        .map((involvement: any) => involvement.third_person_id);
      console.log(involvedThirdPersonIds);
      let thirdPersonInvolvementTypes = involvements
        .filter((involvement: any) => involvement.third_person_id !== null)
        .map((involvement: any) => involvement.type);
      console.log(thirdPersonInvolvementTypes);
      const patientInteractionStore = usePatientInteractionStore();
      const attendingInteractionStore = useAttendingInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      const patient =
        patientInteractionStore.currentPatient && involvedPatientIds.includes(patientInteractionStore.currentPatient.id)
          ? {
              index: 0,
              type: 'PATIENT',
              person: patientInteractionStore.currentPatient,
              profileImageSmall: patientInteractionStore.patientProfileImageSmall,
              profileImageLarge: patientInteractionStore.patientProfileImageLarge,
              placeholderMessage:
                `Mit Patient:in sprechen` +
                (patientInvolvementTypes[involvedPatientIds.indexOf(patientInteractionStore.currentPatient.id)] ===
                'ON_THE_PHONE'
                  ? '(am Telefon)'
                  : ''),
              involvement:
                patientInvolvementTypes[involvedPatientIds.indexOf(patientInteractionStore.currentPatient.id)],
            }
          : null;
      const attending =
        attendingInteractionStore.currentAttending &&
        involvedAttendingIds.includes(attendingInteractionStore.currentAttending.id)
          ? {
              index: 1,
              type: 'ATTENDING',
              person: attendingInteractionStore.currentAttending,
              profileImageSmall: attendingInteractionStore.attendingProfileImageSmall,
              profileImageLarge: attendingInteractionStore.attendingProfileImageLarge,
              placeholderMessage:
                (attendingInteractionStore.currentAttending?.details?.sex === 'MALE'
                  ? 'Deinen Oberarzt etwas fragen'
                  : 'Deine Oberärztin etwas fragen') +
                (attendingInvolvementTypes[
                  involvedAttendingIds.indexOf(attendingInteractionStore.currentAttending.id)
                ] === 'ON_THE_PHONE'
                  ? '(am Telefon)'
                  : ''),
              involvement:
                attendingInvolvementTypes[involvedAttendingIds.indexOf(attendingInteractionStore.currentAttending.id)],
            }
          : null;
      const thirdPersons = thirdPersonInteractionStore.currentThirdPersons
        .filter((person) => involvedThirdPersonIds.includes(person.id))
        .map((person, index) => ({
          index: index + 2,
          type: 'THIRD_PERSON',
          person: person,
          thirdPersonIndex: person.index,
          profileImageSmall: thirdPersonInteractionStore.thirdPersonProfileImageSmall(person.index),
          profileImageLarge: thirdPersonInteractionStore.thirdPersonProfileImageLarge(person.index),
          placeholderMessage:
            'Mit ' +
            thirdPersonInteractionStore.thirdPersonName(person.index) +
            ' sprechen' +
            (thirdPersonInvolvementTypes[involvedThirdPersonIds.indexOf(person.id)] === 'ON_THE_PHONE'
              ? '(am Telefon)'
              : ''),
          involvement: thirdPersonInvolvementTypes[involvedThirdPersonIds.indexOf(person.id)],
        }));
      console.log(thirdPersonInteractionStore.currentThirdPersons);
      console.log(thirdPersons);
      console.log(patient);
      console.log([patient, attending, ...thirdPersons]);
      console.log([patient, attending, ...thirdPersons].filter((item) => item !== null));
      return [patient, attending, ...thirdPersons].filter((item) => item !== null);
    },
    getFirstNonNullPersonIndex(taskIndex: number) {
      let store = useCaseInteractionStore();
      let persons = store.getPersons(taskIndex);
      console.log('allPersons', persons);
      // get first non-null person: patient, attending, third person
      let firstPersonIndex = persons.findIndex((person) => person !== null);
      console.log('firstPersonIndex for first nun-null person', firstPersonIndex);
      return firstPersonIndex;
    },
    getFirstNonNullPerson(taskIndex: number) {
      let store = useCaseInteractionStore();
      let persons = store.getPersons(taskIndex);
      console.log('allPersons', persons);
      // get first non-null person: patient, attending, third person
      return persons.find((person) => person !== null);
    },
    _getPersonIndexFromCaseInteraction(caseInteraction: CaseInteraction) {
      if (caseInteraction.current_person_selection_tablename === 'patient') {
        return 0;
      } else if (caseInteraction.current_person_selection_tablename === 'attending') {
        return 1;
      } else if (caseInteraction.current_person_selection_tablename === 'third_person') {
        return 2 + caseInteraction.current_person_selection_index;
      } else {
        console.log('Invalid current_person_selection_tablename ', caseInteraction.current_person_selection_tablename);
        return null;
      }
    },
    async _updateCaseInteractionWithNewSelectedPerson(personIndex: number) {
      if (personIndex === 0) {
        return (await getApiClient()).caseInteractions.selectPersonForInteraction(
          this.currentCaseInteractionId,
          'patient',
          null,
        );
      } else if (personIndex === 1) {
        return (await getApiClient()).caseInteractions.selectPersonForInteraction(
          this.currentCaseInteractionId,
          'attending',
          null,
        );
      } else {
        return (await getApiClient()).caseInteractions.selectPersonForInteraction(
          this.currentCaseInteractionId,
          'third_person',
          personIndex - 2,
        );
      }
    },
    async setCaseInteraction(caseInteraction: CaseInteraction) {
      this.resetCurrentlyReadingChatMessageText();
      this.currentCaseInteraction = caseInteraction;

      // check if patient is already set and the same as in the patient interaction
      // otherwise, fetch patient from API
      if (!(this.currentCase && this.currentCase.id === caseInteraction.case.id)) {
        this.currentCase = await (await getApiClient()).cases.getCase(caseInteraction.case.id);
      }
      console.debug('this.currentCaseInteraction', this.currentCaseInteraction);
      console.debug(' for case ' + this.currentCase.id);

      this.currentTaskIndex = caseInteraction.current_task_interaction_index;
      this.selectedPersonIndex = this._getPersonIndexFromCaseInteraction(caseInteraction);
    },
    async createCaseInteraction(caseId: string, t: any = null) {
      const response = await (
        await getApiClient()
      ).cases.createCaseInteraction(caseId, { learning_objectives: this.currentLearningObjectives });
      await this.setCaseInteraction(response['case_interaction']);
      let authStore = useAuthStore();
      if (!!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
    },
    async setCaseInteractionById(id: string) {
      // fetch patient interaction from API
      const caseInteraction = await (await getApiClient()).caseInteractions.getCaseInteraction(id, true);
      await this.setCaseInteraction(caseInteraction);
    },
    async updateCurrentCaseInteractionState() {
      if (!this.currentCaseInteraction) {
        return;
      }
    },
    setLearningObjectives(learningObjectives: object) {
      this.currentLearningObjectives = learningObjectives;
    },
    async setLearningObjectivesForSection(sectionId: string) {
      const client = await getApiClient();
      const learningObjectives = await client.sections.getLearningObjectivesOfSection(sectionId);
      this.setLearningObjectives(learningObjectives);
    },
    resetLearningObjectives() {
      this.currentLearningObjectives = null;
    },
    async endCase() {
      if (this.currentTaskIndex !== null) {
        await this._leaveTask(this.currentTaskIndex, true);
      }
      // TODO: handle other finish case stuff
    },
    async goToTask(newTaskIndex: number, isInitialTask: boolean = false) {
      if (!this.currentCaseInteraction) {
        return;
      }
      this.awaitingReactionOrDesc = true;
      console.log(this.currentTaskIndex);
      if (this.currentTaskIndex != null) {
        console.log('leaving old task');
        await this._leaveTask(this.currentTaskIndex, false); // no desc: one desc for task change from begin/revisit of new task
        console.log('leaving old task - done');
      }

      // set new task
      console.log(newTaskIndex);
      this.previousTaskIndex = this.currentTaskIndex;
      this.currentTaskIndex = newTaskIndex;
      await (
        await getApiClient()
      ).caseInteractions.setCurrentTaskInteractionIndex(this.currentCaseInteractionId, newTaskIndex);

      console.log(this.currentCaseInteraction.case.task_interactions);
      console.log('started at: ', this.currentCaseInteraction.task_interactions[newTaskIndex].started_at);
      let alreadyVisited = !!this.currentCaseInteraction.task_interactions[newTaskIndex].started_at;
      if (alreadyVisited) {
        console.log('revisiting task');
        await this._revisitTask(newTaskIndex);
        console.log('revisiting task - done');
      } else {
        console.log('beginning new task');
        await this._beginNewTask(newTaskIndex, isInitialTask);
        console.log('beginning new task - done');
      }
      this.awaitingReactionOrDesc = false;
    },
    async _requestDescriptionMessageForEvent(eventType: string, taskIndex: number) {
      let store = useCaseInteractionStore();
      this.waitingForResponse = true;
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();

      let currentTask =
        this.currentTaskIndex !== null && this.currentCase ? this.currentCase.tasks[this.currentTaskIndex] : null;
      await patientInteractionStore.createPreliminaryDescMessage();
      let authStore = useAuthStore();
      console.log('tasks: ', this.currentCaseInteraction.case.tasks);
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        '/observer-interactions/' + patientInteractionStore.currentObserverInteractionId,
        {
          content: '',
          type: 'DESCRIPTION',
          event: {
            type: eventType,
            details: {
              new_task_id: this.currentCaseInteraction.case.tasks[taskIndex].id,
            },
          },
          current_task: currentTask,
          language_level: authStore.currentLanguageLevel,
        },
        patientInteractionStore.appendToLastDescMessageAndRefetchOnceFinished,
      );
    },
    async _triggerReactionForEvent(
      eventType: string,
      taskIndex: number,
      resumeBeforePlaybackFinished: boolean = false, // only used at initial REACT when user waiting at CaseInteraction spinner for the case to start
    ) {
      let caseInteractionStore = useCaseInteractionStore();
      let authStore = useAuthStore();
      this.waitingForResponse = true; // used in this same function directly
      if (caseInteractionStore.selectedPersonIndex == null) {
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      const attendingInteractionStore = useAttendingInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      let store = null as
        | typeof patientInteractionStore
        | typeof attendingInteractionStore
        | typeof thirdPersonInteractionStore
        | null;
      let url = '';
      let messageType = '';
      let concernsThirdPerson = false;
      let thirdPersonIndex = null as number | null;
      let appendToChatMessageAndRefetchOnceFinished = null;
      caseInteractionStore = useCaseInteractionStore();
      console.log(caseInteractionStore.selectedPersonIndex);
      console.log(caseInteractionStore.allPersons);
      console.log(caseInteractionStore.selectedPersonIndex);
      let selectedPerson = caseInteractionStore.selectedPerson;
      console.log(selectedPerson);
      if (selectedPerson?.type === 'PATIENT') {
        store = patientInteractionStore;
        appendToChatMessageAndRefetchOnceFinished = store.appendToLastChatMessageAndRefetchOnceFinished;
        url = '/patient-interactions/' + patientInteractionStore.currentPatientInteractionId;
        messageType = 'REACT';
      } else if (selectedPerson?.type === 'ATTENDING') {
        store = attendingInteractionStore;
        appendToChatMessageAndRefetchOnceFinished = store.appendToLastChatMessageAndRefetchOnceFinished;
        url = '/attending-interactions/' + attendingInteractionStore.currentAttendingInteractionId;
        messageType = 'ATTENDING_REACT';
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        concernsThirdPerson = true;
        thirdPersonIndex = selectedPerson?.thirdPersonIndex as number;
        store = thirdPersonInteractionStore;
        appendToChatMessageAndRefetchOnceFinished = (chunk: string) =>
          store.appendToLastChatMessageAndRefetchOnceFinished(thirdPersonIndex, chunk);
        url =
          '/third-person-interactions/' +
          thirdPersonInteractionStore.currentThirdPersonInteractionIds[thirdPersonIndex];
        messageType = 'THIRD_PERSON_REACT';
      } else {
        console.error('Unknown person type', selectedPerson?.type, ' of person ', selectedPerson, '.');
        return;
      }
      if (concernsThirdPerson) {
        await store.createPreliminaryChatMessage(thirdPersonIndex);
      } else {
        await store.createPreliminaryChatMessage();
      }
      let currentTask =
        this.currentTaskIndex !== null && this.currentCase ? this.currentCase.tasks[this.currentTaskIndex] : null;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          content: '',
          type: messageType,
          event: {
            type: eventType,
            details: {
              new_task_id: this.currentCaseInteraction.case.tasks[taskIndex].id,
            },
          },
          current_task: currentTask,
          language_level: authStore.currentLanguageLevel,
        },
        appendToChatMessageAndRefetchOnceFinished,
      );
      let waitingForAudioAfterTextStreamFinished = 0;
      caseInteractionStore = useCaseInteractionStore();
      while (
        (concernsThirdPerson ? store.anyChatIsStreaming : store.chatIsStreaming) ||
        caseInteractionStore.audioIsStreaming ||
        caseInteractionStore.waitingForResponse
      ) {
        console.log('waiting for ' + messageType + ' for ' + eventType + ' to finish.');
        caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
          return;
        }
        if (
          concernsThirdPerson
            ? store.anyChatIsStreaming
            : store.chatIsStreaming || caseInteractionStore.audioIsStreaming
        ) {
          console.log('CHAT response started');
          this.waitingForResponse = false;
        }
        if (
          !caseInteractionStore.waitingForResponse &&
          resumeBeforePlaybackFinished &&
          caseInteractionStore.firstAudioChunkReceived
        ) {
          // this shall be done if the user is waiting at the CaseInteraction spinner for the case to start
          console.log('Resuming before playback');
          break;
        }
        if (!caseInteractionStore.waitingForResponse && !caseInteractionStore.audioIsStreaming) {
          if (concernsThirdPerson) {
            console.log(store.anyChatIsStreaming);
          } else {
            console.log(store.chatIsStreaming);
          }
          // console.log(
          //   'Text stream FINISHED. Waiting for audio to finish - ' + waitingForAudioAfterTextStreamFinished + '/60',
          // );
          waitingForAudioAfterTextStreamFinished++;
        }
        if (waitingForAudioAfterTextStreamFinished > 10) {
          console.warn('Audio stream did not start after 10 seconds. Breaking loop.');
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('FINISHED waiting for ' + messageType + ' for ' + eventType + ' to finish.');
    },
    async _leaveTask(taskIndex: number, requestDescription: boolean = false) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      console.log('Ending task ', taskIndex);
      if (requestDescription) {
        await this._requestDescriptionMessageForEvent('LEAVE_TASK', taskIndex);
        let caseInteractionStore = useCaseInteractionStore();
        // wait until streaming is FINISHED (so not two incomplete messages are in the chat at the same time)
        while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
          caseInteractionStore = useCaseInteractionStore();
          if (!caseInteractionStore.currentCaseInteraction) {
            console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
            return;
          }
          if (patientInteractionStore.descIsStreaming) {
            console.log('DESC response started');
            this.waitingForResponse = false;
          }
          console.log('leaveTask: waiting for DESC to finish.');
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        console.log('leaveTask: DESC FINISHED.');
      }
      // get reaction of currently focused/ selected person
      await this._triggerReactionForEvent('LEAVE_TASK', taskIndex);
      console.log('leaveTask: REACT FINISHED.');
    },
    async _beginNewTask(taskIndex: number, isInitialTask: boolean = false) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      let caseInteractionStore = useCaseInteractionStore();
      const patientInteractionStore = usePatientInteractionStore();
      console.log('Beginning task ', taskIndex);
      await caseInteractionStore.selectFirstNonNullPerson();
      // request description message for beginning new task
      await this._requestDescriptionMessageForEvent('BEGIN_TASK', taskIndex);
      // wait until streaming is FINISHED (so not two incomplete messages are in the chat at the same time)
      while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
        caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
          return;
        }
        if (patientInteractionStore.descIsStreaming) {
          console.log('DESC response started');
          this.waitingForResponse = false;
        }
        console.log('beingNewTask: waiting for DESC to finish.');
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('beingNewTask: DESC FINISHED.');
      // get reaction of currently focused/ selected person
      await this._triggerReactionForEvent('BEGIN_TASK', taskIndex, isInitialTask);
      console.log('beginNewTask: REACT FINISHED.');
    },
    async _revisitTask(taskIndex: number) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      console.log('Revisiting task ', taskIndex);
      await this.selectFirstNonNullPerson();
      // request description message for beginning new task
      await this._requestDescriptionMessageForEvent('REVISIT_TASK', taskIndex);
      // wait until streaming is FINISHED (so not two incomplete messages are in the chat at the same time)
      let caseInteractionStore = useCaseInteractionStore();
      while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
        caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
          return;
        }
        if (patientInteractionStore.descIsStreaming) {
          console.log('DESC response started');
          this.waitingForResponse = false;
        }
        console.log('revisitTask: waiting for DESC to finish.');
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('revisitTask: DESC FINISHED.');
      // get reaction of currently focused/ selected person
      await this._triggerReactionForEvent('REVISIT_TASK', taskIndex);
      console.log('revisitTask: REACT FINISHED.');
    },
    async selectFirstNonNullPerson() {
      let store = useCaseInteractionStore();
      let firstPersonIndex = store.getFirstNonNullPersonIndex(store.currentTaskIndex);
      if (firstPersonIndex === -1) {
        console.error('No person available');
        return;
      }
      console.log('firstPersonIndex', firstPersonIndex);
      await store.selectPerson(firstPersonIndex);
    },
    async selectPerson(personIndex: number) {
      const caseInteractionStore = useCaseInteractionStore();
      caseInteractionStore.selectedPersonIndex = personIndex;
      await caseInteractionStore._updateCaseInteractionWithNewSelectedPerson(personIndex);
    },
    async readChunk(chunk: object) {
      const { readingChars, readingDurations } = chunk;
      for (let i = 0; i < readingChars.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, readingDurations[i]));
        this.currentlyReadingChatMessageText += readingChars[i];
      }
    },
    async processReadingQueue() {
      // Ensure only one chunk is processed at a time
      if (this.isProcessingReadingChunk) return;
      this.isProcessingReadingChunk = true;

      while (this.readingQueue.length > 0) {
        await new Promise((resolve) => setTimeout(resolve, 0));

        const chunk = this.readingQueue.shift();
        if (!chunk) {
          this.isProcessingReadingChunk = false;
          return;
        }
        await this.readChunk(chunk);
      }

      this.isProcessingReadingChunk = false;
    },
    async addChunkToReadingQueue(chunk: object) {
      console.log('Store: Receiveing chunk=', chunk);
      this.readingQueue.push(chunk);
      // If no chunk is currently being processed, start processing the queue
      if (!this.isProcessingReadingChunk && this.audioAutoplay) {
        await this.processReadingQueue();
      }
    },
    resetCurrentlyReadingChatMessageText() {
      this.readingQueue.length = 0;
      this.currentlyReadingChatMessageText = '';
    },
    setAudioIsStreaming(val: boolean) {
      console.log('Setting audioIsStreaming to ', val);
      this.audioIsStreaming = val;
    },
    setWaitingForResponse(val: boolean) {
      console.log('Setting waitingForResponse to ', val);
      this.waitingForResponse = val;
    },
    async reset() {
      this.currentCase = null;
      this.currentCaseInteraction = null;
      this.currentTaskIndex = null;
      this.currentLearningObjectives = {};
      this.previousTaskIndex = null;
      this.selectedPersonIndex = null;
      this.currentlyReadingChatMessageText = '';
      this.readingQueue.length = 0;
      this.isProcessingReadingChunk = false;
      this.audioIsStreaming = false;
      this.audioAutoplay = true;
      this.awaitingReactionOrDesc = false;
      this.waitingForResponse = true;
      this.firstAudioChunkReceived = false;
    },
  },
});

export const getCurrentTask = () => {
  let store = useCaseInteractionStore();
  return store.currentTask;
};
