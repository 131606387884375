<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
console.log('alertStore instance in SignUp:', useAlertStore);
import { router } from '@/router';
import { ErrorMessage, configure, useForm, useIsSubmitting } from 'vee-validate';
import ProgressButton from '@/components/ProgressButton.vue';

const authStore = useAuthStore();
const alertStore = useAlertStore();

const { meta, errors, handleSubmit, defineField, resetForm } = useForm({
  validationSchema: {
    password: 'required|password',
    passwordConfirm: 'required|confirmed:@password',
    acceptTermsOfUse: 'required',
  },
});

const [password, passwordAttrs] = defineField('password');
const [passwordConfirm, passwordConfirmAttrs] = defineField('passwordConfirm');
const [acceptTermsOfUse, acceptTermsOfUseAttrs] = defineField('acceptTermsOfUse');

const onSubmit = handleSubmit.withControlled(async (values) => {
  // submit invite via API
  const apiClient = await getApiClient();
  apiClient.authentication
    .acceptInvite({
      token_hash: token.value,
      password: values.password,
    })
    .then((response) => {
      console.log('Invite accepted:', response);
      alertStore.success('status.success.invitationAccepted');
    })
    .catch((error) => {
      console.error('Error accepting invite:', error);
      alertStore.error('status.error.acceptInviteError');
    })
    .finally(async () => {
      isSubmitting.value = false;
      await router.push('/account/sign-in');
    });
});

const isSubmitting = useIsSubmitting();

const token = ref(null);

onMounted(async () => {
  await router.isReady();
  // get the query params
  const query = router.currentRoute.value.query;

  token.value = query.token;
  console.debug('Token:', token.value);
  if (!token.value) {
    alertStore.error('status.error.invalidToken');
    router.push('/');
    return;
  }
});
</script>

<template>
  <main class="w-full h-screen max-w-md mx-auto p-6">
    <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.acceptInvite') }}</h1>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.choosePassword') }}
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <form @submit="onSubmit">
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label class="block text-sm mb-2 dark:text-white">{{ $t('message.password') }}</label>
                </div>
                <div class="relative">
                  <input
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    type="password"
                    v-model="password"
                    v-bind="passwordAttrs"
                    :class="{
                      'border-red-500': errors.password,
                    }"
                  />
                  <p class="text-sm text-red-600 mt-2">{{ errors.password }}</p>
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label class="block text-sm mb-2 dark:text-white">{{ $t('message.confirmPassword') }}</label>
                </div>
                <div class="relative">
                  <input
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    type="password"
                    v-model="passwordConfirm"
                    v-bind="passwordConfirmAttrs"
                    :class="{
                      'border-red-500': errors.passwordConfirm,
                    }"
                  />
                  <p class="text-sm text-red-600 mt-2">{{ errors.passwordConfirm }}</p>
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Checkbox -->
              <div>
                <div class="flex items-center">
                  <div class="flex">
                    <input
                      type="checkbox"
                      name="acceptTermsOfUse"
                      v-model="acceptTermsOfUse"
                      v-bind="acceptTermsOfUseAttrs"
                      :class="{
                        'border-red-500': errors.acceptTermsOfUse,
                      }"
                      class="border-gray-200 rounded text-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                    />
                  </div>
                  <div class="ml-3">
                    <label class="text-sm dark:text-white"
                      >{{ $t('message.acceptTerms') }}
                      <a class="text-blue-600 decoration-2 hover:underline font-medium" href="/privacy-and-terms">{{
                        $t('message.termsOfUse')
                      }}</a>
                      und
                      <a class="text-blue-600 decoration-2 hover:underline font-medium" href="/privacy-and-terms">{{
                        $t('message.privacyPolicy')
                      }}</a></label
                    >
                  </div>
                </div>
                <p class="text-sm text-red-600 mt-2">{{ errors.acceptTermsOfUse }}</p>
              </div>

              <!-- End Checkbox -->

              <ProgressButton
                size="sm"
                :text="$t('message.complete')"
                :showProgress="isSubmitting"
                :disabled="!meta.valid || isSubmitting"
                type="submit"
              />
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
