/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tag } from '../models/Tag';
import type { TagCreate } from '../models/TagCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Available Tags
   * Returns all available tags.
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public getAvailableTags(): CancelablePromise<Array<Tag>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tags/',
    });
  }

  /**
   * Create Tag
   * Creates a new tag and associated CaseList.
   * @param requestBody
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public createTag(requestBody: TagCreate): CancelablePromise<Tag> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/tags/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
