<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';

type SocialMediaItem = {
  brand: string;
  url: string;
};

const props = defineProps({
  firstName: {
    type: String,
    default: 'Team Member',
  },
  lastName: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: 'Team member',
  },
  professionalBackground: {
    type: String,
    default: 'Physiker',
  },
  mail: {
    type: String,
    default: '',
  },
  socialMedia: {
    type: Array as () => SocialMediaItem[],
    default: () => [],
  },
});

const profileImg = ref();

onMounted(async () => {
  await nextTick();
  import(
    `@/assets/images/about-us/${props.firstName
      .toLowerCase()
      .replace('ü', 'ue')
      .replace('ä', 'ae')
      .replace('ö', 'oe')
      .replace('é', 'e')
      .replace('ß', 'ss')}.jpg`
  ).then((img) => {
    profileImg.value = img.default;
  });
});
</script>

<template>
  <div class="grid sm:flex sm:items-center gap-y-3 justify-left gap-x-4 md:pl-16 xl:pl-36">
    <a :href="'mailto:' + props.mail">
      <img class="rounded-full w-28 h-28" :src="profileImg" :alt="props.firstName" />
    </a>

    <div class="flex-col flex gap-4">
      <div>
        <a :href="'mailto:' + props.mail">
          <h3 class="font-medium text-gray-800 dark:text-neutral-200">
            {{ props.firstName }}
          </h3>
          <div class="text-xs uppercase text-gray-500 dark:text-neutral-500">
            {{ props.position }} | {{ props.professionalBackground }}
          </div>
        </a>
      </div>
      <!-- Social Brands -->
      <div class="sm:mt-auto flex space-x-2.5 items-center">
        <SocialIcon v-for="item in props.socialMedia" :key="item.brand" :brand="item.brand" :to="item.url" size="4" />
      </div>
      <!-- End Social Brands -->
    </div>
  </div>
</template>

<style scoped></style>
