<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, Ref, ref } from 'vue';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import { formatDate, mapJobStatus, mapTitle } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import TextEditor from '@/views/courses/TextEditor.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import SelectCasesModal from '@/components/SelectCasesModal.vue';
import { v4 as uuidv4 } from 'uuid';

import { useI18n } from 'vue-i18n';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import SectionMediaUpload from '@/views/courses/SectionMediaUpload.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import SectionCreateExercise from '@/views/courses/SectionCreateExercise.vue';
import PairOfTerms from '@/components/didactics/pair_of_terms/PairOfTerms.vue';
import { DEBUG } from '@/helper/debugging';
import SectionCreateVocabListAndTest from '@/views/courses/SectionCreateVocabListAndTest.vue';
import SectionContent from '@/views/courses/SectionContent.vue';
import { debounce } from 'lodash';
import { onBeforeUnmount } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import Badge from '@/components/Badge.vue';
import { HSTooltip } from 'preline';
import Tooltip from '@/components/Tooltip.vue';

const { t } = useI18n();

const props = defineProps(['index', 'chapterId', 'chapterIndex', 'outerHeaderHeight', 'fullWidth']);
const caseList = ref();
const media = ref([] as any[]);
const pairOfTermsGames = ref([] as any[]);
const uuid = ref(uuidv4());

// ref for components
const unsavedChangesTextCounter = ref(0);
const unsavedChangesTitleCounter = ref(0);
const unsavedChangesLearningObjectivesCounter = ref(0);
const aboutToDeleteTextItemAtIndex = ref([] as boolean[]);
const aboutToDeleteContentItemWithId = ref(null as string | null);
const createVocabRef = ref(null);
const titleEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const sectionContentComponent = ref<InstanceType<typeof SectionContent> | null>(null);

const isAddingContentItem = ref(false);
const outerContentContainer = ref(null);
const gap = ref(50);
const padding = ref(100);

const vocabListLoading = ref(false);

const outerContentContainerWidth = computed(() => {
  return props.fullWidth - 2 * padding.value;
});

const unsavedChangesCounter = computed(() => {
  return (
    unsavedChangesTextCounter.value + unsavedChangesTitleCounter.value + unsavedChangesLearningObjectivesCounter.value
  );
});

const adjustPadding = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    padding.value = 5;
  } else if (screenWidth < 1024) {
    padding.value = 10;
  }
  padding.value = Math.min(100, Math.round(screenWidth * 0.05));
};

const debounceAdjustPadding = debounce(adjustPadding, 100);
const computedContentForExtraction = ref('');
const learningObjectives = computed(() => sectionContentComponent.value?.learningObjectives);

const computeTotalContent = () => {
  const title = titleEditor.value ? titleEditor.value.getHTMLContent() : '';

  if (!sectionContentComponent.value) {
    return title;
  }
  let content = sectionContentComponent.value.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHTMLContent();
    })
    .join(' ');

  return title + ' ' + content;
};

const onExtractLearningObjectives = async () => {
  computedContentForExtraction.value = computeTotalContent();
  if (!sectionContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  if (!computedContentForExtraction.value) {
    // empty content, nothing to do
    return;
  }
  userConformationModalOverwriteLearningObjectives.value.promptUserConformation().catch((error: any) => {
    console.debug('User declined: ' + error);
    return false;
  });
};

const extractLearningObjectives = async () => {
  if (!sectionContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  sectionContentComponent.value.learningObjectives.extractAndSetLearningObjectives(computedContentForExtraction.value);
};

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const userConformationModalOverwriteLearningObjectives = ref();
// const userConformationModalOverwriteVocab = ref();
const userConformationModalHandleDeleteTextItem = ref();
const selectCasesModal = ref(null);
const fetchCompleted = ref(false);
const sendingTranslationRequest = ref(false);
const sendingVocabularyExtractionRequest = ref(false);

const { currentChapter } = storeToRefs(courseStore);
const emit = defineEmits([
  'unsavedChangesText',
  'unsavedChangesLearningObjectives',
  'changesTextCleared',
  'unsavedChangesTitle',
  'changesTitleCleared',
  'onDeleteSection',
]);

const section = computed(() => {
  if (!currentChapter.value || !currentChapter.value.sections || props.index == null) {
    return null;
  }
  return currentChapter.value.sections[props.index];
});

const isPublished = computed(() => {
  return section.value?.published_at != null;
});

function signalUnsavedChangesLearningObjectives() {
  unsavedChangesCounter.value++;
  emit('unsavedChangesLearningObjectives');
}

function signalUnsavedChangesText(contentItemId: string, newHtml: string) {
  console.log('unsaved changes forwarded');
  unsavedChangesCounter.value++;
  emit('unsavedChangesText', contentItemId, newHtml);
}

function signalChangesTextCleared(contentItemId: string) {
  console.log('changes cleared forwarded');
  unsavedChangesCounter.value--;
  emit('changesTextCleared', contentItemId);
}

function signalUnsavedChangesTitle(newRatText: string) {
  console.log('unsaved changes forwarded');
  unsavedChangesCounter.value++;
  emit('unsavedChangesTitle', newRatText);
}

function signalChangesTitleCleared() {
  console.log('changes cleared forwarded');
  unsavedChangesCounter.value--;
  emit('changesTitleCleared');
}

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  if (!sectionLearningObjectives) {
    return;
  }

  console.log(sectionContentComponent.value);
  console.log(sectionContentComponent.value?.learningObjectives);

  if (!sectionContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  if (!section.value) {
    return;
  }
  console.log('learningObjectives ref: ' + sectionContentComponent.value.learningObjectives);
  console.log('section.learning_objectives: ' + JSON.stringify(section.value.learning_objectives));
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  sectionContentComponent.value.learningObjectives.setLearningObjectives(sectionLearningObjectives.slice(), false);
}

onBeforeMount(async () => {
  await courseStore.settingCoursePromise;
  await courseStore.settingChapterPromise; // only mount after chapter is loaded to store
  // console.log('section: ' + JSON.stringify(section));
});

onMounted(async () => {
  await nextTick(() => {
    HSTooltip.autoInit();
  });

  if (!section.value) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + section.value.learning_objectives?.length);
  setLearningObjectives(section.value.learning_objectives);

  adjustPadding();
  window.addEventListener('resize', debounceAdjustPadding);

  setTimeout(async () => {
    await triggerSectionRefetch(); // replaces case preview with full case details
  }, 200); // deferring this is a bit of a dirty hack
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceAdjustPadding);
});

const onTranslateSection = () => {
  // TODO i18n
  sendingTranslationRequest.value = true;
  if (!section.value) {
    return;
  }
  courseStore
    .requestSectionTranslations(section.value.id, 'deu', ['eng', 'rus', 'aeb', 'pes'])
    .then(() => {
      alertStore.success('Übersetzungsauftrag wurde erfolgreich gestartet.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Starten des Übersetzungsauftrags: ' + error);
    })
    .finally(() => {
      sendingTranslationRequest.value = false;
    });
};

// const onExtractVocabulary = async () => {
//   // TODO i18n
//   if (unsavedChangesCounter.value > 0) {
//     alertStore.error('Bitte speichern Sie Ihre Änderungen, bevor Sie Vokabeln extrahieren lassen.');
//     return;
//   }
//   if (!section.value) {
//     return;
//   }
//   await userConformationModalOverwriteVocab.value.promptUserConformation().catch((error: any) => {
//     console.debug('User declined: ' + error);
//     return false;
//   });
// };

// const extractVocabulary = async () => {
//   sendingVocabularyExtractionRequest.value = true;
//   courseStore
//     .extractSectionVocabularyAndExampleFormulations(section.value.id)
//     .then(() => {
//       alertStore.success('Vokabular erfolgreich extrahiert.');
//     })
//     .catch((error) => {
//       alertStore.error('Fehler beim Extrahieren des Vokabulars: ' + error);
//     })
//     .finally(() => {
//       sendingVocabularyExtractionRequest.value = false;
//     });
// };

const resetUnsavedChangesTitle = async () => {
  unsavedChangesTitleCounter.value = 0;
  if (!titleEditor.value) {
    return;
  }
  titleEditor.value.resetEmitState();
};

const resetUnsavedChangesTextItems = async () => {
  unsavedChangesTextCounter.value = 0;
  if (!sectionContentComponent.value.contentEditors.length) {
    return;
  }
  await sectionContentComponent.value.resetTextEditorEmitStates();
};

const resetUnsavedChangesLearningObjective = () => {
  unsavedChangesLearningObjectivesCounter.value = 0;
};

const onDeleteContentItem = async (contentItemId: string, content_item_type: string, textItemIndex: number) => {
  if (!section.value) {
    return;
  }

  aboutToDeleteContentItemWithId.value = contentItemId;
  if (content_item_type === 'TEXT') {
    aboutToDeleteTextItemAtIndex.value[textItemIndex] = true; // okay if nextTick before open
  }
  await userConformationModalHandleDeleteTextItem.value.promptUserConformation();
};

const deleteContentItem = async () => {
  if (!section.value) {
    return;
  }
  if (!aboutToDeleteContentItemWithId.value) {
    return;
  }
  fetchCompleted.value = false;
  await courseStore
    .removeContentItem(section.value.id, aboutToDeleteContentItemWithId.value)
    .then(() => {
      alertStore.success('Gelöscht.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Löschen>: ' + error);
    })
    .finally(async () => {
      await setEditorRefs(); // do not forget!
      aboutToDeleteTextItemAtIndex.value = aboutToDeleteTextItemAtIndex.value.map(() => false); // all to false
      fetchCompleted.value = true;
    });
};

const addTextSnippet = async () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  fetchCompleted.value = false;
  courseStore
    .addTextSnippet(section.value.id, props.index)
    .then(() => {
      alertStore.success('Textabschnitt erfolgreich hinzugefügt.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Hinzufügen des Textabschnitts: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      fetchCompleted.value = true;
      await setEditorRefs();
    });
};

const addPairOfTermsGame = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  fetchCompleted.value = false;
  courseStore
    .addPairOfTermsGame(section.value.id)
    .then(() => {
      alertStore.success('Zuordnungsübung erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Zuordnungsübung: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      fetchCompleted.value = true;
      await setEditorRefs();
    });
};

const onAddCase = () => {
  if (!section.value) {
    return;
  }
  if (!selectCasesModal.value) {
    return;
  }
  selectCasesModal.value.promptSelection().catch((error: any) => {
    alertStore.error('Fehler beim Öffnen der Fallauswahl: ' + error);
    return false;
  });
};

const addCases = async (selectedCaseIds: string[]) => {
  console.log('add cases: ' + selectedCaseIds);
  if (!section.value?.id) {
    return;
  }
  if (!selectedCaseIds.length) {
    return;
  }
  selectedCaseIds.map((caseId) => {
    courseStore
      .addCase(section.value.id, caseId)
      .then(() => {
        alertStore.success('Fall erfolgreich hinzugefügt.');
      })
      .catch((error: any) => {
        alertStore.error('Fehler beim Hinzufügen des Falls ' + caseId + ': ' + error);
      });
  });
};

const setEditorRefs = async () => {
  if (!section.value) {
    return;
  }
  if (!section.value.section_content_items) {
    return;
  }
  if (!sectionContentComponent.value) {
    return;
  }
  await sectionContentComponent.value.setEditorRefs();
  if (section.value.section_content_items) {
    aboutToDeleteTextItemAtIndex.value.length = 0;
    for (let contentItem of section.value.section_content_items) {
      if (contentItem.content_type === 'TEXT') {
        aboutToDeleteTextItemAtIndex.value.push(false);
      }
    }
  }
  // console.log('Now holding # content editors: ' + sectionContentComponent.value?.contentEditors.value.length);
  await nextTick();
};

const triggerSectionRefetch = async () => {
  fetchCompleted.value = false;
  await courseStore.refetchSelectedSection(props.index);
  await setEditorRefs();
  fetchCompleted.value = true;
};

const getLearningObjectives = () => {
  return learningObjectives.value.getLearningObjectives();
};

const generateAndAddMoreVocab = async (contentItemId: string) => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  vocabListLoading.value = true;
  // append all text items via ' ' to get a single string
  const context = sectionContentComponent.value?.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHTMLContent();
    })
    .join(' ');
  courseStore
    .generateAndAddMoreVocab(section.value.id, contentItemId, context)
    .then(() => {
      alertStore.success('Vokabeln hinzugefügt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Erzeugen weiterer Vokabeln: ' + error);
    })
    .finally(async () => {
      vocabListLoading.value = false;
    });
};

const addVocabListExtractingFromLastTextItem = async () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  fetchCompleted.value = false;
  // get last text item
  const textToExtractFrom =
    sectionContentComponent.value?.contentEditors[
      sectionContentComponent.value?.contentEditors.length - 1
    ].value[0].getHTMLContent();
  courseStore
    .addVocabListFromExtractions(section.value.id, textToExtractFrom)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      fetchCompleted.value = true;
      await setEditorRefs();
    });
};

const addVocabListExtractingFromAllTextItems = async () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  fetchCompleted.value = false;
  // append all text items via ' ' to get a single string
  const textToExtractFrom = sectionContentComponent.value?.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHTMLContent();
    })
    .join(' ');
  courseStore
    .addVocabListFromExtractions(section.value.id, textToExtractFrom)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      fetchCompleted.value = true;
      await setEditorRefs();
    });
};

const addEmptyVocabList = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  fetchCompleted.value = false;
  courseStore
    .addEmptyVocabList(section.value.id)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      fetchCompleted.value = true;
      await setEditorRefs();
    });
};

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!section.value) {
    return;
  }
  courseStore
    .publishSection(section.value.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Lehreinheit ist jetzt für Kursteilnehmer:innen sichtbar.'
          : 'Lehreinheit ist jetzt vor Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
    });
};

defineExpose({
  titleEditor,
  getLearningObjectives,
  resetUnsavedChangesTextItems,
  resetUnsavedChangesTitle,
  resetUnsavedChangesLearningObjective,
});
</script>

<template>
  <!-- Blog Article -->
  {{ DEBUG ? aboutToDeleteTextItemAtIndex : null }}
  {{ DEBUG ? props.index : null }}
  <div
    class="px-0.5 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
    :style="{ width: props.fullWidth + 'px' }"
    v-if="!fetchCompleted"
  >
    <LoadingSpinnerLarge />
  </div>
  <div
    v-show="!!fetchCompleted"
    :style="{ width: props.fullWidth + 'px' }"
    class="mx-auto transition-all duration-500 ease-in-out pt-6 lg:pt-10 pb-12 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
  >
    <div class="grow transition-all duration-500 ease-in-out">
      <div class="mx-auto flex justify-between items-center mb-6" :style="{ width: outerContentContainerWidth + 'px' }">
        <div class="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div class="grow">
            <div class="flex justify-end items-center gap-x-2">
              <!-- Button Group -->
              <!-- End Button Group -->

              <div class="flex-col flex justify-between items-center gap-x-2 overflow-auto">
                <div class="inline-flex justify-between">
                  <div>
                    <!-- Tooltip -->
                    <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                      <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                        <span>
                          <span class="text-xs text-gray-500"> by </span>
                          <span class="font-semibold text-gray-800 dark:text-gray-200">
                            {{ section.user.academic_title ? mapTitle(section.user.academic_title) + ' ' : ''
                            }}{{ section.user.first_name }}
                            {{ section.user.last_name }}
                          </span>
                        </span>
                        <div class="text-xs text-gray-500">
                          {{ section.user.job_status ? mapJobStatus(section.user.job_status) : '' }}
                        </div>
                        <span
                          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-white font-medium text-xs"
                          role="tooltip"
                        >
                          Author of this section
                          <!-- TODO i18n -->
                          <!-- TODO proper profile card or sth alike -->
                        </span>
                      </div>
                    </div>
                    <!-- End Tooltip -->
                    <ul class="text-xs text-gray-500">
                      <li
                        class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                      >
                        {{ formatDate(section.created_at) }}
                      </li>
                    </ul>
                  </div>
                  <div class="flex-shrink-0">
                    <ProfileImage
                      :image="section.user.userProfileImageSmall"
                      initials=""
                      size="4rem"
                      :showIngameLevel="false"
                    />
                  </div>
                </div>

                <div class="inline-flex w-full justify-between items-center gap-x-2 overflow-auto">
                  <!-- Publish -->
                  <div class="w-full mx-auto flex justify-end items-center gap-x-2 mt-6">
                    <div class="inline-flex justify-between items-center w-full">
                      <div class="font-semibold text-gray-800 dark:text-gray-200">
                        Status
                        <Tooltip
                          message="Klicken zum Veröffentlichen der Lerneinheit.
                            Veröffentlichte Lerneinheiten sind für alle Nutzer:innen sichtbar, die
                            Zugriff auf den entsprechenden Kurs haben - für alle anderen natürlich nicht!"
                        />
                      </div>
                      <div class="group cursor-pointer" @click.prevent="publishSection(!isPublished)">
                        <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="!isPublished">
                          <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />
                        </div>
                        <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="!isPublished">
                          <Badge text="Publish draft" color="blue" :showDot="false" fontSize="text-sm" icon="publish" />
                        </div>
                        <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="isPublished">
                          <Badge text="Lesson is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />
                        </div>
                        <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="isPublished">
                          <Badge
                            text="Hide lesson"
                            color="blue"
                            :showDot="false"
                            :pulse="true"
                            icon="hide_source"
                            fontSize="text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Avatar Media -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div
        class="mx-auto flex space-y-5 transition-all duration-500 ease-in-out md:space-y-8"
        ref="outerContentContainer"
        :style="{ width: outerContentContainerWidth + 'px' }"
      >
        <div
          class="space-y-3"
          :style="{
            gap: `${gap}px`,
          }"
        >
          <h2 class="text-2xl flex-col min-w-full font-bold md:text-3xl dark:text-white text-center">
            <div class="z-10 flex justify-center items-center px-48 text-gray-300">
              <span class="text-gray-300">{{ chapterIndex + 1 }}</span
              ><span class="whitespace-pre text-gray-300 px-0.5">.</span>{{ section.index + 1 }}
            </div>
            <TextEditor
              ref="titleEditor"
              :key="section.id"
              :content="section.title"
              :allowFormatting="false"
              :rawTextMode="true"
              @unsavedChanges="signalUnsavedChangesTitle"
              @changesCleared="signalChangesTitleCleared"
              :width="outerContentContainerWidth + 'px'"
            />
          </h2>

          <SectionContent
            ref="sectionContentComponent"
            :key="section.id"
            :allow-editing="true"
            :chapter-id="chapterId"
            :section="section"
            :outer-width="outerContentContainerWidth"
            :about-to-delete-content-item-with-id="aboutToDeleteContentItemWithId"
            :about-to-delete-text-item-at-index="aboutToDeleteTextItemAtIndex"
            :external-fetch-completed="fetchCompleted"
            :outer-header-height="props.outerHeaderHeight"
            :vocab-list-loading="vocabListLoading"
            @onExtractLearningObjectives="onExtractLearningObjectives"
            @signalUnsavedChangesLearningObjectives="signalUnsavedChangesLearningObjectives"
            @signalUnsavedChangesText="signalUnsavedChangesText"
            @signalChangesTextCleared="signalChangesTextCleared"
            @onDeleteContentItem="
              (contentItemId, content_item_type, textItemIndex) =>
                onDeleteContentItem(contentItemId, content_item_type, textItemIndex)
            "
            @generateAndAddMoreVocab="
              (contentItemId) => {
                generateAndAddMoreVocab(contentItemId);
              }
            "
          />
        </div>
      </div>

      <div
        class="px-1 pt-3 md:px-2 lg:px-8 xl:px-24"
        :style="{
          width: fullWidth + 'px',
        }"
      >
        <div class="bg-gray-100 rounded-lg p-0.5 sm:p-2">
          <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8 mb-3">
            Einen Inhalt hinzufügen
          </p>

          <div class="grid grid-cols-5 sm:gap-2 md:gap-2 lg:gap-2 auto-rows-min">
            <div class="flex-col flex">
              <button
                class="h-full cursor-pointer select-none flex-col flex border-2 border-gray-200 border-dashed bg-white rounded-xl text-center items-center justify-center"
                :disabled="isAddingContentItem"
                @click.prevent="addTextSnippet"
              >
                <span
                  translate="no"
                  class="material-symbols-outlined notranslate text-gray-400 dark:text-gray-500 text-7xl text-center"
                  >description</span
                >
                <span class="pe-1 font-medium text-gray-400 dark:text-neutral-200 text-sm pt-2 pb-1"
                  >Weiteren Textabschnitt</span
                >
                <span
                  class="relative text-sm cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
                  >Erstellen</span
                >
              </button>
              <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Textabschnitt</label>
            </div>

            <div class="flex-col flex">
              <SectionMediaUpload :section-id="section.id" @uploadedItem="triggerSectionRefetch" />
              <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Medieninhalt</label>
            </div>

            <div class="flex-col flex">
              <SectionCreateVocabListAndTest
                ref="createVocabRef"
                :section-id="section.id"
                @createNewPairOfTermsExercise="addPairOfTermsGame"
                @createEmptyVocabList="addEmptyVocabList"
                @createAutomatedVocabListLast="addVocabListExtractingFromLastTextItem"
                @createAutomatedVocabListFull="addVocabListExtractingFromAllTextItems"
              />
              <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Vokabelliste</label>
            </div>

            <div class="flex-col flex">
              <SectionCreateExercise
                :section-id="section.id"
                @createNewPairOfTermsExercise="addPairOfTermsGame"
                :createVocabRef="createVocabRef"
              />

              <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Übung</label>
            </div>

            <div class="flex-col flex">
              <span
                class="h-full cursor-pointer flex-col select-none flex border-2 border-gray-200 border-dashed bg-white rounded-xl text-center items-center justify-center"
                @click.prevent="onAddCase"
              >
                <span
                  translate="no"
                  class="material-symbols-outlined notranslate text-gray-400 dark:text-gray-500 text-7xl text-center"
                  >personal_injury</span
                >
                <span class="pe-1 font-medium text-gray-400 dark:text-neutral-200 text-sm pt-2 pb-1"
                  >Patientenfall, Kollegengespräch, Doku-Task auswählen oder</span
                >
                <span
                  class="relative text-sm cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
                  >Erstellen</span
                >
              </span>
              <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Fall-Simulation</label>
            </div>
          </div>
        </div>
      </div>

      <!-- End content -->
    </div>
  </div>
  <!-- End Blog Article -->

  <!-- Bottom button group (not sticky as in template) -->
  <div class="inset-x-0 text-center justify-center flex gap-x-2 mb-4">
    <button
      class="flex items-center shadow-md text-white bg-red-600 hover:bg-red-700 rounded-full py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="emit('onDeleteSection')"
    >
      <span translate="no" class="material-symbols-outlined notranslate text-2xl -my-1">delete</span>
      {{ $t('message.delete') }}
    </button>
    <button
      class="flex items-center shadow-md rounded-full cursor-pointer text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="onTranslateSection"
      :disabled="sendingTranslationRequest"
    >
      <span translate="no" class="material-symbols-outlined notranslate text-xl" v-show="!sendingTranslationRequest"
        >language</span
      >
      <span
        v-show="sendingTranslationRequest"
        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
      Übersetzung erzeugen
      <!--      <Tooltip text="Der Auftrag wird über Nacht durchgeführt" />-->
      <!-- TODO: i18n -->
    </button>
    <!--    <button-->
    <!--      class="flex items-center shadow-md rounded-full cursor-pointer text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"-->
    <!--      @click="onExtractVocabulary"-->
    <!--      :disabled="sendingVocabularyExtractionRequest"-->
    <!--    >-->
    <!--      <span translate="no" class="material-symbols-outlined notranslate text-xl" v-show="!sendingVocabularyExtractionRequest">dictionary</span>-->
    <!--      <span-->
    <!--        v-show="sendingVocabularyExtractionRequest"-->
    <!--        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"-->
    <!--        role="status"-->
    <!--        aria-label="loading"-->
    <!--      />-->
    <!--      Vokabular extrahieren-->
    <!--      &lt;!&ndash;      <Tooltip text="Der Auftrag wird über Nacht durchgeführt" />&ndash;&gt;-->
    <!--      &lt;!&ndash; TODO: i18n &ndash;&gt;-->
    <!--    </button>-->
    {{ DEBUG ? uuid : null }}
  </div>
  <!-- End Bottom Button Group -->

  <UserConfirmationModal
    ref="userConformationModalHandleDeleteTextItem"
    prompt_message=""
    approve_message="Ja, löschen"
    discard_message="Nein, zurück"
    :overlayId="'confirmDeleteTextItemModal' + uuid + uuidv4()"
    approve_color="bg-red-600 hover:bg-red-700"
    @approved="deleteContentItem"
    @declined="
      () => {
        aboutToDeleteContentItemWithId = null;
        aboutToDeleteTextItemAtIndex = aboutToDeleteTextItemAtIndex.map(() => false);
      }
    "
  >
    Möchten Sie diesen Inhalt wirklich entfernen? Dies kann nicht rückgängig gemacht werden.
  </UserConfirmationModal>

  <UserConfirmationModal
    ref="userConformationModalOverwriteLearningObjectives"
    prompt_message=""
    approve_message="Ja, neu Lernziele generieren und überschreiben"
    discard_message="Nein, zurück"
    :overlayId="'confirmOverwriteLearningObjectives' + uuid"
    @approved="extractLearningObjectives"
  >
    Wenn Sie die Lernziele automatisch extrahieren lassen, werden die bisherigen Lernziele für diesen Abschnitt
    überschrieben. Möchten Sie fortfahren?
  </UserConfirmationModal>

  <!--  <UserConfirmationModal-->
  <!--    ref="userConformationModalOverwriteVocab"-->
  <!--    prompt_message=""-->
  <!--    approve_message="Ja, neu Vokabular extrahieren und überschreiben"-->
  <!--    discard_message="Nein, zurück"-->
  <!--    :overlayId="'confirmOverwriteVocab' + uuid"-->
  <!--    @approved="extractVocabulary"-->
  <!--    @declined=""-->
  <!--  >-->
  <!--    Wenn Sie das Vokabular automatisch extrahieren lassen, wird das aktuelle Vokabular für diesen Abschnitt inklusive-->
  <!--    möglicher Überarbeitungen überschrieben. Möchten Sie fortfahren?-->
  <!--  </UserConfirmationModal>-->

  <SelectCasesModal
    :overlayId="'selectCases' + uuid"
    ref="selectCasesModal"
    closeMessage="Schließen"
    :sectionId="section.id"
    @selected="addCases"
  />
</template>

<style scoped></style>
