<script setup>
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import Tooltip from '@/components/Tooltip.vue';

import ProgressButton from '@/components/ProgressButton.vue';
import { router } from '@/router';
import { storeToRefs } from 'pinia';
import { useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import DOMPurify from 'dompurify';
import { getStreamingClient } from '@/apistreamer/streamingclient';

const patientInteractionStore = usePatientInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const { patientFirstName, patientLastName, patientAge, patientHeight, patientWeight, currentObserverInteractionId } =
  storeToRefs(patientInteractionStore);
const { currentTask } = storeToRefs(caseInteractionStore);

const reportForm = ref(null);
const isLoading = ref(true);
const isLoadingFeedback = ref(false);
const resolvePromise = ref(undefined);
const rejectPromise = ref(undefined);
const heading = 'Bericht';
const submitMessage = 'Bericht absenden';

const refName = ref(null);

const required = [
  'first_name',
  'last_name',
  'age',
  'height',
  'weight',
  'general_anamnesis',
  'medication_anamnesis',
  'allergy_anamnesis',
  'social_anamnesis',
  'substance_anamnesis',
  'proposed_procedure',
];

const firstNameFeedback = ref('');
const lastNameFeedback = ref('');
const ageFeedback = ref('');
const heightFeedback = ref('');
const weightFeedback = ref('');

const generalAnamnesisContentFeedback = ref('');
const generalAnamnesisContentFeedbackLoading = ref(false);
const generalAnamnesisLanguageFeedback = ref('');
const generalAnamnesisLanguageFeedbackLoading = ref(false);
const medicationAnamnesisContentFeedback = ref('');
const medicationAnamnesisContentFeedbackLoading = ref(false);
const medicationAnamnesisLanguageFeedback = ref('');
const medicationAnamnesisLanguageFeedbackLoading = ref(false);
const allergyAnamnesisContentFeedback = ref('');
const allergyAnamnesisContentFeedbackLoading = ref(false);
const allergyAnamnesisLanguageFeedback = ref('');
const allergyAnamnesisLanguageFeedbackLoading = ref(false);
const socialAnamnesisContentFeedback = ref('');
const socialAnamnesisContentFeedbackLoading = ref(false);
const socialAnamnesisLanguageFeedback = ref('');
const socialAnamnesisLanguageFeedbackLoading = ref(false);
const substanceAnamnesisContentFeedback = ref('');
const substanceAnamnesisContentFeedbackLoading = ref(false);
const substanceAnamnesisLanguageFeedback = ref('');
const substanceAnamnesisLanguageFeedbackLoading = ref(false);
const proposedProcedureContentFeedback = ref('');
const proposedProcedureContentFeedbackLoading = ref(false);
const proposedProcedureLanguageFeedback = ref('');
const proposedProcedureLanguageFeedbackLoading = ref(false);

onMounted(async () => {
  await nextTick(async () => {
    await resetValues();
    isLoading.value = false;
  });
});

function getEmptyForm() {
  return {
    first_name: '',
    last_name: '',
    age: '',
    height: '',
    weight: '',
    general_anamnesis: '',
    medication_anamnesis: '',
    allergy_anamnesis: '',
    social_anamnesis: '',
    substance_anamnesis: '',
    proposed_procedure: '',
  };
}

async function resetValues() {
  console.debug('resetValues()');
  const formValues = getEmptyForm();
  reportForm.value.setValues(formValues);
}

async function pauseForMetaChat() {
  console.debug('Initiating meta chat dialog');
  await nextTick(); // Ensure DOM updates are complete
  await new Promise((resolve) => setTimeout(resolve, 200));
  await window.HSOverlay.open(refName.value);
  console.debug('Returning promise');
  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve;
    rejectPromise.value = reject;
  });
}

async function close() {
  console.debug('close()');
  // I do not understand why window.HSOverlay.close is not needed anymore in preline 2.0.x,
  // but the resolvePromise is sufficient. Neither do I understand why the close makes
  // the modal re-open instantly.
  // ** Please leave this comment and the following line for reference! **
  // await window.HSOverlay.close(this.$refs[this.refName]);
  resolvePromise.value = true;
}

function detailsFilled(keys = null) {
  // if keys is null: check if ALL required are provided
  // if keys is not null: check if ANY of the keys provied

  // check if the form has been mounted
  if (!reportForm.value) {
    return null;
  }

  const formValues = reportForm.value.getValues();

  if (!!keys) {
    return !!keys.some((key) => formValues[key]);
  }

  if (!'first_name' in formValues) {
    return null;
  }

  console.debug('formValues: ' + JSON.stringify(formValues));

  return !!required.every((key) => formValues[key]);
}

async function checkFirstName(input) {
  let response;
  if (input === patientFirstName.value) {
    response = '<span class="text-green-500">Gut gemacht!</span>';
  } else {
    response = '<span class="text-red-500">Korrektur: ' + patientFirstName.value + '</span>';
  }
  return DOMPurify.sanitize(response);
}

async function checkLastName(input) {
  let response;
  if (input === patientLastName.value) {
    response = '<span class="text-green-500">Gut gemacht!</span>';
  } else {
    response = '<span class="text-red-500">Korrektur: ' + patientLastName.value + '</span>';
  }
  return DOMPurify.sanitize(response);
}

async function checkAge(input) {
  let response;
  if (!patientAge.value) {
    return '';
  }
  if (Math.round(input) === Math.round(patientAge.value)) {
    response = '<span class="text-green-500">Gut gemacht!</span>';
  } else {
    response = '<span class="text-gray-500">' + patientAge.value + ' Jahre</span>';
  }
  return DOMPurify.sanitize(response);
}

async function checkHeight(input) {
  let response;
  if (!patientHeight.value) {
    return '';
  }
  if (Math.round(input) === Math.round(patientHeight.value)) {
    response = '<span class="text-green-500">Gut gemacht!</span>';
  } else {
    response = '<span class="text-gray-500">' + patientHeight.value + ' cm</span>';
  }
  return DOMPurify.sanitize(response);
}

async function checkWeight(input) {
  let response;
  if (!patientWeight.value) {
    return '';
  }
  if (Math.round(input) === Math.round(patientWeight.value)) {
    response = '<span class="text-green-500">Gut gemacht!</span>';
  } else {
    response = '<span class="text-gray-500">' + patientWeight.value + ' kg</span>';
  }
  return DOMPurify.sanitize(response);
}

function appendToGeneralAnamnesisContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    generalAnamnesisContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    generalAnamnesisContentFeedbackLoading.value = false;
  }
}

async function checkGeneralAnamnesisContent(input) {
  generalAnamnesisContentFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_GENERAL_ANAMNESIS_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToGeneralAnamnesisContentFeedback,
  );
}

function appendToMedicationAnamnesisContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    medicationAnamnesisContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    medicationAnamnesisContentFeedbackLoading.value = false;
  }
}

async function checkMedicationAnamnesisContent(input) {
  medicationAnamnesisContentFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_MEDICATION_ANAMNESIS_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToMedicationAnamnesisContentFeedback,
  );
}

function appendToAllergyAnamnesisContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    allergyAnamnesisContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    allergyAnamnesisContentFeedbackLoading.value = false;
  }
}

async function checkAllergyAnamnesisContent(input) {
  allergyAnamnesisContentFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_ALLERGY_ANAMNESIS_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToAllergyAnamnesisContentFeedback,
  );
}

function appendToSocialAnamnesisContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    socialAnamnesisContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    socialAnamnesisContentFeedbackLoading.value = false;
  }
}

async function checkSocialAnamnesisContent(input) {
  socialAnamnesisContentFeedbackLoading.value = true;
  console.log('currentTask.value', currentTask.value);
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_SOCIAL_ANAMNESIS_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToSocialAnamnesisContentFeedback,
  );
}

function appendToSubstanceAnamnesisContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    substanceAnamnesisContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    substanceAnamnesisContentFeedbackLoading.value = false;
  }
}

async function checkSubstanceAnamnesisContent(input) {
  substanceAnamnesisContentFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_SUBSTANCE_ANAMNESIS_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToSubstanceAnamnesisContentFeedback,
  );
}

function appendToProposedProcedureContentFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    proposedProcedureContentFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    proposedProcedureContentFeedbackLoading.value = false;
  }
}

async function checkProposedProcedureContent(input) {
  proposedProcedureContentFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_PROPOSED_PROCEDURE_CONTENTS',
      },
      current_task: currentTask.value,
    },
    appendToProposedProcedureContentFeedback,
  );
}

function appendToGeneralAnamnesisLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    generalAnamnesisLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    generalAnamnesisLanguageFeedbackLoading.value = false;
  }
}

async function checkGeneralAnamnesisLanguage(input) {
  generalAnamnesisLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_GENERAL_ANAMNESIS_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToGeneralAnamnesisLanguageFeedback,
  );
}

function appendToMedicationAnamnesisLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    medicationAnamnesisLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    medicationAnamnesisLanguageFeedbackLoading.value = false;
  }
}

async function checkMedicationAnamnesisLanguage(input) {
  medicationAnamnesisLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_MEDICATION_ANAMNESIS_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToMedicationAnamnesisLanguageFeedback,
  );
}

function appendToAllergyAnamnesisLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    allergyAnamnesisLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    allergyAnamnesisLanguageFeedbackLoading.value = false;
  }
}

async function checkAllergyAnamnesisLanguage(input) {
  allergyAnamnesisLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_ALLERGY_ANAMNESIS_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToAllergyAnamnesisLanguageFeedback,
  );
}

function appendToSocialAnamnesisLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    socialAnamnesisLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    socialAnamnesisLanguageFeedbackLoading.value = false;
  }
}

async function checkSocialAnamnesisLanguage(input) {
  socialAnamnesisLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_SOCIAL_ANAMNESIS_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToSocialAnamnesisLanguageFeedback,
  );
}

function appendToSubstanceAnamnesisLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    substanceAnamnesisLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    substanceAnamnesisLanguageFeedbackLoading.value = false;
  }
}

async function checkSubstanceAnamnesisLanguage(input) {
  substanceAnamnesisLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_SUBSTANCE_ANAMNESIS_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToSubstanceAnamnesisLanguageFeedback,
  );
}

function appendToProposedProcedureLanguageFeedback(chunk) {
  let chunk_ = JSON.parse(chunk);
  if (chunk_['type'] === 'message') {
    proposedProcedureLanguageFeedback.value += chunk_['content'];
  } else if (chunk_.type === 'id') {
    proposedProcedureLanguageFeedbackLoading.value = false;
  }
}

async function checkProposedProcedureLanguage(input) {
  proposedProcedureLanguageFeedbackLoading.value = true;
  await (
    await getStreamingClient()
  ).streamFetchRequest(
    'POST',
    '/observer-interactions/' + currentObserverInteractionId.value,
    {
      type: 'OBSERVER_REACT',
      content: input,
      event: {
        type: 'SCORE_PROPOSED_PROCEDURE_LANGUAGE',
      },
      current_task: currentTask.value,
    },
    appendToProposedProcedureLanguageFeedback,
  );
}

async function handleSubmit() {
  console.debug('handleSubmit()');
  if (isLoadingFeedback.value) {
    return;
  }
  isLoadingFeedback.value = true;
  const submission = reportForm.value.getValues();

  firstNameFeedback.value = await checkFirstName(submission.first_name);
  lastNameFeedback.value = await checkLastName(submission.last_name);
  ageFeedback.value = await checkAge(submission.age);
  heightFeedback.value = await checkHeight(submission.height);
  weightFeedback.value = await checkWeight(submission.weight);

  await Promise.all([
    checkGeneralAnamnesisContent(submission.general_anamnesis),
    checkMedicationAnamnesisContent(submission.medication_anamnesis),
    checkAllergyAnamnesisContent(submission.allergy_anamnesis),
    checkSocialAnamnesisContent(submission.social_anamnesis),
    checkSubstanceAnamnesisContent(submission.substance_anamnesis),
    checkProposedProcedureContent(submission.proposed_procedure),
    checkGeneralAnamnesisLanguage(submission.general_anamnesis),
    checkMedicationAnamnesisLanguage(submission.medication_anamnesis),
    checkAllergyAnamnesisLanguage(submission.allergy_anamnesis),
    checkSocialAnamnesisLanguage(submission.social_anamnesis),
    checkSubstanceAnamnesisLanguage(submission.substance_anamnesis),
    checkProposedProcedureLanguage(submission.proposed_procedure),
  ]);

  isLoadingFeedback.value = false;
}

defineExpose({
  pauseForMetaChat,
});
</script>

<template>
  <!-- modal contents -->
  <div class="w-full">
    <Form ref="reportForm" @submit="handleSubmit" v-slot="{ values, errors }">
      <div class="grid w-full grid-cols-12 justify-left space-x-2">
        <div />
        <div class="sm:col-span-12 mb-2 inline-block text-sm mt-2.5 font-bold">Stammdaten Patient</div>
        <!-- row -->
        <div class="col-span-3 mb-2">
          <label
            v-show="detailsFilled(['first_name', 'last_name'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            {{ detailsFilled(['first_name']) ? 'Vorname' : '' }}
          </label>
          <Field v-slot="{ field, errors, value }" name="first_name" rules="max:50">
            <textarea
              v-bind="field"
              name="first_name"
              class="resize-none px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="1"
              placeholder="Vorname"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
              @input="firstNameFeedback = ''"
            />
            <ErrorMessage class="text-sm text-red-600" name="first_name" />
            <div v-if="!!firstNameFeedback" class="text-sm" v-html="firstNameFeedback" />
          </Field>
        </div>
        <div class="col-span-3 mb-2">
          <label
            v-show="detailsFilled(['first_name', 'last_name'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            {{ detailsFilled(['last_name']) ? 'Nachname' : '' }}
          </label>
          <Field v-slot="{ field, errors, value }" name="last_name" rules="max:50">
            <textarea
              v-bind="field"
              name="last_name"
              class="resize-none px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="1"
              placeholder="Nachname"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
              @input="lastNameFeedback = ''"
            />
            <ErrorMessage class="text-sm text-red-600" name="last_name" />
            <div v-if="!!lastNameFeedback" class="text-sm" v-html="lastNameFeedback" />
          </Field>
        </div>
        <div class="col-span-6" />
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-2 mb-2">
          <label
            v-show="detailsFilled(['age', 'height', 'weight'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            {{ detailsFilled(['age']) ? 'Alter' : '' }}
          </label>
          <Field v-slot="{ field, errors, value }" name="age" rules="max_value:120|min_value:1|numeric">
            <textarea
              v-bind="field"
              name="age"
              class="resize-none px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="1"
              placeholder="Alter (Jahre)"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
              @input="ageFeedback = ''"
            />
            <ErrorMessage class="text-sm text-red-600" name="age" />
            <div v-if="!!ageFeedback" class="text-sm" v-html="ageFeedback" />
          </Field>
        </div>
        <div class="col-span-2 mb-2">
          <label
            v-show="detailsFilled(['age', 'height', 'weight'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            {{ detailsFilled(['height']) ? 'Größe' : '' }}
          </label>
          <Field v-slot="{ field, errors, value }" name="height" rules="max_value:260|min_value:1|numeric">
            <textarea
              v-bind="field"
              name="height"
              class="resize-none px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="1"
              placeholder="Größe (cm)"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
              @input="heightFeedback = ''"
            />
            <ErrorMessage class="text-sm text-red-600" name="height" />
            <div v-if="!!heightFeedback" class="text-sm" v-html="heightFeedback" />
          </Field>
        </div>
        <div class="col-span-2 mb-2">
          <label
            v-show="detailsFilled(['age', 'height', 'weight'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            {{ detailsFilled(['weight']) ? 'Gewicht' : '' }}
          </label>
          <Field v-slot="{ field, errors, value }" name="weight" rules="max_value:500|min_value:1|numeric">
            <textarea
              v-bind="field"
              name="weight"
              class="resize-none px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="1"
              placeholder="Gewicht (kg)"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
              @input="weightFeedback = ''"
            />
            <ErrorMessage class="text-sm text-red-600" name="weight" />
            <div v-if="!!weightFeedback" class="text-sm" v-html="weightFeedback" />
          </Field>
        </div>
        <div class="col-span-6 mb-2" />
        <!-- end of row -->

        <div class="sm:col-span-12 inline-block text-sm font-bold">Anamnese</div>
        <!-- row -->
        <div class="col-span-12">
          <label
            v-show="detailsFilled(['general_anamnesis'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Allgemeine Anamnese
          </label>
          <Field v-slot="{ field, errors, value }" name="general_anamnesis" rules="max:2500">
            <textarea
              v-bind="field"
              name="general_anamnesis"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="20"
              placeholder="Allgemeine Anamnese (bitte in ganzen Sätzen)"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="general_anamnesis" />
            <div
              v-if="!!generalAnamnesisContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="generalAnamnesisContentFeedback"
            />
            <div
              v-if="!!generalAnamnesisLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="generalAnamnesisLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-12 mt-2">
          <label
            v-show="detailsFilled(['medication_anamnesis'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Medikamentenanamnese
          </label>
          <Field v-slot="{ field, errors, value }" name="medication_anamnesis" rules="max:400">
            <textarea
              v-bind="field"
              name="medication_anamnesis"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="2"
              placeholder="Aktuelle Medikation"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="medication_anamnesis" />
            <div
              v-if="!!medicationAnamnesisContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="medicationAnamnesisContentFeedback"
            />
            <div
              v-if="!!medicationAnamnesisLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="medicationAnamnesisLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-12 mt-2">
          <label
            v-show="detailsFilled(['allergy_anamnesis'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Allergien
          </label>
          <Field v-slot="{ field, errors, value }" name="allergy_anamnesis" rules="max:400">
            <textarea
              v-bind="field"
              name="allergy_anamnesis"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="2"
              placeholder="Allergien"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="allergy_anamnesis" />
            <div
              v-if="!!allergyAnamnesisContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="allergyAnamnesisContentFeedback"
            />
            <div
              v-if="!!allergyAnamnesisLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="allergyAnamnesisLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-12 mt-2">
          <label
            v-show="detailsFilled(['social_anamnesis'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Sozialanamnese, berufliche Expositionen
          </label>
          <Field v-slot="{ field, errors, value }" name="social_anamnesis" rules="max:400">
            <textarea
              v-bind="field"
              name="social_anamnesis"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="2"
              placeholder="Sozialanamnese inkl. häuslicher Versorgung, berufliche Expositionen"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="social_anamnesis" />
            <div
              v-if="!!socialAnamnesisContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="socialAnamnesisContentFeedback"
            />
            <div
              v-if="!!socialAnamnesisLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="socialAnamnesisLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-12 mt-2">
          <label
            v-show="detailsFilled(['substance_anamnesis'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Risikoanamnese, Genussmittel, Drogen
          </label>
          <Field v-slot="{ field, errors, value }" name="substance_anamnesis" rules="max:400">
            <textarea
              v-bind="field"
              name="substance_anamnesis"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="2"
              placeholder="Risikoanamnese inkl. Genussmitteln, Drogen"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="substance_anamnesis" />
            <div
              v-if="!!substanceAnamnesisContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="substanceAnamnesisContentFeedback"
            />
            <div
              v-if="!!substanceAnamnesisLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="substanceAnamnesisLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->

        <!-- row -->
        <div class="col-span-12 mt-2">
          <label
            v-show="detailsFilled(['proposed_procedure'])"
            for="af-account-full-name"
            class="inline-block text-xs text-gray-800 dark:text-gray-200"
          >
            Verdachtsdiagnose und weiteres Vorgehen
          </label>
          <Field v-slot="{ field, errors, value }" name="proposed_procedure" rules="max:2500">
            <textarea
              v-bind="field"
              name="proposed_procedure"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              rows="20"
              placeholder="Verdachtsdiagnose und Vorschläge fürs weitere Procedere inkl. Diagnostik und ggf. Therapie"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
              }"
            />
            <ErrorMessage class="text-sm text-red-600" name="proposed_procedure" />
            <div
              v-if="!!proposedProcedureContentFeedback"
              class="text-sm mb-1 text-gray-500"
              v-html="proposedProcedureContentFeedback"
            />
            <div
              v-if="!!proposedProcedureLanguageFeedback"
              class="text-sm text-gray-500"
              v-html="proposedProcedureLanguageFeedback"
            />
          </Field>
        </div>
        <!-- end of row -->
      </div>

      <!-- modal footer buttons -->
      <div
        class="disabled:pointer-events-none flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700"
      >
        <!-- Submit button -->
        <div>
          <ProgressButton
            :showProgress="isLoadingFeedback"
            :onclick="handleSubmit"
            :disabled="detailsFilled() === false"
            color="blue"
            type="submit"
            :text="submitMessage"
          />
        </div>
        <!-- End of submit button -->
      </div>
      <!-- end of modal footer buttons -->
    </Form>
  </div>
  <!-- end of modal contents -->
</template>
