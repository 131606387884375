<script setup lang="ts"></script>

<template>
  <div class="flex justify-center items-center">
    <div
      class="animate-spin inline-block mt-6 md:mt-0 w-20 h-20 md:w-32 md:h-32 border-2 md:border-4 border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    />
  </div>
</template>

<style scoped></style>
