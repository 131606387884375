/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileImage } from '../models/ProfileImage';
import type { ThirdPerson } from '../models/ThirdPerson';
import type { ThirdPersonDetails } from '../models/ThirdPersonDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ThirdPersonsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Third Persons For Case
   * @param caseId
   * @returns ThirdPerson Successful Response
   * @throws ApiError
   */
  public listThirdPersonsForCase(caseId: string): CancelablePromise<Array<ThirdPerson>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/third-persons/',
      query: {
        case_id: caseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Third Person
   * @param thirdPersonId
   * @returns ThirdPerson Successful Response
   * @throws ApiError
   */
  public getThirdPerson(thirdPersonId: string): CancelablePromise<ThirdPerson> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/third-persons/{third_person_id}',
      path: {
        third_person_id: thirdPersonId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Generate Third Person From Freehand Description And Other Details
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public generateThirdPersonFromFreehandDescriptionAndOtherDetails(
    requestBody: ThirdPersonDetails,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/third-persons/freehand',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create New Third Person Profile Image
   * @param thirdPersonType
   * @param requestBody
   * @returns ProfileImage Successful Response
   * @throws ApiError
   */
  public createNewThirdPersonProfileImage(
    thirdPersonType: string,
    requestBody: ThirdPersonDetails,
  ): CancelablePromise<ProfileImage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/third-persons/profile-image',
      query: {
        third_person_type: thirdPersonType,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
