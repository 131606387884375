/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExperiencePointsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Experience Points For Events
   * @returns any Successful Response
   * @throws ApiError
   */
  public getExperiencePointsForEvents(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/experience-points/',
    });
  }
}
