import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import VueSplide from '@splidejs/vue-splide';
import { router } from './router';
import { defineRule, configure } from 'vee-validate';
import { createI18n } from 'vue-i18n';
import { languages, defaultLocale, fallbackLocale } from '@/i18n';
import Vue3TouchEvents, { type Vue3TouchEventsOptions } from 'vue3-touch-events';

import { mountTrackerMixin } from '@/helper';

import {
  required,
  email,
  confirmed,
  max,
  alpha_dash,
  alpha_spaces,
  max_value,
  min_value,
  numeric,
  is,
} from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import { useAuthStore } from '@/stores/auth.store';
import * as Sentry from '@sentry/vue';

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: fallbackLocale,
  messages: Object.assign(languages),
});

import './assets/main.css';
import 'preline/preline';
// import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp(App);
app
  .mixin(mountTrackerMixin)
  .use(createPinia())
  .use(router)
  .use(VueSplide)
  .use(i18n)
  .use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
    disableClick: false,
    // any other global options...
  });

console.log('Environment:', import.meta.env);

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.casuu\.care/,
      /^https:\/\/api\.casuu\.health/,
      /^https:\/\/casuu\.care/,
      /^https:\/\/casuu\.health/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount('#app');

// i18n and stores are loaded, now we can set the user's language
const authStore = useAuthStore();
i18n.global.locale.value = authStore.userNativeLanguage || defaultLocale;

// define vee-validate rules globally
defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('max', max);
defineRule('alpha_dash', alpha_dash);
defineRule('alpha_spaces', alpha_spaces);
defineRule('max_value', max_value);
defineRule('min_value', min_value);
defineRule('numeric', numeric);
defineRule('is', is);

// minimum 8 and maximum 72 characters
// at least one uppercase letter, one lowercase letter, one number and one special character
defineRule('password', (value) => {
  if (!value) {
    return 'This field is required';
  }
  if (value.length < 8) {
    return 'Das Passwort muss mindestens 8 Zeichen lang sein';
  }
  if (value.length > 72) {
    return 'Das Passwort darf maximal 72 Zeichen lang sein';
  }
  if (!/[A-Z]/.test(value)) {
    return 'Dein Passwort muss mindestens einen Großbuchstaben enthalten';
  }
  if (!/[a-z]/.test(value)) {
    return 'Dein Passwort muss mindestens einen Kleinbuchstaben enthalten';
  }
  if (!/[0-9]/.test(value)) {
    return 'Dein Passwort muss mindestens eine Zahl enthalten';
  }
  return true;
});

configure({
  generateMessage: localize({
    deu: {
      messages: {
        _default: '{field} ist ungültig',
        alpha: 'Darf nur alphabetische Zeichen enthalten',
        alpha_dash: '{field} darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten',
        alpha_num: 'Darf nur alphanumerische Zeichen enthalten',
        alpha_spaces: 'Darf nur alphanumerische Zeichen und Leerzeichen enthalten',
        between: '{field} muss zwischen 0:{min} und 1:{max} liegen',
        confirmed: 'Die Bestätigung stimmt nicht überein',
        digits: '{field} muss numerisch sein und exakt 0:{length} Ziffern enthalten',
        dimensions: '{field} muss 0:{width} x 1:{height} Bildpunkte groß sein',
        email: 'Bitte gib eine gültige E-Mail-Adresse ein',
        not_one_of: '{field} muss ein gültiger Wert sein',
        ext: '{field} muss eine gültige Datei sein',
        image: '{field} muss eine Grafik sein',
        one_of: '{field} muss ein gültiger Wert sein',
        integer: '{field} muss eine ganze Zahl sein',
        length: 'Die Länge von {field} muss 0:{length} sein',
        max: 'Darf nicht länger als 0:{length} Zeichen sein',
        max_value: 'Darf maximal 0:{max} betragen',
        min_value: 'Muss mindestens 0:{min} betragen',
        mimes: '{field} muss einen gültigen Dateityp haben',
        min: '{field} muss mindestens 0:{length} Zeichen lang sein',
        numeric: 'Darf nur Zahlenwerte enthalten',
        regex: 'Das Format von {field} ist ungültig',
        required: 'Dies ist ein Pflichtfeld',
        required_if: '{field} ist ein Pflichtfeld',
        size: '{field} muss kleiner als 0:{size}KB sein',
        url: '{field} ist keine gültige URL',
      },
    },
  }),
});
setLocale('deu');
