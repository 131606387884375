// mountTrackerMixin.js
import { reactive } from 'vue';

const mountState = reactive({
  mountedCount: 0,
  totalComponents: 0,
  allMounted: false,
  portionMounted: 0,
});

const mountTrackerMixin = {
  beforeCreate() {
    if (this.$options.isTrackedComponent !== false) {
      mountState.totalComponents += 1;
      mountState.portionMounted =
        mountState.totalComponents > 0 ? mountState.mountedCount / mountState.totalComponents : 0;
    }
  },
  mounted() {
    if (this.$options.isTrackedComponent !== false) {
      mountState.mountedCount += 1;
      if (mountState.mountedCount === mountState.totalComponents) {
        mountState.allMounted = true;
      }
      mountState.portionMounted =
        mountState.totalComponents > 0 ? mountState.mountedCount / mountState.totalComponents : 0;
    }
  },
  beforeUnmount() {
    if (this.$options.isTrackedComponent !== false) {
      mountState.mountedCount -= 1;
      if (mountState.mountedCount < mountState.totalComponents) {
        mountState.allMounted = false;
      }
      mountState.portionMounted =
        mountState.totalComponents > 0 ? mountState.mountedCount / mountState.totalComponents : 0;
    }
  },
};

export { mountState, mountTrackerMixin };
