<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();

const isSubmitting = ref(false);
const acceptedTerms = ref(false);

const email = ref('');
const password = ref('');
const passwordConfirm = ref('');

const token = ref('');
onMounted(() => {
  // get query param token
  const urlParams = new URLSearchParams(window.location.search);
  token.value = urlParams.get('token');
});

async function handleSignUpConfirmation() {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }

  if (!token.value) {
    alertStore.error('status.error.invalidCode');
    return;
  }

  isSubmitting.value = true;

  const client = await getApiClient();

  client.authentication
    .confirmSignUp(token.value)
    .then(async () => {
      isSubmitting.value = false;
      await router.push('/account/sign-in');
      alertStore.success('status.success.emailConfirmed');
    })
    .catch(async (error) => {
      isSubmitting.value = false;
      // await router.push('/');
      alertStore.error('status.error.emailConfirmationError');
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}
</script>

<template>
  <main class="w-full h-screen max-w-md mx-auto p-6">
    <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700">
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.emailConfirmation') }}</h1>
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.emailConfirmationDetailed') }}
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <form @submit.prevent="handleSignUpConfirmation">
            <div class="grid gap-y-4">
              <button
                type="submit"
                class="py-3 px-4 justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
              >
                <div
                  v-if="isSubmitting"
                  class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span class="sr-only">{{ $t('message.loading') }}</span>
                </div>
                <p v-else>{{ $t('message.confirm') }}</p>
              </button>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
