<script setup lang="ts">
import { computed, onMounted, ref, watch, nextTick } from 'vue';

const props = defineProps({
  item: { type: Object, required: true },
  isInCategory: { type: Boolean, default: false },
  isIncorrect: { type: Boolean, default: false },
});

const draggableItem = ref(null);
const currentPosition = ref({ x: 0, y: 0 });
const initialPosition = ref({ x: 0, y: 0 });
const offset = ref({ x: 0, y: 0 });
const isDragging = ref(false);

const itemClasses = computed(() => ({
  'bg-red-50 border-red-500 text-red-500': props.isIncorrect, // Red background for incorrect drop
  'bg-white': !props.isIncorrect && !props.isInCategory, // Default background in waiting zone
  'bg-teal-50 border-teal-500 text-teal-500': props.isInCategory, // Green background if correctly placed
}));

const handleDragStart = (event) => {
  isDragging.value = true;
  event.dataTransfer.setData('itemId', props.item.id);

  // handle the offset
  const rect = draggableItem.value.getBoundingClientRect();
  offset.value = {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

const trackCurrentPosition = (event) => {
  // Track the current position of the cursor
  currentPosition.value = {
    x: event.clientX,
    y: event.clientY,
  };
};

onMounted(() => {
  // Get the initial position of the draggable item
  const rect = draggableItem.value.getBoundingClientRect();
  initialPosition.value = {
    x: rect.left,
    y: rect.top,
  };
});
</script>

<template>
  <div
    ref="draggableItem"
    :class="[
      'p-2 border rounded cursor-move shadow-sm hover:shadow-md focus:shadow-md text-center w-32 transition-transform transform duration-1000',
      itemClasses,
      { 'animate-bounce-back': props.isIncorrect },
    ]"
    :style="{
      left: currentPosition.x - offset.x + 'px',
      top: currentPosition.y - offset.y + 'px',
      opacity: isDragging ? 0.4 : 1,
    }"
    draggable="true"
    @dragstart="handleDragStart"
    @drag.prevent="trackCurrentPosition"
    @dragend.prevent="isDragging = false"
    style="{ position: fixed }"
  >
    {{ item.content }}
  </div>
</template>

<style scoped>
@keyframes bounce-back {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-bounce-back {
  animation: bounce-back 0.5s ease;
}
</style>
