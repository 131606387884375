/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OralExaminer } from '../models/OralExaminer';
import type { OralExaminerCreate } from '../models/OralExaminerCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OralExaminersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Oral Examiner
   * @returns OralExaminer Successful Response
   * @throws ApiError
   */
  public listOralExaminer(): CancelablePromise<Array<OralExaminer>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/oral-examiners/',
    });
  }

  /**
   * Create Oral Examiner
   * @param requestBody
   * @returns OralExaminer Successful Response
   * @throws ApiError
   */
  public createOralExaminer(requestBody: OralExaminerCreate): CancelablePromise<OralExaminer> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/oral-examiners/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Oral Examiner
   * @param examinerId
   * @returns OralExaminer Successful Response
   * @throws ApiError
   */
  public getOralExaminer(examinerId: string): CancelablePromise<OralExaminer> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/oral-examiners/{examiner_id}',
      path: {
        examiner_id: examinerId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
