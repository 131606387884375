import { defineStore } from 'pinia';
import { getApiClient } from '@/apiclient/client';

export const useCountryStore = defineStore({
  id: 'country',
  state: () => ({
    countries: [],
  }),
  getters: {},
  actions: {
    async loadCountries() {
      const client = await getApiClient();
      this.countries = await client.countries
        .getAvailableCountries()
        .then((countries) => {
          return countries;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    getLanguageNameFromCode(code) {
      const countries = this.countries.find((countries) => countries.iso_alpha_2 === code);
      return countries ? countries.iso_name : code;
    },
  },
});
