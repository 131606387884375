import { useAuthStore } from '@/stores';
import { joinURL } from '@/helper';

class StreamingClient {
  private readonly authToken: string;
  public readonly baseUrl = import.meta.env.VITE_REST_API_BASE_URL;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  private joinURL(baseURL: string, path: string) {
    return joinURL(baseURL, path);
  }

  async streamFetchRequest(method: string, url: string, requestData: any, callable: (chunk: string) => void) {
    const full_url = this.joinURL(import.meta.env.VITE_REST_API_BASE_URL, url);
    console.debug('Fetching from ' + full_url);
    try {
      let response;
      if (requestData) {
        console.debug('Request data: ' + JSON.stringify(requestData));
        response = await fetch(full_url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`,
          },
          body: JSON.stringify(requestData),
        });
      } else {
        // e.g. GET (which should not have a body)
        response = await fetch(full_url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`,
          },
        });
      }

      const reader = response.body.getReader();
      let buffer = '';
      const streamReader = () => {
        reader.read().then(({ value, done }) => {
          if (done) {
            console.log('Stream ended');
            return;
          }
          // console.debug('Appending to buffer "' + new TextDecoder().decode(value));
          buffer += new TextDecoder().decode(value);
          while (buffer.includes('\r\n')) {
            const indexOfMessageEnd = buffer.indexOf('\r\n');
            const chunk = buffer.slice(0, indexOfMessageEnd);
            buffer = buffer.slice(indexOfMessageEnd + 2);
            // console.debug('Chunk: ' + chunk);
            callable(chunk);
          }
          streamReader(); // Continue reading next chunk
        });
      };

      streamReader();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  public createWebSocket(url: string, provider: string, model: string, voiceId: string) {
    let url_ = this.joinURL(import.meta.env.VITE_REST_API_WS_URL, url);
    if (!url_.endsWith('/')) {
      console.warn('URL does not end with trailing /. Note that there is no default redirect in WS');
    }
    url_ = this.joinURL(url_, provider);
    url_ = this.joinURL(url_, model);
    url_ = this.joinURL(url_, voiceId);

    console.debug('Creating Websocket at ' + url_);
    return new WebSocket(url_);
  }
}

const getStreamingClient = async () => {
  const authStore = useAuthStore();
  // this call will also try to refresh the token if it is expired
  await authStore.getValidatedToken();
  // TODO: ProxyObejct is returned here, not the actual token
  // workaround: use token from storage
  const token = localStorage.getItem('token');
  const accessToken = token ? JSON.parse(token)['access_token'] : null;
  return new StreamingClient(accessToken);
};

export { getStreamingClient };
