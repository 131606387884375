/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CountriesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Available Countries
   * @returns Country Successful Response
   * @throws ApiError
   */
  public getAvailableCountries(): CancelablePromise<Array<Country>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/countries/',
    });
  }
}
